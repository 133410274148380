
import React, { useState, useEffect } from 'react';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';
import { urlServidor } from '../../helpers';
import { getAllSliderPublic } from '../../querys/Slider/metodos';


function SliderPublico() {

    const [carrusel, setCarrusel] = useState(null);
    const [actualizar, setActualizar] = useState(false);

    function ftEffect() {
        async function effect() {
            try {
                let slider = await getAllSliderPublic({ estatus: true,dominio: window.location.hostname });
                if (Array.isArray(slider) && slider.length === 0) throw new Error("Sin información");
                setCarrusel(slider.map(({ title, imagen, ...rest }) => ({
                    ...rest,
                    leyenda: title,
                    imagen: `${urlServidor}${imagen}`
                })));

            } catch ({ message }) {
                console.error(message);
            }
        } effect();

    }

    useEffect(ftEffect, [actualizar]);
    return (
        <Carousel autoPlay transitionTime={1000} infiniteLoop
        >
            {
                carrusel && carrusel.map(({ imagen, leyenda }, key) => (
                    <div key={key}>
                        <img src={imagen} />
                        {leyenda && <p className="legend">{leyenda}</p>}
                    </div>
                ))
            }

        </Carousel>
    );


}
export default SliderPublico;
