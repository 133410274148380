import React, { useState, useRef, useEffect } from 'react';
import { Switch, Redirect } from 'react-router-dom';
import { Validaciones } from '@renedelangel/helpers';
// master components
import Tabla from '../../_layout/masterComponents/Tabla';
import FormularioModal from '../../_layout/masterComponents/FormularioModal';

// Redux
import { useSelector } from "react-redux";

//generic components
import SweetAlert from '../../_layout/genericComponents/ModalConfirmacion';
import { NetworkError } from "../../_layout/genericComponents/Metodos";

// material-ui icons
import Add from "@material-ui/icons/Add";
import Edit from "@material-ui/icons/Edit";
import DeleteIcon from '@material-ui/icons/Delete';
import SyncIcon from '@material-ui/icons/Sync';
import SaveIcon from '@material-ui/icons/Save';
import ArtTrackIcon from '@material-ui/icons/ArtTrack';
import CloseIcon from '@material-ui/icons/Close';

import { getPost, tablaPost } from '../../querys/Noticias/metodos';
import { addNoticias, updateNoticias } from '../../rest/noticias';
import { deletePost } from '../../mutations/Noticias/metodos';

import { handleFocus, handleErrorInputText, handleModificar, handelAlertEliminar, handleEliminar, handleGuardar, handleErrorGeneric } from '../../_layout/helpers/handles';
import { info, danger, success, primary } from '../../_layout/helpers/colores';

import { urlServidor } from '../../helpers';
import moment from 'moment';


const { trim } = Validaciones;

function Noticias() {


    const { token } = useSelector(state => state.login);

    const cleanState = { postID: null, fechaPublicacion: "", tituloPost: "", contenidoPost: "", creadorID: "", imagen: "" };
    const cleanErrorState = {
        fechaPublicacion: { error: false, helperText: "" },
        tituloPost: { error: false, helperText: "" },
        contenidoPost: { error: false, helperText: "" },
        creadorID: { error: false, helperText: "" },
        imagen: { error: false, helperText: "" }

    }
    const cleanNotificaciones = { mensaje: "", color: null, open: false };


    const [state, setState] = useState(cleanState);
    const [errorState, setErrorState] = useState(cleanErrorState);
    const [notificaciones, setNotificaciones] = useState(cleanNotificaciones);
    const [open, setOpen] = useState(false);
    const [disabled, setDisabled] = useState(false);
    const [alert, setAlert] = useState(null);
    const [loader, setLoader] = useState(false);
    const [actualizar, setActualizar] = useState(false);

    // const [errorToken, setErrorToken] = useState(null);
    // const tokenError = useErrorToken(errorToken);

    let postIDRef = useRef(null);
    let tituloPostRef = useRef(null);
    let contenidoPostRef = useRef(null);


    const acciones = [{
        icono: Edit,
        color: info,
        descripcion: "Modificar",
        parametros: [{ campo: "postID", variable: "post" }],
        disabled: { multiseleccion: true },
        onClick: (parametros) => handleModificar({
            parametros, token, setState, setOpen, setAlert,
            asyncGet: getPost /*setErrorToken*/
        }, ({ imagen }) => setState(state => ({ ...state, urlImagen: `${urlServidor}${imagen}?token=${token}` })))
    }, {
        icono: DeleteIcon,
        color: danger,
        descripcion: "Eliminar",
        parametros: [{ campo: "postID" }, { campo: "fechaPublicacion" }, { campo: "tituloPost" }, { campo: "contenidoPost" }, { campo: "creadorID" }, { campo: "imagen" }],
        disabled: { multiseleccion: true },
        onClick: ({ postID = "seleccionado" }) => handelAlertEliminar({
            setAlert,
            mensaje: `al post ${postID}`,
            onCancel: () => setAlert(null),
            onConfirm: () => handleEliminar({
                token, setAlert, setActualizar, actualizar,
                parametros: { postID }, asyncDelete: deletePost /*setErrorToken*/
            })
        })
    }
    ];

    const botones = [{
        icono: Add,
        color: success,
        descripcion: "Agregar",
        onClick: () => setOpen(true),
        disabled: { multiseleccion: true }
    }, {
        icono: SyncIcon,
        color: info,
        descripcion: "Actualizar",
        onClick: () => setActualizar(!actualizar),
        disabled: { multiseleccion: true }
    }];

    const infoTabla = {
        botones,
        acciones,
        actualizar,
        id: "postID",
        color: primary,
        title: "Noticias",
        iconTable: <ArtTrackIcon />,
        headers: [
            { variable: "postID", descripcion: "ID" },
            { variable: "fechaPublicacion", descripcion: "Fecha Publicación" },
            { variable: "tituloPost", descripcion: "Título del Post" },
            { variable: "contenidoPost", descripcion: "Contenido del Post" },
            { variable: "creadorID", descripcion: "Creador ID" },
            { variable: "imagen", descripcion: "Imagen" },

        ],
        responsiveTitle: ["postID", "fechaPublicacion", "tituloPost", "contenidoPost", "creadorID", "imagen"],
        filter: [

            { campo: "creadorID" },

        ],

        alineacion: [{ columnas: [0, 6], alineacion: "center" }],
        formato: [{ columnas: ["fechaPublicacion"], tipo: "fecha" },
        { columnas: ["imagen"], tipo: "imagen", url: urlServidor, token }],
    }

    const inputs = [{

        disabled,
        id: "tituloPost",
        value: state.tituloPost,
        error: errorState.tituloPost.error,
        success: state.tituloPost && !errorState.tituloPost.error ? true : undefined,
        helperText: errorState.tituloPost.helperText,
        inputRef: tituloPostRef,
        title: "Título del Post*",
        placeholder: "Capture el título del post",
        onChange: ({ target: { value } }) => handleChange(value, "tituloPost"),
        onKeyDown: (evt) => handleFocus(evt, tituloPostRef)
    },
    {
        disabled,
        id: "contenidoPost",
        value: state.contenidoPost,
        inputRef: contenidoPostRef,
        title: "Contenido de la Noticia*",
        placeholder: "Capture el contenido del post",
        onChange: ({ target: { value } }) => handleChange(value, "contenidoPost"),
        onKeyDown: (evt) => handleFocus(evt, contenidoPostRef)

    },
    {
        disabled,
        id: "imagen",
        value: state.urlImagen,
        title: "Portada del post*",
        tipo: "imageupload",
        onChange: handleSetImagen,
        removeImage: state.imagen
    }];

    const accionesFormulario = [{
        loader,
        disabled,
        icono: SaveIcon,
        color: info,
        descripcion: "Guardar",
        onClick: () => handleGuardar({
            setLoader,
            setDisabled,
            setActualizar,
            actualizar,
            setNotificaciones,
            handleClose,
            handleFocus,
            refFocus: postIDRef,
            mensajeCorrecto: `El post se ${state.postID ? "modifico" : "agrego"} correctamente`,
            asyncCallback: ftGuardar,
            /*setErrorToken*/
        }),
        // inputRef: accederRef,
    }, {
        disabled,
        icono: CloseIcon,
        color: danger,
        descripcion: "Cancelar",
        onClick: handleClose
    }];

    function ftErrorInputText({ condicion, ref, keyError, mensajeError }) {
        return handleErrorInputText({
            cleanErrorState, condicion, ref, keyError, mensajeError,
            loader: setLoader, disabled: setDisabled, errorState: setErrorState
        });
    }

    function handleValidaciones({ postID, tituloPost, contenidoPost, imagen }) {

        let error;
        let validaciones = [{
            condicion: !tituloPost || trim(tituloPost) === "",
            ref: tituloPostRef, keyError: "tituloPost",
            mensajeError: "Para que podamos funcionar bien necesitamos que le definas un título al post"
        },
        {
            condicion: !contenidoPost || trim(contenidoPost) === "",
            ref: contenidoPostRef, keyError: "contenidoPost",
            mensajeError: "Para que podamos funcionar bien necesitamos un contenido en el post"
        }];

        validaciones.forEach(({ condicion, ref, keyError, mensajeError }) => {
            if (error) return;
            error = ftErrorInputText({ condicion, ref, keyError, mensajeError });
        });

        if (error) return error;

        handleErrorGeneric({
            condicion: !postID && !imagen,
            mensaje: "Es necesario subir una imagen"
        });

    }

    function ftGuardar() {
        async function ftGuardar() {

            let { postID, fechaPublicacion, tituloPost, contenidoPost, creadorID, imagen } = state;

            let error = await handleValidaciones({ postID, fechaPublicacion, tituloPost, contenidoPost, creadorID, imagen });

            if (error) return error;

            if (postID) await updateNoticias({
                data:
                    { postID, fechaPublicacion: trim(fechaPublicacion), tituloPost, contenidoPost, creadorID, imagen }, token
            });
            else await addNoticias({
                data:
                    { fechaPublicacion: trim(fechaPublicacion), tituloPost, contenidoPost, creadorID, imagen }, token
            });

        }
        return ftGuardar();
    }

    function handleClose() {
        setOpen(false);
        setDisabled(false);
        setLoader(false);
        setNotificaciones({ ...cleanNotificaciones });
        setState({ ...cleanState });
        setErrorState({ ...cleanErrorState });
    }

    function handleChange(value, key) { setState({ ...state, [key]: value }); }
    function handleSetImagen(imagen) { setState({ ...state, imagen, urlImagen: null }); }

    function ftEffect() {
        async function effect() {
            try {



            } catch ({ message }) {
                setAlert({
                    descripcion: `Algunos datos necesarios para funcionar no se cargaron correctamente, intenta actualizar la página o verifica tu conexión a internet; a continuación se muestrán más detalles del error: ${NetworkError(message)}`,
                    title: "¡Una disculpa!",
                    tipo: danger,
                    msjConfirmacion: "Aceptar",
                    onConfirm: () => setAlert(null)
                });
                /* setErrorToken(message);*/
            }
        } effect();
    }

    useEffect(ftEffect, [actualizar]);

    return (token ? <>
        <Tabla
            infoTabla={infoTabla}
            asyncData={tablaPost}
            token={token}
        />
        <FormularioModal
            open={open}
            title={`${state.postID ? "Modificar" : "Agregar"} Post`}
            onClose={handleClose}
            notificaciones={notificaciones}
            closeNotification={() => setNotificaciones({ ...cleanNotificaciones })}
            inputs={inputs}
            acciones={accionesFormulario}

        />
        { alert && <SweetAlert
            title={alert.title}
            descripcion={alert.descripcion}
            tipo={alert.tipo}
            msjConfirmacion={alert.msjConfirmacion}
            msjCancelacion={alert.msjCancelacion}
            onConfirm={alert.onConfirm}
            showConfirm={alert.showConfirm}
            showCancel={alert.showCancel}
            onCancel={() => setAlert(null)}
        />}
    </> : <><Switch><Redirect fromn="*" to={"/"} /></Switch></>);

}

export default Noticias;


