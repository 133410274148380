import React, { useEffect } from 'react';
import PropTypes from "prop-types";

// generic components
import Modal from '../../genericComponents/Modal';
import Formulario from '../../genericComponents/Formulario';
import Notificaciones from '../../genericComponents/Notificaciones';

function FormularioModal(props) {

    let { open = false, title = "", onClose, notificaciones = {}, closeNotification, inputs = [], acciones = [], focus, maxWidth, actualizar = false } = props;

    function ftEffect() {
        if(open && focus && focus.current && focus.current.focus) focus.current.focus();
    }

    useEffect(ftEffect, [open], actualizar);

    return(<Modal
        open={open}
        title={title}
        onClose={onClose}
        maxWidth={maxWidth ? 'xl' : false}
    >
        {
            notificaciones.open && <Notificaciones
                close={true}
                message={notificaciones.mensaje}
                color={notificaciones.color}
                open={notificaciones.open}
                closeNotification={closeNotification}
            />
        }
        <Formulario
            header={false}
            inputs={inputs}
            acciones={acciones}
            {...props}
        />
    </Modal>);

}

FormularioModal.propTypes = {
    open: PropTypes.bool,
    title: PropTypes.string,
    onClose: PropTypes.func,
    notificaciones: PropTypes.object,
    closeNotification: PropTypes.func,
    inputs: PropTypes.arrayOf(PropTypes.object),
    acciones: PropTypes.arrayOf(PropTypes.object),
    focus: PropTypes.object
};

export default FormularioModal;
