import React from 'react';

import Principal from "./componentes/Principal";
//import Header from "./componentes/Header";

//Redux
import store from './redux';
import { Provider } from 'react-redux';

function App() {



    return (
        <Provider store={store}>
            <Principal />
        </Provider>
    );
}

export default App;
