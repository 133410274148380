import React from "react";
import '../base.css';
import './estilos.css';

function Boton(props) {
  let { titulo, colorFondo, colorletra = "var(--letraSecondaryColor)", onClick, inputRef, id = "", disabled } = props;
  
  function handleKeyDown(event) {
    if (disabled) {
      return;
    }

    if (event.key === 'Enter') {
      onClick();
    }
  }

  return (<div className={"contenedorcBoton"}>
    <div id={id} tabIndex={0}
      className={"cBoton" + (disabled ? " disabled" : "")}
      style={{ background: colorFondo, color: colorletra }}
      onClick={disabled ? () => { return; } : onClick} ref={inputRef}
      onKeyDown={(e) => handleKeyDown(e)}>
      <div className="titulocBoton">{titulo}</div>
    </div>
  </div>)
}

export default Boton;