import { SET_CARRITO,
    AGREGAR_PRODUCTO,
    AGREGAR_PRODUCTO_CARRITO,
    AGREGAR_PRODUCTO_ERROR,
    ELIMINAR_PRODUCTO_CARRITO,
    MODIFICAR_PRODUCTO_CARRITO
     } from '../types';

export function agregarArticuloRedux(producto){
    return (dispatch) => {

        dispatch(agregarProducto());
        try {
             dispatch(agregarAlCarritoRedux(producto));

        }catch(error){
             dispatch(agregarAlCarritoError(true));
        }
    }
}

export const setCarrito = (carrito) => ({
    type: SET_CARRITO,
    payload: carrito
});

export const agregarProducto = () => ({
   type: AGREGAR_PRODUCTO,
   payload: true
});


export const agregarAlCarritoRedux = (producto) => ({
   type: AGREGAR_PRODUCTO_CARRITO,
   payload: producto
});

export const agregarAlCarritoError = (estado) => ({
   type: AGREGAR_PRODUCTO_ERROR,
   payload: estado
});

export const eliminarDelCarritoRedux = (id) => ({
    type: ELIMINAR_PRODUCTO_CARRITO,
    payload: id
});

export const modificarProductoRedux = (item) => ({
    type: MODIFICAR_PRODUCTO_CARRITO,
    payload: item
});





