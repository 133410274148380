import { FetchAPI } from "@renedelangel/helpers";
import { urlServidor } from "helpers";
import { ADD_SUSCRIPCION } from "../index";


export const addSuscripcion = async(variables, token) =>{
    try{
        let {addSuscripcion} = await FetchAPI.graphql({
            token,
            variables,
            url: urlServidor,
            query: ADD_SUSCRIPCION
        });
        return addSuscripcion;
    }catch({message}) {throw new Error(message);}
}