import { FetchAPI } from "@renedelangel/helpers";

import { urlServidor } from '../../../helpers';

import { ADD_USUARIO, UPDATE_USUARIO, REGISTRAR_USUARIO, ADD_USUARIOS, UPDATE_USUARIOS, DELETE_USUARIOS, UPDATE_USUARIOSESTATUS, ADD_USUARIOSADMI } from '../index';

export const addUsuario = async (variables, token) => {
    try {

        let { addUsuario } = await FetchAPI.graphql({
            token,
            variables,
            url: urlServidor,
            query: ADD_USUARIO
        });

        return addUsuario;

    } catch ({ message }) { throw new Error(message); }
};

export const updateUsuario = async (variables, token) => {
    try {

        let { updateUsuario } = await FetchAPI.graphql({
            token,
            variables,
            url: urlServidor,
            query: UPDATE_USUARIO
        });

        return updateUsuario;

    } catch ({ message }) { throw new Error(message); }
};

export const registrarUsuario = async (variables) => {
    try {
        let { registrarUsuario } = await FetchAPI.graphql({
            variables,
            url: urlServidor,
            query: REGISTRAR_USUARIO
        });
        return registrarUsuario;

    } catch ({ message }) {
        console.log(message);
        throw new Error(message);
    }
};


export const addUsuarios = async (variables, token) => {
    try {

        let { addUsuarios } = await FetchAPI.graphql({
            token,
            variables,
            url: urlServidor,
            query: ADD_USUARIOS
        });

        return addUsuarios;

    } catch ({ message }) { throw new Error(message); }
};

export const addUsuarioPorAdmin = async (variables, token) => {
    try {

        let { addUsuarioPorAdmin } = await FetchAPI.graphql({
            token,
            variables,
            url: urlServidor,
            query: ADD_USUARIOSADMI
        });

        return addUsuarioPorAdmin;

    } catch ({ message }) { throw new Error(message); }
};

export const updateUsuarios = async (variables, token) => {
    try {

        let { updateUsuarios } = await FetchAPI.graphql({
            token,
            variables,
            url: urlServidor,
            query: UPDATE_USUARIOS
        });

        return updateUsuarios;

    } catch ({ message }) { throw new Error(message); }
};

export const updateUsuariosEstatus = async (variables, token) => {
    try {

        let { updateUsuariosEstatus } = await FetchAPI.graphql({
            token,
            variables,
            url: urlServidor,
            query: UPDATE_USUARIOSESTATUS
        });

        return updateUsuariosEstatus;

    } catch ({ message }) { throw new Error(message); }
};

export const deleteUsuarios = async (variables, token) => {
    try {

        let { deleteUsuarios } = await FetchAPI.graphql({
            token,
            variables,
            url: urlServidor,
            query: DELETE_USUARIOS
        });

        return deleteUsuarios;

    } catch ({ message }) { throw new Error(message); }
};
