
export const rgxCorreo = /^([A-Z|a-z|0-9](\.|_){0,1})+[A-Z|a-z|0-9]@([A-Z|a-z|0-9|-])+((\.){0,1}[A-Z|a-z|0-9]){2}(\.[a-z]{2,3})+$/;

export const rgxPassword = /^(?=(.*[\d]){1,})(?=(.*[a-zñ]){1,})(?=(.*[A-ZÑ]){1,})(?=(.*[@#$%¡!*=<>()|¿?&-]){1,})(?:[\da-zA-ZÑñ=@#$%¡!*<>()|¿?&-]){8,30}$/;

export const rgxNumeros = /^[0-9]+$/;

export const rgxPhoneNumber = /^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/;

export const rgxRFC = /^([A-Z&Ñ]{3,4}[0-9]{2}(0[1-9]|1[012])(0[1-9]|[12][0-9]|3[01])[A-Z0-9]{2}[0-9A])$/;

export const rgxUrl = /^[(http(s)?)://(www.)?a-zA-Z0-9@:%._+~#=]{2,256}.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)$/;

export const rgxDecimal = /^[0-5]{1,1}(?:.[0-9]{1,4})?$/;

export const rgxNumerosMaximo10 = /^[0-9]{10}$/;

export const rgxCodigoPostal =  /^[0-4]{1,5}$/;

export const rgxLetras = /^[a-z.A-Z]{1,100}$/;

export const rgxNombre = /^[ A-Za-zäÄëËïÏöÖüÜáéíóúáéíóúÁÉÍÓÚÂÊÎÔÛâêîôûàèìòùÀÈÌÒÙñÑ'.]{1,500}$/;

export const rgxUrlValidacion = /^[(http(s)?):\/\/(www\.)?a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)$/;

export function toFixed(num, fixed) {
    if(num == null)
        {return '0'}
        
    let re = new RegExp('^-?\\d+(?:\.\\d{0,' + (fixed || -1) + '})?');
    return num.toString().match(re)[0];
}

export const redondeo = (valor, decimales) => {
    /* let exp = Math.pow(10, decimales);
    console.log('valor', valor);
    console.log('valor', valor.toFixed(2));
    let res = (parseFloat(valor.toString().match(/^-?\d+(?:\.\d{0,2})?/)[0]) * exp) / exp
    console.log('res', res);
    return parseFloat(res.toString().match(/^-?\d+(?:\.\d{0,2})?/)[0]); */
    let resultado = valor.toFixed(2);
    return resultado;

}



