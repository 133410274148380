import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';

import reducer from './reducer';
import { TOKEN, USUARIO, CARRITO, seed } from '../helpers';
import { getInitialStorage, setLocalStorage } from '../_layout/helpers/localstorage';

// Obtener datos del local storage
const initialState = {
    login: {
        token: getInitialStorage(TOKEN, seed),
        usuario: JSON.parse(getInitialStorage(USUARIO, seed))
    },
    busqueda: {
        busqueda: null
    },
    carrito: {
        carrito: JSON.parse(getInitialStorage(CARRITO, seed))
    }
}

const middleware = [thunk];

const store = createStore(
    reducer,
    initialState,
    compose(applyMiddleware(...middleware),
        window.__REDUX_DEVTOOLS_EXTENSION__ ?
            window.__REDUX_DEVTOOLS_EXTENSION__() : f => f
    )
);

// Guardar en el local storage
store.subscribe(() => {
    let { token, usuario } = store.getState().login

    if(token) setLocalStorage(TOKEN, token, seed);

    if(token && usuario) setLocalStorage(USUARIO, JSON.stringify(usuario), seed);

    let { carrito } = store.getState().carrito;

     if(carrito) setLocalStorage(CARRITO, JSON.stringify(carrito), seed);

});

export default store;
