import { FetchAPI } from "@renedelangel/helpers";
import { urlServidor,strUrlSArchivos } from '../../../helpers';

//Querys Graphql
import {  GET_BOLETIN,FILTER_BOLETINES, 
          ADD_BOLETIN,UPDATE_BOLETIN,
          DELETE_BOLETIN,
          UPDATE_STATUS_BOLETIN,
        DELETE_SUSCRIPCION } from '../index';

export const getBoletin= async (variables, token) => {
    try {

        let { getBoletin } = await FetchAPI.graphql({
            token,
            variables,
            url: urlServidor,
            query: GET_BOLETIN
        });

        getBoletin.boletinImg = `${strUrlSArchivos}${'/public/tonline'}${getBoletin.ruta}`

        return getBoletin;

    } catch({message}) { throw new Error(message); }
}

export const filterBoletines = async (variables, token) => {

    try {
        let { filterBoletines } = await FetchAPI.graphql({
            token,
            variables,
            url: urlServidor,
            query: FILTER_BOLETINES
        });

        
        let aux = filterBoletines.listado.map(element => {
            let auxFilter = {};
            for(const key in element){
                if(key == 'ruta'){
                    auxFilter = {
                        ...element, ruta: `${strUrlSArchivos}${'/public/tonline'}${element[key]}`
                    }
                }
            }

            return auxFilter;
        })

        filterBoletines.listado = aux;
        
        return filterBoletines;

    } catch({message}) { throw new Error(message); }
}

export const addBoletin = async (variables, token) => {
    try {

        let { addBoletin } = await FetchAPI.graphql({
            token,
            variables,
            url: urlServidor,
            query: ADD_BOLETIN
        });

        return addBoletin;

    } catch({message}) { throw new Error(message); }
};

export const updateBoletin = async (variables, token) => {
    try {

        let { updateBoletin } = await FetchAPI.graphql({
            token,
            variables,
            url: urlServidor,
            query: UPDATE_BOLETIN
        });

        return updateBoletin;

    } catch({message}) { throw new Error(message); }
};

export const deleteBoletin= async (variables, token) => {
    try {

        let { deleteBoletin } = await FetchAPI.graphql({
            token,
            variables,
            url: urlServidor,
            query: DELETE_BOLETIN
        });

        return deleteBoletin;

    } catch({message}) { throw new Error(message); }
};

export const deleteSuscripcion= async (variables, token) => {
    try {

        let { deleteSuscripcion } = await FetchAPI.graphql({
            token,
            variables,
            url: urlServidor,
            query: DELETE_SUSCRIPCION
        });

        return deleteSuscripcion;

    } catch({message}) { throw new Error(message); }
};

export const updateStatusBoletin= async (variables, token) => {
    try {

        
        let { updateStatusBoletin } = await FetchAPI.graphql({
            token,
            variables,
            url: urlServidor,
            query: UPDATE_STATUS_BOLETIN
        });

        return updateStatusBoletin;

    } catch({message}) { throw new Error(message); }
};
