import React, { useState, useRef, useEffect } from 'react'
import Formulario from '../../_layout/genericComponents/Formulario';
// generic components
import SweetAlert from '../../_layout/genericComponents/ModalConfirmacion';
import { handleAlertGeneric, handleAlertMutationGeneric } from '../../_layout/helpers/handles';
import { urlServidor } from '../../helpers';
import { NetworkError } from "../../_layout/genericComponents/Metodos";
import { info, danger, success, primary } from "../../_layout/helpers/colores";
// Redux
import { useSelector } from 'react-redux';
//Querys
import { selectEstatus } from '../../querys/CatalogosTienda';
// material-ui cons
import PrintIcon from '@material-ui/icons/Print';


function Reportes() {

    const { token, usuario } = useSelector(state => state.login);


    const showFile = (archivo, name = 'file.pdf',) => {
        let url = window.URL.createObjectURL(archivo);
        let a = document.createElement('a');
        a.href = url;
        a.download = name;
        a.click();
        a.remove();
    };

    const formato = "YYYY-MM-DD";
    
    const cleanState = {
        modulo: null,
        submodulo: null,
        nombreTipo: "",
        nombreReporte: "",
        tipoReporte: "",
        nombreEmpresa: "",
        formatoReporte: "PDF",
        estatus: null,
        estatusReembolso: null,
        fechaInicio: null,
        fechaFin: null,

    }

    const cleanErrorState = {
        modulo: { error: false, helperText: "" },
        submodulo: { error: false, helperText: "" },
        nombreReporte: { error: false, helperText: "" },
        tipoReporte: { error: false, helperText: "" },
        estatus: { error: false, helperText: "" },
        estatusReembolso: { error: false, helperText: "" },
        fechaInicio: { error: false, helperText: "" },
        fechaFin: { error: false, helperText: "" }

    }

    const cleanStateBloqueo = {
        modulo: true,
        submodulo: true,
        nombreTipo: true,
        tipoReporte: false,
        nombreReporte: true,
        estatus: true,
        estatusReembolso: true,
        fechaInicio: true,
        fechaFin: true    
    }

    const [stateBloqueo, setStateBloqueo] = useState(cleanStateBloqueo);
    const [state, setState] = useState(cleanState);
    const [errorState, setErrorState] = useState(cleanErrorState);
    const [disabled, setDisabled] = useState(false);
    const [seleccionables, setSeleccionables] = useState({});
    const [alert, setAlert] = useState(null);
    const [loader, setLoader] = useState(false);
    const [actualizar, setActualizar] = useState(false);    

    const listFormatoReporte = [{
        label: 'PDF',
        value: 'PDF'
    },
    {
        label: 'Excel',
        value: 'Excel'
    },
    ];

    const modulos = [
        { label: "Reportes", value: 1 }
    ];

    const estatusReembolso = [
        {value: "null",  label: "MOSTRAR TODO"},       
        {value: 1,  label: "PENDIENTE"},           
        {value: 2,  label: "EN PROCESO"},       
        {value: 3,  label: "REEMBOLSO APLICADO"},         
        {value: 4, label: "NO PROCEDE" },       
        {value: 5,  label: "CANCELADO"}  
    ]

    const subModulos = [
        { label: "Seguimiento de Pedido", value: "SeguimientoPedido", tipo: 1 },
        { label: "Reembolsos", value: "Reembolsos", tipo: 1 },
    ];

    const inputs = [{
        id: "IDTRF",
        tipo: "fieldset",
        leyenda: `Criterios de Selección de Reporte: ${state.modulo !== 0 ? state.nombreTipo : ""} - ${state.submodulo ? state.submodulo : ""} `,
        inputs: [
        {
            disabled,
            id: "modulo",
            value: state.modulo,
            grid: { md: 4, lg: 4 },
            error: errorState.modulo.error,
            success: state.modulo && !errorState.modulo.error ? true : undefined,
            helperText: errorState.modulo.helperText,
            title: "Tipo Reporte",
            placeholder: "Seleccionar Tipo de Reporte",
            tipo: "autocomplete",
            data: modulos,
            onChange: (target) => {
                target && setState({ ...state, modulo: Number(target.value), nombreTipo: target.label, submodulo: null, nombreReporte: "", tipoReporte: null });
                setStateBloqueo(cleanStateBloqueo);
            }
        },
        {
            disabled: state.modulo ? false : true,
            id: "tipoReporte",
            value: state.tipoReporte,
            grid: { md: 4, lg: 4 },
            error: errorState.tipoReporte.error,
            success: state.tipoReporte && !errorState.tipoReporte.error ? true : undefined,
            helperText: errorState.tipoReporte.helperText,
            title: "Reporte",
            placeholder: "Seleccionar Reporte",
            tipo: "autocomplete",
            data: seleccionables.resultSubmodulos,
            onChange: (target) => {target && ftOnchageInputsBloqueo(target.value, target.label, "tipoReporte"); }
        },
        {
            disabled: stateBloqueo.estatus,
            id: "estatus",
            value: state.estatus,
            grid: { md: 2, lg: 2 },
            error: errorState.estatus.error,
            success: state.estatus && !errorState.estatus.error ? true : undefined,
            helperText: errorState.estatus.helperText,
            title: "Estatus de Seguimiento",
            placeholder: "Seleccionar Estatus",
            tipo: "autocomplete",
            data:  seleccionables.seleccionableEstatus, //estatus,
            onChange: (target) => target && setState( state => ({...state,estatus: target.value}))
        },
        {
            disabled: stateBloqueo.estatusReembolso,
            id: "estatusReembolso",
            value: state.estatusReembolso,
            grid: { md: 2, lg: 2 },
            error: errorState.estatusReembolso.error,
            success: state.estatusReembolso && !errorState.estatusReembolso.error ? true : undefined,
            helperText: errorState.estatusReembolso.helperText,
            title: "Estatus de Reembolsos",
            placeholder: "Seleccionar Estatus",
            tipo: "autocomplete",
            data: estatusReembolso,
            onChange: (target) => target && setState( state => ({...state,estatusReembolso: target.value}))
        },
        {
            disabled: stateBloqueo.fechaInicio,
            id: "fechaInicio",
            value: state.fechaInicio,
            grid: { md: 4, lg: 4 },
            error: errorState.fechaInicio.error,
            success: state.fechaInicio && !errorState.fechaInicio.error ? true : undefined,
            helperText: errorState.fechaInicio.helperText,
            placeholder: "Fecha Inicio",
            tipo: "datetimepicker",
            onlyDate: true,
            onChange: (value) => typeof(value) === 'object' &&  handleChange(value.format(formato), "fechaInicio")
        },
        {
            disabled: stateBloqueo.fechaFin,
            id: "fechaFin",
            value: state.fechaFin,
            grid: { md: 4, lg: 4 },
            error: errorState.fechaFin.error,
            success: state.fechaFin && !errorState.fechaFin.error ? true : undefined,
            helperText: errorState.fechaFin.helperText,
            placeholder: "Fecha Fin",
            tipo: "datetimepicker",
            onlyDate: true,
            onChange: (value) => typeof(value) === 'object' &&  handleChange(value.format(formato), "fechaFin")
        },
        {
            disabled,
            id: "formatoReporte",
            value: state.formatoReporte,
            grid: { md: 4, lg: 4 },
            title: "Formato de Reporte",
            tipo: "select",
            data: listFormatoReporte,
            onChange: ({ target: { value } }) => setState({ ...state, formatoReporte: value }),
        },
        ],
    },
    ];

    
    function handleChange(value, key) { setState({...state, [key]: value }); }

    const accionesFormulario = [{
        loader,
        disabled,
        icono: PrintIcon,
        color: primary,
        descripcion: "Generar Reporte",
        onClick: () => state.submodulo ?
            handleAlertGeneric({
                setAlert,
                onCancel: () => setAlert(null),
                mensaje: {
                    title: `Generar Reporte`,
                    descripcion: `¿Deseas generar y descargar el reporte de ${state.nombreReporte}?`,
                    tipo: "warning",
                    msjConfirmacion: "Aceptar",
                    msjCancelacion: "Cancelar"
                },

                onConfirm: () => handleAlertMutationGeneric({
                    token,
                    setAlert,
                    setActualizar,
                    actualizar,
                    asyncMutation: GenerarReporte,
                    mensajes: {
                        msjEspera: {
                            title: "Espere...",
                            descripcion: `Generando el reporte de ${state.nombreReporte} `,
                            tipo: success
                        },
                        msjCorrecto: {
                            title: "Se generó con éxito",
                            descripcion: ` Se descargó el reporte de ${state.nombreReporte}`,
                            msjConfirmacion: "Aceptar"
                        }
                    }
                })
            }) : setAlert({
                descripcion: "Debe seleccionar un reporte",
                title: "¡Una disculpa!",
                tipo: danger,
                msjConfirmacion: "Aceptar",
                onConfirm: () => setAlert(null)
            })
    },
    ];

    //Función para bloquear los inputs dependiendo del reporte seleccionado
    function ftOnchageInputsBloqueo(value, label) {
        setState({ ...state, submodulo: label, nombreReporte: label, tipoReporte: value });

        switch (value) {
            case "SeguimientoPedido":
                setStateBloqueo({ ...stateBloqueo, estatus: false  })
                break;
            case "Reembolsos":
                setStateBloqueo({ ...stateBloqueo,estatusReembolso: false, fechaInicio: false, fechaFin: false})
                break;
            default:
                setStateBloqueo(cleanStateBloqueo);
        }
    }


    //funcion para generar el PDF con sus parámetros correspondientes
    async function GenerarPDF({ nombreReporte, tipoReporte, formatoReporte, reporte, estatus, fechaInicio, fechaFin, estatusReembolso}) {
        let myHeaders = new Headers();
        myHeaders.append("token", token);
        myHeaders.append("mimeType", "multipart/form-data");
        let formdata = new FormData();
        formdata.append("nombreReporte", reporte);
        formdata.append("tipoReporte", tipoReporte);
        formdata.append("formatoReporte", formatoReporte);
        formdata.append("estatus", estatus);
        formdata.append("estatusReembolso", estatusReembolso);
        formdata.append("fechaInicio", fechaInicio);
        formdata.append("fechaFin", fechaFin);
        formdata.append("perfil", usuario.perfil);
        formdata.append("usuarioID", usuario.usuarioID);
        console.log('fe', fechaInicio, fechaFin)



        let requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: formdata
        };

        await fetch(`${urlServidor}/service/reportes/ReportesGenCatalogos`, requestOptions)
            .then(response => {
                if (!response.ok) return response.json();
                return response.blob()
            })
            .then(result => {
                let { error, mensaje } = result;
                if (error) throw new Error(mensaje);
                showFile(result, `${nombreReporte}${state.formatoReporte === "PDF" ? ".pdf" : ".xlsx"}`);
                setAlert(null)
            })
            .catch(({ message }) => { throw new Error(message) });

    }


    async function GenerarReporte() {
        let { tipoReporte, nombreReporte, nombreEmpresa, formatoReporte, estatus, fechaInicio, fechaFin, estatusReembolso } = state;
        let reporte = "reporteGenCatalogos";

        await GenerarPDF({ tipoReporte, nombreReporte, reporte, nombreEmpresa, formatoReporte, estatus, fechaInicio, fechaFin, estatusReembolso});

    }

    useEffect(() => {
        async function ftEffect() {
            try {

                let resultado = subModulos.filter(({ tipo }) => tipo === Number(state.modulo));
                let listadoEstatus = await selectEstatus({filtro:{}}, token)  

                setSeleccionables(seleccionables => ({
                    ...seleccionables,  
                    resultSubmodulos: resultado,
                    seleccionableEstatus: listadoEstatus
                }))


            } catch ({ message }) {
                setAlert({
                    descripcion: `Algunos datos necesarios para funcionar no se cargaron correctamente, intenta actualizar la página o verifica tu conexión a internet; a continuación se muestrán más detalles del error: ${NetworkError(
                        message
                    )}`,
                    title: "¡Una disculpa!",
                    tipo: danger,
                    msjConfirmacion: "Aceptar",
                    onConfirm: () => setAlert(null)
                });
            }
        }
        ftEffect();
    }, [state.modulo]);


    return (<>

        <Formulario 
            color = {primary}
            title = {"Reportes"}
            header={true}
            inputs={inputs}
            acciones={accionesFormulario}
        />

        {
            alert && < SweetAlert
                title={alert.title}
                descripcion={alert.descripcion}
                tipo={alert.tipo}
                msjConfirmacion={alert.msjConfirmacion}
                msjCancelacion={alert.msjCancelacion}
                onConfirm={alert.onConfirm}
                showConfirm={alert.showConfirm}
                showCancel={alert.showCancel}
                onCancel={
                    () => setAlert(null)
                }
            />
        } </>
    );
}

export default Reportes;
