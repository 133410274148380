import React, { useState, useRef, useEffect } from "react";
import "./styles.css";
import ReactDom from "react-dom";
import { Encriptar } from "@renedelangel/helpers";
import PopUpAlert from "./PopUpAlerta";
import { urlServidor } from "helpers";
import { addSuscripcion } from "../../../mutations/Suscripciones/metodos/index";
import LoaderLogoSoftwone2 from "../../../_layout/assets/svg/Animaciones/logoSoftwone2";

export default function PopUpSusccribirse(props) {
    let { loginPrincipal, loginInside } = props;
    const [openSuscribirse, setOpenSuscribirse] = useState(false);
    const [correoSuscribirse, setCorreoSuscribirse] = useState({
        correo: ""
    });
    const [loader, setLoader] = useState(false);
    const seedToken = "c29mdHdvbmVAdG9ubGluZQ==";
    const [textoAlert, setTextoAlert] = useState({
        titulo: "",
        mensaje: "",
        error: false
    });

    const [openAlert, setOpenAlert] = useState(false);

    const delay = ms => new Promise(resolve => setTimeout(resolve, ms));

    const regexpCorreo = /^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/;

    function handleValidaciones({ correo }) {
        let error;
        let validaciones = [
            {
                condicion: correo == null || correo == "",
                ref: correoRef,
                keyError: "correo",
                mensajeError: "Debe indicar un correo"
            },
            {
                condicion: !regexpCorreo.test(correo),
                ref: correoRef,
                keyError: "correo",
                mensajeError: "Debe indicar un correo valido"
            }
        ];

        validaciones.forEach(({ condicion, ref, mensajeError }) => {
            if (error) return;
            if (condicion) {
                setTextoAlert({
                    titulo: "Revisa tus datos",
                    mensaje: mensajeError,
                    error: true
                });
                setOpenAlert(true);

                ref.current.focus();
                ref.current.select();
                error = true;
            }
        });

        if (error) return error;
    }

    async function stop() {
        setCorreoSuscribirse({
            correo: ""
        });
        await delay(3000);
        setOpenAlert(false);
    }

    function handleChange(e) {
        const { name, value } = e.target;
        setCorreoSuscribirse(prevState => {
            return {
                ...prevState,
                [name]: value
            };
        });
    }

    function handleClosePopUpSuscribirse() {
        setOpenSuscribirse(false);
        loginInside(false);
    }

    const { correo } = correoSuscribirse;

    const correoRef = useRef(null);

    async function recoverPass() {
        
        let error = handleValidaciones({ correo });
        if (error) return;

        const tokenTemp = Encriptar.GenerarToken({
            data: { usuario: { usuario: "Suscripcion",dominio: window.location.hostname } },
            expiresIn: "5m",
            seed: seedToken
        });

        try {
            setLoader(true);
            let datos = await addSuscripcion({ input: { correo,dominio: window.location.hostname } }, tokenTemp);
            datos = {...datos,dominio: window.location.hostname, suscripcionID: datos.suscripcionID}
            await enviarCorreoSuscripcion(datos, tokenTemp);
        } catch ({ message }) {
            setLoader(false);
            setTextoAlert({
                titulo: "¡Error!",
                mensaje: message,
                error:true
            });
            setOpenAlert(true);
        }

    }

    async function enviarCorreoSuscripcion(datos, tokenTemp){
        let myHeaders = new Headers();
            myHeaders.append("token", tokenTemp);
            myHeaders.append("mimeType", "multipart/form-data");

            let formdata = new FormData();
            formdata.append("datos", JSON.stringify(datos));

            let requestOptions = {
                method: "POST",
                headers: myHeaders,
                body: formdata
            };

            await fetch(
                `${urlServidor}/suscripcion`,
                requestOptions
            )
                .then(response => {
                    if (!response.ok) return response.json();
                    
                })
                .catch(error => {
                    setTextoAlert({
                        titulo: "Error",
                        mensaje: "Ha ocurrido un error, vuelve a intentarlo",
                        error:true
                    });
                    setOpenAlert(true);
                stop();
                });

                setTextoAlert({
                    titulo: "¡Exito!",
                    mensaje: "Se ha registrado de forma correcta el correo",
                    error:false
                });
                setOpenAlert(true);
                await stop();

            loginInside(false);
    }

    return (
        <>
            <div
                className="contenedorGeneralPopUpSuscribirse"
                onClick={() => {
                    handleClosePopUpSuscribirse();
                }}
            >
                <div
                    className="contenedorInternoPopUpSuscribirse"
                    onClick={e => {
                        e.stopPropagation();
                    }}
                >
                    <div className="descripcionPopUpSuscribirse">
                        ¡SUSCRIBETE A NUESTRO BLOG Y RECIBE NUESTRAS NOTICIAS!
                    </div>
                    <input
                        ref={correoRef}
                        className="inputCorreoPopUpSuscribirse"
                        placeholder="Correo electrónico"
                        type="input"
                        name="correo"
                        value={correo}
                        onChange={e => handleChange(e)}
                    ></input>
                    {
                       openAlert && <div className={ textoAlert.error? "cMsjErrSuscrip" : "cMsjSucceSuscrip"}>{textoAlert.mensaje} </div>
                    }
                    <button
                        onClick={() => {
                            recoverPass();
                        }}
                        className="buttonEnviarPopUpSuscribirse"
                    >
                        Enviar
                    </button>
                    <button
                        onClick={() => {
                            handleClosePopUpSuscribirse();
                        }}
                        className="buttonRegresarPopUpSuscribirse"
                    >
                        Regresar
                    </button>
                </div>
            </div>
            {
                loader ? 
                <LoaderLogoSoftwone2/> : 
                <></>
            }
        </>
    );
}
