export const DELETE_DESEOS = `
mutation deleteDeseos($deseosID: ID!) {
    deleteDeseos(id: $deseosID)
  }
  `;

export const UPSERT_DESEOS = `
mutation addDeseos($input: InputAddDeseos!) {
    addDeseos(input: $input){
    deseosID,
    productoID,
    descripcionCorta
  }
}
  `;
