import { FetchAPI } from "@renedelangel/helpers";

import { urlServidor } from '../../../helpers';

import {ADD_DIRECCION, UPDATE_DIRECCION, DELETE_DIRECCION, UPDATE_CAMBIOTIPODIRECCION} from '../index';

export const addDireccion = async (variables, token) => {
    try {

        let { addDireccion } = await FetchAPI.graphql({
            token,
            variables,
            url: urlServidor,
            query: ADD_DIRECCION
        });

        return addDireccion;

    } catch({message}) { throw new Error(message); }
};

export const updateDireccion = async (variables, token) => {
    try {

        let { updateDireccion } = await FetchAPI.graphql({
            token,
            variables,
            url: urlServidor,
            query: UPDATE_DIRECCION
        });

        return updateDireccion;

    } catch({message}) { throw new Error(message); }
};

export const deleteDireccion = async (variables, token) => {
    try {

        let { deleteDireccion } = await FetchAPI.graphql({
            token,
            variables,
            url: urlServidor,
            query: DELETE_DIRECCION
        });

        return deleteDireccion;

    } catch({message}) { throw new Error(message); }
};

export const updateCambioTipoDireccion = async (variables, token) => {
    try {

        let { updateCambioTipoDireccion } = await FetchAPI.graphql({
            token,
            variables,
            url: urlServidor,
            query: UPDATE_CAMBIOTIPODIRECCION
        });

        return updateCambioTipoDireccion;

    } catch({message}) { throw new Error(message); }
};
