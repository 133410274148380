import React, { useState, useRef, useEffect } from 'react'
import './estilos.css';

// Generic components
import SweetAlert from '../../../_layout/genericComponents/ModalConfirmacion';
import Formulario from '../../../_layout/genericComponents/Formulario';
import Preloader from '../../../_layout/genericComponents/Preloader';

import { Switch, Redirect, useParams } from 'react-router-dom';

//componentes
import Input from '../../../_layout/NuevosComponentes/Input';
import Boton from '../../../_layout/NuevosComponentes/Boton';

// material-ui icons
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import HelpIcon from '@material-ui/icons/Help';
import LockOpenIcon from '@material-ui/icons/LockOpen';
import VpnKeyIcon from '@material-ui/icons/VpnKey';
import ErrorIcon from '@material-ui/icons/Error';
import PersonIcon from '@mui/icons-material/Person';
import LockIcon from '@mui/icons-material/Lock';

import { asyncHandleFocus, handleErrorInputText } from '../../../_layout/helpers/handles';
import { rgxPassword } from '../../../helpers/regexp';
import { info, warning, primary, danger, success } from '../../../_layout/helpers/colores';

import { isToken } from '../../../querys/Login/metodos';
import { updatePassword } from '../../../mutations/Login/metodos';

function CambiarPassword() {

    const { token } = useParams();

    const cleanDisabled = { password: false, cambiar: true, iniciar: false };
    const cleanState = { confirmarpassword: "", password: "" };
    const cleanErrorState = {
        password: { error: false, helperText: ""  },
        confirmarpassword: { error: false, helperText: ""  }
    };
    const cleanInfo = {
        message: "Después de capturar su nueva contraseña presione el botón 'Cambiar contraseña'",
        icon: HelpIcon,
        color: primary
    };

    const [state, setState] = useState(cleanState);
    const [infoInput, setInfoInput] = useState(cleanInfo);
    const [componente, setComponente] = useState(null);
    const [errorState, setErrorState] = useState(cleanErrorState);
    const [loader, setLoader] = useState(false);
    const [disabled, setDisabled] = useState(cleanDisabled);
    const [alert, setAlert] = useState(null);
    const [enlaceValido, setEnlaceValido] = useState(false);

    const title = "Recuperar contraseña";
    const grid = { xs:12, sm: 12, md: 5, lg: 4 };

    let passwordRef = useRef(null);
    let confirmarpasswordRef = useRef(null);
    let cambiarRef = useRef(null);

    const inputs = [{
        disabled: disabled.password,
        id: "password",
        value: state.password,
        error: errorState.password.error,
        success: state.password && !errorState.password.error ? true : undefined,
        helperText: errorState.password.helperText,
        title: "Nueva contraseña",
        placeholder: "Capture su nueva contraseña",
        icono: <VpnKeyIcon />,
        inputProps: { type: "password" },
        inputRef: passwordRef,
        onChange: ({target:{value}}) => handleState(value, "password"),
        /* onKeyDown: (evt) => asyncHandleFocus(evt, confirmarpasswordRef, true, ftCambiarPassword) */
    }, {
        disabled: disabled.password,
        id: "confirmarpassword",
        value: state.confirmarpassword,
        error: errorState.confirmarpassword.error,
        success: state.confirmarpassword && !errorState.confirmarpassword.error ? true : undefined,
        helperText: errorState.confirmarpassword.helperText,
        title: "Confirmar contraseña",
        placeholder: "Vuelva a capturar su nueva contraseña",
        icono: <VpnKeyIcon />,
        inputProps: { type: "password" },
        inputRef: confirmarpasswordRef,
        onChange: ({target:{value}}) => handleState(value, "confirmarpassword"),
        /* onKeyDown: (evt) => asyncHandleFocus(evt, cambiarRef, false, ftCambiarPassword) */
    }];

    const acciones = [{
        loader,
        disabled: !enlaceValido,
        icono: ArrowRightIcon,
        color: info,
        descripcion: "Cambiar contraseña",
        onClick: () => {ftCambiarPassword()},
        inputRef: cambiarRef
    }, {
        disabled: disabled.iniciar,
        icono: ArrowRightIcon,
        color: warning,
        descripcion: "Ir a Iniciar sesión",
        onClick: () => setComponente(<><Switch><Redirect from={"*"} to={"/"} /></Switch></>)
    }];

    function handleState(value, id) {
        setState(state => ({
            ...state,
            [id]: value
        }));
    }

    function ftErrorInputText({ condicion, ref, keyError, mensajeError }){
        return handleErrorInputText({ cleanErrorState, condicion, ref, keyError, mensajeError,
            loader: setLoader, errorState: setErrorState });
    }

    function handleValidaciones({ password, confirmarpassword }) {

        let error;
        let validaciones = [{
            condicion: !rgxPassword.test(password),
            ref: passwordRef, keyError: "password",
            mensajeError: "La estructura de la contraseña debe contener una letra mayúscula, una letra minúscula, un número, un caracter especial y de 8 a 30 caracteres"
        }, {
            condicion: password !== confirmarpassword, ref: confirmarpasswordRef, keyError: "confirmarpassword",
            mensajeError: "La contraseña de confirmación no es igual a la contraseña capturada"
        }];

        validaciones.forEach(({ condicion, ref, keyError, mensajeError }) => {
            if(error) return;
            error = ftErrorInputText({ condicion, ref, keyError, mensajeError });
        });

        if(error) return error;

    }

    function ftCambiarPassword() {
        async function cambiarPassword() {
            setLoader(true);
            setDisabled(disabled => ({
                ...disabled,
                password: true,
                iniciar: true
            }));
            try {

                let { password, confirmarpassword } = state;

                let error = await handleValidaciones({ password, confirmarpassword });
                
                if(error) {
                    setDisabled(cleanDisabled);
                    return error;
                }

                let cambio = await updatePassword({ password }, token);

                if(!cambio) throw new Error("Ocurrió un error al cambiar la contraseña");

                setAlert({
                    descripcion: "Hemos podido actualizar tu contraseña correctamente en unos segundos te redireccionaremos a la ventana de inicio para que puedas iniciar tu sesión; si no deseas esperar presiona el siguiente botón",
                    title: "¡Contraseña actualizada!",
                    tipo: success,
                    msjConfirmacion: "Aceptar",
                    onConfirm: () => setComponente(<><Switch><Redirect from={"*"} to={"/"} /></Switch></>)
                });

                setTimeout(() => setComponente(<><Switch><Redirect from={"*"} to={"/"} /></Switch></>), 3000);

            } catch({message}) {
                setAlert({
                    descripcion: message,
                    title: "¡Te pedimos una disculpa!",
                    tipo: danger,
                    msjConfirmacion: "De acuerdo",
                    onConfirm: () => setAlert(null)
                });
            }
            setLoader(false);
            setDisabled(cleanDisabled);
        } return cambiarPassword();
    }

    function ftEffect() {
        async function effect() {
            let errorInfo = {
                message: "Lamentablemente el enlace para cambiar su contraseña ya ha caducado",
                icon: ErrorIcon,
                color: danger,
                error: true
            };
            try {
                let hasToken = await isToken(token);
                
                !!hasToken ? setInfoInput(cleanInfo) : setInfoInput(errorInfo)
                setEnlaceValido(!!hasToken);
                setComponente(true);
            } catch({message}) {
                console.error(message);
                setInfoInput(errorInfo);
                setComponente(true);
            }
        } effect();
    }
    
    useEffect(ftEffect, [token]);

    return (
        <>
            <div className="contenedorPrincipalRecupera"> 
            <div className="contenedorRecupera">
                <div className='leyenda'>Estás a punto de cambiar tu contraseña</div>
                <div className='sigPaso'>Al terminar, te enviaremos a iniciar sesión <br/> con tu nueva contraseña</div>
                <div className='formulario'> 
                    <Input
                        id="nuevoPassword"
                        claseAuxInput="cInputRecuperar"
                        placeholder="Nueva contraseña"                    
                        posIcono="left"
                        password={true}
                        onChange={({target:{value}}) => handleState(value, "password")}
                        />
                    <Input
                        id="confirmaPassword"
                        claseAuxInput="cInputRecuperar"
                        placeholder="Confirmar contraseña"
                        error={errorState.confirmarpassword.error ? errorState.confirmarpassword.helperText : ""}                    
                        posIcono="left"
                        password={true}
                        onChange={({target:{value}}) => handleState(value, "confirmarpassword")}
                    />
                    <div className="cContenedorBotonesLogin">
                      <Boton titulo="Cambiar contraseña" colorFondo="var(--secondaryColor)" onClick={() => ftCambiarPassword()} />
                    </div>
                </div>
            </div>
            </div>
            { alert && <SweetAlert
                title={alert.title}
                descripcion={alert.descripcion}
                tipo={alert.tipo}
                msjConfirmacion={alert.msjConfirmacion}
                msjCancelacion={alert.msjCancelacion}
                onConfirm={alert.onConfirm}
                showConfirm={alert.showConfirm}
                showCancel={alert.showCancel}
                onCancel={() => setAlert(null)}
            /> }
        </> 
    );

}

export default CambiarPassword;
