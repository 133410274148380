import React, { useState, useRef, useEffect } from 'react';
import './estilos.css';
// master components
import Formulario from '../../_layout/genericComponents/Formulario';
// Redux
import { useSelector } from "react-redux";

import moment from 'moment';
//generic components
import SweetAlert from '../../_layout/genericComponents/ModalConfirmacion';
import { NetworkError } from "../../_layout/genericComponents/Metodos";
import { actualizaArchivo } from "../../helpers/serverStorage";
import { strUrlSArchivos } from '../../helpers/index';

import Boton from '../../_layout/NuevosComponentes/Boton';

// material-ui icons
import SaveIcon from '@material-ui/icons/Save';

import * as Consultas from './Consultas';

import { handleFocus, handleErrorInputText, handleGuardar } from '../../_layout/helpers/handles';
import { info, danger, success } from '../../_layout/helpers/colores';
import { HexColorPicker } from "react-colorful";

function Configuraciones() {

    const { token } = useSelector(state => state.login);

    const cleanState = {};
    const cleanErrorState = {};
    const cleanNotificaciones = { mensaje: "", color: null, open: false };
    const [stDatos, setState] = useState(cleanState);
    const [errorState, setErrorState] = useState(cleanErrorState);
    const [notificaciones, setNotificaciones] = useState(cleanNotificaciones);
    const [disabled, setDisabled] = useState(false);
    const [alert, setAlert] = useState(null);
    const [loader, setLoader] = useState(false);
    const [actualizar, setActualizar] = useState(false);
    const [seleccionables, setSeleccionables] = useState({});

    const fotos = {
        foto1: { valor: "", file: "", eliminar: false },
        foto2: { valor: "", file: "", eliminar: false },
        foto3: { valor: "", file: "", eliminar: false },
        foto4: { valor: "", file: "", eliminar: false },
        foto5: { valor: "", file: "", eliminar: false }
    }
    const [stFotografias, setFotografias] = useState(fotos);
    const pdfs = {
        aviso:      { valor: "", file: "", eliminar: false },
        politica:   { valor: "", file: "", eliminar: false } 
    }
    const [stPdf, setPdf] = useState(pdfs);

    let titleModal = `Configuraciones`;

    const inputs = [];
    const InputsConID = [];
    const Secciones = [];
    let ArrayRef = [];
    const paginas = [
        { label: "Productos", value: "Productos" },
        { label: "LandingPage", value: "LandingPage" },
    ];

    cleanErrorState["ordenServicioID"] = { error: false, helperText: "" };
    cleanState["empresaID"] = 0;

    InicializarInputs("nombre", "Nombre", "Capture el nombre de la empresa", "", "Datos generales", 100);
    InicializarInputs("nombreCorto", "Nombre corto", "", "", "Datos generales", 30);
    InicializarInputs("rfc", "RFC", "RFC", "", "Datos generales", 13);
    InicializarInputs("direccion", "Dirección", "", "", "Datos generales", 0);
    InicializarInputs("telefono", "Teléfono", "", "", "Datos generales", 50);
    InicializarInputs("correoElectronico", "Correo electrónico", "", "", "Datos generales", 100);
    InicializarInputs("mision", "Misión", "", "", "Datos generales");
    InicializarInputs("vision", "Visión", "", "", "Datos generales");
    InicializarInputs("nosotros", "Nosotros", "", "", "Datos generales");
    InicializarInputs("paginaInicio", "Pagina de inicio de la tienda", "", "", "Datos generales");
    InicializarInputs("facebookLink", "Facebook (Link)", "", "", "Datos generales");
    InicializarInputs("facebookID", "Facebook (ID)", "", "", "Datos generales");
    InicializarInputs("logoEmpresa", "Logo", "Seleccione el logo", 0, "Imágenes");
    InicializarInputs("imgMision", "Imagen (Misión)", "", "", "Imágenes");
    InicializarInputs("imgVision", "Imagen (Visión)", "", "", "Imágenes");
    InicializarInputs("imgNosotros", "Imagen (Nosotros)", "", "", "Imágenes");
    InicializarInputs("leyenda", "", "", 0, "Imágenes");
    // ------------------------------------------------------------------------------------
    InicializarInputs("imgOferta", "Imagen (Oferta)", "", "", "Imagen Oferta");
    InicializarInputs("mostrarOferta", "Mostrar Oferta", "", false, "Configurar Oferta");
    InicializarInputs("fechaInicioOferta", "Fecha Inicio", "", "", "Configurar Oferta", 10);
    InicializarInputs("fechaFinOferta", "Fecha Final", "", "", "Configurar Oferta", 10);
    // ------------------------------------------------------------------------------------
    InicializarInputs("avisoLink", "PDF Aviso de Privacidad", "", "", "Documentos");
    InicializarInputs("politicaLink", "PDF Política de Compras", "", "", "Documentos");
    InicializarInputs("leyendaDocs", "", "", 0, "Documentos");

    inputs.push({
        tipo: "custom",
        id: "colores",
        custom: SeccionColores()
    })

    function setColor(color, key) {
        if (!color) {
            color = '#ffffff';
        }
        setState({ ...stDatos, [key]: color });
    }

    function SeccionColores() {
        cleanState["primaryColor"] = "";
        cleanState["letraPrimaryColor"] = "";
        cleanState["secondaryColor"] = "";
        cleanState["letraSecondaryColor"] = "";
        cleanState["backgroundColor"] = "";

        return (<div className="cContenedorColores" style={{ borderColor: stDatos.secondaryColor }}>
            <div className="cListaColores">
                {selectorColor("primaryColor", stDatos.primaryColor, "Color primario")}
                {selectorColor("letraPrimaryColor", stDatos.letraPrimaryColor, "Letra en color primario")}
                {selectorColor("secondaryColor", stDatos.secondaryColor, "Color secundario")}
                {selectorColor("letraSecondaryColor", stDatos.letraSecondaryColor, "Letra en color secundario")}
                {selectorColor("backgroundColor", stDatos.backgroundColor, "Color de fondo")}
            </div>
            <div className="cEjemploColores">
                <div className="cEjemploFondo" style={{ backgroundColor: stDatos.backgroundColor }}>
                    <div className="cEjemplosEtiquetas" style={{ backgroundColor: stDatos.backgroundColor }}>
                        <div className="cEjemploMenu" style={{ backgroundColor: stDatos.primaryColor, color: stDatos.letraPrimaryColor, borderColor: stDatos.letraPrimaryColor}}>
                            Ejemplo de etiqueta
                        </div>
                        <div className="cEjemploMenu" style={{ backgroundColor: stDatos.primaryColor, color: stDatos.letraPrimaryColor, borderColor: stDatos.letraPrimaryColor}}>
                            Ejemplo de etiqueta
                        </div>
                        <div className="cEjemploMenu" style={{ backgroundColor: stDatos.primaryColor, color: stDatos.letraPrimaryColor, borderColor: stDatos.letraPrimaryColor }}>
                            Ejemplo de etiqueta
                        </div>
                    </div>
                    <div className="cEjemploBoton" style={{ color: stDatos.letraPrimaryColor }}>
                        <Boton titulo="Ejemplo"
                            colorFondo={stDatos.secondaryColor}
                            colorletra={stDatos.letraSecondaryColor} />
                    </div>
                </div>
            </div>
        </div>);
    }

    function selectorColor(key, value, titulo) {
        return (<div className="cDivColor">
            <div className="cContendorEjemploColor">
                <div className="cEtiquetaColor">
                    <div className="cTitloEtiquetaColor">
                        {titulo}
                    </div>
                </div>
                <input value={value} onChange={({ target }) => setColor(target.value, key)} />
                <div className="cDivEjemploColor" style={{ backgroundColor: value }}>
                </div>
            </div>
            <div className="cSelectorColor">
                <HexColorPicker color={value} onChange={(color) => setColor(color, key)} />
            </div>
        </div>)
    }

    //Orden de inputs (grid)
    //-------------------------------------------
    Secciones["Datos generales"].grid = { md: 12, lg: 12 };
    Secciones["Imágenes"].grid = { md: 12, lg: 12 };
    Secciones["Imagen Oferta"].grid = { xs: 12, md: 6, lg: 6 };
    Secciones["Configurar Oferta"].grid = { xs: 12, md: 6, lg: 6 };
    Secciones["Documentos"].grid = { md: 12, lg: 12 };
    //-------------------------------------------
    InputsConID["nombre"].grid = { md: 8, lg: 8 };
    InputsConID["nombreCorto"].grid = { md: 4, lg: 4 };
    InputsConID["rfc"].grid = { md: 4, lg: 4 };
    InputsConID["direccion"].grid = { md: 8, lg: 8 };
    InputsConID["telefono"].grid = { md: 4, lg: 4 };
    InputsConID["correoElectronico"].grid = { md: 4, lg: 4 };
    InputsConID["mision"].grid = { md: 12, lg: 12 };
    InputsConID["vision"].grid = { md: 12, lg: 12 };
    InputsConID["nosotros"].grid = { md: 12, lg: 12 };
    InputsConID["paginaInicio"].grid = { md: 12, lg: 6 };
    InputsConID["facebookLink"].grid = { md: 12, lg: 6 };
    InputsConID["facebookID"].grid = { md: 12, lg: 4 };

    InputsConID["mision"].multiline = true;
    InputsConID["mision"].rows = 3;
    InputsConID["vision"].multiline = true;
    InputsConID["vision"].rows = 3;
    InputsConID["nosotros"].multiline = true;
    InputsConID["nosotros"].rows = 3;

    InputsConID["logoEmpresa"].grid = { md: 3, lg: 3 };
    InputsConID["imgMision"].grid = { md: 3, lg: 3 };
    InputsConID["imgVision"].grid = { md: 3, lg: 3 };
    InputsConID["imgNosotros"].grid = { md: 3, lg: 3 };
    InputsConID["leyenda"].grid = { xs: 12, md: 12, lg: 12 };

    InputsConID["imgOferta"].grid = { xs:12, md: 12, lg: 12 };

    InputsConID["mostrarOferta"].grid = {md:12, lg:12}
    InputsConID["fechaInicioOferta"].grid = {md:12, lg:12}
    InputsConID["fechaFinOferta"].grid = {md:12, lg:12}

    InputsConID["avisoLink"].grid = { xs: 12, md: 6, lg: 6} ;
    InputsConID["politicaLink"].grid = {xs: 12, md: 6, lg: 6};
    InputsConID["leyendaDocs"].grid = { xs: 12, md: 12, lg: 12 };

    InputsConID["leyenda"].tipo = "label";
    InputsConID["leyenda"].title = "Las imágenes deben tener un tamaño máximo de 10 MB y una resolución mínima de 300px";
    
    InputsConID["logoEmpresa"].tipo = "imageupload";
    InputsConID["logoEmpresa"].removeImage = stDatos.foto1;
    InputsConID["logoEmpresa"].onChange = handleSetImagen1;
    
    InputsConID["imgMision"].tipo = "imageupload";
    InputsConID["imgMision"].removeImage = stDatos.foto2;
    InputsConID["imgMision"].disabled = stDatos.foto1 !== "" ? false : true;
    InputsConID["imgMision"].onChange = handleSetImagen2;
    
    InputsConID["imgVision"].tipo = "imageupload";
    InputsConID["imgVision"].removeImage = stDatos.foto3;
    InputsConID["imgVision"].disabled = stDatos.foto2 !== "" ? false : true;
    InputsConID["imgVision"].onChange = handleSetImagen3;
    
    InputsConID["imgNosotros"].tipo = "imageupload";
    InputsConID["imgNosotros"].removeImage = stDatos.foto4;
    InputsConID["imgNosotros"].disabled = stDatos.foto3 !== "" ? false : true;
    InputsConID["imgNosotros"].onChange = handleSetImagen4;

    InputsConID["imgOferta"].tipo = "imageupload";
    InputsConID["imgOferta"].removeImage = stDatos.foto5;
    InputsConID["imgOferta"].disabled = stDatos.foto5 !== "" ? false : true;
    InputsConID["imgOferta"].onChange = handleSetImagen5;
    
    InputsConID["mostrarOferta"].disabled = stFotografias.foto5.eliminar
    InputsConID["mostrarOferta"].tipo = "checkbox";
    InputsConID["mostrarOferta"].checked = stDatos.mostrarOferta;
    InputsConID["mostrarOferta"].onChange = handleSetMostrarFecha
    
    InputsConID["fechaInicioOferta"].disabled = !stDatos.mostrarOferta
    InputsConID["fechaInicioOferta"].tipo = "datetimepicker";
    InputsConID["fechaInicioOferta"].timeFormat = false;
    InputsConID["fechaInicioOferta"].onlyDate = true;
    InputsConID["fechaInicioOferta"].value = stDatos.fechaInicioOferta;
    InputsConID["fechaInicioOferta"].onChange = (value) => {handleSetFecha(value, 0)}

    InputsConID["fechaFinOferta"].disabled = !stDatos.mostrarOferta
    InputsConID["fechaFinOferta"].tipo = "datetimepicker";
    InputsConID["fechaFinOferta"].timeFormat = false;
    InputsConID["fechaFinOferta"].onlyDate = true;
    InputsConID["fechaFinOferta"].value = stDatos.fechaFinOferta
    InputsConID["fechaFinOferta"].onChange = (value) => {handleSetFecha(value, 1)}

    InputsConID["avisoLink"].tipo = "fileupload"
    InputsConID["avisoLink"].onChange = handleSetFileAviso;
    
    InputsConID["politicaLink"].tipo = "fileupload"
    InputsConID["politicaLink"].onChange = handleSetFilePolitica;
    
    InputsConID["leyendaDocs"].tipo = "label";
    InputsConID["leyendaDocs"].title = "Las documentos deben tener un tamaño máximo de 10 MB";
    
    InputsConID["paginaInicio"].tipo = "select";
    InputsConID["paginaInicio"].data = seleccionables.paginas;

    // InputsConID["paginaInicio"].onChange =  ({ target: { value } }) => setState({ ...stDatos, paginaInicio: value });

    function formatearFecha(pFecha) {
        if (moment(pFecha).isValid() === false || !stDatos["imgOferta"] || !stDatos["mostrarOferta"]) {
            return null;
        }

        let formatoFecha = "YYYY-MM-DD";
        return moment(pFecha).format(formatoFecha);
    }

    function handleSetFecha(value, index){
        const tipoFecha = ["fechaInicioOferta", "fechaFinOferta"];
        if(typeof (value) === 'object'){
            handleChange(value, tipoFecha[index]) 
        }
        else{
            setState(stDatos => ({...stDatos,[tipoFecha[index]]: "" }));
        }

        if(errorState[tipoFecha[0]].error){
            setErrorState(errorState => ({
                ...errorState,
                [tipoFecha[0]]: { helperText: "", error: false }
            }));
        }

        if(errorState[tipoFecha[1]].error){
            setErrorState(errorState => ({
                ...errorState,
                [tipoFecha[1]]: { helperText: "", error: false }
            }));
        }
    }

    function handleSetMostrarFecha(){
        setState({...stDatos, "mostrarOferta":!stDatos.mostrarOferta});

        if(errorState["fechaInicioOferta"].error){
            setErrorState(errorState => ({
                ...errorState,
                ["fechaInicioOferta"]: { helperText: "", error: false }
            }));
        }
        if(errorState["fechaFinOferta"].error){
            setErrorState(errorState => ({
                ...errorState,
                ["fechaFinOferta"]: { helperText: "", error: false }
            }));
        }
    }

    function handleSetImagen1(foto1) {
        let remover = false;
        if (foto1 === null) {
            remover = true;
        }
        let errorValida = validaTamañoImagen(foto1, 'La imagen del logo', "logoEmpresa");

        setFotografias({ ...stFotografias, foto1: { url: stDatos.logoEmpresa, orden: 1, file: foto1, eliminar: remover } });
        setState({ ...stDatos, foto1, logoEmpresa: null });
    }

    function handleSetImagen2(foto2) {
        let remover = false;
        if (foto2 === null) {
            remover = true;
        }
        let errorValida = validaTamañoImagen(foto2, 'La imagen de misión', "imgMision");

        setFotografias({ ...stFotografias, foto2: { url: stDatos.imgMision, orden: 2, file: foto2, eliminar: remover } });

        setState({ ...stDatos, foto2, imgMision: null });
    }

    function handleSetImagen3(foto3) {
        let remover = false;
        if (foto3 === null) {
            remover = true;
        }
        let errorValida = validaTamañoImagen(foto3, 'La imagen de visión', "imgVision");

        setFotografias({ ...stFotografias, foto3: { url: stDatos.imgVision, orden: 3, file: foto3, eliminar: remover } });

        setState({ ...stDatos, foto3, imgVision: null });
    }

    function handleSetImagen4(foto4) {
        let remover = false;
        if (foto4 === null) {
            remover = true;
        }
        let errorValida = validaTamañoImagen(foto4, 'La imagen de Nosotros', "imgNosotros");

        setFotografias({ ...stFotografias, foto4: { url: stDatos.imgNosotros, orden: 4, file: foto4, eliminar: remover } });

        setState({ ...stDatos, foto4, imgNosotros: null });
    }

    function handleSetImagen5(foto5) {
        let remover = false;
        if (foto5 === null) {
            remover = true;
        }
        let errorValida = validaTamañoImagen(foto5, 'La imagen de oferta', "imgOferta");

        setFotografias({ 
            ...stFotografias, 
            foto5: { 
                url: stDatos.imgOferta, 
                orden: 5,
                file: foto5, 
                eliminar: remover 
            } 
        });

        setState({ ...stDatos, foto5, imgOferta: null });
    }

/*     console.log("stDatos",stDatos) */

    function handleSetFileAviso(fileAviso){
        let remover = false;
        if(fileAviso === null){
            remover = true;
        }
        let errorValida = validaTamañoDocumento(fileAviso, "El archivo Aviso de Privacidad", "avisoLink")
        setPdf({
            ...stPdf, 
            aviso: {
                url: stDatos.avisoLink, 
                file: fileAviso, 
                eliminar: remover
            }
        });

        setState({...stDatos, fileAviso, avisoLink: null});
    }

    function handleSetFilePolitica(filePolitica){
        let remover = false;
        if(filePolitica === null){
            remover = true;
        }
        let errorValida = validaTamañoDocumento(filePolitica, "El archivo Politica de Compras", "politicaLink")
        setPdf({
            ...stPdf, 
            politica: {
                url: stDatos.politicaLink, 
                file: filePolitica, 
                eliminar: remover
            }
        });

        setState({...stDatos, filePolitica, politicaLink: null});
    }

    function InicializarInputs(Identificador, pTitulo = "", pPlaceholder = "", ValorInicial = "", Seccion = "", MaxLength = 0, textAlign = 'left') {

        cleanErrorState[Identificador] = { error: false, helperText: "" };
        cleanState[Identificador] = ValorInicial;
        ArrayRef[Identificador] = useRef(null);

        if (pTitulo === "") {
            pTitulo = Identificador;
        }

        if (pPlaceholder === "") {
            pPlaceholder = pTitulo;
        }
        let tInput = {
            value: stDatos[Identificador],
            id: Identificador,
            error: errorState[Identificador].error,
            success: stDatos[Identificador] && !errorState[Identificador].error ? true : undefined,
            helperText: errorState[Identificador].helperText,
            inputRef: ArrayRef[Identificador],
            title: pTitulo,
            placeholder: pPlaceholder,
            textAlign: textAlign,
            onChange: ({ target: { value } }) => handleChange(value, Identificador, MaxLength),
            onKeyDown: () => { return; }
        };

        InputsConID[Identificador] = tInput;
        if (Seccion === "") {
            inputs.push(tInput);
            return;
        }

        let tInputsDeSeccion = [];
        let InputFieldSet;
        if (Secciones[Seccion]) {
            InputFieldSet = Secciones[Seccion];
            tInputsDeSeccion = InputFieldSet.inputs;
        } else {
            InputFieldSet = {
                id: Seccion,
                leyenda: Seccion,
                placeholder: Seccion,
                tipo: "fieldset",
                sizeLetra: "1.05em"
            };

            inputs.push(InputFieldSet);
        }

        tInputsDeSeccion.push(tInput);
        InputFieldSet.inputs = tInputsDeSeccion;
        Secciones[Seccion] = InputFieldSet;
    }

    const accionesFormulario = [{
        loader,
        disabled: disabled,
        icono: SaveIcon,
        color: info,
        descripcion: "Guardar",
        onClick: () => ftGuardar(),
        //}),
        // inputRef: accederRef,
    }];

    function ftErrorInputText({ condicion, ref, keyError, mensajeError }) {
        return handleErrorInputText({
            cleanErrorState, condicion, ref, keyError, mensajeError,
            loader: setLoader, disabled: setDisabled, errorState: setErrorState
        });
    }

    async function handleValidaciones() { 
        let error;
        let claves = Object.keys(errorState);
        let msjError = '';

        let validaciones = [
            {
                condicion: !stDatos["nombre"] || stDatos["nombre"] === "",
                ref: ArrayRef["nombre"], keyError: "nombre",
                mensajeError: "Debe especificar el nombre de la empresa"
            },
            {
                condicion: stDatos["imgOferta"] && stDatos["mostrarOferta"] && !moment(stDatos["fechaInicioOferta"]).isValid(),  
                ref: ArrayRef["fechaInicioOferta"],
                keyError: "fechaInicioOferta",
                mensajeError: "La fecha de inicio no tiene un formato valido"
            },
            {
                condicion: stDatos["imgOferta"] && stDatos["mostrarOferta"] && !moment(stDatos["fechaFinOferta"]).isValid(),  
                ref: ArrayRef["fechaFinOferta"],
                keyError: "fechaFinOferta",
                mensajeError: "La fecha fin no tiene un formato valido"
            },
            {
                condicion: stDatos["imgOferta"] && stDatos["mostrarOferta"] && moment(stDatos["fechaFinOferta"]) < moment(stDatos["fechaInicioOferta"]), 
                ref: ArrayRef["fechaFinOferta"],
                keyError: "fechaFinOferta",
                mensajeError: "La fecha fin no puede ser menor a la de inicio"
            },
        ];
        
        console.log(moment(stDatos["fechaInicioOferta"]))
        validaciones.forEach(({ condicion, ref, keyError, mensajeError }) => {
            if (error) return;
            error = ftErrorInputText({ condicion, ref, keyError, mensajeError });            
        });
        
        for (let i = 0; i < claves.length; i++) {
            let clave = claves[i];
            if (errorState[clave].error) {

                msjError = errorState[clave].helperText;
                await setAlert({
                    descripcion: errorState[clave].helperText,
                    title: "¡Error!",
                    tipo: "danger",
                    msjConfirmacion: "Aceptar",
                    onConfirm: () => setAlert(null),
                    showCancel: false
                });
                error = true;
                setLoader(false);
                setDisabled(false);

                break;
            }
        }

        if (error) return error;
        
    }

    function validaTamañoImagen(foto, nombre, key) {

        setErrorState({ ...cleanErrorState });
        setAlert(null);
        let error = false;

        if (foto === null) {
            return error;
        }

        let sizeByte = foto.size;
        let sizekiloByte = parseInt(sizeByte / 1024);

        if (sizekiloByte > 10000) { 
            setAlert({
                descripcion: `${nombre} supera el limite de tamaño permitido`,
                title: "¡Error!",
                tipo: "danger",
                msjConfirmacion: "Aceptar",
                onConfirm: () => setAlert(null),
                showCancel: false
            });
            error = true;
            setErrorState(errorState => ({
                ...errorState,
                [key]: { helperText: `${nombre} supera el limite de tamaño permitido`, error: true }
            }));
            return error;
        }

    }

    function validaTamañoDocumento(doc, nombre, key, mbLimit=10) {

        setErrorState({ ...cleanErrorState });
        setAlert(null);
        let error = false;

        if (doc === null) {
            return error;
        }

        let sizeByte = doc.size;
        let sizekiloByte = parseInt(sizeByte / 1024);

        if (sizekiloByte > (mbLimit*1000)) { 
            setAlert({
                descripcion: `${nombre} supera el limite de tamaño permitido`,
                title: "¡Error!",
                tipo: "danger",
                msjConfirmacion: "Aceptar",
                onConfirm: () => setAlert(null),
                showCancel: false
            });
            error = true;
            setErrorState(errorState => ({
                ...errorState,
                [key]: { helperText: `${nombre} supera el limite de tamaño permitido`, error: true }
            }));
            return error;
        }

    }

    async function guardarPdfs(DatosGuardar){
        for(const key in stPdf){
            if(!(stPdf[key].file || stPdf[key].eliminar))
                continue;
            
            let url = stPdf[key].url;
            let urloriginal = '';
            if(url){
                if(url.substring(0, 5) === "https"){
                    let lenServer = strUrlSArchivos.length;
                    url = url.substring(lenServer + 1, url.length);
                }
                let indice = url.indexOf('?');
                urloriginal = url.substring(0, indice);
            }           
            let ruta = stPdf[key].url ? urloriginal : 'configuraciones/';

            let result = await actualizaArchivo( 
                ruta,                           
                stPdf[key].file,                
                0,  // privado
                0,  // id
                token,                          
                stPdf[key].eliminar);      

            if (result) {
                if (stPdf[key].eliminar === true) {
                    result = "";
                }
                switch (key) {
                    case "aviso":
                        DatosGuardar.avisoLink = result;
                        break;
                    case "politica":
                        DatosGuardar.politicaLink = result;
                        break;
                    default:
                        break;
                }
            }
        }
        return DatosGuardar;
    }


    function ftGuardar() {
        async function ftGuardar() {
            try {
                let error = await handleValidaciones();
                if (error) return error;

                let DatosGuardar = { ...stDatos };
                DatosGuardar.logoEmpresa = DatosGuardar.logoEmpresaSinConcatenar;
                DatosGuardar.imgMision = DatosGuardar.imgMisionSinConcatenar;
                DatosGuardar.imgVision = DatosGuardar.imgVisionSinConcatenar;
                DatosGuardar.imgNosotros = DatosGuardar.imgNosotrosSinConcatenar;
                DatosGuardar.imgOferta = DatosGuardar.imgOfertaSinConcatenar;
                DatosGuardar.avisoLink = DatosGuardar.avisoLinkSinConcatenar;
                DatosGuardar.politicaLink = DatosGuardar.politicaLinkSinConcatenar;
                DatosGuardar.fechaInicioOferta = formatearFecha(DatosGuardar.fechaInicioOferta);
                DatosGuardar.fechaFinOferta = formatearFecha(DatosGuardar.fechaFinOferta);
                DatosGuardar.mostrarOferta = DatosGuardar.mostrarOferta == "" ? false : DatosGuardar.mostrarOferta; 

                //console.log("DatosGuardar: ",DatosGuardar)

                for (const key in stFotografias) {
                    if (stFotografias[key].file || stFotografias[key].eliminar) {
                        let url = stFotografias[key].url;
                        let urloriginal = '';

                        if (url) {
                            if (url.substring(0, 5) === 'https') {
                                let lenServer = strUrlSArchivos.length;
                                url = url.substring(lenServer + 1, url.length);
                            }
                            let indice = url.indexOf('?');
                            urloriginal = url.substring(0, indice);
                        }
                        let ruta = stFotografias[key].url ? urloriginal : 'configuraciones/';
                        let result = await actualizaArchivo(ruta, stFotografias[key].file, 0, Number(stFotografias[key].IDimagen), token, stFotografias[key].eliminar);

                        if (result) {
                            if (stFotografias[key].eliminar === true) {
                                result = "";
                            }
                            switch (key) {
                                case "foto1":
                                    DatosGuardar.logoEmpresa = result;
                                    break;
                                case "foto2":
                                    DatosGuardar.imgMision = result;
                                    break;
                                case "foto3":
                                    DatosGuardar.imgVision = result;
                                    break;
                                case "foto4":
                                    DatosGuardar.imgNosotros = result;
                                    break;
                                case "foto5":
                                    DatosGuardar.imgOferta = result;
                                    break;
                                default:
                                    break;
                            }
                        }
                    }
                }
                
                DatosGuardar = await guardarPdfs(DatosGuardar);

                delete DatosGuardar["foto1"];
                delete DatosGuardar["foto2"];
                delete DatosGuardar["foto3"];
                delete DatosGuardar["foto4"];
                delete DatosGuardar["foto5"];
                delete DatosGuardar["fileAviso"]
                delete DatosGuardar["filePolitica"]
                delete DatosGuardar["logoEmpresaSinConcatenar"];
                delete DatosGuardar["imgMisionSinConcatenar"];
                delete DatosGuardar["imgVisionSinConcatenar"];
                delete DatosGuardar["imgNosotrosSinConcatenar"];
                delete DatosGuardar["imgOfertaSinConcatenar"];
                delete DatosGuardar["avisoLinkSinConcatenar"]
                delete DatosGuardar["politicaLinkSinConcatenar"]

                let result = await Consultas.updateEmpresasConfig(DatosGuardar, token);
                let resultado = await Consultas.getEmpresasConfig({ dominio: 0 }, token);

                

                setState({
                    ...stDatos, 
                    logoEmpresa: resultado.logoEmpresa, 
                    imgMision: resultado.imgMision,
                    imgVision: resultado.imgVision, 
                    imgNosotros: resultado.imgNosotros,
                    imgOferta: resultado.imgOferta,
                    avisoLink: resultado.avisoLink, 
                    politicaLink: resultado.politicaLink
                });

                setAlert({
                    descripcion: 'Se actualizó la información correctamente',
                    title: "¡Listo!",
                    tipo: "success",
                    msjConfirmacion: "Aceptar",
                    onConfirm: () =>{ 
                        setAlert(null)
                        window.location.href = window.location.href;
                    },
                    showCancel: false
                });

            }
            catch ({ message }) {
                setAlert({
                    descripcion: message,
                    title: "¡Error!",
                    tipo: "danger",
                    msjConfirmacion: "Aceptar",
                    onConfirm: () => setAlert(null),
                    showCancel: false
                });
            }
        }
        return ftGuardar();
    }

    function handleClose() {
        setNotificaciones({ ...cleanNotificaciones });
    }

    function handleChange(value, key, MaxLength = 0) {
        if (value.length > MaxLength && MaxLength !== 0) {
            return;
        }

        setState(stDatos => ({
            ...stDatos,
            [key]: value
        }));

        setErrorState(errorState => ({
            ...errorState,
            [key]: { helperText: "", error: false }
        }));
    }

    function ValidarEmail(value) {
        if (value.length > 100) {
            return;
        }

        let validRegex = rgxCorreo;

        if (value.match(validRegex)) {
            setErrorState(errorState => ({
                ...errorState,
                correo: { helperText: "", error: false }
            }));
        } else {
            setErrorState(errorState => ({
                ...errorState,
                correo: { helperText: "El correo electrónico no es válido", error: true }
            }));
        }

        setState(stDatos => ({
            ...stDatos,
            correo: value
        }));
    }

    useEffect(() => {
        async function ftEffect() {
            try {
                let result = await Consultas.getEmpresasConfig({ dominio: 0 }, token);
                // console.log(result);
                setState(result);                                 
                setState({...result, 
                    fechaInicioOferta : (result.mostrarOferta === false || !result["imgOferta"]) ? "" 
                        : moment(result.fechaInicioOferta),
                    fechaFinOferta : (result.mostrarOferta === false || !result["imgOferta"])? "" 
                        : moment(result.fechaFinOferta),
                    mostrarOferta: !result["imgOferta"] ? false : true,

                }); 
                setNotificaciones({ ...cleanNotificaciones })

                setSeleccionables(seleccionables => ({
                    ...seleccionables,
                    paginas
                }))

            } catch ({ message }) {
                setAlert({
                    descripcion: `Algunos datos necesarios para funcionar no se cargaron correctamente, intenta actualizar la página o verifica tu conexión a internet; a continuación se muestrán más detalles del error: ${NetworkError(message)}`,
                    title: "¡Una disculpa!",
                    tipo: danger,
                    msjConfirmacion: "Aceptar",
                    onConfirm: () => setAlert(null)
                });
            }
        } ftEffect();
    }, []);

    return (<>
        <br></br>
        <Formulario
            open={true}
            title={titleModal}
            onClose={handleClose}
            notificaciones={notificaciones}
            closeNotification={() => setNotificaciones({ ...cleanNotificaciones })}
            inputs={inputs}
            acciones={accionesFormulario}
            focus={ArrayRef['nombre']}
        />
        {alert && <SweetAlert
            title={alert.title}
            descripcion={alert.descripcion}
            tipo={alert.tipo}
            msjConfirmacion={alert.msjConfirmacion}
            msjCancelacion={alert.msjCancelacion}
            onConfirm={alert.onConfirm}
            showConfirm={alert.showConfirm}
            showCancel={alert.showCancel}
            onCancel={() => setAlert(null)}
        />}
    </>);

}

export default Configuraciones;
