import { FetchAPI } from "@renedelangel/helpers";

import { urlServidor } from '../../../helpers';
import {  GET_PAQUETERIA, TABLA_PAQUETERIA } from '../index';

export const getPaqueteria = async (variables, token) => {
    try {

        let { getPaqueteria } = await FetchAPI.graphql({
            token,
            variables,
            url: urlServidor,
            query: GET_PAQUETERIA
        });

        return getPaqueteria;

    } catch({message}) { throw new Error(message); }
}

export const tablaPaqueteria = async (variables, token) => {
    try {
        let { filterPaqueteria } = await FetchAPI.graphql({
            token,
            variables,
            url: urlServidor,
            query: TABLA_PAQUETERIA
        });

        return filterPaqueteria;

    } catch({message}) { throw new Error(message); }
}
