import React, { useState, useEffect } from 'react';
import { Validaciones } from '@renedelangel/helpers';

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// generic components
import CardListItemImage from '../../genericComponents/CardListItemImage';
import Input from '../../genericComponents/Inputs'
import Custom from '../../genericComponents/Tabla/metodos';
import { NetworkError } from '../../genericComponents/Metodos';
import Loader from "../../genericComponents/Loader";

//Redux
import { setBusqueda } from '../../../redux/actions/busquedaAction';

// core components
import GridContainer from "../../components/Grid/GridContainer.js";
import GridItem from "../../components/Grid/GridItem.js";
import Card from "../../components/Card/Card.js";
import CardBody from "../../components/Card/CardBody.js";
import CardIcon from "../../components/Card/CardIcon.js";
import CardHeader from "../../components/Card/CardHeader.js";
import Accordion from "../../components/Accordion/Accordion.js";
import Muted from "../../components/Typography/Muted.js";
import Slider from "@material-ui/core/Slider";

import FilterListIcon from '@material-ui/icons/FilterList';

import noImage from "../../assets/svg/undraw_blank_canvas_3rbb.svg";

import styles from "../../assets/jss/material-dashboard-pro-react/views/extendedTablesStyle.js";
import { Button, TextField } from '@material-ui/core';

const useStyles = makeStyles(styles);
const { CompararDosObjetos } = Validaciones;



function CardFilter({ asyncData, infoSeccion, parametrosFijos = {}, token, mostrarFiltros = true, gruposIDS=[] }) {

    const classes = useStyles();
    const setBusquedaRedux = (busqueda) => dispatch(setBusqueda(busqueda));
    //let noImage =  '/imagenes/fotoTienda.jpg';

    const { limiteInicial = 16, filtro, color = "primary", title, icon, hideHeader = false, ocultarListaRegistros, busqueda, actualizar = false, actualizarCard = {id:0, like:0}, leyenda, leyenda2, grupoInicial, subgrupoInicial } = infoSeccion;

    const cleanError = { ok: false, mensaje: null };
    const defaultGrid = { xs: 6, sm: 6, md: (filtro ? 6 : 4), lg: (filtro ? 3 : 2) };
    const defaultGridSinFiltro = { xs: 6, sm: 6, md: (mostrarFiltros ? 6 : 4), lg: (mostrarFiltros ? 3 : 2) };     
   
    const [paginacion, setPaginacion] = useState({
        paginas: 1,
        listadoPaginas: 5,
        registros: 0,
        limite: !isNaN(Number(limiteInicial)) ? limiteInicial : 16,
        busqueda: { gruposIDs: grupoInicial ? [grupoInicial] : [],
            subgruposIDs : subgrupoInicial ? [subgrupoInicial] : []                         
         },
        onClick: (activa) => setPaginaActiva(activa),

    });
    const [data, setData] = useState([]);
    const [paginaActiva, setPaginaActiva] = useState(1);
    const [error, setError] = useState(cleanError);
    const [loader, setLoader] = useState(true);
    const [search, setSearch] = useState({});
    const [dataFiltrada, setDataFiltrada] = useState([]);
    const [value, setValue] = useState([0,35000]);

    const rangeSelector = (event, newValue) => {
        setValue(newValue);
}
    let container = {
        direction: "row",
        justify: "center",
        alignItems: "stretch"
    };
    let newFiltro = null;

    if(filtro && typeof filtro === "object") {
        Object.keys(filtro).forEach(key => {
            newFiltro = !newFiltro ? [] : newFiltro;
            switch(filtro[key].tipo) {
                case "switch":
                    if(Array.isArray(filtro[key].data) && filtro[key].data.length > 0) filtro[key].data.forEach(info => {
                        let input = {
                            onChange: (value) => {
                                setPaginacion(paginacion => ({
                                    ...paginacion,
                                    busqueda: {
                                        ...paginacion.busqueda,
                                        [key]: (paginacion.busqueda[key] && Array.isArray(paginacion.busqueda[key]) && paginacion.busqueda[key].length > 0) ? [...paginacion.busqueda[key], value] : [value]
                                    }
                                }))
                            }
                        };
                    });
                    break;
                default:
                    break;
            }
        });
    }
    function handleRegistros({target:{value}}) {
        setPaginacion({
            ...paginacion,
            limite: Number(value)
        });
    }
    function ftEffect() {   
     
        if(!asyncData) return;
        async function effect() {
            try {

                setPaginacion(paginacion => ({ ...paginacion, registros: 0, paginas: 0 }));
                setError(error => ({mensaje: "", ok: false}));
                setLoader(true);

                let params = {};
                if(parametrosFijos && typeof parametrosFijos === "object") params = { ...parametrosFijos };
                if(busqueda && Object.keys(busqueda).length > 0) {
                    params = { ...params, ...busqueda };
                    setSearch(busqueda);
                }
                let gruposSelec = paginacion.busqueda.gruposIDs;
                gruposIDS(gruposSelec);

                let filtro = {ventaOnline:1, limite: paginacion.limite, pagina: paginaActiva,  ...paginacion.busqueda, ...params };

                let { props, formato, grid, msjSinRegistros, actions } = infoSeccion;
            
                let resultado = await asyncData({ filtro }, token);

                /*if(typeof props !== "object" || typeof resultado !== "object") throw new Error(msjSinRegistros ? msjSinRegistros : "No se encontraron resultados")*/
                let { paginas, listado, registros } = resultado;

                /*if(!Array.isArray(listado) || listado.length === 0) throw new Error(msjSinRegistros ? msjSinRegistros : "No se encontraron resultados")*/
                let acciones = actions;

                let newData = listado.map(info => {
                    let data = {};
                    let opciones = []
                    for(let indice in acciones) {
                        let opcion = acciones[indice];
                        let icono2 = opcion.icono;
                        let deseoId =  info.deseosID ? info.deseosID : null;
                        if (opcion.icono.id !== undefined)
                        {
                            icono2 = deseoId ? opcion.icono.id : opcion.icono.sin;
                        }
                        opcion = {...opcion, icono: icono2}
                        opciones.push(opcion);
                    }
                    actions = opciones;
                    for(let key in props) {
                        let value = info[props[key]],
                            act = Array.isArray(actions) && actions.length > 0 ? { actions } : {};
                        if(Array.isArray(formato) && formato.length > 0) formato.forEach(({columnas, ...rest}) => {
                            if(Array.isArray(columnas) && columnas.length > 0) columnas.forEach(col => {
                                if(col === props[key]) value = <Custom valor={value} { ...rest } />;
                            });
                        });
                        value = !value && key === "imagen" ? noImage : value;
                        value = !value && key === "deseosID" ? null : value;
                        data = { ...data, ...act, [key]: value }
                    }
                    data = { ...data, leyenda:leyenda, leyenda2: leyenda2 }
                    return {
                        ...data,
                        grid: grid ? grid : defaultGrid
                    };
                });          
                setPaginacion(paginacion => ({ ...paginacion, registros, paginas }));
                setData([ ...newData ]);
                setDataFiltrada([...newData]);
                if(newData.length == 0) {
                    setError({ mensaje: msjSinRegistros ? msjSinRegistros : "No se encontraron resultados", ok: true });
                }
            } catch({message:mensaje}) {
                setError({ mensaje: NetworkError(mensaje), ok: true });
            }
            setLoader(false);
        } effect();
    }   
    useEffect(ftEffect, [paginaActiva, paginacion.limite, paginacion.busqueda, actualizar, token]);

    useEffect(() => {
        let subgrupos = paginacion.busqueda.subgruposIDs;       
        let filtrosSub = [];    
        filtrosSub =  filtro.subgruposIDs ? filtro.subgruposIDs.data : [];      
        setError(error => ({mensaje: "", ok: false}));
    
        if (subgrupos && filtrosSub){
            let count = subgrupos.length;
            subgrupos.map((item, index) => {
                let indexFiltros = filtrosSub.map(item2 => item2.value).indexOf(item.toString());              
                let subIDS = subgrupos;
                if(indexFiltros < 0){
                    delete subgrupos[index];
                    if (count == 1){
                        subgrupos = [];
                    }                    
                    setPaginacion(paginacion => ({...paginacion, busqueda: {...paginacion.busqueda,
                        subgruposIDs : subgrupos}
                    }))
                }
            })
        }
        if((busqueda && Object.keys(busqueda).length > 0) && CompararDosObjetos(search, busqueda)) return;
        if(paginaActiva > 1) setPaginaActiva(1);
        else ftEffect();

    }, [busqueda, paginacion]);

    function filtrarPorPrecio(data)
    {
        let dataFiltro = [];
        data.forEach(element =>  {

            if (element.textFooterLeft.props.valor >= value[0] && element.textFooterLeft.props.valor <= value[1] )
            /* if (data[i].textFooterLeft.props.valor >= value[0] && data[i].textFooterLeft.props.valor <= value[1] )  */
            {
                dataFiltro.push(element);
                
            }
            else
            {
               /*  i++;  */
                 /* console.log("no"); */
            }
        });
        /* setLoader(true); */
        setDataFiltrada(dataFiltro);
        setPaginacion(paginacion => ({...paginacion, registros: dataFiltro.length}));
    }

    return (<GridContainer
        direction={container.direction}
        justify={container.justify}
        alignItems={container.alignItems}
    >
        {newFiltro && mostrarFiltros && <GridItem xs={12} md={4} lg={3} xl={3} >
            <Card>
                <CardHeader color={color} icon>
                    <CardIcon color={color}>
                        <FilterListIcon />
                    </CardIcon>
                    <h4 className={classes.cardIconTitle}>Filtrado</h4>
                </CardHeader>
                <CardBody>
                    <GridContainer
                        direction={container.direction}
                        justify={container.justify}
                        alignItems={container.alignItems}
                    >
                        <GridItem xs={12}><hr /></GridItem>
                        {

                            Object.keys(filtro).map((key, index) => (<GridItem key={index} xs={12}>

                                <Accordion
                                   active={-1}
                                   collapses={[
                                    {
                                        title: filtro[key].title,
                                        content:
                                             <Input
                                                { ...filtro[key] }
                                                tipo={"checkbox"}
                                                multiple={true}
                                                disabled={loader}
                                                checked={paginacion.busqueda[key] ? paginacion.busqueda[key] : []}
                                                onChange={checked => setPaginacion(paginacion => ({
                                                    ...paginacion,
                                                    busqueda: {
                                                        ...paginacion.busqueda,
                                                        [key]: checked
                                                    }
                                                }), setValue([0,35000]))}
                                            />
                                    }]
                                }
                                />
                            </GridItem>))}
                    </GridContainer>

                    <GridContainer
                    justify={"center"}>
                    <div > Filtro por precio  </div>
                    </GridContainer>

                    <GridContainer
                        direction={container.direction}
                        justify={container.justify}
                        alignItems={container.alignItems}
                        >
                            <div style = {{
                                margin: 'auto',
                                display: 'block',
                                width: '100%'
                            }}>
                                <Slider
                                value = {value}
                                onChange = {rangeSelector}
                                valueLabelDisplay = "auto"
                                max = {35000}
                                step = {500}
                                onChangeCommitted = {() => filtrarPorPrecio(data)}
                                />
                                Entre {value[0]} y {value[1]}

                            </div>

                            </GridContainer>

                </CardBody>
            </Card>
        </GridItem> }
        <GridItem  xs={12} md={filtro && mostrarFiltros ? 8 : 12} lg={filtro && mostrarFiltros ? 9 : 12} xl={filtro && mostrarFiltros ? 9 : 12} >
            <Card>
                {
                    !!!hideHeader && <CardHeader color={color} icon>
                        {
                            icon && <CardIcon color={color}>
                                { icon }
                            </CardIcon>
                        }
                        { title ? <h5 className={classes.cardIconTitle}>{title}</h5> : <br /> }
                        {
                            paginacion && <Muted className={classes.cardIconTitle}>
                                Página <b>{paginaActiva}</b> de <b>{paginacion.paginas && paginacion.paginas}</b> | Total de registros: <b>{paginacion.registros}</b>
                            </Muted>
                        }
                    </CardHeader>
                }
                <CardBody className={classes.productos}>
                    <GridContainer
                        direction={container.direction}
                        justify={container.justify}
                        alignItems={container.alignItems}
                    >
                        { !!!hideHeader &&  <GridItem xs={12}><hr /></GridItem> }
                        <GridItem xs={12} md={12} lg={12}>
                            {
                                error.ok ? <h4 style={{ textAlign: "center" }}>{error.mensaje}</h4> : (loader ? <Loader /> : <CardListItemImage
                                    data={dataFiltrada}
                                    leyenda={leyenda}
                                    leyenda2={leyenda2}
                                    paginacion={paginacion}
                                    paginaActiva={paginaActiva}
                                    ocultarListaRegistros={ocultarListaRegistros}
                                    handleRegistros={handleRegistros}
                                />)
                            }
                        </GridItem>
                    </GridContainer>
                </CardBody>
            </Card>
        </GridItem>
    </GridContainer>);

}

export default CardFilter;
