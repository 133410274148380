import React from 'react';

import CircularProgress from '@material-ui/core/CircularProgress';
import Button from "../../../components/CustomButtons/Button.js";

import { makeStyles } from "@material-ui/core/styles";
import styles from "../../../assets/jss/material-dashboard-pro-react/views/buttonsStyle.js";

const useStyles = makeStyles(styles);

export default function Boton(props) {

    const { color = "primary", onClick = (evt) => console.log(evt), descripcion, disabled = false, loader = false, inputRef } = props;
    let newDescripcion = descripcion ? descripcion : "";

    const classes = useStyles();

    let none = () => { return; };
    
    return (<Button
        color={color}
        round
        className={classes.marginRight}
        onClick={disabled ? none : onClick}
        disabled={disabled}
        ref={inputRef}
    >
        { loader ? <CircularProgress color="inherit" style={{ maxHeight: "15px" }} className={classes.icon} /> : <props.Icono className={classes.icon} /> } {newDescripcion}
    </Button>);
}
