export const GET_DEPARTAMENTO = `
    query getDepartamento($departamentoID:ID!) {
        getDepartamento(id:$departamentoID){
            departamentoID
            descripcion
            estatus
        }
    }
`;

export const TABLA_DEPARTAMENTO = `
    query filterDepartamento($filtro:InputFilterDepartamento!)  {
        filterDepartamento(filtro:$filtro){
            paginas
            registros
            listado{
                departamentoID
                descripcion
                estatus
            }
        }
    }
`;

export const GET_GRUPOERP = `
    query getGrupoERP($grupoID: ID!) {
        getGrupoERP(id:$grupoID) {
            grupoID
            grupo
        }
    }
`;

export const GET_ALL_GRUPOERP = `
    query getAllGrupoERP($filtro:InputGetGrupos) {
        getAllGrupoERP(filtro:$filtro){
            grupoID
            grupo
            imagen
        }
    }
`;

export const GET_DEPARTAMENTOGRUPO = `
    query getDepartamentoGrupo($departamentoID: ID!) {
        getDepartamentoGrupo(id:$departamentoID){
            departamentoID
            grupoID
            grupoErpID
        }
    }
`;

export const GET_ALLDEPARTAMENTOGRUPO = `
    query getAllDepartamentoGrupo($empresaID: ID){
        getAllDepartamentoGrupo(empresaID: $empresaID) {
            departamentoID
            departamento
            estatus
            empresaID
            grupoID
            grupoErpID
        }
    }
`;
export const GET_SUBGRUPOERP = `
    query getSubGrupoERP($filtro:InputGetSubGrupos!) {
        getSubGrupoERP(filtro:$filtro) {
            subgrupoID
            subgrupo
            grupoID
            grupo
        }
    }
`;

export const GET_ALL_SUBGRUPOERP = `
    query getAllSubGrupoERP($filtro:InputGetSubGrupos!) {
        getAllSubGrupoERP(filtro:$filtro){
            subgrupoID
            subgrupo
            grupoID
            grupo
        }
    }
`;
