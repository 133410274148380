import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import AlertaImagen from 'componentes/RecuperarPassword/Alerta';
import RecuperarPassword from 'componentes/RecuperarPassword';
import Login from '../../../componentes/LoginB';
import Registro from 'componentes/Registro';
import Modal from '../Modal';
import moment from 'moment';
import * as Consultas from '../../../componentes/Configuraciones/Consultas';
import { useEnumPerfil } from 'hooks/enums';


export default function PopUpLogin(props){

    let {openOpciones, setOpenOpciones, propsAccionLogin, propsRequiereAccionLogin} = props;

    const [openOferta, setOpenOferta] = useState(false);
    const [correo, setCorreo] = useState('');
    const now = moment();
    const { perfilAdministrador, perfilModerador, perfilUsuario } = useEnumPerfil(perfilID);
    const { usuario, token } = useSelector(state => state.login);
    const { nombre, perfilID } = usuario ? usuario : {};
    const [infoEmpresa, setInfoEmpresa] = useState({});

    const cleanOpenOpciones = { open: false, formulario: "", descripcion: "" };

    function ftEffect() {
        async function effect() {
            let info = await Consultas.getEmpresasConfig({}, token);
            setInfoEmpresa(info);
        } effect();
    }
    useEffect(ftEffect, [perfilAdministrador, perfilModerador, perfilUsuario]);

    function showOferta(){
        const fechaInicio = moment(infoEmpresa.fechaInicioOferta);
        const fechaFin = moment(infoEmpresa.fechaFinOferta)
        // Obtenemos el formato YYYY-MM-DD omitiendo las horas del "moment"
        //let fecha = `${now.year()}-${now.month() < 9 ? `0${now.month() + 1}` : now.month() + 1}-${now.date()}`
        //const fechaActual = moment(fecha)
        const fechaActual = moment(now.format("YYYY-MM-DD"))

        if( infoEmpresa.imgOferta !== '' &&
            infoEmpresa.mostrarOferta === true &&
            fechaInicio <= fechaActual &&
            fechaActual <= fechaFin){
            return true;
        }
        
        return false;
    }

    function recuperarPassword(correo) {
        setCorreo(correo);
        setOpenOpciones({ ...openOpciones, formulario: "RecuperarPassword", open: true, descripcion: "RecuperarPassword" })
        //setOpenOpciones({...openOpciones, formulario: "alerta", open: true, descripcion: "RecuperarPassword" })
    }
    function enviarAlerta(correo) {
        setCorreo(correo);
        setOpenOpciones({ ...openOpciones, formulario: "alerta", open: true, descripcion: "RecuperarPassword" })
        //setOpenOpciones({...openOpciones, formulario: "alerta", open: true, descripcion: "RecuperarPassword" })
    }
    function regresar(correo) {
        setCorreo(correo);
        setOpenOpciones({ ...openOpciones, formulario: "login", open: true, descripcion: "login" })
    }

    return(<>
    {(openOferta) && showOferta() && 
            <Modal
                claseAux="cOfertas"
                titulo="Aprovecha esta gran oferta"
                cModalChico={true}
                open={openOferta}
                cerrar={() => setOpenOferta(!openOferta)}
                >
                <div className='contenedorImagen'>
                    <img className='imagenOferta' src={infoEmpresa.imgOferta} alt="Imagen de Oferta"></img>
                </div>
            </Modal>
        }
    {(openOpciones.open && openOpciones.formulario === "login") ?
            <Modal
                claseAux="cIniciaSesion"
                titulo="INICIA SESIÓN"
                cModalChico={true}
                title={openOpciones.descripcion}
                open={openOpciones.open}
                cerrar={() => setOpenOpciones(cleanOpenOpciones)}>
                <Login onClose={() => setOpenOpciones(cleanOpenOpciones)}
                    abrirRegistro={() => setOpenOpciones({ ...openOpciones, formulario: "registro", open: true })}
                    correo={correo}
                    onRecuperar={correo => {
                        recuperarPassword(correo);
                    }}
                    setOpenOferta={setOpenOferta}
                    propsAccionLogin = {propsAccionLogin}
                    propsRequiereAccionLogin = {propsRequiereAccionLogin}
                />
            </Modal> :
            <></>
        }
        {
            (openOpciones.open && openOpciones.formulario === "alerta") &&
            <AlertaImagen
                open={openOpciones.open}
                titulo={"Revisa tu correo y sigue las instrucciones"}
                correo={correo}
                cerrar={() => setOpenOpciones(cleanOpenOpciones)}
            />
        }
        {
            (openOpciones.open && openOpciones.formulario === "RecuperarPassword") &&
            <RecuperarPassword
                open={openOpciones.open}
                correo={correo}
                cerrar={() => setOpenOpciones(cleanOpenOpciones)}
                onEnviar={correo => {
                    enviarAlerta(correo)
                }
                }
                regresarPag={correo => regresar(correo)}
            />
        }

        {(openOpciones.open && openOpciones.formulario === "registro") &&
            <Modal
                claseAux="cRegistro"
                titulo={"CREAR UNA CUENTA"}
                cModalChico={true}
                open={openOpciones.open}
                onClose={() => setOpenOpciones(cleanOpenOpciones)}
                cerrar={() => setOpenOpciones(cleanOpenOpciones)}>
                <Registro onClose={() => setOpenOpciones(cleanOpenOpciones)} />
            </Modal>
        }
    </>)
}