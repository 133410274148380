import React from 'react'

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";

// @material-ui/icons
import Close from "@material-ui/icons/Close";

// core components
import Button from "../../components/CustomButtons/Button.js";

import styles from "../../assets/jss/material-dashboard-pro-react/views/notificationsStyle.js";

const useStyles = makeStyles(styles);

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

export default function Modal(props) {

    let { title, open = true, onClose = (evt) => console.log(evt), children } = props;

    const classes = useStyles();

    return (<Dialog
        classes={{ root: classes.center + " " + classes.modalRoot, paper: classes.modal }}
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={null}
        aria-labelledby="classic-modal-slide-title"
        aria-describedby="classic-modal-slide-description"
        maxWidth={false}
        fullWidth={true}
        scroll="body"
    >
        <DialogTitle
            id="classic-modal-slide-title"
            disableTypography
            className={classes.modalHeader}
        >
        <Button
            justIcon
            className={classes.modalCloseButton}
            key="close"
            aria-label="Close"
            color="transparent"
            onClick={onClose}
        >
            <Close className={classes.modalClose} />
        </Button>
        <h4 className={classes.modalTitle}>{title}</h4>
        <hr />
        </DialogTitle>
        <DialogContent
            id="classic-modal-slide-description"
            className={classes.modalBody}
        >
            { children && children }
        </DialogContent>
    </Dialog>);
}
