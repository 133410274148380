///import config from './config'

//SOFTWONE
//Configuracion de produccion
//Llave publica key_evIdNmYzqQwN5mkyQS7qXO5
//Llave privada key_Fo5gtYX4mlHBDHR1AQKmE6
//////////////////////////////////////////////////////////////////////////
//Configuracion de pruebas
//Llave publica key_Fx3XueouZjJb88jlxw5u9mb
//Llave privada key_rP1I2PHlNEqxl5JZgrRtvH
//////////////////////////////////////////////////////////////////////////
// Configuracion de pruebas INTERHECO:
// Llave privada: key_s0wFBsDreLxG6hibp8iy7HJ
// Llave publica: key_FmEZFQXRXcRUC57gRZ6CSAq
//////////////////////////////////////////////////////////////////////////
// Configuracion de produccion INTEHECO
// Llave privada: key_0xFXhCyINP3AMqVSWI4U15u
// Llave publica: key_criqpHvoCIEX3w4MAKvktwm
//////////////////////////////////////////////////////////////////////////
//Llave de prueba general
//key_Ayxq6eHvasZqzxxBLz5jq4A
//////////////////////////////////////////////////////////////////////////

export const privateKey = window.location.hostname == 'interheco.com.mx' ?  'key_0xFXhCyINP3AMqVSWI4U15u' : 'key_s0wFBsDreLxG6hibp8iy7HJ';
export const publicKey = window.location.hostname == 'interheco.com.mx' ? 'key_criqpHvoCIEX3w4MAKvktwm' : 'key_FmEZFQXRXcRUC57gRZ6CSAq';


export const publicKeyBASE64 = Buffer.from(publicKey).toString('base64');
export const privateKeyBASE64 = Buffer.from(privateKey).toString('base64');
const conektaHelper = {
  initConekta: () => {
    window.Conekta.setPublicKey(publicKey)
  },
  getCardBrand: (cardNumber) => {
    return window.Conekta.card.getBrand(cardNumber)
  },
  validateCardNumber: (cardNumber) => {
    return window.Conekta.card.validateNumber(cardNumber)
  },
  validateCvc: (cvc) => {
    return window.Conekta.card.validateCVC(cvc)
  },
  validateExpirationDate: (expiryMonth, expiryYear) => {
    return window.Conekta.card.validateExpirationDate(expiryMonth, `20${expiryYear}`)
  },
  tokenize: (cardNumber, cardHolder, expiryMonth, expiryYear, cvc, successCallback, errorCallback) => {
    const tokenParams = {
      card: {
        number: cardNumber,
        name: cardHolder,
        exp_year: expiryYear,
        exp_month: expiryMonth,
        cvc
      }
    }
    window.Conekta.Token.create(tokenParams, successCallback, errorCallback)

    return [successCallback, successCallback];
  }
}

export default conektaHelper