import React, { useState, useRef, useEffect } from 'react';
import { Switch, Redirect } from 'react-router-dom';
import {Validaciones} from '@renedelangel/helpers';
// master components
import Tabla from '../../_layout/masterComponents/Tabla';
import FormularioModal from '../../_layout/masterComponents/FormularioModal';

// Redux
import { useSelector } from "react-redux";

//generic components
import SweetAlert from '../../_layout/genericComponents/ModalConfirmacion';
import { NetworkError } from "../../_layout/genericComponents/Metodos";
import Preloader from '../../_layout/genericComponents/Preloader';

// material-ui icons
import Add from "@material-ui/icons/Add";
import Edit from "@material-ui/icons/Edit";
import DeleteIcon from '@material-ui/icons/Delete';
import SyncIcon from '@material-ui/icons/Sync';
import SaveIcon from '@material-ui/icons/Save';
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import CloseIcon from '@material-ui/icons/Close';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import OfflinePinIcon from '@material-ui/icons/OfflinePin';


import { getUsuarios, tablaUsuarios, correoActivaCuenta } from '../../querys/Usuario/metodos';
import { addUsuarioPorAdmin, updateUsuarios, deleteUsuarios, updateUsuariosEstatus } from '../../mutations/Usuario/metodos';
import {selectEnumPerfilSinUsuario} from '../../querys/Enums/metodos';

import { handleFocus, handleErrorInputText, handleModificar, handleAlertGeneric, handleAlertMutationGeneric, handelAlertEliminar, handleEliminar, handleGuardar } from '../../_layout/helpers/handles';
import { info, danger, success, primary, rose } from '../../_layout/helpers/colores';

import { rgxCorreo } from '../../helpers/regexp';

import PopUpAgregarDireccion from '_layout/NuevosComponentes/PopUpAgregarDireccion';

import { tablaDireccion } from "../../querys/Direcciones/metodos";

import { deleteDireccion } from 'mutations/Direcciones/metodos';

import { getFacturacion, getAllFacturacion, tablaFacturacion } from 'querys/Facturacion';
import { deleteFacturacion, cambiarEstatusFacturacion } from 'mutations/Facturacion';

const { trim } = Validaciones;

export default function Facturacion() {

    const { usuario, token } = useSelector(state => state.login);

    const [open, setOpen] = useState(false);
    const [alert, setAlert] = useState(null);
    const [actualizar, setActualizar] = useState(false);
    const [direccionID, setDireccionID] = useState(null);

    const acciones = [{
        icono: OfflinePinIcon,
        color: info,
        id: 'cambiarEstatus',
        descripcion: "Cambiar estatus",
        parametros: [{ campo: "factVentPendID", variable: "factVentPendID" }],
        disabled: { multiseleccion: true },
        onClick: (parametros) => {

            handleAlertGeneric({
                setAlert,
                mensaje: {
                    title: "¿Estas seguro?",
                    descripcion: `Estás a punto de cambiar el estatus al registro`,
                    msjConfirmacion: `¡Si!`,
                    msjCancelacion: "No"
                },
                onCancel: () => setAlert(null),
                onConfirm: () => handleAlertMutationGeneric({
                    token, setAlert, setActualizar, actualizar,
                    mensajes: {
                        msjEspera: { descripcion: "Espere unos momentos estamos cambiando el estatus" },
                        msjCorrecto: {
                            title: "¡Se cambio el estatus!",
                            descripcion: `El estatus se cambio exitosamente`,
                            msjConfirmacion: "Aceptar"
                        }
                    },
                    parametros:{input: parametros}, asyncMutation: cambiarEstatusFacturacion /*setErrorToken*/
                })
            })
        }
    }/* ,{
        icono: DeleteIcon,
        color: danger,
        descripcion: "Eliminar",
        parametros: [{ campo: "factVentPendID" }],
        disabled: { multiseleccion: true },
        onClick: ({factVentPendID}) => handelAlertEliminar({
            setAlert,
            mensaje: `la dirección de facturación`,
            onCancel: () => setAlert(null),
            onConfirm: () => handleEliminar({
                token, setAlert, setActualizar, actualizar,
                parametros: {factVentPendID}, asyncDelete: deleteFacturacion,
                msjCorrecto : { title: `Se elimino el registro`, descripcion: `Se ha logrado eliminar el pendiente de facturación de forma correcta`, tipo: success, msjConfirmacion: `Aceptar` }
            })
        })
    } */];

    const botones = [{
        icono: SyncIcon,
        color: info,
        descripcion: "Actualizar",
        onClick: () => setActualizar(!actualizar),
        disabled: { multiseleccion: true }
    }];

    const infoTabla = {
        botones,
        acciones,
        actualizar,
        id: "tablaFacturacion",
        color: primary,
        title: "Facturación",
        iconTable: <SupervisorAccountIcon />,
        headers: [
            { variable: 'factVentPendID', descripcion: 'ID', hide: true},
            { variable: 'rfc', descripcion: 'RFC Cliente', hide: false},
            { variable: 'fechaTicket', descripcion: 'Fecha de venta', hide: false},
            { variable: 'totalTicket', descripcion: 'Total del Ticket', hide: false},
            { variable: 'pendiente', descripcion: 'Pendiente', hide: true},
            { variable: 'referenciaTicket', descripcion: 'Referencia de Ticket en ERP', hide: false},
            { variable: 'estatus', descripcion: 'Estatus', hide: false}
        ],
        accionesEffect:
        [
        { botones: ["cambiarEstatus"], ocultar: (data) => data["estatus"].value == 'Finalizado'  ? true: false} 
        //{ botones: ["Devolucion"], ocultar: (data) => data["estatus"].value == "Sol. Devolucion" ? true: false},
        ],
        formato: [
            {
                columnas: ["totalTicket"], tipo: "moneda"
            },
            /* {
                columnas: ["fechaTicket"], tipo: "fecha"
            } */
        ]
    }

    useEffect(() => {
        async function ftLimpiarInformacion(){
            if(open == false){
                setDireccionID(null);
            }
        }
        ftLimpiarInformacion()
    }, [open])

    return(<>
        <Tabla
            infoTabla={infoTabla}
            asyncData={tablaFacturacion}
            token={token}
        />
        
            {alert && <SweetAlert
            title={alert.title}
            descripcion={alert.descripcion}
            tipo={alert.tipo}
            msjConfirmacion={alert.msjConfirmacion}
            msjCancelacion={alert.msjCancelacion}
            onConfirm={alert.onConfirm}
            showConfirm={alert.showConfirm}
            showCancel={alert.showCancel}
            onCancel={() => setAlert(null)}
            />}
    </>);

}