import React, { useState, useEffect } from 'react';
import './estilos.css';
import Input from '../../NuevosComponentes/Input';
import MiniBoton from '../../NuevosComponentes/MiniBoton';
import SearchIcon from '@material-ui/icons/Search';
import Typography from '@material-ui/core/Typography';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Paper from '@material-ui/core/Paper';
import Grow from '@material-ui/core/Grow';
import Popper from '@material-ui/core/Popper';
import Divider from '@material-ui/core/Divider';
import Dashboard from '@material-ui/icons/Dashboard';
import { MenuLineal } from '../../../componentes/MenuLineal/MenuLineal.js';
import Center from '../Center';

import { white } from '../../helpers/colores';
import classNames from "classnames";

// you would import our component like this
import { makeStyles } from "@material-ui/core/styles";
import styles from '../../assets/jss/material-dashboard-pro-react/generic/appBarStyle.js';
import Encriptar from '@renedelangel/helpers/lib/Encriptar';
import { useSelector, useDispatch } from 'react-redux';
import {
    setToken,
    setUsuario,
    setConfiguracion,
    setFechasFiltro
} from '../../../redux/actions/loginAction';
import { vectorMenuIcon } from '../../assets/svg/exportsvg';
import MenuResponsive from '../../NuevosComponentes/MenuResponsive/index'
import { setCarrito } from '../../../redux/actions/carritoActions';
import { getCarrito } from 'querys/Carrito';
import './estilosDropDownMenu.css';


const useStyles = makeStyles(styles);

function HeaderBusqueda2({ children, /* window, */ brand, color = white, titleBusqueda = "", placeholderBusqueda = "", tipoBusqueda, dataBusqueda = [],
    acciones = [], onSearch = (evt) => console.log(evt), redirigir, clase = "", paginaPrincipal = "LandingPage", setOpenOpciones, token, usuario }) {
    const classes = useStyles();
    const cleanSearch = tipoBusqueda === "autocomplete" ? null : "";
    const [search, setSearch] = useState(cleanSearch);
    const tipografia = {
        align: "center",
        variant: "h5",
        component: "h5"
    };

    const [listadoOpen, setListadoOpen] = React.useState(null);
    const handleClickListado = (event, open, callback) => {
        if (open && (listadoOpen && listadoOpen.contains(event.target))) setListadoOpen(event.currentTarget);
        else setListadoOpen(event.currentTarget);
        if (callback) callback();
    };

    let seed = 'c29mdHdvbmVAdG9ubGluZQ=='
    /*   const { usuario, token } = useSelector(state => state.login); */
    const dispatch = useDispatch();
    const setTokenRedux = (token) => dispatch(setToken(token));
    const setUsuarioRedux = usuario => {
        dispatch(setUsuario(usuario));
    };
    const setCarritoRedux = (carritoUsuario) => dispatch(setCarrito(carritoUsuario));

    const [openMenuResponsive, setOpenMenuResponsive] = useState(false);

    const dropdownItem = classNames(classes.dropdownItem, classes.primaryHover);
    let newActions = null;
    if (Array.isArray(acciones) && acciones.length > 0) {
        newActions = acciones.map(({
            tipo, descripcion, onClick = (evt) => console.log(evt),
            openEvento = () => console.error("La propiedad openEvento no recibe nada para poder abrir las opciones"),
            open = false, notificaciones, listado, ...rest
        }, key) => {
            let props = {}
            if (notificaciones) props = { ...props, "aria-haspopup": "true" };

            switch (tipo) {
                case "listado":
                    return (<>
                        <MiniBoton
                            colorFondo="transparent"
                            /* titulo={descripcion} */
                            onClick={(evt) => handleClickListado(evt, open, () => openEvento(evt))}
                            icono={
                                rest.icono
                                    ? <rest.icono style={{ color }} />
                                    : <Dashboard className={classes.headerLinksSvg + ' ' + classes.links} />
                            }
                        >
                            {notificaciones ? <div className="cNotificacionBoton">{notificaciones}</div> : <></>}
                        </MiniBoton>
                        {
                           open && 
                           <div>
                            <div className='cCloseOnAway' onClick={(evt) => handleClickListado(evt, open, () => openEvento(evt))}></div>
                           <div className='citemMenuDD unselectable'>
                                {
                                    listado.map(({ descripcion, onClick = (evt) => console.log(evt), division }, index) => {
                                        if (division) return (<Divider key={index} light />);
                                        return (<div className='cEachItemMenuDD unselectable' key={index} onClick={onClick}>{descripcion}
                                        </div>);
                                    })
                                }
                            </div>
                            </div>
                        }
                        </>
                        );
                    break;
                case "boton":
                    return (<>
                        <MiniBoton
                            colorFondo="transparent"
                            titulo={descripcion}
                            onClick={onClick}
                            icono={
                                rest.icono
                                    ? <rest.icono style={{ color }} />
                                    : <Dashboard className={classes.headerLinksSvg + ' ' + classes.links} />
                            }
                        >
                            {notificaciones ? <div className="cNotificacionBoton">{notificaciones}</div> : <></>}
                        </MiniBoton>
                    </>)
                    break;
                case "label":
                    return (
                        <div className="cLabelMonto noselect" onClick={onClick}>
                            <div className="cLabelAux" >
                                {descripcion}
                            </div>
                        </div>
                    );
            }
        })
    }

    function ftMarca(enlace) {
        let imagen = <img style={{ width: "90%", maxWidth: "280px" }}
            src={brand.logoUrl} alt="logotipo" />;
        return enlace ? <a href={enlace} >{imagen}</a> : imagen;
    }

    function localChangeBusqueda(value) {
        if (tipoBusqueda === "autocomplete") {
            setSearch(value);
            onSearch(value);
        } else {
            setSearch(value)
        }
    }

    useEffect(() => {
        async function ftForzarCierreSesion() {
            if (token !== null) {
                let tokenValido = Encriptar.ValidarToken({ token, seed })
                if (!tokenValido) {
                    setTokenRedux(null);
                    setUsuarioRedux(null);
                    localStorage.clear();
                    window.location.reload()
                }else {
                    let carritoSelec = await getCarrito({
                        usuarioID: Number(usuario.usuarioID)
                    }, token);
                    setCarritoRedux(carritoSelec.listado);
                }
            } 
        }
        ftForzarCierreSesion();
    }, [])



    return (<>
        <div className="cContenedorHeader">
            <div className="cContenedorHeaderV1">
                <div className="cContenedorHeaderLogo">
                    <div className='c_menu_button' onClick={() => { setOpenMenuResponsive(!openMenuResponsive) }}>
                        {vectorMenuIcon}
                    </div>
                    {brand.tipo === "imagen" ? <Center>{ftMarca(brand.enlace)}</Center> : <Typography {...tipografia}>{brand.descripcion.toString().toUpperCase()}</Typography>}
                </div>
                <div className="cHeaderLogoMenu">
                    <div className="cContenedorHeaderBusqueda">
                        <Input
                            claseAuxInput="cInputBusqueda"
                            placeholder={"Buscar"}
                            value={search}
                            data={dataBusqueda}
                            onChange={({ target }) => localChangeBusqueda(target.value)}
                            onKeyEnter={() => { if (search.replace(/[&\/\\#,+()$~%.'":*?<>{}]/g, '') !== '') { onSearch(search) } }}
                            onClickIcono={() => { if (search.replace(/[&\/\\#,+()$~%.'":*?<>{}]/g, '') !== '') { onSearch(search) } 
                        }}
                            icono={<SearchIcon style={{ color: 'var(--primaryColor)' }} />}
                        />
                    </div>
                    <div className="cContenedorHeaderMenu">
                        {!(token) &&
                            <div className="cContenedorSesion">
                                <div className="cRegistrarse" onClick={() => setOpenOpciones({ open: true, formulario: "registro", descripcion: "Registrarse" })}>
                                    Registrarse
                                </div>
                                <div className="cIniciarSesion" onClick={() => setOpenOpciones({ open: true, formulario: "login", descripcion: "Iniciar sesión" })}>
                                    Iniciar sesión
                                </div>
                            </div>}
                        {newActions}
                    </div>
                </div>
            </div>
            <div className="cContenedorHeaderMenuLineal">
                <div className="cContenedorHeaderMenuLinealAux">
                    {MenuLineal(redirigir, paginaPrincipal)}
                </div>
            </div>
            {
                openMenuResponsive ?
                    <MenuResponsive
                        paginaPrincipal={paginaPrincipal}
                        redirigir={redirigir}
                        openMenuResponsive={openMenuResponsive}
                        setOpenMenuResponsive={setOpenMenuResponsive}
                    />
                    :
                    <>
                    </>
            }
        </div>
        {children}

    </>);
};

export default HeaderBusqueda2;
