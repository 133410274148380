import React, { useState, useEffect } from "react";
// used for making the prop types of this component
import PropTypes from "prop-types";

//material ui
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import DeleteIcon from '@material-ui/icons/Delete';
// import AddAPhotoIcon from '@material-ui/icons/AddAPhoto';
import SwitchCameraIcon from '@material-ui/icons/SwitchCamera';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';

// core components
import Button from "../../components/CustomButtons/Button.js";

import defaultImage from "../../assets/img/image_placeholder.jpg";
import defaultAvatar from "../../assets/img/placeholder.jpg";
import { makeStyles } from "@material-ui/core/styles";

const styles = {
  btnAgregar: {
   width: 100,
   height:20,
   fontSize: "0.5em"
  },
  btnCambiar: {
   width: 80,
   height:20,
   fontSize: "0.5em"
  },
  foto: {
   width: 80,
   maxHeight:20,
   fontSize: "0.5em"
  }
};
const useStyles = makeStyles(styles);

export default function FileUpload(props) {

  const classes = useStyles();
  const [file, setFile] = useState(null);
  const [imagePreviewUrl, setImagePreviewUrl] = useState(
        props.avatar ? defaultAvatar : defaultImage
  );
 ;

  let fileInput = React.createRef();



  const handleImageChange = e => {
    try{
      e.preventDefault();
      let reader = new FileReader();
      let file = e.target.files[0];
      let vFile = file.type.split('/');

      if(vFile[1] === 'pdf'){
        reader.onloadend = () => {
          setFile(file);
          if(props.onChange) props.onChange(file, reader.result);
          setImagePreviewUrl(reader.result);
        };
        reader.readAsDataURL(file);
      }
      else {
        alert('Formato no valido');
      }
    }catch(error){
      console.log(error);
    }
  };
  // eslint-disable-next-line
  const handleSubmit = e => {
    e.preventDefault();
    // file is the file/image uploaded
    // in this function you can save the image (file) on form submit
    // you have to call it yourself
  };
  const handleClick = () => {
    fileInput.current.click();

  };
  const handleRemove = () => {
    setFile(null);
    if(props.onChange) props.onChange(null);
    setImagePreviewUrl(props.avatar ? defaultAvatar : defaultImage);
    fileInput.current.value = null;
    fileInput.current.base = null;
  };
  let { avatar, addButtonProps, changeButtonProps, removeButtonProps } = props;

  function ftEffect() { if(!props.removeImage && file) handleRemove(); }

  useEffect(ftEffect, [props.removeImage]);

  return (
    <div className="fileinput text-center">
      <input type="file" onChange={handleImageChange} ref={fileInput} className={classes.foto} accept=".pdf"
      />

      <div className={"thumbnail" + (avatar ? " img-circle" : "")}>
          {/* <embed
              src={props.value ? props.value : imagePreviewUrl}
              type={props.value ? "application/pdf" : "image/jpeg"}   
              height="200px"
          /> */}
          {/* <object
              data={props.value ? props.value : imagePreviewUrl}
              type={props.value ? "application/pdf" : "image/jpeg"}   
              height="200px"
          >
            <div>
              No soporta vista de navegador
            </div>
          </object> */}
          <iframe
              src={props.value ? props.value : imagePreviewUrl}
              height="200px"
          ></iframe>
      </div>

      <div>
        {file === null && !props.value ? (
          <Button {...addButtonProps} disabled={props.disabled} onClick={() => handleClick()} className={classes.btnAgregar}>
            <PictureAsPdfIcon/> {avatar ? "Agregar PDF" : "Agregar"}
          </Button>
        ) : (
          <span>
            <Button {...changeButtonProps} disabled={props.disabled} onClick={() => handleClick()} className={classes.btnCambiar}>
            <SwitchCameraIcon /> Cambiar
            </Button>
            {avatar ? <br /> : null}
            <Button {...removeButtonProps} disabled={props.disabled} onClick={() => handleRemove()} className={classes.btnCambiar}>
              <i className="fas fa-times"/>
            <DeleteIcon />Remover
            </Button>
          </span>
        )}
      </div>
    </div>
  );
}

FileUpload.propTypes = {
  avatar: PropTypes.bool,
  addButtonProps: PropTypes.object,
  changeButtonProps: PropTypes.object,
  removeButtonProps: PropTypes.object
};
