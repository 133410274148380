import {
    defaultFont,
    primaryColor,
    defaultBoxShadow,
    infoColor,
    successColor,
    warningColor,
    dangerColor,
    whiteColor,
    roseColor,
    blackColor,
    grayColor
} from "../../material-dashboard-pro-react.js";

const detalleStyle = theme => ({
    managerClasses: {
        [theme.breakpoints.up("xs")]: {
            display: "inline-block"
        }
    },
    linkText: {
        zIndex: "3",
        ...defaultFont,
        fontSize: "14px",
        margin: "0!important",
        textTransform: "none"
    },
  
    links: {
        width: "20px",
        height: "20px",
        zIndex: "4",
        [theme.breakpoints.down("sm")]: {
            display: "block",
            width: "30px",
            height: "30px",
            color: "inherit",
            opacity: "0.8",
            marginRight: "16px",
            marginLeft: "-5px"
        }
    },
    datosPrincipales:{     
      
        display: "flex",  
       
    },   
    inputs:{
        padding: "6px",
        fontSize: "10",
        marginBottom: "5px",
        border: "unset",
       // borderBottom: "solid 0.1em  " ,
        borderColor: "gray",
        fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
        fontColor: grayColor[4],
        display: "block",
        width: "100%",                 
    },
    inputsTexto:{
        padding: "6px",
        fontSize: "10",
        marginBottom: "5px",
        border: "unset",
        border: "solid 0.1em " ,
        borderColor: "gray",
        fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
        fontColor: grayColor[4],
        display: "block",
        width: "100%",                 
    },
    divCorto:{
       width: "30%",
       marginRight: "auto"  
      // margin:"3px"
    },
    divLargo:{ 
       width: "100%", 
     //  margin: "3px",
     
    },
    tipoEntrega:{
        fontSize: "16px",
        fontWeight: "600",
        width: "100%",
        textAlign: "center",
        margin: "5px 0px 5px 0px",    
        backgroundColor: grayColor[4],//"var(--ColorFondoMenu)",
        padding: "4px",
        fontColor: "var(--ColorLetrasMenu)"      
    },
    devolucion:{
        width: "100%",      
    },
    tituloDevolucion: {
        width: "100%",
        borderWidth: "1px 1px 0px 1px",
        borderStyle: "solid",
        borderColor: grayColor[0],      
        color: blackColor,    
        fontWeight: "600",

    },
    inputDevolucion: {
        width: "100%",      
        height: "60px",
        resize: "none",
        border: "solid 0.1em  " ,
        borderColor: grayColor[0],     
        fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',   
    },
    seleccionable: {      
        padding: "5px",  
        marginBottom: "5px",        
        cursor: "pointer",
        userSelect: "none"
    },
    estatusPago:{      
        fontSize: "16px",
        fontWeight: "600",
        width: "100%",
        textAlign: "center",
        margin: "5px 0px 5px 0px",    
        backgroundColor: grayColor[5],
        fontColor:"white"       
    },
    botones:{      
        fontSize: "16px",
        fontWeight: "600",
        width: "100%",
        textAlign: "center",       
        padding: "10px",
        display: "flex" ,     
        alignItems: "center",
        "@media (max-width: 1200px)": {
            flexDirection: "column" ,
            margin: "5px 0px 5px 0px",  
        },       
        "@media (min-width: 1201px)": {           
            flexDirection: "row" ,
            margin: "20px 0px 5px 0px",  
        },     
       
    },
    multiline:{
        padding: "10px",
        fontSize: "10",
       // marginBottom: "10px",      
        fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
        fontColor: "gray",
        minWidth: "350px",
        height: "5em",
       // display: "flex",
       // position: "absolute"
    },
    labels:{
        padding: "5px",
        fontSize: "9",    
        minWidth: "100px", 
        color: "#3D3B3B",
        fontWeight: "600",       
        fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif', 
      //  position: "absolute",
       // float:"left",
        display: "inlineBlock"    
    },
    tablaTotales:{
        position:"absolute",
        float: "right"
    },
    notifications: {
        zIndex: "4",
        position: "absolute",
        top: "5px",
        border: "1px solid " + whiteColor,
        right: "5px",
        fontSize: "9px",
        background: dangerColor[0],
        color: whiteColor,
        minWidth: "16px",
        height: "16px",
        borderRadius: "10px",
        textAlign: "center",
        lineHeight: "14px",
        verticalAlign: "middle",
        display: "block"
    },
    primary: {
        backgroundColor: primaryColor[0],
        color: whiteColor,
        ...defaultBoxShadow
    },
    contenido: {      
        width:"100%",
        background: "gray",
        display: "flex",      
        height: "100%",    
        fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif', 
        "@media (max-width: 1200px)": {
            flexDirection: "column" ,
        },       
        "@media (min-width: 1201px)": {           
            flexDirection: "row" ,
        },
        "&:before,&:after": {
        display: "table",
        content: '" "'
        },
        "&:after": {
        clear: "both"
        }   
    },
    datos:{
       padding: "10px 10px 10px 10px",
    },
    titulos:{
        width: "100%",
        padding: "10px",
        fontSize: "14px",
        fontWeight: "800",
        borderBottom: "solid 0.1em",
        borderColor: grayColor[0],

    },
    panelDatos: {
       // float:"left",
        width: "50%",
        background: "white",
        marginTop: "10px",      
        marginLeft: "10px", 
        "@media (max-width: 1200px)": {
        width: "100%",
        margin: "0px",
        padding: "15px",
        },       
        "@media (min-width: 1201px)": {
        width: "43%"
        },
        "&:before,&:after": {
        display: "table",
        content: '" "'
        },
        "&:after": {
        clear: "both"
        }
    },
    panelProductos:{
      //  width: "50%",
        background: "white",
        float:"right",       
        flexGrow: "100",
        "@media (max-width: 1200px)": {
       // width: "100%",
        marginTop: "5px",
        },       
        "@media (min-width: 1201px)": {
            marginLeft: "10px",
            marginTop: "10px",
            marginRight: "10px",

        },
        "&:before,&:after": {
        display: "table",
        content: '" "'
        },
        "&:after": {
        clear: "both"
        }
    }
        
    })

export default detalleStyle;
