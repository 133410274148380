import { FetchAPI } from "@renedelangel/helpers";

import { urlServidor } from '../../helpers';

const GET_FACTURACION = `
query getFacturacion($filtro: InputGetFacturacion){
    getFacturacion(filtro: $filtro){
        factVentPendID
        rfc
        fechaTicket
        totalTicket
        pendiente
        referenciaTicket
        estatus
    }
}
`
const GETALL_FACTURACION = `
query getAllFacturacion($filtro: InputGetFacturacion){
    getAllFacturacion(filtro: $filtro){
        factVentPendID
        rfc
        fechaTicket
        totalTicket
        pendiente
        referenciaTicket
        estatus
    }
}
`
const TABLA_FACTURACION = `
query filterFacturacion($filtro: InputFilterFacturacion){
    filterFacturacion(filtro: $filtro){
        listado{
            factVentPendID
            rfc
            fechaTicket
            totalTicket
            pendiente
            referenciaTicket
            estatus
        }
        registros
        paginas
    }
}
`

export const getFacturacion = async (variables, token) => {
    try {
        let { getFacturacion } = await FetchAPI.graphql({
            token,
            variables,
            url: urlServidor,
            query: GET_FACTURACION
        })
        return getFacturacion;
    }
    catch({ message }) { throw new Error(message) }
}

export const getAllFacturacion = async (variables, token) => {
    try {
        let { getAllFacturacion } = await FetchAPI.graphql({
            token,
            variables,
            url: urlServidor,
            query: GETALL_FACTURACION
        })
        return getAllFacturacion;
    }
    catch({ message }){ throw new Error(message) }
}

export const tablaFacturacion = async (variable, token) => {
    try {
        
        let {
            factVentPendID,
            rfc,
            fechaTicket,
            totalTicket,
            pendiente,
            referenciaTicket,
            pagina,
            limite 
        } = variable.filtro;

        let filtro = {
            factVentPendID: factVentPendID === null ? null : Number(factVentPendID),
            rfc,
            fechaTicket,
            totalTicket,
            pendiente,
            referenciaTicket,
            pagina,
            limite 
        }

        let variables = {filtro};

        let { filterFacturacion } = await FetchAPI.graphql({
            token,
            variables,
            url: urlServidor,
            query: TABLA_FACTURACION
        });
        return filterFacturacion;

    }
    catch({ message }) { throw new Error(message) }
}