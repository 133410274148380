import React,{useState} from "react";
import './estilos.css';
import phone from '../../assets/svg/phone.svg';
import mail from '../../assets/svg/mail.svg';
import fb from '../../assets/svg/fb.svg';
import pdf from '../../assets/svg/down_pdf.svg';
import PopUpSusccribirse from "../../../_layout/NuevosComponentes/PopUpSuscribirse/index.jsx";

export default function Footer({ logoFooter, telefono, correo, facebookID, facebookLink, avisoLink=null, politicaLink=null }) {
    const [openSuscribirse, setOpenSuscribirse] = useState(false);

    let movil = false;
    if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
        movil = true;
    }

    return (
        <>
        <div className="contenedorFooter noselect">
            <div className="contenedorFooterAux">
                <div className="cLogoFooter">
                    <img style={{ width: "100%", minWidth: '180px', maxWidth: '280px', margin: '0 auto'}}
                        src={logoFooter} alt="logotipo" />
                </div>
                <div className="cSusFooter">
                    <div className="cLabelSus">
                        Recibe nuestras ofertas especiales
                    </div>
                    <div className="cBtnSus" onClick={()=>{setOpenSuscribirse(true)}}>
                        <div className="cTituloBtnSus">
                            Suscribirse
                        </div>
                    </div>
                    <div className="cLabelDown">
                        Descarga nuestro Aviso de Privacidad y Política de Compras
                    </div>
                    <div className="cBtnsDescargas">
                        {   (avisoLink) &&
                            <a className="cBtnDown"
                                href={avisoLink}
                                download={"Aviso de Privacidad.pdf"}
                                target="_blank">
                                <div className="cTituloBtnDown">
                                    Privacidad
                                    <img src={pdf} className="cLogoPDF"/>
                                </div>
                            </a>
                        }
                        
                        {   politicaLink &&
                            <a className="cBtnDown"
                                href={politicaLink}
                                download={"Politica de Compras y Devoluciones.pdf"}
                                target="_blank">
                                <div className="cTituloBtnDown">
                                    Política
                                    <img src={pdf} className="cLogoPDF"/>
                                </div>
                            </a> 
                        }                              
                    </div>
                </div>
                <div className="cInfoContactoFooter">
                    <div className="cInfoContactoFooterAux">
                        {telefono &&
                            <div className="cIcoContactoFooter" title={telefono}>
                                <img className="cIcoFooter" src={phone} alt="Teléfono" onClick={() => window.open('tel:' + telefono, '_self')} />
                            </div>}
                        {correo &&
                            <div className="cIcoContactoFooter">
                                <img className="cIcoFooter" src={mail} alt="Correo" onClick={() => window.open("mailto:" + correo, '_self')} />
                            </div>}
                        {(facebookID || facebookLink) &&
                            <div className="cIcoContactoFooter">
                                {((movil && facebookID) &&
                                    <img className="cIcoFooter" src={fb} onClick={() => window.open("fb://page/" + facebookID, '_self')} />)}
                                {((!movil && facebookLink) &&
                                    <a href={facebookLink} target="_blank" rel="noopener noreferrer" >
                                        <img className="cIcoFooter" src={fb} />
                                    </a>)}
                            </div>}
                    </div>
                </div>
            </div>
            <div className="contenedorFooterDerechos">
                Todos los derechos reservados
            </div>
        </div>
        {
            openSuscribirse? <PopUpSusccribirse loginInside={setOpenSuscribirse} /> : <></>  
          }
        </>
    );
}