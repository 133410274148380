import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Alert from '@material-ui/lab/Alert';
import CheckIcon from '@material-ui/icons/Check';
import Snackbar from '@material-ui/core/Snackbar';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';

const useStyles = makeStyles((theme) => ({
  root: {
    width: 'auto',    
    '& > * + *': {
      marginTop: theme.spacing(20),
    },
  },
}));

export default function AlertaGenerica({  visible, descripcion, tipo="success", ocultarAl = 3000, handleClose  }) {
const classes = useStyles();

const [open, setOpen] = useState(false);
  //success,  error, warning, info
   return (
    <div className={classes.root}>
       <Snackbar open={visible} autoHideDuration={3000} anchorOrigin={{ vertical: "top", horizontal: "center" }}  onClose={handleClose} >
            <Alert autoHideDuration={3000} variant="filled" onClose={handleClose} severity={tipo}>
                {descripcion}
            </Alert>
        </Snackbar> 
    </div>
  );
}