import {
    defaultFont,
    primaryColor,
    defaultBoxShadow,
    infoColor,
    successColor,
    warningColor,
    dangerColor,
    whiteColor,
    roseColor,
    blackColor,
    grayColor
} from "../jss/material-dashboard-pro-react.js"

const confirmarPedidoStyle = theme => ({
    managerClasses: {
        [theme.breakpoints.up("xs")]: {
            display: "inline-block"
        }
    },
    tabs: {
        overflow: "hidden",
        border: "1px solid ",
        borderColor: primaryColor[0],
        backgroundColor: "white",
        width: "100%",
        margin:"5px"
       
    },
    tabcontent: {
        display: "none",
        padding: "6px 12px",
        border: "1px solid",
        borderColor: primaryColor[0],
        borderTop: "none",
      
    },
    tabcontentActivo: {
        display: "block",
        padding: "6px 12px",
        border: "1px solid",
        borderColor: primaryColor[5],
        borderTop: "none",       
        height: "auto"
    },
    tabButton: {
        backgroundColor: "inherit",
        float: "left",
        border: "none",
        outline: "none",
        cursor: "pointer",
        padding: "14px 16px",
        transition: "0.3s",
        fontSize: "17px",
        "&:hover": {
            backgroundColor: grayColor[4]
        },       
    },
    tabButtonActivo: {
        backgroundColor: "inherit",
        float: "left",
        border: "none",
        outline: "none",
        cursor: "pointer",
        padding: "14px 16px",
        transition: "0.3s",
        fontSize: "17px",  
        fontColor: "white",   
        backgroundColor: primaryColor[0],
        width:"100%",
        marginBottom: "10px",
        color:"white"
       
    },
     
    formControl: {
        marginBottom: "10px",
        fontSize: "1em",
        marginLeft: '0px',
        marginRight: 'auto',
        paddingBottom: 0,
        marginTop: 0,
    
      },
      inputError: {
        border: "solid 1px",
        borderColor: "red",    
        padding: "10px",
        marginBottom: "5px"
      },
      inputCorrecto: {
        border:"unset",
        border: "solid 0.1em",
        borderColor: grayColor[5],
        padding: "10px",   
        width: "100%", 
        marginBottom: "5px"
      },
      
      
    metodoEntrega: {
        width: '250px',
        maxWidth: 360,
        height: "auto",
        textAlign: "rigth",
        marginLeft: "0px",
        display: "block",
        fontSize: "1em",

      },
    textArea:
    {
        width: "100%",       
        minHeight: "80px",
        maxHeight: "100px",
        border: "solid 1px",
        borderColor: grayColor[5],
        textAlign: "left",
        display: "flex"
    },
    textAreaError:
    {
        width: "100%",    
        minHeight: "80px",
        maxHeight: "100px",
        border: "solid 1px",
        borderColor: "red"
    },
    selec:{
        width: "100%",
        padding: "5px",
        marginBottom: "5px"
    },
    containerCheck: {
        display: "block",
        position: "relative",      
        cursor: "pointer",
        fontsize: "22px",
        padding:"10px"
  
      },
    check:{      
        top: "0",
        left: "0",
        width: "100%",
        backgroundColor: "#eee"
    },
    panelFactura:{
        width: "100%",
        display: "flex",
    },
    items:{
        width: "100%",
    }, 
    barraBotones:{
        display: "flex",
        width:"100%",
      },
   
    panelPrincipal:{
        width: "100%",
        background: "white",  
        flexGrow: "100",   
        display: "flex"    
    },
    barraBotonesAux:{
        display: "flex",
        width:"50%",
        marginLeft: "50%",
        marginTop: "10px",
        "@media (min-width: 300px)": {
        width:"100%",
        marginLeft:"0%",
        },
        "@media (min-width: 992px)": {
        width:"40%",
        marginLeft: "60%",
        },
        "@media (min-width: 1200px)": {
         width:"25%",
         marginLeft: "75%"
        },
      },
      botonVolver: {
        minWidth: "unset",
        borderRadius: "5px",
        backgroundColor:grayColor[4],
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        width: "100%",
      },
        
    })

export default confirmarPedidoStyle;
