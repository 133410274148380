import {
  successColor,
  tooltip,
  cardTitle,
  grayColor,
  roseColor
} from "../../material-dashboard-pro-react.js";

import hoverCardStyle from "../hoverCardStyle.js";

const dashboardStyle = {
  ...hoverCardStyle,
  tooltip,
  cardTitle: {
    ...cardTitle,
    marginTop: "0px",
    marginBottom: "3px"
  },
  cardIconTitle: {
    ...cardTitle,
    marginTop: "5px",
    marginBottom: "0px"
  },
  cardImagen: {
    marginBottom: "0px",
    marginTop: "5px",
    textAlign: "center",
    overflow: "hidden",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    display: "inline-block",
    position: "relative",
    objectFit: "contain",
    "@media (min-width: 100px)": {
     width: "auto",
     height: "80px"
    },
    "@media (min-width: 300px)": {
     width: "auto",
     height: "150px"
    },
    "@media (min-width: 768px)": {
      width: "auto",
      height: "150px",
    },
    "@media (min-width: 992px)": {
       width: "auto",
      height: "200px",
    },
    "@media (min-width: 1200px)": {
      width: "auto",
      height: "220px",
    },

  },
  contenedorImg:{
    position: "relative",
    texAlign: "center",
    height:"auto",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
},
cardTitulo:
{
    ...cardTitle,
   fontSize:"18px",
   textAlign:"left",
   fontWeight:"600",
   color:grayColor[3],
   fontFamily: '"Helvetica Neue",Helvetica,Arial,sans-serif',
   paddingTop: "5px",
   verticalAlign: "middle",
   visited: "black",
   "&:visited": {
    color: "black",
    },
    "@media (min-width: 200px) and (max-width: 1000px)": {
     width: "auto",
     fontSize: "10px",
     minHeight: "40px",
     maxHeight: "40px",
     overflow: "hidden",
    },
    "@media (min-width: 1200px)": {
      width: "auto",
      height: "50px",
      fontSize: "14px",
      fontWeight:"500",
    },

},
  cardProductTitle: {
    ...cardTitle,
   color:grayColor[3],
    marginTop: "0px",
    textAlign: "left",
    marginBottom: "0px",
    paddingTop: "0px",
    paddingRight: "0px",
    paddingBottom:"5px",
    paddingLeft: "0px",
    fontFamily: '"Helvetica Neue",Helvetica,Arial,sans-serif',
    verticalAlign: "middle",
    width:"100%",

    "@media (min-width: 200px) and (max-width: 1000px)": {
     height: "40px",
     paddingBottom:"0px",    },
    "@media (min-width: 1200px)": {
      width: "auto",
      height: "45px",
      fontSize: "14px",
      fontWeight:"500",
      display: "inline"
    },
  },
  cardCategory: {
    color: grayColor[0],
    fontSize: "10px",
    paddingTop: "10px",
    marginBottom: "0",
    marginTop: "0",
    margin: "0"
  },
  cardProductDesciprion: {
    textAlign: "center",
    color: grayColor[0],
    fontSize:"8px"
  },
  stats: {
    color: grayColor[0],
    fontSize: "12px",
    lineHeight: "22px",
    display: "inline-flex",
    "& svg": {
      position: "relative",
      top: "4px",
      width: "16px",
      height: "16px",
      marginRight: "3px"
    },
    "& .fab,& .fas,& .far,& .fal,& .material-icons": {
      position: "relative",
      top: "4px",
      fontSize: "16px",
      marginRight: "3px"
    }
  },
  productStats: {
    paddingTop: "3px",
    paddingBottom: "3px",
    margin: "0"
  },
  successText: {
    color: successColor[0]
  },
  upArrowCardCategory: {
    width: 14,
    height: 14
  },
  underChartIcons: {
    width: "17px",
    height: "17px"
  },
  price: {
    color: grayColor[6],
    fontSize:"18px",
    marginLeft: "10px",
    "& h3": {
      marginBottom: "0px",
      marginTop: "0px",
      fontWeight:"500",
      fontSize: "16px"
    },
    "@media (min-width: 200px)": {
      fontWeight:"400",
      fontSize: "14px",
      marginLeft: "5px",
    },
    "@media (min-width: 1200px)": {
      fontWeight:"500",
      fontSize: "18px",
      marginLeft: "15px",
    },
  },

  contenedor:{
    position: "relative",
    display: "inline-block",
    textAlign: "center"
  },
  leyenda:{
      position: "absolute",
      top: "10px",
      left: "10px",
      fontSize:"12px",
      fontWeight:"400",
      color:grayColor[1]
  },
  leyendaDisponible:{
      top: "10px",
      left: "10px",
      fontSize:"14px",

      color:grayColor[3]
  },
  centrado:{
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)"
  },

  existencia: {
    color: grayColor[1],
    fontSize:"12px",
    marginLeft: "00px",
    display:"flex",
    "& h3": {
      marginTop: "0px",
      fontWeight:"500",
      fontSize: "10px"
    },
    "@media (min-width: 300px)": {
      fontWeight:"400",
      fontSize: "10px",
      marginLeft: "0px",
    },
    "@media (min-width: 1200px)": {
      fontWeight:"500",
      fontSize: "12px",
      marginLeft: "0px",
    },
  },

  icono: {
    color: roseColor[0],
    fontSize:"20px",
    marginLeft: "5px",
    maxWidth: "unset",
    "@media (min-width: 200px)": {
      fontWeight:"200",
      fontSize: "12px",
      marginLeft: "0px",
    },
    "@media (min-width: 1200px)": {
      fontWeight:"500",
      fontSize: "20px",
      marginLeft: "5px",

    },
  }
};

export default dashboardStyle;
