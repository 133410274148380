import arrowLeft from './arrow l.svg';
import arrowRight from './arrow r.svg';
import enCamino from './Estatus/enCamino.svg';
import enProceso from './Estatus/enProceso.svg';
import entregado from './Estatus/entregado.svg';
import noEnviado from './Estatus/noEnviado.svg';
import noPagado from './Estatus/noPagado.svg';
import pagado from './Estatus/pagado.svg';
import pendProceso from './Estatus/pendProceso.svg';
import noEntregado from './Estatus/noEntregado.svg';
import reembolsoProceso from './Estatus/reembolsoProceso.svg';
import reembolsoFinalizado from './Estatus/reembolsoFinalizado.svg';
import pedCancelado from './Estatus/cancelled.svg'
import solicitudReembolso from './Estatus/solicitudReembolso.svg'
import reembolsoPendFinalizar from './Estatus/reembolsoPendFinalizar.svg'

const vectorArrowR =
<svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 122.88 75.32"  id='vectorArrowR'>
<polygon class="st0" points="122.88,37.66 84.89,75.32 84.89,51.08 0,51.08 0,24.24 84.89,24.24 84.89,0 122.88,37.66"/>
</svg>



const vectorArrowL =    
<svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 122.88 75.32"  id ='vectorArrowL'>
<polygon class="st0" points="0,37.66 37.99,75.32 37.99,51.08 122.88,51.08 122.88,24.24 37.99,24.24 37.99,0 0,37.66"/>
</svg>  

const vectorSaveIcon = 
<svg xmlns="http://www.w3.org/2000/svg" data-name="Layer 1" viewBox="0 0 64 64" id = 'vectorSaveIcon'>
<path fill="none" stroke="#010101" stroke-miterlimit="10" stroke-width="4" d="M58,58H12L6,52V8A2,2,0,0,1,8,6H56a2,2,0,0,1,2,2Z"/>
<rect width="36" height="24" x="14" y="6" fill="none" stroke="#010101" stroke-miterlimit="10" stroke-width="4"/>
<rect width="24" height="16" x="18" y="42" fill="none" stroke="#010101" stroke-miterlimit="10" stroke-width="4"/>
<line x1="26" x2="26" y1="48" y2="58" fill="none" stroke="#010101" stroke-miterlimit="10" stroke-width="4"/>
</svg>

const vectorMenuIcon = 
<svg xmlns="http://www.w3.org/2000/svg" data-name="Layer 1" id = 'vectorMenuIcon'>
<path d="M4,10h24c1.104,0,2-0.896,2-2s-0.896-2-2-2H4C2.896,6,2,6.896,2,8S2.896,10,4,10z M28,14H4c-1.104,0-2,0.896-2,2  s0.896,2,2,2h24c1.104,0,2-0.896,2-2S29.104,14,28,14z M28,22H4c-1.104,0-2,0.896-2,2s0.896,2,2,2h24c1.104,0,2-0.896,2-2  S29.104,22,28,22z"/>
</svg>

const loaderSoftwone = 
<div>

</div>

const iconoReembolso =   
<svg id="iconoReembolso" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 629 635">
<path fill={"green"} class="clsReembolso" d="M309.19,592c-11.93-1.7-24.08-2.46-35.74-5.25-98.61-23.6-163.08-84.44-185-183.09-22.13-99.42,10.71-182.54,91.44-245.08,36.9-28.58,79.8-43.5,126.61-46.21,8.24-.47,16.52-.07,24.79-.07l.37-1.54-38.71-37,26.2-27.5L408,131l-84.67,88.8c-9.1-8.65-18-17.14-27.51-26.12l36.13-38c-66.17-6.94-158.58,32.57-194.13,125.75-33.64,88.17-.93,187.85,81.13,238.3,85.67,52.67,196,33.51,259.39-47.65,21.53-27.56,34.23-59.05,39-93.7a193.78,193.78,0,0,0-12.84-101.25c-13.19-32.41-33.83-59.3-61.48-81.26,8.86-11.36,17.44-22.39,26.22-33.66a225.32,225.32,0,0,1,36.25,34.73c27.27,32.5,45.4,69.57,52.52,111.35,12.67,74.41-4.53,141.54-53.46,199.33-38.52,45.49-88.16,72.31-147.07,81.72-7.17,1.14-14.42,1.78-21.62,2.66Z"/>
<path fill="green" class="clsReembolso" d="M307.37,243.55h37.84V265c23.74,3.92,41.84,15.15,52,38q-15.94,7.44-31.68,14.75c-16-20-37-22-59.73-16.47a26.45,26.45,0,0,0-12.94,8.44c-5.58,6.68-3.42,15.81,4.67,19s16.64,4.64,25.1,6.34c13.54,2.72,27.38,4.23,40.72,7.68,18.5,4.79,32.9,15.08,38,34.83,7.65,29.62-7.5,57.53-36.53,67.3-6,2-12.28,3.39-19.2,5.26V474H307.37c0-6.17-.21-12.3.08-18.41.18-3.77-1-5.07-4.86-5.7-21.87-3.58-40.44-12.92-53-32-1.94-2.95-3.52-6.15-5.19-9.28-.65-1.23-1.07-2.58-1.91-4.61l35-14.9c5.85,14.15,17.37,21,31.08,24.49,14.08,3.52,28.28,3.21,41.62-3.13a25.61,25.61,0,0,0,10.12-8.91c5-7.89,3-16.85-5.51-20.82s-17.63-6.23-26.72-8.17c-12.81-2.74-26-3.76-38.72-6.85-17.92-4.36-31.88-14.17-36.55-33.26-6.79-27.79,6.41-52.17,33.33-62A102.2,102.2,0,0,1,302.45,266c4-.74,5.24-2.39,5-6.31C307.13,254.4,307.37,249.11,307.37,243.55Z"/>
</svg>



export {
    arrowLeft,
    arrowRight,
    vectorArrowR,
    vectorArrowL,
    vectorSaveIcon,
    vectorMenuIcon,
    loaderSoftwone,
    iconoReembolso,

    enCamino,
    entregado,
    enProceso,
    noEnviado,
    noPagado,
    pagado,
    pendProceso,
    noEntregado, 
    reembolsoProceso,
    reembolsoFinalizado,
    pedCancelado,
    solicitudReembolso,
    reembolsoPendFinalizar    
    
}