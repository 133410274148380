
export const GET_POST =  `
query getPost($post: ID!) {
    getPost(id:$post){
      postID
      fechaPublicacion
      tituloPost
      contenidoPost
      creadorID
      imagen
    }
  }

`;

export const TABLA_POST = `
query filterPost($filtro:InputFilterPost!) {
    filterPost(filtro:$filtro) {
      paginas
      registros
      listado{
        postID
        fechaPublicacion
        tituloPost
        contenidoPost
        creadorID
        imagen
      }
    }
  }

`;

