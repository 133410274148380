import React from 'react';
// react component plugin for creating a beautiful datetime dropdown picker
import Datetime from "react-datetime";
import 'moment/locale/es';

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import FormHelperText from "@material-ui/core/FormHelperText";

import classNames from "classnames";

import styles from "../../../assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.js";
const useStyles = makeStyles(styles);

export default function DateTimePicker (props) {

    const classes = useStyles();

    let { onlyTime = false, onlyDate = false, onChange, inputProps = {}, value, error, success, helperText, id } = props;

    let timeFormat = !onlyDate, dateFormat = !onlyTime;

    let helpTextClasses = classNames({
        [classes.labelRootError]: error,
        [classes.labelRootSuccess]: success && !error
    });

    return (<>
        { inputProps.placeholder && <InputLabel className={classes.label}>{inputProps.placeholder}</InputLabel> }
        <FormControl fullWidth>
            <Datetime
                timeFormat={timeFormat}
                dateFormat={dateFormat}
                onChange={onChange}
                value={value}
                inputProps={inputProps}
                locale="es"
            />
            {error && helperText !== undefined ? (
                <FormHelperText id={id + "-text"} className={helpTextClasses}>
                    {helperText}
                </FormHelperText>
            ) : null}
        </FormControl>
    </>);
}
