// Error 404
import Error404 from '../Error404';

// Componentes notificaciones
import ActivarCuenta from '../Login/ActivarCuenta';
import MensajeActivacion from '../Registro/componente/MensajeActivacion';

// Componentes
import CambiarPassword from '../RecuperarPassword/CambiarPassword';
import Perfil from '../Usuarios/Perfil';
import Direcciones from '../Direcciones';
import Departamentos from '../Departamentos';
import Configuraciones from '../Configuraciones';
import Paqueterias from '../Paqueterias';
import Noticias from '../Noticias';
import Usuarios from '../Usuarios';
import Slider from '../Slider';
import SliderPublico from '../SliderPublico';
import Detalles from '../Productos/DetalleProducto';
import DetallePedido from '../DetalleCompra';
import CapturaReembolsos from '../Reembolsos';
import Deseos from '../Deseos';
import SeguimientoPedido from '../SeguimientoPedido';
import Reportes from '../Reportes';
import Nosotros from '../Nosotros';
import DetallesProducto from '../../_layout/NuevosComponentes/DetallesProducto';
import Suscripciones from '../Suscripciones/Index.jsx';
import SuscripcionCancelada from '../SuscripcionCancelada/index.jsx';                               

import Productos from "../Productos";
import Carrito from "../Carrito"
import Pedido from "../Pedidos"
import ProductosAdmin from "../Productos/administrador"
import LandingPage from '../LandingPage/index';
import Contacto from '../Contacto';
import LoaderLogoSoftwone from '../../_layout/assets/svg/Animaciones/logoSoftwone'
import LoaderLogoSoftwone2 from '../../_layout/assets/svg/Animaciones/logoSoftwone2';

import Wallet from 'componentes/Wallet';
import Facturacion from 'componentes/Facturacion';
import DireccionesFacturacion from 'componentes/DireccionesFacturacion';

export default [{
    titulo: "Verificar correo electrónico",
    ruta: "/activar-cuenta/:token",
    componente: ActivarCuenta
}, {
    titulo: "Cambiar contraseña",
    ruta: "/cambiar-password/:token",
    componente: CambiarPassword
}, {
    titulo: "Perfil de usuario",
    ruta: "/Perfil",
    componente: Perfil,
    permisos: {
        usuario: true,
        moderador: true,
        administrador: true
    }
}, {
    titulo: "¡Registro éxitoso!",
    ruta: "/registro-exitoso",
    componente: MensajeActivacion
}, {
    titulo: "¡Productos!",
    ruta: "/Productos",
    componente: Productos
}, {
    titulo: "¡Productos!",
    ruta: "/products/:id",
    componente: Productos
},
{
    titulo: "Configuraciones",
    ruta: "/Configuraciones",
    componente: Configuraciones,
    permisos: {
        usuario: false,
        moderador: false,
        administrador: true
    }
}, {
    titulo: "Catálogo de direcciones",
    ruta: "/direcciones",
    componente: Direcciones,
    permisos: {
        usuario: true,
        moderador: false,
        administrador: false
    }
},
{
    titulo: "Filtrado de Detalles",
    ruta: "/producto/:id",
    componente: DetallesProducto
}, {
    titulo: "Filtrado de Deseos",
    ruta: "/deseos",
    componente: Deseos
}, {
    titulo: "Filtrado de compras",
    ruta: "/seguimientoPedido",
    componente: SeguimientoPedido
}, {
    titulo: "Reportes",
    ruta: "/reportes",
    componente: Reportes,
    permisos: {
        usuario: true,
        moderador: false,
        administrador: true
    }
},{
    titulo: "Suscripciones",
    ruta: "/suscripciones",
    componente: Suscripciones,
    permisos: {
        usuario: true,
        moderador: false,
        administrador: true
    }
},
{
    titulo: "Filtrado de Pedido",
    ruta: "/detallePedido/:id",
    componente: DetallePedido
},
{
    titulo: "Captura de Reembolsos",
    ruta: "/reembolsos",
    componente: CapturaReembolsos
},
{
    titulo: "Catálogo de paqueterías",
    ruta: "/paqueterias",
    componente: Paqueterias,
    permisos: {
        usuario: false,
        moderador: false,
        administrador: true
    }
}, {
    titulo: "Filtrar Post",
    ruta: "/blog",
    componente: Noticias,
    permisos: {
        usuario: false,
        moderador: true,
        administrador: true
    }
},
{
    titulo: "Filtrar Usuarios",
    ruta: "/usuarios",
    componente: Usuarios,
    permisos: {
        usuario: false,
        moderador: false,
        administrador: true
    }
},
{
    titulo: "Filtrar Slider",
    ruta: "/slider",
    componente: Slider,
    permisos: {
        usuario: false,
        moderador: true,
        administrador: true
    }

},
{
    titulo: "Filtrar Slider",
    ruta: "/productosAdmin",
    componente: ProductosAdmin,
    permisos: {
        usuario: false,
        moderador: true,
        administrador: true
    }

},
{
    titulo: "Carrito",
    ruta: "/carrito",
    componente: Carrito
},
{
    titulo: "Pedido",
    ruta: "/pedido",
    componente: Pedido
},
{
    titulo: "Inicio",
    ruta: "/LandingPage",
    componente: LandingPage
},
{
titulo: "Nosotros",
ruta: "/Nosotros",
componente: Nosotros
}
,
{
    titulo: "Contacto",
    ruta: "/Contacto",
    componente: Contacto
},
{
    titulo: "Tarjetas",
    ruta: "/wallet",
    componente: Wallet
},
{
    ruta: '/cancelarSuscripcion/:data',
    componente: SuscripcionCancelada
},
{
    titulo: "Facturación",
    ruta: "/Facturacion",
    permisos: {
        usuario: false,
        moderador: true,
        administrador: true
    },
    componente: Facturacion
    
},
{
    titulo: "Direcciones de Facturación",
    ruta: "/direccionesFacturacion",
    permisos: {
        usuario: true,
        moderador: true,
        administrador: true
    },
    componente: DireccionesFacturacion
},
{
    ruta: "*",
    componente: LandingPage
}
];
