import { useState, useEffect } from 'react';
import { EnumSexo, EnumPerfil } from '../enums';

import { NetworkError } from '../_layout/genericComponents/Metodos';

export function useEnumPerfil(perfilID) {

    const [perfil, setPerfil] = useState({});

    function ftEffect() {
        async function effect() {
            try {
                const perfil = await EnumPerfil();
                if(perfil.ADMINISTRADOR.isEqual(perfilID)) setPerfil({ perfilAdministrador: true });
                if(perfil.MODERADOR.isEqual(perfilID)) setPerfil({ perfilModerador: true });
                if(perfil.USUARIO.isEqual(perfilID)) setPerfil({ perfilUsuario: true });
            } catch({message}) { setPerfil({ error: NetworkError(message) }); }
        } effect();
    }

    useEffect(ftEffect, [perfilID]);

    return perfil;

}

export function useEnumSexo(generoID) {

    const [sexo, setSexo] = useState({});

    function ftEffect() {
        async function effect() {
            try {
                const sexo = await EnumSexo();
                if(sexo.HOMBRE.isEqual(generoID)) setSexo({ sexoHombre: true });
                if(sexo.MUJER.isEqual(generoID)) setSexo({ sexoMujer: true });
            } catch({message}) { setSexo({ error: NetworkError(message) }); }
        } effect();
    }

    useEffect(ftEffect, [generoID]);

    return sexo;

}
