import {urlServidor} from '../helpers';

const fetchRest = async ({ data, token, method = "POST", url }) => {
    try {

        let formData = new FormData();
        let keys = Object.keys(data);
        keys.forEach(key => { formData.append(key, data[key]); });

        let rh = await fetch( `${urlServidor}/${url}`, {
            method,
            body: formData,
            headers: { token }
        }).then(res => res.json());

        if(!rh.ok) throw new Error(rh.mensaje);

        return rh;

    } catch({message}) { throw new Error(message); }
};

export const addSlider = async ({ data, token }) => {

    try { return await fetchRest({ data, token, url: "slider"}); }
    catch({message}) { throw new Error(message); }
};


export const updateSlider = async ({ data, token }) => {
    try { return await fetchRest({ data, token, url: "slider", method: "PUT" }); }
    catch({message}) { throw new Error(message); }
};
