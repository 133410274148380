// EJSA
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// material-ui icons
import Assignment from "@material-ui/icons/Assignment";
import SearchIcon from '@material-ui/icons/Search';
import ErrorIcon from '@material-ui/icons/Error';
import HelpIcon from '@material-ui/icons/Help';

// core components
import GridContainer from "../../components/Grid/GridContainer.js";
import GridItem from "../../components/Grid/GridItem.js";
import Table from "../../components/Table/Table.js";
import Card from "../../components/Card/Card.js";
import CardBody from "../../components/Card/CardBody.js";
import CardIcon from "../../components/Card/CardIcon.js";
import CardHeader from "../../components/Card/CardHeader.js";
import Acordion from "../../components/Accordion/Accordion.js";
import Paginations from "../../components/Pagination/Pagination.js";
import Muted from "../../components/Typography/Muted.js";

// generic components
import Loader from "../Loader";
import Notificaciones from "../Notificaciones";
import Inputs from  "../Inputs";
import { MiniBoton } from "../Botones";

// Componentes Custom
import Custom from "./metodos";

// media querys
import useMediaQuerys from "../MediaQuerys";
import Center from "../Center";

import { ftPaginacionGetComponentData } from "../../helpers/components";

import styles from "../../assets/jss/material-dashboard-pro-react/views/extendedTablesStyle.js";

// Helpers
import { Validaciones } from "@renedelangel/helpers";

const { trim, CompararDosObjetos } = Validaciones;
const useStyles = makeStyles(styles);

export default function Tabla (props) {

    const classes = useStyles();

    const { data, filter = [], iconTable, title, hideHeader = false, responsiveTitle, showHelp = false, activeResponsive = -1, accionesEffect = [],
        multiseleccion = [], id, multiCheck, multiCheckAllSelected, onChangeSwitch, alineacion, formato = [], paginacion, paginaActiva = 1, color = "info", rowColor,
        busqueda, loader = false, error = {}, botones = [], acciones = [], disabledMultiseleccion, disabledNormal, disabledLoader, handleSearch = (evt) => console.log(evt),
        handleRegistros = (evt) => console.log(evt), rangoFechas, seleccionFiltro, token, url, ocultarListaRegistros } = props;

    const [search, setSearch] = useState({});
    const [checkAll, setCheckAll] = useState(false);

    let container = {
        direction: "row",
        justify: "center",
        alignItems: "center"
    };

    let placeholderBusqueda = "Capture la palabra a buscar";

    let { isTabletOrMobile, isTabletOrMobileDevice } = useMediaQuerys();
    let responsive = isTabletOrMobile || isTabletOrMobileDevice;

    // Se formatea el objeto para que la tabla lo interprete de forma correcta
    let header = [], keysHeader = [], dataFormat = [], headerSearch = [], body = [], dataResponsive = [], alineacionPosiciones = [], alineacionEstilos = [], pages = [];

    if(Array.isArray(data) && data.length > 0) {

        let newData = [ ...data ];

        if(Array.isArray(multiseleccion) && multiseleccion.length > 0 && !error.si) newData = newData.map(data => {
            let value = {}, ident;
            multiseleccion.forEach(({ campo, variable }) => {
                let idData = variable ? variable : campo;
                if(id) ident = `${id}-${data[id].value}`;
                value = { ...value, [idData]: data[campo].value };
            });
            return {
                Seleccionar: {
                    value: <Inputs
                        tipo="switch"
                        checked={multiCheck[ident]}
                        onChange={() => onChangeSwitch(ident, { [ident]: value })}
                    />,
                    label: "Seleccionar",
                    hide: false
                },
                ...data,
            }
        });

        if(Array.isArray(acciones) && acciones.length > 0 && !error.si) newData = newData.map(data => ({
            ...data,
            Acciones: {
                value: acciones.map(({icono, color, descripcion, parametros, onClick, disabled, id}, index) => {
                    let newParametros = {};
                    if(Array.isArray(parametros) && parametros.length > 0) parametros.forEach(({ campo, variable }) => {
                        let id = variable ? variable : campo;
                        if(data[campo]) newParametros = { ...newParametros, [id]: data[campo].value };
                    });

                    let componente = <MiniBoton
                        key={index}
                        Icono={icono}
                        color={color}
                        descripcion={descripcion}
                        disabled={disabled.multiseleccion ? disabledMultiseleccion : (disabled.normal ? disabledNormal : disabledLoader)}
                        onClick={(e) => onClick(newParametros, e)}
                    />;

                    if(Array.isArray(accionesEffect) && accionesEffect.length > 0) {
                        accionesEffect.forEach(({ botones, ocultar }) => {
                            botones.forEach(boton => {
                                if((boton === id) && ocultar(data)) componente = null;
                            });
                        });
                    }

                    return componente;
                }),
                label: "Acciones",
                hide: false
            }
        }));

        keysHeader = Object.keys(newData[0]);
        header = [];
        keysHeader.forEach(key => {
            if(!newData || !newData[0] || !newData[0][key]) return;
            if(!newData[0][key].hide) header = [ ...header, newData[0][key].label ];
        });

        dataFormat = newData.map(campos => {
            let resultado = {};         
            keysHeader.forEach(key => {
                let siEntro = false, valor = {};
                if(!campos[key].hide) {
                    if(Array.isArray(formato) && formato.length > 0) {
                        formato.forEach(({ columnas, tipo, titulo, ...otros }) => {
                            if(Array.isArray(columnas) && columnas.length > 0) {
                                columnas.forEach(col => {
                                   let tit = titulo ? campos[titulo].value : "";
                                    if(key === col) {
                                        siEntro = true;
                                        valor = { [key]: campos[key].value === undefined ? "" : <Custom valor={campos[key].value} tipo={tipo} id={key} token={token} url={url} row={campos} { ...otros } titulo={tit} /> };
                                    }
                                });
                            }
                            if(typeof columnas === "string") {
                                if(key === columnas) {
                                    siEntro = true;
                                    valor = { [key]: campos[key].value === undefined ? "" : <Custom valor={campos[key].value} tipo={tipo} id={key} row={campos} { ...otros } /> };
                                }
                            }
                        });
                    }
                }
                if(!siEntro) valor = { [key]: !campos[key].value ? "" : key === "Seleccionar" || key === "Acciones" ? campos[key].value : <Custom valor={campos[key].value} /> };
                resultado = { ...resultado, ...valor };
            });
            return resultado;
        });

        keysHeader.forEach(key => {
            let res = "";
            let hide = false;
            filter.forEach(({ campo, variable, placeholder }) => {
                if(!newData || !newData[0] || !newData[0][key]) return;
                hide = newData[0][key].hide;
                if(key === "Seleccionar") {
                    res = <Inputs
                        tipo="switch"
                        checked={checkAll}
                        onChange={({target:{checked}}) => {
                            setCheckAll(checkAll => !checkAll);
                            multiCheckAllSelected(checked, newData);
                        }}
                    />;
                    return;
                }
                if(campo === key) {
                    let id = variable ? variable : key;
                    let mensaje = placeholder ? placeholder : placeholderBusqueda;
                    res = <Inputs
                        key={id}
                        id={id}
                        value={search[id] || ''}
                        placeholder={mensaje}
                        onChange={({target:{ value, id }}) => setSearch({ ...search, [id]: value })}
                        onKeyDown={onSearchEnter}
                        icono={<SearchIcon style={{ cursor: "pointer" }} onClick={onSearch} />}
                    />;
                }
            });
            if(multiseleccion.length > 0 && filter.length === 0 && key === "Seleccionar") res = <Inputs
                tipo="switch"
                checked={checkAll}
                onChange={({target:{checked}}) => {
                    setCheckAll(checkAll => !checkAll);
                    multiCheckAllSelected(checked, newData);
                }}
            />;
            headerSearch = hide ? [ ...headerSearch ] : [ ...headerSearch, res ];
        });

        dataFormat = dataFormat.length > 0 ? dataFormat : newData;

        body = dataFormat.map((campos, index) => {
            let resultado = [], color;
            keysHeader.forEach(key => {
                if(!newData || !newData[index] || !newData[index][key]) return;
                if(!newData[index][key].hide) resultado = [...resultado, campos[key]];
            });
            if(rowColor && typeof rowColor === "function") color = rowColor(newData[index]);
            return color ? { color, data: resultado } : resultado;
        });

        body = [headerSearch, ...body];

        // responsiveTitle
        dataResponsive = dataFormat.map((campos, index) => {

            let title = "";
            if(Array.isArray(responsiveTitle) && responsiveTitle.length > 0) {
                responsiveTitle.forEach(dato => { title += `${!newData[index][dato] ? "No existe la variable" : (typeof newData[index][dato].value === "string" ? newData[index][dato].value : "")} - `; });
                title = title.substring(0, title.length - 3);
            } else { title = "El responsiveTitle es obligatorio"; }

            return {
                title,
                content: <GridContainer>
                    {
                        keysHeader.map((key, keyIndex) => ((!newData[0][key].hide && (campos[key] && campos[key] !== "")) && <GridItem xs={12} key={keyIndex}>
                            <p><b>{ newData[0][key].label }</b> { typeof campos[key] !== "object" && campos[key] }</p>
                            { typeof campos[key] === "object" && <>{ campos[key] }<br /><br /></> }
                        </GridItem>))
                    }
                </GridContainer>
            }

        });

    }

    if(Array.isArray(alineacion) && alineacion.length > 0) {

        alineacion.forEach(({ columnas, alineacion }) => {
            let estilos = [], estilo = classes[alineacion];
            alineacionPosiciones = Array.isArray(columnas) ? [...alineacionPosiciones, ...columnas] : [...alineacionPosiciones, (!isNaN(Number(columnas)) && Number(columnas))];
            if(Array.isArray(columnas) && columnas.length > 0) estilos = columnas.map(() => estilo);
            else estilos = [estilo];
            alineacionEstilos = [...alineacionEstilos, ...estilos];
        });

    }

    if(typeof paginacion === "object") pages = ftPaginacionGetComponentData(paginacion, paginaActiva);

    let titleRegistros = "Máximo número de registros a mostrar";

    const registros = {
        id: "registrosTabla",
        tipo: "select",
        title: titleRegistros,
        data: [
            {
                value: undefined,
                disabled: true,
                label: titleRegistros
            },
            { value: 10, label: "10 registros" },
            { value: 25, label: "25 registros" },
            { value: 50, label: "50 registros" },
            { value: 100, label: "100 registros" }
        ]
    };

    function onSearch() {
        let newSearch = { ...search }, keys = Object.keys(newSearch);
        keys.forEach(key => { newSearch = { ...newSearch, [key]: newSearch[key] ? newSearch[key].replace(/[\^?+*\\]/g, "") : "" } });
        handleSearch({ ...trim(newSearch, true) } );
    }

    function onSearchEnter({key, keyCode}) { if(key === "Enter" || keyCode === 13) onSearch(); }

    function effectBusqueda() { if(!CompararDosObjetos(busqueda, search)) setSearch({ ...busqueda }); }

    useEffect(effectBusqueda, [data]);

    return (

        <GridContainer
            direction={container.direction}
            justify={container.justify}
            alignItems={container.alignItems}
        >
            <GridItem xs={12}>
                <Card>
                    {
                        !!!hideHeader && <CardHeader color={color} icon>
                            <CardIcon color={color}>
                                { iconTable ? iconTable : <Assignment /> }
                            </CardIcon>
                            <h4 className={classes.cardIconTitle}>{title}</h4>
                            {
                                paginacion && <Muted className={classes.cardIconTitle}>
                                    Página <b>{paginaActiva}</b> de <b>{paginacion.paginas && paginacion.paginas}</b> | Total de registros: <b>{paginacion.registros}</b>
                                </Muted>
                            }
                        </CardHeader>
                    }
                    {
                        (Array.isArray(filter) && filter.length > 0) && <CardBody>
                            {
                                showHelp && <>
                                    <br />
                                    <Notificaciones
                                        message={<span>Para realizar la búsqueda presione: <b>Enter/Intro</b> o el icono <SearchIcon style={{ fontSize: "16px" }} /><br/>Los campos de búsqueda no toman en cuenta los siguientes caracteres: <b>^ + ? * \</b></span>}
                                        icon={HelpIcon}
                                        color={color}
                                    />
                                </>
                            }
                            {
                                responsive && keysHeader.map((key) => {
                                    let res = "";
                                    filter.forEach(({ campo, variable, placeholder }) => {
                                        if(campo === key && !data[0][key].hide) {
                                            let id = variable ? variable : key;
                                            let mensaje = placeholder ? placeholder : placeholderBusqueda;
                                            res = <Inputs
                                                key={id}
                                                id={id}
                                                value={search[id] || ''}
                                                title={mensaje}
                                                placeholder={mensaje}
                                                onChange={({target:{ value, id }}) => setSearch({ ...search, [id]: value })}
                                                onKeyDown={onSearchEnter}
                                                icono={<SearchIcon style={{ cursor: "pointer" }} onClick={onSearch} />}
                                                disabled={loader}
                                            />;
                                        }
                                    });
                                    return res;
                                })
                            }
                        </CardBody>
                    }
                    {
                        ((Array.isArray(rangoFechas) && rangoFechas.length > 0) || (Array.isArray(seleccionFiltro) && seleccionFiltro.length > 0)) && <CardBody>
                            <GridContainer
                                direction={container.direction}
                                justify={container.justify}
                                alignItems={container.alignItems}
                            >
                                {
                                    (Array.isArray(rangoFechas) && rangoFechas.length > 0) && rangoFechas.map(({ id, onChange, ...rest }, index) => (<Inputs
                                        key={index}
                                        id={id}
                                        onChange={e => onChange(e, id)}
                                        { ...rest }
                                    />))
                                }
                                {
                                    (Array.isArray(seleccionFiltro) && seleccionFiltro.length > 0) && seleccionFiltro.map(({ id, validaTexto, onChange, onKeyDown, cleanStateValues, ...rest }, index) => (<Inputs
                                        key={index}
                                        id={id}
                                        onChange={e => onChange(e, id, { validaTexto, cleanStateValues })}
                                        onKeyDown={e => onKeyDown(e)}
                                        { ...rest }
                                    />))
                                }
                            </GridContainer>
                        </CardBody>
                    }
                    {
                        (Array.isArray(botones) && botones.length > 0) && <CardBody>
                            <GridContainer
                                direction={container.direction}
                                justify={container.justify}
                                alignItems={container.alignItems}
                            >
                                { botones }
                            </GridContainer>
                        </CardBody>
                    }
                    <CardBody>
                        {
                            loader ? <Loader /> : <>
                                { responsive ? <><Acordion
                                        active={activeResponsive}
                                        collapses={!error.si ? dataResponsive : []}
                                    />
                                    {
                                        (error && error.si) && <Notificaciones
                                            message={error.message}
                                            icon={ErrorIcon}
                                            color="danger"
                                            closeNotification={error.onClick}
                                            close={true}
                                        />
                                    }
                                </> : <Table
                                    hover
                                    tableHead={header}
                                    tableData={body}
                                    customCellClasses={alineacionEstilos}
                                    customClassesForCells={alineacionPosiciones}
                                    customHeadCellClasses={alineacionEstilos}
                                    customHeadClassesForCells={alineacionPosiciones}
                                    error={error}
                                /> }
                                <hr />
                                {
                                    !ocultarListaRegistros && <GridContainer
                                        direction={container.direction}
                                        justify={container.justify}
                                        alignItems={container.alignItems}
                                    >
                                        <Inputs
                                            id={registros.id}
                                            tipo={registros.tipo}
                                            title={registros.title}
                                            value={paginacion.limite}
                                            grid={{ xs: 12, sm: 7, md: 4, lg: 3 }}
                                            data={registros.data}
                                            onChange={handleRegistros}
                                        />
                                    </GridContainer>
                                }
                                {
                                    (paginacion && paginacion.paginas > 1) && <Center>
                                        <Paginations
                                            pages={pages}
                                            color={color}
                                        />
                                    </Center>
                                }
                            </>
                        }
                    </CardBody>
                </Card>
            </GridItem>
        </GridContainer>
    );
}

Tabla.propTypes = {
    data: PropTypes.array.isRequired,
    hideHeader: PropTypes.bool,
    filter: PropTypes.array,
    iconTable: PropTypes.node,
    title: PropTypes.string,
    showHelp: PropTypes.bool,
    responsiveTitle: PropTypes.arrayOf(PropTypes.string),
    activeResponsive: PropTypes.number,
    alineacion: PropTypes.arrayOf(PropTypes.object),
    formato: PropTypes.arrayOf(PropTypes.object),
    paginacion: PropTypes.object,
    paginaActiva: PropTypes.number,
    color: PropTypes.oneOf([
        "warning",
        "primary",
        "danger",
        "success",
        "info"
    ]),
    rowColor: PropTypes.func,
    busqueda: PropTypes.object,
    loader: PropTypes.bool,
    error: PropTypes.object,
    handleSearch: PropTypes.func,
    handleRegistros: PropTypes.func
};
