import React, { useState, useRef, useEffect } from 'react'
import './estilos.css';

// Generic components
import SweetAlert from '../../_layout/genericComponents/ModalConfirmacion';

import { Switch, Redirect, useParams } from 'react-router-dom';

//componentes
import Input from '../../_layout/NuevosComponentes/Input';
import Boton from '../../_layout/NuevosComponentes/Boton';

import { rgxCorreo } from '../../helpers/regexp';

import { getNewPassword } from '../../querys/Login/metodos';

// material-ui icons
import EmailIcon from '@mui/icons-material/Email';

import { asyncHandleFocus, handleErrorInputText } from '../../_layout/helpers/handles';
import { info, warning, primary, danger, success } from '../../_layout/helpers/colores';
import LoaderLogoSoftwone2 from '_layout/assets/svg/Animaciones/logoSoftwone2';


function RecuperarPassword(props) {

    const { token } = useParams();

    let {open = false,  cerrar,  correo, onEnviar, regresarPag } = props;

    const cleanDisabled = { password: false, cambiar: true, iniciar: false };
    const cleanState = { email: "" };
    const cleanErrorState = {
        email: { error: false, helperText: ""  },
    };   

    const [state, setState] = useState(cleanState);
    //const [infoInput, setInfoInput] = useState(cleanInfo);
    const [componente, setComponente] = useState(null);
    const [errorState, setErrorState] = useState(cleanErrorState);
    const [loader, setLoader] = useState(false);
    const [disabled, setDisabled] = useState(cleanDisabled);
    const [alert, setAlert] = useState(null);
    const [enlaceValido, setEnlaceValido] = useState(false);

    const title = "Recuperar contraseña";
    const grid = { xs:12, sm: 12, md: 5, lg: 4 };

    let correoRef = useRef(null);

    function handleState(value, id) {
        setState(state => ({
            ...state,
            [id]: value
        }));
        setErrorState(cleanErrorState)
    }

    function ftErrorInputText({ condicion, ref, keyError, mensajeError }){
        return handleErrorInputText({ cleanErrorState, condicion, ref, keyError, mensajeError,
            loader: setLoader, errorState: setErrorState });
    }
    function handleValidaciones(correo){

        let error;
        let validaciones = [
            {
                condicion: !rgxCorreo.test(correo) || correo.trim() === "",
                ref: correoRef, keyError: "email",
                mensajeError: "Captura un correo electrónico válido"
            },
        ];

        validaciones.forEach(({ condicion, ref, keyError, mensajeError }) => {
            if(error) return;
            error = ftErrorInputText({ condicion, ref, keyError, mensajeError });
        });

        if(error) return error;

    }
    
    
    function ftEnviar(){
        async function ftEnviar(){
            setLoader(true);
            try {
           
                let error = await handleValidaciones(state.email);

                if (error) {
                    setLoader(false);
                    return error;}
           
                let x = await getNewPassword({ username: state.email, dominio: window.location.hostname, href: window.location.origin });

                onEnviar(state.email)
                setLoader(false);
            
        } catch ({ message }) {
            setLoader(false);
            setAlert({
              descripcion: message,
              title: "¡Te pedimos una disculpa!",
              tipo: danger,
              msjConfirmacion: "De acuerdo",
              onConfirm: () => setAlert(null)
            });
          }
          //setDisabled(false);
          //setLoader(cleanLoader);

        }ftEnviar()            
    }
    function regresar(){
        regresarPag(state.email)
    }  

    return (
       <>
            <div className="principal"> 
            <div className="form">
                <div className='textoResaltado'>RECUPERAR CONTRASEÑA</div>
                <div className='texto'>Te enviaremos un enlace a tu correo para que puedas cambiar la contraseña</div>
                <div className='formularioRecuperar'> 
                    <Input
                        id="correo"
                        claseAuxInput="inputCap"
                        placeholder="Correo eléctronico"                    
                        posIcono="left" 
                        error= {errorState.email.helperText}   
                        ref= {correoRef}                   
                        icono={<EmailIcon style={{ display: "flex", margin: "auto", color: "var(--letraPrimaryColor)", fontSize: "inherit" }} />}
                        onChange={({target:{value}}) => handleState(value, "email")}
                        />                   
                    <div className="cContenedorBotonesLogin">
                      <Boton titulo="Enviar" colorFondo="var(--primaryColor)" onClick={() => ftEnviar()} />
                      <div className="regresar" onClick={()=>regresar()}>Regresar</div>
                    </div>
                </div>
            </div>
            {
                loader ? 
                <LoaderLogoSoftwone2/> :
                <></>
            }
            </div>
            { alert && <SweetAlert
                title={alert.title}
                descripcion={alert.descripcion}
                tipo={alert.tipo}
                msjConfirmacion={alert.msjConfirmacion}
                msjCancelacion={alert.msjCancelacion}
                onConfirm={alert.onConfirm}
                showConfirm={alert.showConfirm}
                showCancel={alert.showCancel}
                onCancel={() => setAlert(null)}
            /> }

            
        </> 
    );

}

export default RecuperarPassword;
