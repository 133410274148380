import React from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";

const styles = {
  grid: {
    padding: "0 5px !important",
    flexDirection: "column",
  },
  gridPedido: {
    padding: "0 5px !important",
    flexDirection: "column",
     "@media (min-width: 768px)": {
     flexDirection: "column",
    },
    "@media (min-width: 992px)": {
     flexDirection: "column",
    },
    "@media (min-width: 1200px)": {
     flexDirection: "row",
     display: "flex"
    },

  }
};

const useStyles = makeStyles(styles);

export default function GridItem(props) {
  const classes = useStyles();
  const { children, className, ...rest } = props;

  return (
    <Grid item {...rest} className={classes.grid + " " + className} >
      {children}
    </Grid>
  );
}

GridItem.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node
};
