import { useState, useEffect } from 'react';
import { Validaciones } from '@renedelangel/helpers';

import { NetworkError } from '../_layout/genericComponents/Metodos';

const { trim } = Validaciones;

export function useTitle(title) {

    const [error, setError] = useState({});

    useEffect(() => {
        try {
            if(title && trim(title) !== "") document.title = title;
        } catch({message}) { setError({ error: NetworkError(message) }); }
    }, [title]);

    return error;

}
