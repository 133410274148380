import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import CarruselProductos from '../../_layout/components/CarruselProductos';
import { tablaProductos } from '../../querys/Productos/metodos';
import './estilos.css';
import { getAllGrupoERP } from "../../querys/Departamentos/metodos";
import { Link } from 'react-router-dom';
import landing1 from '../../_layout/assets/img/landing1.png';
import landing2 from '../../_layout/assets/img/landing2.png'
import abrasivosAzul from '../../_layout/assets/img/abrasivos_azul.png';

function LandingPage() {
    const [productosOfertas, setProductosOfertas] = useState(null);
    const [productosNuevosLanzamientos, setProductosNuevosLanzamientos] = useState(null);
    const { token, usuario } = useSelector(state => state.login);
    const [grupos, setGrupos] = useState([]);
    const [actualizarProductosOfertas, setActualizarProductosOfertas] = useState(false);
    const [actualizarProductosNuevos, setActualizarProductosNuevos] = useState(false);

    let imagenDefault = abrasivosAzul;

    useEffect(() => {
        async function ftEffect() {
            try {
                const listaGrupos = await getAllGrupoERP({}, token);
                let lisGrupos = []

                for (let i = 0; i < listaGrupos.length; i++) {
                    let liGrupo = listaGrupos[i];
                    if (liGrupo.imagen === '') {
                        liGrupo.imagen = imagenDefault;
                    }
                    lisGrupos.push(liGrupo);
                }
                setGrupos(lisGrupos);
            } catch ({ message }) {
                console.error('Error en  el efect', message);
            }
        } ftEffect();
    }, []);

    useEffect(() => {
        async function ftEffect() {
            try {
                let { listado } = await tablaProductos({
                    filtro: {
                        limite: 20,
                        pagina: 1,
                        oferta: 1,
                        precioMaximo: 99999999,
                        precioMinimo: 1,
                    }
                }, token);

                let tListatoProd = [];
                for (let i = 0; i < listado.length; i++) {
                    const tProd = listado[i];
                    tListatoProd.push({
                        id: tProd.productoID,
                        titulo: tProd.descripcionCorta,
                        imagen: tProd.imagenPortada,
                        descripcion: tProd.descripcion,
                        deseosID: tProd.deseosID
                    });
                }

                setProductosOfertas(tListatoProd);
            } catch ({ message }) {
                console.error('Error en  el efect', message);
            }
        } ftEffect();
    }, [actualizarProductosOfertas, token, usuario, actualizarProductosNuevos]);

    useEffect(() => {
        async function ftEffect() {
            try {
                let { listado } = await tablaProductos({
                    filtro: {
                        limite: 20,
                        pagina: 1,
                        nuevoLanzamiento: 1,
                        precioMaximo: 99999999,
                        precioMinimo: 1,
                    }
                }, token);

                let tListatoProd = [];
                for (let i = 0; i < listado.length; i++) {
                    const tProd = listado[i];
                    tListatoProd.push({
                        id: tProd.productoID,
                        titulo: tProd.descripcionCorta,
                        imagen: tProd.imagenPortada,
                        descripcion: tProd.descripcion,
                        deseosID: tProd.deseosID
                    });
                }

                setProductosNuevosLanzamientos(tListatoProd);
            } catch ({ message }) {
                console.error('Error en  el efect', message);
            }
        } ftEffect();
    }, [actualizarProductosNuevos, token, usuario, actualizarProductosOfertas]);

    let categorias = grupos ? <> <div className="tituloGrupos noselect" >CATEGORÍAS</div>
        <div className="categorias noselect">
            {
                grupos.map((grupo, key) => {

                    return (<>
                        <div className="grupos" key={"grupos" + key}>
                            <Link to={{
                                pathname: "/productos",
                                state: { grupoParam: grupo.grupoID }
                            }}>
                                <div className="contenedorGrupo">
                                    <div className="imagenGrupo">
                                        <img className="formatoImagen" src={grupo.imagen} alt={grupo.grupo} title={grupo.grupo} />
                                    </div>
                                    <div className="tituloGrupo">
                                        <p>{grupo.grupo}</p>
                                    </div>
                                </div>
                            </Link>
                        </div>
                    </>);
                })
            }
        </div> </> : "";

    return (<>
        <div className="cContenedorLanding">
            <div className="cSeccionA noselect">
                <div className="cSeccionAPrincipal">
                    <div className="cSeccionAFondo">
                        <img className="cImgAFondo" src={landing1} />
                    </div>
                    <div className="cSeccionAParrafo">
                        <div className="cSeccionALinea1">
                            Somos el único puente
                        </div>
                        <div className="cSeccionALinea2">
                            entre el fabricante
                        </div>
                        <div className="cSeccionALinea3">
                            y el cliente final
                        </div>
                    </div>
                </div>
                <div className="cSeccionAEnvios">
                    ENVÍOS A TODA LA REPÚBLICA MEXICANA
                </div>
            </div>
            <CarruselProductos items={productosNuevosLanzamientos} Titulo={"NUEVOS LANZAMIENTOS"} Etiqueda={"Nuevo"} mostrarBoton={false} mostrarDescripcion={false}
                actualizar={actualizarProductosNuevos} setActualizar={setActualizarProductosNuevos} cambioPaginaAuto={true} enumerarPaginacion={true} flechasCambioPagina={true}/>

            <CarruselProductos items={productosOfertas} Titulo={"Ofertas"} mostrarBoton={true} mostrarDescripcion={true}
                actualizar={actualizarProductosOfertas} setActualizar={setActualizarProductosOfertas} cambioPaginaAuto={true} enumerarPaginacion={true} flechasCambioPagina={true}/>

            {categorias}
            <div className="cSeccionB noselect">
                <div className="cSeccionBPrincipal">
                    <div className="cSeccionBFondo">
                        <img className="cImgBFondo" src={landing2} />
                    </div>
                    <div className="cSeccionBParrafo">
                        <div className="cSeccionBLinea1">
                            Compra con nosotros
                        </div>
                        <div className="cSeccionBLinea2">
                            FÁCIL, RÁPIDO Y SEGURO
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </>);
}

export default LandingPage;