import React, { useState,useRef, useEffect } from 'react';
import { Switch, Redirect } from 'react-router-dom';
import {  Encriptar } from '@renedelangel/helpers';

// hooks
import { useTitle } from '../../hooks/title';

// Redux
import { useSelector } from "react-redux";

//generic components
import SweetAlert from '../../_layout/genericComponents/ModalConfirmacion';
import Modal from '../../_layout/genericComponents/Modal';
import Reembolsos from '../Reembolsos'

import LocalAtmIcon from '@mui/icons-material/LocalAtm';
// material-ui icons


import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import SyncIcon from '@material-ui/icons/Sync';

//Colores
import { info, success, danger, rose, primary } from '../../_layout/helpers/colores';

import Tabla from '../../_layout/masterComponents/Tabla';

//Consultas
import { tablaSeguimientoPedido,updateSeguimientoPedido, getDetallePedido } from '../../querys/SeguimientoPedido';
import { selectEstatus } from '../../querys/CatalogosTienda';
import { tablaCompras } from '../../querys/Compras';

const {Encrypt,Decrypt}=Encriptar;

function SeguimientoPedido() {

    const { usuario, token } = useSelector(state => state.login);
    const perfil = usuario ? usuario.perfil : 'Usuario';  
    const cleanNotificaciones = { mensaje: "", color: null, open: false };

    const cleanState = {
    ventaID: null,
    estatus: "",
    usuarioID: null
    };
    
    const cleanErrorState = {
        ventaID: { error: false, helperText: "" },
        estatus: { error: false, helperText: "" },
    }   

    const cleanOpenOpciones = { open: false, ventaID: null, descripcion: "" };
    const [state, setState] = useState(cleanState);
    const [errorState, setErrorState] = useState(cleanErrorState);
    const [open, setOpen] = useState(false);
    const [disabled, setDisabled] = useState(false);
    const [notificaciones, setNotificaciones] = useState(cleanNotificaciones);
    const [actualizar, setActualizar] = useState(false);
    const [loader, setLoader] = useState(false);
    const [alert, setAlert] = useState(null);
    const [detalle, setDetalle] = useState(null);
    const [redirigir, setRedirigir] = useState(null);
    const [seleccionableEstatus, setSelecionableEstatus] = useState([]);
    const [estatusSelec, setEstatusSelec ] = useState(2)
    
    let botones = [
        {
            icono: SyncIcon,
            color: info,
            descripcion: "Actualizar",
            onClick: () => setActualizar(!actualizar),
            disabled: { multiseleccion: true }
        }
    ];

    const acciones = [   
    {
        icono: LocalAtmIcon,
        color: primary,
        id: "Reembolso",
        descripcion:  "Ver reembolso",
        parametros: [{campo: "ventaID"}, {campo: "reembolsoID"}, {campo: "usuario"}],
        disabled: {multiseleccion: true},
        onClick: ({ ventaID, reembolsoID }) => verReembolsos(ventaID, reembolsoID)
    },
    {
        icono: FormatListBulletedIcon,
        color: rose,
        id: "Detalles",
        descripcion: "Ver detalles",
        parametros: [{campo: "ventaID"}, {campo: "estatus"}, {campo: "usuario"}],
        disabled: {multiseleccion: true},
        onClick: ({ ventaID }) => redireccionar(ventaID)   
        }
    ];

    const infoTablaUsuario = {
        botones,
        acciones,
        actualizar,
        title: "Seguimiento de Pedidos",
        headers: [
            { variable: "reembolsoID", descripcion: "Reembolso", hide: true },
            { variable: "ventaID", descripcion: "Folio" },
            { variable: "fechaCompra", descripcion: "Fecha" },
            { variable: "usuarioID", descripcion: "Usuario ID", hide: true},
            { variable: "usuario", descripcion: "Usuario", hide: true},
            { variable: "detalleVenta", descripcion: "Detalle de Venta" },
            { variable: "total", descripcion: "Total" },
            { variable: "estatus", descripcion: "Estatus", hide: true },
            { variable: "nomEstatus", descripcion: "Estatus", hide: false },
            { variable: "numeroGuia", descripcion: "No. de Guia" }
        ],
        alineacion: [{ columnas:  [0, 1, 2], alineacion: "left" }],
        responsiveTitle: ["fechaCompra"],
        filter: [         
            { campo: "ventaID" },
            { campo: "numeroGuia"}            
        ],
        formato: [
            {
                columnas: ["total"], tipo: "moneda",
            },
            {
                columnas: ["detalleVenta"], tipo: "table"
            }
        ],
        selectFilter: [
            {
                campo: "estatus", placeholder: "Seleccione el estado del pedido",         
                data: seleccionableEstatus ? seleccionableEstatus : [],
                defaultValue: estatusSelec, grid: { sm: 4, md: 4, lg: 4 }
            },
        ],
        accionesEffect:
        [
        { botones: ["Reembolso"], ocultar: (data) => data["reembolsoID"].value == null  ? true: false}       
    ]
    }
    const infoTablaAdministrador = {
        botones,
        acciones,
        actualizar,
        title: "Seguimiento de Pedidos",
        headers: [
            { variable: "ventaID", descripcion: "Folio"},
            { variable: "reembolsoID", descripcion: "Reembolso", hide:true},
            { variable: "fechaCompra", descripcion: "Fecha compra" },          
            { variable: "nombreUsuario", descripcion: "Nombre" },
            { variable: "usuario", descripcion: "Usuario" },      
            { variable: "nomEntrega", descripcion: "Tipo Entrega"},
            { variable: "numeroGuia", descripcion: "Número guia" },          
            { variable: "estatus", descripcion: "Estatus", hide:true },
            { variable: "nomEstatus", descripcion: "Estatus" },
            { variable: "totalCompra", descripcion: "Total" },
        ],
        alineacion: [{ columnas:  [0, 1, 2], alineacion: "left" }],
        responsiveTitle: ["ventaID", "fechaCompra"],
        filter: [         
            { campo: "ventaID" },   
            { campo: "numeroGuia"}        
        ],
        formato: [
            {
                columnas: ["totalCompra"], tipo: "moneda",
            }
        ],
        selectFilter: [
            {
                campo: "estatus", placeholder: "Seleccionar estatus del pedido", retorna: "string",
                data: seleccionableEstatus,
                defaultValue: estatusSelec, grid: { sm: 4, md: 4, lg: 4 }
            },
        ],
        accionesEffect:
        [
         { botones: ["Reembolso"], ocultar: (data) => data["reembolsoID"].value == null ? true: false}        
    ]
    }
    function verReembolsos(ventaID, reembolsoID) {        
        async function verDetalle(){            
            setOpen(true);
            setState({ventaID: ventaID, reembolsoID: reembolsoID })
        }
        return verDetalle();
    }

    function handleClose() {
        setOpen(false);
        setDisabled(false);
        setLoader(false);
        setNotificaciones({ ...cleanNotificaciones });
        setState({ ...cleanState });
        setErrorState({ ...cleanErrorState });     
    }   

    useEffect(() => {
        async function ftInicializar(){      
            let listadoEstatus = await selectEstatus({filtro:{}}, token)        
            setSelecionableEstatus(listadoEstatus)    
        }        
        ftInicializar()
    }, [])
  
    function redireccionar(ventaID){
        let seed = "softwone";
        const parametros = `{"id": "${ventaID}"}`;
        let url = Encrypt({data: parametros, seed}).replace(/\//g, "&")
        setRedirigir(getRederigir(`DetallePedido/${url}`))
    }

    function getRederigir(url = "/") {
        return (<><Switch><Redirect from="/seguimientoPedido" to={url} /></Switch></>);
    }
  
    return (  token ? <>
        { redirigir }      
        <Tabla
            infoTabla={perfil == "Administrador" ? infoTablaAdministrador : infoTablaUsuario}
            asyncData={perfil == "Administrador" ? tablaCompras : tablaSeguimientoPedido}
            token={token}
        />
        {open && <Reembolsos  
            onClose={() => handleClose()}             
            openFormulario = {open}       
            ventaID = {state.ventaID}
            reembolsoID = {state.reembolsoID}
         />
        }
        {alert && <SweetAlert
            title={alert.title}
            descripcion={alert.descripcion}
            tipo={alert.tipo}
            msjConfirmacion={alert.msjConfirmacion}
            msjCancelacion={alert.msjCancelacion}
            onConfirm={alert.onConfirm}
            showConfirm={alert.showConfirm}
            showCancel={alert.showCancel}
            onCancel={() => setAlert(null)}
        />}
      </> : <><Switch><Redirect fromn="*" to={"/"} /></Switch></>);

}
export default SeguimientoPedido;
