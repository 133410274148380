export const ADD_PAQUETERIA = `
mutation addPaqueteria($input: InputAddPaqueteria!) {
    addPaqueteria(input:$input) {
      paqueteriaID
      paqueteria
      descripcion
      costo
      ventaMinima
      estatus


    }

  }`;

export const UPDATE_PAQUETERIA =  `
  mutation updatePaqueteria($input: InputUpdatePaqueteria!) {
    updatePaqueteria(input:$input) {
      paqueteriaID
      paqueteria
      descripcion
      costo
      estatus
    }
}`;


export const DELETE_PAQUETERIA = `
mutation deletePaqueteria($paqueteriaID: ID!)
	{ deletePaqueteria(id: $paqueteriaID)
}`;


export const UPDATE_PAQUETERIAESTATUS = `
mutation updatePaqueteriaEstatus($input:InputUpdatePaqueteriaEstatus!) {
    updatePaqueteriaEstatus(input:$input)
  }`;
