import FetchAPI from "@renedelangel/helpers/lib/FetchAPI";
import { urlServidor } from '../../helpers';

const DELETE_FACTURACION = `
mutation deleteFacturacion($input: InputDeleteFacturacion!){
    deleteFacturacion(input: $input)
}
`

const CAMBIARESTATUS_FACTURACION = `
mutation cambiarEstatusFacturacion($input: InputCambioEstatusFacturacion!){
    cambiarEstatusFacturacion(input: $input)
}
`


export const deleteFacturacion = async (variables, token) =>{
    try{
        let {
            deleteFacturacion
        } = await FetchAPI.graphql({
            token,
            variables,
            url: urlServidor,
            query: DELETE_FACTURACION
        });

        return deleteFacturacion;
        
    }catch({message}){
        throw new Error(message);
    }
}

export const cambiarEstatusFacturacion = async (variables, token) =>{
    try{
        let {
            cambiarEstatusFacturacion
        } = await FetchAPI.graphql({
            token,
            variables,
            url: urlServidor,
            query: CAMBIARESTATUS_FACTURACION
        });

        return cambiarEstatusFacturacion;
        
    }catch({message}){
        throw new Error(message);
    }
}