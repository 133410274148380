export const GET_BOLETIN = `
query getBoletin($boletinID:ID!) {
    getBoletin(id:$boletinID){
        boletinID
        asunto
        contenido
        estatus
        fechaCreacion
        fechaEnviado
        imagen
        archivoAdj
        ruta
        storageID
    }
  }`;

export const FILTER_BOLETINES = `
query filterBoletines($filtro: InputFilterBoletines!) {
    filterBoletines(filtro: $filtro) {
        registros
        paginas
        listado {
            boletinID
            asunto
            contenido
            estatus
            fechaCreacion
            fechaEnviado
            imagen
            archivoAdj
            dominio
            ruta
            storageID
        }
    }

}
`;

export const UPDATE_BOLETIN = `
  mutation updateBoletin($input: InputUpdateBoletin!) {
    updateBoletin(input:$input) {
        boletinID
        asunto
        contenido
        estatus
        fechaCreacion
        fechaEnviado
        imagen
        archivoAdj
    }
}
`;

export const ADD_BOLETIN = `
  mutation addBoletin($input: InputAddBoletin!) {
    addBoletin(input:$input) {
        boletinID
        asunto
        contenido
        estatus
        fechaCreacion
        fechaEnviado
        imagen
        archivoAdj
    }
}
`;

export const DELETE_BOLETIN = `
mutation deleteBoletin($boletinID: ID!)
	{ deleteBoletin(id: $boletinID)
}`;


export const UPDATE_STATUS_BOLETIN = `
mutation updateStatusBoletin($boletinID: ID!)
	{ updateStatusBoletin(id: $boletinID)
}`;

export const DELETE_SUSCRIPCION = `
mutation deleteSuscripcion($input: InputDeleteSuscripcion!)
{ 
    deleteSuscripcion(input: $input)
}`;