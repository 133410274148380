import React, { useState, useRef, useEffect } from 'react';
import { Switch, Redirect } from 'react-router-dom';
import {Validaciones} from '@renedelangel/helpers';
// master components
import Tabla from '../../_layout/masterComponents/Tabla';
import FormularioModal from '../../_layout/masterComponents/FormularioModal';

// Redux
import { useSelector } from "react-redux";

//generic components
import SweetAlert from '../../_layout/genericComponents/ModalConfirmacion';
import { NetworkError } from "../../_layout/genericComponents/Metodos";
import Preloader from '../../_layout/genericComponents/Preloader';

// material-ui icons
import Add from "@material-ui/icons/Add";
import Edit from "@material-ui/icons/Edit";
import DeleteIcon from '@material-ui/icons/Delete';
import SyncIcon from '@material-ui/icons/Sync';
import SaveIcon from '@material-ui/icons/Save';
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import CloseIcon from '@material-ui/icons/Close';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import MapIcon from '@material-ui/icons/Map';


import { getUsuarios, tablaUsuarios, correoActivaCuenta } from '../../querys/Usuario/metodos';
import { addUsuarioPorAdmin, updateUsuarios, deleteUsuarios, updateUsuariosEstatus } from '../../mutations/Usuario/metodos';
import {selectEnumPerfilSinUsuario} from '../../querys/Enums/metodos';

import { handleFocus, handleErrorInputText, handleModificar, handleAlertGeneric, handleAlertMutationGeneric, handelAlertEliminar, handleEliminar, handleGuardar } from '../../_layout/helpers/handles';
import { info, danger, success, primary, rose } from '../../_layout/helpers/colores';

import { rgxCorreo } from '../../helpers/regexp';

import PopUpAgregarDireccion from '../../_layout/NuevosComponentes/PopUpAgregarDireccion';

import { tablaDireccion } from "../../querys/Direcciones/metodos";

import { deleteDireccion } from 'mutations/Direcciones/metodos';


const { trim } = Validaciones;

export default function DireccionesFacturacion() {

    const { usuario, token } = useSelector(state => state.login);

    const [open, setOpen] = useState(false);
    const [alert, setAlert] = useState(null);
    const [actualizar, setActualizar] = useState(false);
    const [direccionID, setDireccionID] = useState(null);

    const acciones = [{
        icono: Edit,
        color: info,
        descripcion: "Modificar",
        parametros: [{ campo: "direccionID", variable: "direccionID" }],
        disabled: { multiseleccion: true },
        onClick: (parametros) => {
            setDireccionID(parseInt(parametros.direccionID))
            setOpen(true);
        }
    },{
        icono: DeleteIcon,
        color: danger,
        descripcion: "Eliminar",
        parametros: [{ campo: "direccionID" }],
        disabled: { multiseleccion: true },
        onClick: ({direccionID}) => handelAlertEliminar({
            setAlert,
            mensaje: `la dirección de facturación`,
            onCancel: () => setAlert(null),
            onConfirm: () => handleEliminar({
                token, setAlert, setActualizar, actualizar,
                parametros: {direccionID}, asyncDelete: deleteDireccion,
                msjCorrecto : { title: `Se elimino el registro`, descripcion: `Se ha logrado eliminar la dirección de facturación de forma correcta`, tipo: success, msjConfirmacion: `Aceptar` }
            })
        })
    }];

    const botones = [{
        icono: Add,
        color: success,
        descripcion: "Agregar",
        onClick: () => setOpen(true),
        disabled: { multiseleccion: true }
    },{
        icono: SyncIcon,
        color: info,
        descripcion: "Actualizar",
        onClick: () => setActualizar(!actualizar),
        disabled: { multiseleccion: true }
    }];

    const infoTabla = {
        botones,
        acciones,
        actualizar,
        id: "tablaDireccionesFacturacion",
        color: primary,
        title: "Direcciones de Facturación",
        iconTable: <MapIcon />,
        headers: [
            { variable: "direccionID", descripcion: "ID", hide: true},
            { variable: "usuarioID", descripcion: "usuarioID", hide: true},
            { variable: "nombreDireccion", descripcion: "Nombre de dirección"},
            { variable: "nombreResponsable", descripcion: "Responsable"},
            { variable: "codigoPostal", descripcion: "C.P."},
            { variable: "cambioTipo", descripcion: "cambioTipo", hide: true},
        ],
        responsiveTitle: ["nombreDireccion", "codigoPostal"]
    }

    useEffect(() => {
        async function ftLimpiarInformacion(){
            if(open == false){
                setDireccionID(null);
            }
        }
        ftLimpiarInformacion()
    }, [open])

    return(<>
        <Tabla
            infoTabla={infoTabla}
            asyncData={tablaDireccion}
            token={token}
            parametrosFijos={{usuarioID: usuario.usuarioID,
                tipoDireccion: 2}}
        />
        { open
            ?
                <PopUpAgregarDireccion
                openAgregarDireccion = {open}
                setOpenAgregarDireccion = {setOpen}
                usuario = {usuario}
                token = {token}
                actualizar = {actualizar}
                setActualizar = {setActualizar}
                propsSetAlert = {setAlert}
                propsDireccionID = {direccionID}
                />
                :
                <></>
            }
            {alert && <SweetAlert
            title={alert.title}
            descripcion={alert.descripcion}
            tipo={alert.tipo}
            msjConfirmacion={alert.msjConfirmacion}
            msjCancelacion={alert.msjCancelacion}
            onConfirm={alert.onConfirm}
            showConfirm={alert.showConfirm}
            showCancel={alert.showCancel}
            onCancel={() => setAlert(null)}
            />}
    </>);

}