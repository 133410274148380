import React from 'react';
import ReactDOM from 'react-dom';
import { createBrowserHistory } from "history";
import { Router, Route, Switch, Redirect } from "react-router-dom";

import App from './App';

import "_layout/assets/scss/material-dashboard-pro-react.scss?v=1.8.0";

const history = createBrowserHistory();

ReactDOM.render(
    <Router history={history}>
        <Switch>
            <Route path="/" component={App} />
            <Redirect from="/" to="/" />
        </Switch>
    </Router>,
    document.getElementById('root')
);
