import React, { useState, useEffect } from "react";
import {StaticRouter, useHistory} from 'react-router-dom';

import PropTypes from "prop-types";

// media querys
import useMediaQuerys from "../../genericComponents/MediaQuerys";

//COMPONENTES
import CardHeader from "../../components/Card/CardHeader.js";

//material ui
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormGroup from '@material-ui/core/FormGroup';
import FormLabel from '@material-ui/core/FormLabel';
import FormControl from '@material-ui/core/FormControl';
import Checkbox from '@material-ui/core/Checkbox';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import TabPanel from '@material-ui/lab/TabPanel';
import AppBar from '@material-ui/core/AppBar';
import TabContext from '@material-ui/lab/TabContext';
import TabList from '@material-ui/lab/TabList';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';

// Redux
import { useDispatch,useSelector } from 'react-redux';
//import { setEntrega } from '../../redux/actions/entregaActions';

import { getDireccion, tablaDireccion, tablaDireccionSucursal } from '../../../querys/Direcciones/metodos';

// @material-ui/core components
import { makeStyles, useTheme  } from "@material-ui/core/styles";

//formato
import { rgxNumeros, rgxRFC } from '../../../helpers/regexp';

import styles from "../../assets/cssComponentes/confirmarPedido";
import cardIconStyle from "_layout/assets/jss/material-dashboard-pro-react/components/cardIconStyle";
import { StarRate } from "@material-ui/icons";

const useStyles = makeStyles(styles);

function PanelEntrega(props){
    const { datosPedido, dirCliente, dirSucursal, recibeDatos, paqueteria, errorStatePedido, recibeError , disabled} = props;
    const classes = useStyles();
    const [datosEntrega, setDatosEntrega] = useState({tipoEntrega:datosPedido.tipoEntrega, idDireccion:datosPedido.idDireccion, direccionEntrega:datosPedido.direccionEntrega, requiereFactura: datosPedido.requiereFactura});
    const { usuario, token } = useSelector(state => state.login);
    const [errorState, setErrorState] = useState(errorStatePedido);   

    useEffect(()=> {
        try{
        
            recibeDatos({...datosPedido,
                tipoEntrega: datosEntrega.tipoEntrega,
                direccionEntrega: datosEntrega.direccionEntrega,
                idDireccion: datosEntrega.idDireccion,
                costoEnvio: datosEntrega.costoEnvio,
                requiereFactura: datosEntrega.requiereFactura
            });
        }catch({message}){
        console.log('Error');
        }
    },[datosEntrega]);   

    const handleChange = (e) => {
        if (e.target.name=="requiereFactura"){
            setDatosEntrega({ ...datosEntrega, [e.target.name]: e.target.checked })
        }else if (e.target.name=="tipoEntrega"){
            let envio=0;
            let dir = '';
            let idDir=null;
            if(Number(e.target.value) == 1){
                envio =  paqueteria.costo;
                idDir = datosEntrega.idDireccion;
            }else{
                dir =  dirSucursal[0].direccionFormateada;
            }
            setDatosEntrega({...datosEntrega, tipoEntrega: Number(e.target.value),
            costoEnvio: envio, direccionEntrega: dir, idDireccion: idDir})
        }else
        {
            setDatosEntrega({...datosEntrega, [e.target.name]: e.target.value})
        }
    };

    async function changeDireccionesEntrega(e){
        let dir = e.target.value;
        try{
            const direccion = await getDireccion({direccionID:dir}, token);
            setDatosEntrega({...datosEntrega, idDireccion:dir, direccionEntrega: direccion.direccionFormateada});
            actualizaState('idDireccion', false, "")
        }catch(error){
            console.log('error', error)
        }
    }
    useEffect(()=> {
        try{
            setErrorState(errorStatePedido)
        }catch({message}){
        console.log('Error', message);
        }
    },[errorStatePedido]);

    function actualizaState(key, error , msj){
        setErrorState(errorState => ({
            ...errorState,
            [key]: { helperText: msj , error: error }
        }));
        recibeError({...errorStatePedido, [key]: { helperText: msj , error: error }})
    }    

    return (
        (<div>            
            <FormGroup row >
                <RadioGroup  disabled = {disabled} row aria-label="gender" name="tipoEntrega" value={datosEntrega.tipoEntrega} onChange={(e) => handleChange(e)} >
                <FormControlLabel value={1} control={<Radio />} label="Enviar a domicilio" />
                <FormControlLabel value={2} control={<Radio />} label="Recoger en tienda" />
                </RadioGroup>
            </FormGroup>          
           
            <div >
                <select id="direccion"  onChange={(e) => changeDireccionesEntrega(e)}
                    value={datosEntrega.idDireccion}
                    name="idDireccion" className={classes.inputCorrecto}  
                >
                <option value={null}>
                      Seleccione la dirección de entrega
                </option>
               { dirCliente ?
                    dirCliente.map(direccion => (
                            <option
                                value = {direccion.direccionID}>
                                {direccion.nombreDireccion}
                            </option>
                        ))
                        :
                        <option value="">
                        No hay direcciones
                        </option>
               }              
                </select>
            <label>{errorState.nombreFactura.helperText}</label>        
            </div>
            <div >
            <input type="text" name="direccionEntrega"  className={errorState.idDireccion.error ? classes.textAreaError : classes.textArea} 
                value={datosEntrega.direccionEntrega} onChange={(e) => handleChange(e)}
                placeholder="Dirección de entrega"
                ></input>
            <label>{errorState.rfc.helperText}</label>
            </div>
       
        </div>)
    )
}
PanelEntrega.propTypes = {
children: PropTypes.node,
index: PropTypes.any.isRequired,
value: PropTypes.any.isRequired,
};
function PanelFacturacion(props){
    const { datosPedido, dirFacturacion,  recibeDatos, errorStatePedido, recibeError, disabled} = props;
    const classes = useStyles();
    const [datosFactura, setDatosFactura] = useState(datosPedido);
    const { usuario, token } = useSelector(state => state.login);
    const [errorState, setErrorState] = useState(errorStatePedido);

    useEffect(()=> {
        try{
            recibeDatos({...datosPedido,
                nombreFactura: datosFactura.nombreFactura,
                rfc: datosFactura.rfc,
                idDireccionFac: datosFactura.idDireccionFac,
                direccionFactura: datosFactura.direccionFactura,
                requiereFactura: datosFactura.requiereFactura
            });
        }catch({message}){
        console.log('Error');
        }
    },[datosFactura]);
    useEffect(()=> {
        try{
            setErrorState(errorStatePedido)
        }catch({message}){
        console.log('Error');
        }
    },[errorStatePedido]);

    function actualizaState(key, error , msj){
        setErrorState(errorState => ({
            ...errorState,
            [key]: { helperText: msj , error: error }
        }));
        recibeError({...errorStatePedido, [key]: { helperText: msj , error: error }})
    }
    const handleChange = (e) => {

        if (e.target.name=="requiereFactura"){
            setDatosFactura({ ...datosFactura, [e.target.name]: e.target.checked })
            actualizaState(e.target.name, false, "")
        }else{
       
            let val = e.target.value;
            val = val.toUpperCase()
            actualizaState(e.target.name, false, "")
            setDatosFactura({...datosFactura, [e.target.name]: val})
        }

    };
    const validaDatos = (e) => {
        let val = e.target.value;
        actualizaState(e.target.name, false, "")
        if(e.target.name = "rfc"){
            if(!rgxRFC.test(val))
            {
            actualizaState(e.target.name, true, "El RFC no tiene el formato correcto")
            }
        }
    };
    async function changeDirecciones(e){
        let dir = e.target.value;
        try{
            const direccion = await getDireccion({direccionID:dir}, token);
            setDatosFactura({...datosFactura, idDireccionFac: dir, direccionFactura: direccion.direccionFormateada});
            actualizaState("idDireccionFac", false, "")
        }catch(error){
            console.log('error', error)
        }
    }

    return (
        (<div >      
           
            <FormControlLabel
                control={<Checkbox checked={datosFactura.requiereFactura}  onChange ={(e) => handleChange(e)} name="requiereFactura"
               />}
                label="Requiere factura?"
                className={classes.formControl}
            />
            
            <div >
            <input type="text" name="nombreFactura"  className={errorState.nombreFactura.error ? classes.inputError : classes.inputCorrecto} 
                value={datosFactura.nombreFactura}  onChange={(e) => handleChange(e)}
                placeholder="Nombre o razón social"
                ></input>
            <label>{errorState.nombreFactura.helperText}</label>
            </div>

            <div >
            <input type="text" name="rfc"  className={errorState.rfc.error ? classes.inputError : classes.inputCorrecto} 
                value={datosFactura.rfc}  onChange={(e) => handleChange(e)}
                placeholder="Rfc"
                ></input>
            <label>{errorState.rfc.helperText}</label>
            </div>

            <div >
                <select id="select1"  onChange={(e) => changeDirecciones(e)}
                  value={datosFactura ? datosFactura.idDireccionFac : ""}
                  name="idDireccionFac" className={classes.inputCorrecto}  
                >
                <option value={null}>
                      Seleccione la dirección de facturación
                </option>
               { dirFacturacion ?
                    dirFacturacion.map(direccion => (
                            <option
                                value = {direccion.direccionID}>
                                {direccion.nombreDireccion}
                            </option>
                        ))
                        :
                        <option value="">
                        No hay direcciones
                        </option>
               }              
                </select>        
            </div>

            <div >
            <input type="text" name="direccion"  className={errorState.idDireccionFac.error ? classes.textAreaError : classes.textArea} 
                value={datosFactura.direccionFactura} onChange={(e) => handleChange(e)}
                placeholder="Dirección de la factura"
                ></input>
            <label>{errorState.idDireccionFac.helperText}</label>
            </div>               
        </div>)
    )
}
PanelFacturacion.propTypes = {
children: PropTypes.node,
index: PropTypes.any.isRequired,
value: PropTypes.any.isRequired,
};

export default function MetodoEntregaFormulario  (props)  {

    const classes = useStyles();
    const theme = useTheme();
    const { usuario, token } = useSelector(state => state.login);
    const {datosPedido, paqueteria, recibeDatos, errorStatePedido, recibeError, disabled, pageActiva} = props;
    let { isTabletOrMobile, isTabletOrMobileDevice } = useMediaQuerys();
    let responsive = isTabletOrMobile || isTabletOrMobileDevice;

    //const [datosEntrega, setDatosEntrega] = useState(datosPedido);
    const [dirCliente, setDirCliente] = useState();
    const [dirSucursal, setDirSucursal] = useState();
    const [dirFacturacion, setDirFacturacion] = useState();
    const [value, setValue] = useState(pageActiva);
    const [errorState, setErrorState] = useState(errorStatePedido);
    const [state, setState] = useState();


    const handleChange = (event, newValue) => {
        setValue(newValue);
    };  

    useEffect(() => {
        async function ftEffect() {
            try {
            let direcciones = await tablaDireccion({filtro:{limite: 100, pagina:1, usuarioID:usuario.usuarioID, tipoDireccion:1}}, token);
            setDirCliente(direcciones.listado);          

            let direccionSuc = await tablaDireccionSucursal({filtro:{pagina:1, limite:1}}, token);

            setDirSucursal(direccionSuc.listado);

            let direccionFac = await tablaDireccion({filtro:{limite: 100, pagina:1, usuarioID:usuario.usuarioID, tipoDireccion:2}}, token);

            setDirFacturacion(direccionFac.listado);           
           

            } catch({message}) {
            console.log('error', message)
            }
        } ftEffect();
    }, []);

    useEffect(() => {
        async function ftEffect() {
            try {
                setValue(pageActiva.toString());               
            } catch({message}) {
            console.log('error metodo entrega', message)
            }
        } ftEffect();
    }, [pageActiva]);
    
    return ( 
        <Grid container >
        <Grid item xs={12}>
        <TabContext value={value}>
        <AppBar position="static" >
            <TabList onChange={handleChange} aria-label="simple tabs example">
                {     
                    pageActiva == 1? <Tab label="Método de entrega" value="1" /> : <div></div>
                }
                <Tab label="Facturación" value="2"  /> 
            </TabList>
        </AppBar>   
            {
                pageActiva == 1 ? 
                <TabPanel value={"1"}>    
                 
                    <PanelEntrega datosPedido={datosPedido} dirCliente={dirCliente}
                    dirSucursal={dirSucursal} recibeDatos={recibeDatos} paqueteria={paqueteria} 
                    recibeError={recibeError} errorStatePedido={errorStatePedido} disabled = {disabled} 
                    >
                    </PanelEntrega>   

                </TabPanel>                 
                 : <div></div>
            }           
            <TabPanel value={"2"}>
            {
                <PanelFacturacion datosPedido={datosPedido} dirFacturacion={dirFacturacion}
                recibeDatos={recibeDatos} recibeError={recibeError} errorStatePedido={errorStatePedido} 
                disabled={disabled}></PanelFacturacion>
            }
            </TabPanel>
        </TabContext>
        </Grid>
    </Grid>
    );
}
MetodoEntregaFormulario.propTypes = {
    data: PropTypes.array.isRequired,
    hideHeader: PropTypes.bool,
    filter: PropTypes.array,
    iconTable: PropTypes.node,
    title: PropTypes.string,
    showHelp: PropTypes.bool,
    responsiveTitle: PropTypes.arrayOf(PropTypes.string),
    activeResponsive: PropTypes.number,
    alineacion: PropTypes.arrayOf(PropTypes.object),
    formato: PropTypes.arrayOf(PropTypes.object),
    paginacion: PropTypes.object,
    paginaActiva: PropTypes.number,
    rowColor: PropTypes.func,
    busqueda: PropTypes.object,
    loader: PropTypes.bool,
    error: PropTypes.object,
    handleSearch: PropTypes.func,
    handleRegistros: PropTypes.func
};
