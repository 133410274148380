import {
    defaultFont,
    primaryColor,
    defaultBoxShadow,
    infoColor,
    successColor,
    warningColor,
    dangerColor,
    whiteColor,
    roseColor,
    blackColor
} from "../../material-dashboard-pro-react.js";

const headerStyle = theme => ({
    managerClasses: {
        [theme.breakpoints.up("xs")]: {
            display: "inline-block"
        }
    },
    linkText: {
        zIndex: "3",
        ...defaultFont,
        fontSize: "14px",
        margin: "0!important",
        textTransform: "none"
    },
    buttonLink: {
        // [theme.breakpoints.down("sm")]: {
        //     display: "flex",
        //     margin: "5px 15px 0",
        //     width: "auto",
        //     height: "auto",
        //     "& svg": {
        //         width: "30px",
        //         height: "24px",
        //         marginRight: "19px",
        //         marginLeft: "3px"
        //     },
        //     "& .fab,& .fas,& .far,& .fal,& .material-icons": {
        //         width: "30px",
        //         fontSize: "24px",
        //         lineHeight: "30px",
        //         marginRight: "19px",
        //         marginLeft: "3px"
        //     }
        // }
    },
    links: {
        width: "20px",
        height: "20px",
        zIndex: "4",
        [theme.breakpoints.down("sm")]: {
            display: "block",
            width: "30px",
            height: "30px",
            color: "inherit",
            opacity: "0.8",
            marginRight: "16px",
            marginLeft: "-5px"
        }
    },
    notifications: {
        zIndex: "4",
        position: "absolute",
        top: "5px",
        border: "1px solid " + whiteColor,
        right: "5px",
        fontSize: "9px",
        background: dangerColor[0],
        color: whiteColor,
        minWidth: "16px",
        height: "16px",
        borderRadius: "10px",
        textAlign: "center",
        lineHeight: "14px",
        verticalAlign: "middle",
        display: "block"
    },

    primary: {
        backgroundColor: primaryColor[0],
        color: whiteColor,
        ...defaultBoxShadow
    },
    white: {
        backgroundColor: whiteColor,
        color: blackColor,
        ...defaultBoxShadow
    },
    black: {
        backgroundColor: blackColor,
        color: whiteColor,
        ...defaultBoxShadow
    },
    info: {
        backgroundColor: infoColor[0],
        color: whiteColor,
        ...defaultBoxShadow
    },
    success: {
        backgroundColor: successColor[0],
        color: whiteColor,
        ...defaultBoxShadow
    },
    warning: {
        backgroundColor: warningColor[0],
        color: whiteColor,
        ...defaultBoxShadow
    },
    danger: {
        backgroundColor: dangerColor[0],
        color: whiteColor,
        ...defaultBoxShadow
    },
    rose: {
        backgroundColor: roseColor[0],
        color: whiteColor,
        ...defaultBoxShadow
    }
});

export default headerStyle;
