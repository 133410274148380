import React, { useState, useRef, useEffect } from 'react';
import { Switch, Redirect, useParams, Link, useLocation } from 'react-router-dom';
import { Validaciones } from '@renedelangel/helpers';
import { Encriptar } from '@renedelangel/helpers';

// master components
import { makeStyles } from "@material-ui/core/styles";
import LocationOnIcon from '@mui/icons-material/LocationOn';
import SaveIcon from '@material-ui/icons/Save';
import SyncIcon from '@material-ui/icons/Sync';

// Redux
import { useSelector } from "react-redux";

//generic components
import SweetAlert from '../../_layout/genericComponents/ModalConfirmacion';
import Preloader from '../../_layout/genericComponents/Preloader';
import FormularioModal from '../../_layout/masterComponents/FormularioModal';

import { getReembolso } from '../../querys/Reembolsos';
import { updateSeguimientoPedido,  } from '../../querys/SeguimientoPedido';

import { handleFocus, handleErrorInputText,   handleGuardar } from '../../_layout/helpers/handles';
import { urlCliente } from '../../helpers';


import styles from '../../_layout/assets/jss/material-dashboard-pro-react/generic/detallePedido.js'
const useStyles = makeStyles(styles);

// material-ui icons



import { info, danger, success, primary, rose } from '../../_layout/helpers/colores';
import { TurnLeftRounded } from '@mui/icons-material';



const {Encrypt,Decrypt}=Encriptar;
const { trim } = Validaciones;

function CapturaReembolsos(props) {

    let { titulo="Reembolsos",           
       onClose,  
       openFormulario,      
       ventaID,
       reembolsoID
     } = props;    

    const { usuario, token } = useSelector(state => state.login);
    const classes = useStyles();
    const perfil = usuario ? usuario.perfil : 'Usuario';        
    let rutaPedido = useLocation();
    rutaPedido = urlCliente + rutaPedido.pathname;   

    const cleanNotificaciones = { mensaje: "", color: null, open: false };
    const cleanDisabledInputs = { empresaID: false, cer: false, key: false, emisor: false, receptor: false, fechaIni: false, fechaFin: false, tipo: false, tipoSolicitud: false };
    const cleanState = {
        ventaID: null,
        reembolsoID: null,
        motivoReembolso: 0,
        razonReembolso: "",
        tipoSolucion: "",
        solucionReembolso:"",
        fechaAlta: "",
        fechaSolucion: "",    
        estatusReembolso: 1,  
    }; 
  
    const columns = [{ nombre: 'Subtotal', width: 90 },
    {nombre: 'Envio', width: 150 },
    { nombre: 'Total', width: 150 },];
    const motivos = [
        { label: 'Seleccione una opcion', value: 0 },
        { label: 'Ya no quiero esta compra', value: 1 },
        { label: 'He realizado esta compra por error', value: 2 },
        { label: 'La compra no llegó en el tiempo esperado', value: 3 },
        { label: 'No reconozco esta compra', value: 4 },     
    ]
    const solucionesReembolso = [
        { label: 'Seleccion el tipo de solución', value: 0 },
        { label: 'Se aplicó el reembolso', value: 1 },
        { label: 'No aplica el reembolso', value: 2 },
        { label: 'Reembolso parcial', value: 3 },
        { label: 'Otro', value: 4 },
    ]
    const estatusReembolso = [
        { label: 'PENDIENTE', value: 1 },
        { label: 'EN PROCESO', value: 2 },
        { label: 'FINALIZADO', value: 3 },
      
    ]
    const cleanStateDetalle = [];    
    const cleanErrorState = {
        ventaID: { error: false, helperText: "" },
        motivoReembolso: { error: false, helperText: "" },
        razonReembolso: { error: false, helperText: "" },
        tipoSolucion: { error: false, helperText: "" },
        solucionReembolso: { error: false, helperText: "" },       
        fechaAlta:{ error: false, helperText: ""},
        fechaSolucion : {error: false, helperText: ""}
    }
    let titleModal = perfil == "Administrador" ? `Revisar reembolso `: `Solicitar reembolso `;
    titleModal = titleModal + `(${perfil})`

    let ventaIDRef = useRef(null);
    let motivoReembolsoRef = useRef(null);
    let razonReembolsoRef = useRef(null);
    let tipoSolucionRef = useRef(null);
    let solucionReembolsoRef = useRef(null);
    let fechaAltaRef = useRef(null);
    let fechaSolucionRef = useRef(null);
    

    const [state, setState] = useState(cleanState);
    const [stateTotales, setStateTotales] = useState({subtotal:0, envio: 0, total:0});
    const [error, setError] = useState(false);
    const [errorState, setErrorState] = useState(cleanErrorState);
    const [notificaciones, setNotificaciones] = useState(cleanNotificaciones);
    const [open, setOpen] = useState(false);
    const [disabled, setDisabled] = useState(false);
    const [alertInput, setAlertInput] = useState(null);
    const [alert, setAlert] = useState(null);
    const [loader, setLoader] = useState(false);
    const [actualizar, setActualizar] = useState(false);
    const [redirigir, setRedirigir] = useState(null);
    const [stateOrigen, setStateOrigen] = useState(cleanState);
    const [totales, setTotales] = useState({"subtotal":0, "envio": 0, "total": 0});

    const rows = [{ nombre: 'Subtotal', valor: stateTotales.subtotal, campo: "Subtotal", tipo: "moneda"},      
    {nombre: 'Costo de envío', valor:  stateTotales.envio, campo: "Envio", tipo: "moneda"},
    { nombre: 'Total con Envío', valor:  stateTotales.total, campo: "Total", tipo:"moneda" }];

    const accionesFormulario = [
    {
        icono: SaveIcon,
        color: info,
        descripcion: "Guardar",
        parametros: [{ campo: "ventaID" }],
        disabled: stateOrigen.reembolsoID != null ? true : perfil == "Administrador" && stateOrigen.estatusReembolso <=2 ? false : perfil=="Usuario" && stateOrigen.estatusReembolso <2 ? false : true,
        //
        onClick: () =>  handleGuardar({
            setLoader,
            setDisabled,
            setActualizar,
            actualizar,
            setNotificaciones,
            handleClose,
            handleFocus,
            refFocus: razonReembolsoRef,
            mensajeCorrecto: `El reembolso se ${state.reembolsoID ? "actualizó" : "agregó"} correctamente`,
            asyncCallback: actualizarReembolso,
        }),
    }];

    function redireccionar(){      
        setRedirigir(getRederigir(`seguimientoPedido`))
    }
    function getRederigir(url = "/") {
        return (<><Switch><Redirect from="/DetallePedido" to={url} /></Switch></>);
    }

    const botones = [{
        icono: SyncIcon,
        color: info,
        descripcion: "Actualizar",
        onClick: () => setActualizar(!actualizar),
        disabled: { multiseleccion: true }
    }];
  
    const inputs= [  
        {
            disabled : true,
            id: "fechaAlta",
            value: state.fechaAlta,
            error: errorState.fechaAlta.error,
            success: state.fechaAlta && !errorState.fechaAlta.error ? true : undefined,
            helperText: errorState.fechaAlta.helperText,
            inputRef: fechaAltaRef,
            title: "Fecha de solicitud",
            placeholder: "Fecha de solicitud",
           // tipo: "datetimepicker",
            grid: { md: 4, lg: 4, sm: 6, xs:12 },
            onChange: (value) => {
                handleChange(value, "fechaAlta")
            }
        },    
        {
            disabled : true,
            id: "fechaSolucion",
            value: state.fechaSolucion,
            error: errorState.fechaSolucion.error,
            success: state.fechaSolucion && !errorState.fechaSolucion.error ? true : undefined,
            helperText: errorState.fechaSolucion.helperText,
            inputRef: fechaSolucionRef,
            title: "Fecha de resolución *",
            placeholder: "Fecha de Resolución",
        //    tipo: "datetimepicker",
            hide: perfil == "Usuario" && state.tipoSolucion == null ? true : false,
            grid: { md: 4, lg: 4, sm: 6, xs:12 },
            onChange: (value) => {
                if (typeof value !== "object") return;
                handleChange(value, "fechaSolucion")
            }
        }, 
        {
            disabled : perfil == "Administrador" && stateOrigen.estatusReembolso <=2 ? false : true,
            id: "estatusReembolso",
            value: state.estatusReembolso,
            title: "Estatus reembolso",            
            tipo: "select",
            grid: { md: 4, lg: 4, sm: 6, xs:12 },
            data: estatusReembolso,
            onChange: ({ target: { value } }) => handleChange(value, "estatusReembolso"),
        },     
       
       {
        id: "Usuario",
        tipo: "fieldset",
        leyenda: "Solicitud",
        grid: { lg: 12, xs:12 },   
        inputs: [ 
            {
                disabled: perfil == "Usuario" && stateOrigen.estatusReembolso < 2 ? false : true,
                id: "motivoReembolso",
                value: state.motivoReembolso,
                error: errorState.motivoReembolso.error,
                success: state.motivoReembolso && !errorState.motivoReembolso.error ? true : undefined,
                helperText: errorState.motivoReembolso.helperText,
                title: "Motivo de reembolso",
                grid: { md: 6, lg: 6, xs: 12 },
                onChange: ({ target: { value } }) => handleChange(value, "motivoReembolso"),
                tipo: "select",
                data: motivos,
                
            },         
            {
                disabled : perfil == "Usuario" && stateOrigen.estatusReembolso < 2 ? false : true,
                id: "razonReembolso",
                value: state.razonReembolso,
                error: errorState.razonReembolso.error,
                success: state.razonReembolso && !errorState.razonReembolso.error ? true : undefined,
                helperText: errorState.razonReembolso.helperText,
                textAlign: "left",
                placeholder: "Danos más detalles",
                inputRef:razonReembolsoRef,
               // multiline: "true",
                title: "Descripción de su solicitud *",
                grid: { md: 12, lg: 12,  xs: 12 },
                onChange: ({ target: { value } }) => { handleChange(value, "razonReembolso")  }
            },  ]   
       }   
    ]
    const inputsAdmin = [{
        id: "Admin",
        tipo: "fieldset",
        leyenda: "Seguimiento",
        grid: { lg: 12, xs:12 },   
        inputs: [
            {
                disabled : perfil == "Administrador" && stateOrigen.estatusReembolso <= 2 ? false : true,
                id: "tipoSolucion",
                value: Number(state.tipoSolucion),
                checked: state.tipoSolucion,
                title: "tipo Solución",              
                error: errorState.tipoSolucion.error,
                success: state.tipoSolucion && !errorState.tipoSolucion.error ? true : undefined,
                helperText: errorState.tipoSolucion.helperText,
                grid: { md: 6, lg: 6, xs: 12 },
                onChange: ({ target: { value } }) => handleChange(value, "tipoSolucion"),              
                tipo: "select",        
                data: solucionesReembolso,
     
                onKeyDown: (evt) => handleFocus(evt, solucionReembolsoRef)
            },            
            {
                disabled: perfil == "Administrador" && stateOrigen.estatusReembolso <= 2 ? false : true,
                id: "solucionReembolso",
                value: state.solucionReembolso,
                error: errorState.solucionReembolso.error,
                success: state.solucionReembolso && !errorState.solucionReembolso.error ? true : undefined,
                helperText: errorState.solucionReembolso.helperText,
                textAlign: "left",              
                title: "Solución al reembolso *",
                grid: { md: 12, lg: 12,  XSLTProcessor: 12 },
                onChange: ({ target: { value } }) => {
                        handleChange(value, "solucionReembolso")
                }
            }]
        }
    ]

    function ftErrorInputText({ condicion, ref, keyError, mensajeError }) {
        return handleErrorInputText({
            cleanErrorState, condicion, ref, keyError, mensajeError,
            loader: setLoader, disabled: setDisabled, errorState: setErrorState
        });
    
    }       
    function handleClose() {
        setOpen(false);
        setDisabled(false);
        setLoader(false);
        setNotificaciones({ ...cleanNotificaciones });
        setState({ ...cleanState });
        setErrorState({ ...cleanErrorState });
        onClose()
        //setStateGrupo(cleanStateGrupo);
    }   

    function handleValidaciones({ motivoReembolso, razonReembolso, tipoSolucion, solucionReembolso, estatusReembolso }) {
        let error;      
        let validaciones = [              
        {
            condicion: (!razonReembolso || trim(razonReembolso) === "") && perfil=="Usuario",
            ref: razonReembolsoRef, keyError: "razonReembolso",
            mensajeError: "Debe especificar la razón de su solicitud de reembolso"
        },    
        {
            condicion: razonReembolso && razonReembolso.length < 15  && perfil=="Usuario",
            ref: razonReembolsoRef, keyError: "razonReembolso",
            mensajeError: "Ingrese una razón más extensa (mínimo 15 caracteres)"
        },      
        {
            condicion: !motivoReembolso && perfil == "Usuario" ,
            ref: motivoReembolsoRef, keyError: "motivoReembolso",
            mensajeError: "Debe indicar el motivo de su solicitud"
        },     
        {
            condicion: !solucionReembolso && estatusReembolso >= 3 && perfil == "Administrador",
            ref: solucionReembolsoRef, keyError: "solucionReembolso",
            mensajeError: "Debe indicar la solución a la solicitud de reembolso"
        },
        {
            condicion: solucionReembolso && solucionReembolso.length < 15  && perfil=="Administrador",
            ref: solucionReembolsoRef, keyError: "solucionReembolso",
            mensajeError: "Ingrese una solución más extensa (mínimo 15 caracteres)"
        },      
        {
            condicion: tipoSolucion == 0 && estatusReembolso >= 3 && perfil == "Administrador",
            ref: tipoSolucionRef, keyError: "tipoSolucion",
            mensajeError: "Debe indicar el tipo de solución que se dió al reembolso"
        },           
        ];
        validaciones.forEach(({ condicion, ref, keyError, mensajeError }) => {
            if (error) return;
            error = ftErrorInputText({ condicion, ref, keyError, mensajeError });
        });
        if (error) return error;
    }
  
    function actualizarReembolso(){  
        async function actualizarReembolso(){
            setState({...state, devolver:true}); 
            let {                 
                motivoReembolso,
                razonReembolso,
                tipoSolucion,
                solucionReembolso,
                estatusReembolso
            } = state;
            let correoUsuario = usuario.usuario;
          
            let error = await handleValidaciones({
                motivoReembolso, razonReembolso, tipoSolucion, solucionReembolso, estatusReembolso });
            if (error) return error;
            try {
                    let estatusPedido = 7;
                    if (state.estatusReembolso == 2) {
                        estatusPedido = 8;
                    }
                    if (state.estatusReembolso == 3) {
                        estatusPedido = 9;
                    }
                    await updateSeguimientoPedido({ventaID: ventaID, reembolsoID: Number(reembolsoID), estatus:estatusPedido, usuario: correoUsuario, 
                        motivoReembolso: Number(motivoReembolso), razonReembolso: razonReembolso,
                        tipoSolucion: Number(tipoSolucion), solucionReembolso: solucionReembolso, 
                        estatusReembolso: Number(estatusReembolso), urlPedido: rutaPedido
                    },token);
                    
            }catch({message}){
               /* setAlert({
                    descripcion: `Error al realizar la solicitud ${NetworkError(message)}`,
                    title: "Error!",
                    tipo: "danger",
                    msjConfirmacion: "Aceptar",
                    onConfirm: () => setAlert(null)
                });*/
                console.log('message', message)
            }
            
        }
        return actualizarReembolso();
    }   
    useEffect(() => {
        async function ftEffect() {
            try {        
                if (reembolsoID) {
                 
                    let reembolso = await getReembolso({ reembolsoID: reembolsoID}, token);                      
                    setState(reembolso); 
                    setStateOrigen(reembolso);                 
                                                                         
                }                             

            } catch({message}) {
               console.log('error', message)
            }
        } ftEffect();
    }, [reembolsoID, actualizar]);   

    useEffect(() => {
        async function scrollTop(){
            window.scrollTo(0, 0);
        }
        scrollTop()
    }, [])

    function handleChange(value, key) { setState({ ...state, [key]: value }); }

    if(perfil == "Administrador" || stateOrigen.estatusReembolso > 1 ){                        
        inputsAdmin.map((item, index) => {
            inputs.push(item);
        });                                     
    }   
    
    return ((token && !error) ? (usuario.usuarioID ? <>     
          <FormularioModal           
            open={openFormulario}
            title={titulo}
            onClose={()=>{onClose()}}
            notificaciones={notificaciones}
            closeNotification={() => setNotificaciones({ ...cleanNotificaciones })}
            inputs={inputs}
            acciones={accionesFormulario}
         
         />
        {
            alert && <SweetAlert title={alert.title}
                descripcion={alert.descripcion}
                tipo={alert.tipo}
                msjConfirmacion={alert.msjConfirmacion}
                msjCancelacion={alert.msjCancelacion}
                onConfirm={alert.onConfirm}
                showConfirm={alert.showConfirm}
                showCancel={alert.showCancel}
                onCancel={
                    () => setAlert(null)
                }
        />}
      
    </> : <Preloader />) : <><Switch><Redirect fromn="*" to={"/"} /></Switch></>);
}
export default CapturaReembolsos;
