import React, {useState, useEffect} from 'react';
import './styles.css';

import FormularioPedidoNuevo  from '../FormularioPedidoNuevo'




export default function PedidoNuevo(props){

    let {
        datos,
        pageActiva,
        paqueteria,
        datosPedido,
        recibeDatos,
        totales,
        grabarPedido,
        volver,
        errorState,
        recibeError,
        propsSetAlert
    } = props;

    return (<>

    <FormularioPedidoNuevo 
    datos = {datos}
    pageActiva = {pageActiva}
    paqueteria = {paqueteria}
    datosPedido = {datosPedido}
    recibeDatos = {recibeDatos}
    totales = {totales}
    grabarPedido = {grabarPedido}
    volver = {volver}
    errorState = {errorState}
    recibeError = {recibeError}
    propsSetAlert = {propsSetAlert}
    />
    </>)
}