import React from "react";
import cx from "classnames";
import PropTypes from "prop-types";
import {redondeo} from '../../../../helpers/regexp.js';
import { Fechas, Utilidades } from "@renedelangel/helpers";


import { makeStyles } from "@material-ui/core/styles";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

const useStyles = makeStyles({
  table: {
    minWidth: 350,
    width: 350,
    textAlign: "center",
    margin: "0px",
  },
  titulos:{
    fontWeight: "400",
    fontSize: "18px",
    padding:"0px"
  },
  valores:{
    fontWeight: "500",
    fontSize: "18px",
    padding:"5px"
  },

});

function createData(name, calories, fat, carbs, protein) {
  return { name, calories, fat, carbs, protein };
}

export default function TablaLista(props){

    const {rows, columns, datos } = props;
    let datosTabla = [datos];
    const noTruncarDecimales = {maximumFractionDigits: 20,  minimumFractionDigits: 2, style: 'currency', currency: 'MXN'};

    const { NumberToMoney } = Utilidades;

    const classes = useStyles();

    rows.map((row, index) => {
      if (row.tipo == "moneda"){      
        let valor = redondeo(row.valor,2);     
        valor = parseFloat(valor).toLocaleString('es-MX', noTruncarDecimales);       
        row.valor =  valor;

      }
    })

    return (
         <>
        <Table className={classes.table} ALIGN="right">
            <TableBody>
            {rows.map((row, index) => (
                <TableRow key={row.nombre}>
                <TableCell className={classes.titulos} align="right">
                    {row.nombre}
                </TableCell>
                      <TableCell  className={classes.valores} align="right">{row.valor}</TableCell>
                </TableRow>
            ))}
            </TableBody>
        </Table>
        </>


    );

}
