
import { FetchAPI } from "@renedelangel/helpers";
import { NetworkError } from "_layout/genericComponents/Metodos";
import { urlServidor, b64tofile } from '../../helpers';
import {  getArchivo, deleteFileStorage, blobToBase64, getFileServer } from "../../helpers/serverStorage";


//CONSULTAS
export const getFile = async (variables, token) => {
    let query = `
        query get($filtro: InputGetServerStorage) {
            getServerStorage(filtro: $filtro){
            serverStorageID
            origenID,
            tabla,
            tipo,
            ruta,
            clasificacion,
            nombreArchivo
            }
        }
    `;
    let resultado = await ejecutar({filtro: variables}, token, query);
    let nomArchivo = resultado["nombreArchivo"];

    let punto = nomArchivo.indexOf('.');
    let ext = nomArchivo.substring(punto, nomArchivo.length );
    let nomDocumento = nomArchivo.substring(0, punto);

    let rutaFile = '/tonline/public'+resultado.ruta;

    let b64 = await getArchivo(rutaFile,  token);
    let inicio = b64.length;
    let cadena = b64.indexOf(',')
    let b64sinFormato = b64.substring(cadena+1, inicio);
    let archivo = await b64tofile(b64sinFormato, nomArchivo, ext);

    resultado["archivoFile"] = archivo;
    resultado["archivoB64"] = b64;
    resultado["nombreDocumento"] = nomDocumento;
    resultado["extension"] = ext;
    resultado["ruta"] = rutaFile;
    return resultado;
}

export const tablaServerStorage = async (variables, token) => {

    let query = `
       query filtro($filtro: InputFilterServerStorage){
       filterServerStorage(filtro: $filtro){
        listado{
        serverStorageID
        origenID,
        tabla,
        tipo,
        ruta,
        clasificacion,
        nombreArchivo,
        documento
        }
        registros
        paginas
    }
}
    `;
    let result = await ejecutar(variables, token, query);
    let listado = result.listado;
    let listado2 = listado.map(({ ruta,  ...rest }) => {
                let rutaFormato = '/private/tonline'+ruta;
                return {
                    ...rest,
                    ruta: rutaFormato
                }
            })
    result.listado = listado2;
    return result;
}

//MUTATION
export const deleteFile = async (variables, token) => {
    let query = `
    mutation deleteFile($input: InputDeleteFile!) {
        deleteFile(input: $input)
    }
    `;
    return await ejecutar(variables, token, query);
}
export const upsertFile = async (variables, token) => {

    let query = `mutation upsertFile($input: InputUpsertArchivo!) {
            upsertFile(input: $input){
            serverStorageID
            origenID,
            tabla,
            tipo,
            ruta,
            clasificacion,
            nombreArchivo
        }
    }
    `;

    return await ejecutar(variables, token, query);
}
// FIN MUTATION

export const ejecutar = async (variables, token, query) => {
    try {
        let Respuesta = await FetchAPI.graphql({
            token,
            variables,
            url: urlServidor,
            query: query
        });

        return Respuesta[Object.keys(Respuesta)[0]];

    } catch (error) {
        throw new Error(error.message);
    }
}
