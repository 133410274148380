import { strUrlSArchivos, empresa } from '../index';
import { upsertFile, deleteFile } from 'mutations/ServerStorage';
const tokenfijo = "c29mdHdvbmUyMDIwQCM=";

export async function getArchivo(rutaimagen, token) {
    let resultado ='';
    if (rutaimagen != "")
    {
        try {
            const response = await fetch(
                `${rutaimagen}`,
                {
                    method: "GET",
                    headers: {
                        token: tokenfijo
                    }
                }
            )
                .then(res => res.blob())
                .then(blob => {
                    let result = blobToBase64(blob).then(res => {
                        return res;
                    });
                    return result;
                });
            resultado = response;
        } catch (error) {
            console.log(`Ha ocurrido algo inesperado, inténtalo  más tarde ` );
        }
    }
    return resultado;
};

export async function postArchivo(rutaimagen, archivo, privado, token) {

    let rutaArchivo = '';
    let rutagral = empresa + '/' + rutaimagen + '/';

        try {
            const formData = new FormData();
            formData.append("archivo", archivo);
            formData.append("aplicacion", "tonline");
            formData.append("ruta", rutagral);
            privado === 1 ? formData.append("privado", "true") :  formData.append("publico", "true");

            let response = await fetch(
                `${strUrlSArchivos}/upload`,
                {
                    method: "POST",
                    headers: {
                        token: tokenfijo,//props.token
                        maxFileSize: '50mb',
                    },
                    body: formData,
                }
            )
                .then(res => res.json())
                .then(res => {
                    if (res.ok ===  true) {

                        let url = res.resultado;
                        let base = strUrlSArchivos+'/privado/tonline';
                        let tam = base.length;
                        if (privado === 0)
                        {
                            tam = tam -1;
                        }
                        return url = url.substring(tam);
                    } else {
                        console.log(res.mensaje);
                    }
                });
        rutaArchivo = response;
        } catch (error) {
            console.log(`Ha ocurrido algo inesperado, inténtalo  más tarde `, error);
        }
    return rutaArchivo;
};

export async function putArchivo(rutaimagen, archivo, token) {

    if(rutaimagen === "" || rutaimagen === null || rutaimagen === undefined ) return;
    if(rutaimagen.substring(0,5) === 'https'){
        let lenServer = strUrlSArchivos.length;
        rutaimagen = rutaimagen.substring(lenServer+1, rutaimagen.length);
        rutaimagen = rutaimagen + "/";
    }
    let respuesta = '';

    try {
            const formData = new FormData();
            formData.append(
                "archivo", archivo
            );
            formData.append(
                "ruta", rutaimagen
            );
            let response = await fetch(
                `${strUrlSArchivos}/upload`,
                {
                    method: "PUT",
                    headers: {
                        token: tokenfijo
                    },
                    body: formData
                }
            )
                .then(res => res.json())
                .then(res => {                  
                    return res.resultado;
                });
        respuesta = response;
        } catch (error) {
            console.log(`Ha ocurrido algo inesperado, inténtalo  más tarde `, error);
        }
    return respuesta;

};
export async function deleteFileStorage(rutaimagen, token) {

    if(rutaimagen === "" || rutaimagen === null || rutaimagen === undefined ) return;

    if(rutaimagen.substring(0,5) === 'https'){
        let lenServer = strUrlSArchivos.length;
        rutaimagen = rutaimagen.substring(lenServer+1, rutaimagen.length);
    }
    let respuesta = true;
        try {
            const formData = new FormData();
            formData.append(
                "ruta", rutaimagen//"imagenes/fotoPerfil"
            );
            let response = await fetch(
                `${strUrlSArchivos}/upload`,
                {
                    method: "DELETE",
                    headers: {
                        token: tokenfijo//props.token
                    },
                    body: formData
                }
            )
                .then(res => res.json())
                .then(res => {        
                    return res.ok;
                });
        respuesta = response;
        } catch (error) {
            console.log(`Ha ocurrido algo inesperado, inténtalo  más tarde `);
        }
    return respuesta;

};
export async function actualizaArchivo (rutaimagen, archivo, privado, id, token, remover, requiereGuardar = false, datos){
    let result;
    let resultado;

    if(requiereGuardar == true){
        if(remover == true){
            let rutaEliminar = privado == 1 ? 'private/tonline' + datos.ruta : 'public/tonline' + datos.ruta
            result = eliminaArchivo({ serverStorageID: Number(id), ruta: rutaEliminar, token });
        }

    {
        result = await postArchivo(rutaimagen, archivo, privado, tokenfijo);
        resultado = result;
    }

    if (result !== "") {
        let rutaBd =  result;
        let rs = await upsertFile({ input: { serverStorageID: id, 
            origenID: datos.origenID, 
            tabla: datos.tabla, 
            tipo: datos.tipo, 
            ruta: rutaBd, 
            clasificacion: datos.clasificacion, 
            nombreArchivo: datos.nombreArchivo,
            size: (archivo.size/1024/1024)
        } }, token);

        resultado = { error: false, resultado: rs };
    } else { resultado = { error: true, resultado: null }; }
        
    return resultado;
    }

    if(remover === true){
        result = deleteFileStorage(rutaimagen, tokenfijo);
        resultado = result;
        return resultado;
    }
    if (id > 0 )
    {
        result = await putArchivo(rutaimagen, archivo, tokenfijo);
        resultado = result ? result : ""
    }else{
        result = await postArchivo(rutaimagen, archivo, privado, tokenfijo);
        resultado = result;
    }
    return resultado;
}

export async function eliminaArchivo({ serverStorageID, ruta, token }) {
    try {
        let resultado = { error: false, resultado: null };
        if (serverStorageID == null || ruta == "")//valida que se tengan datos necesarios
        {
            return resultado;
        }
        let res = await deleteFileStorage(ruta);
        if (res == true) {
            let resBd = await deleteFile({ input: { serverStorageID } }, token);
            resultado = { error: false, resultado: resBd };
        } else {
            resultado = { error: true, resultado: null };
        }
        return resultado;
    } catch (error) {
        console.log(error);
    }
}

export const blobToBase64 = blob => {
    const reader = new FileReader();
    reader.readAsDataURL(blob);
    return new Promise(resolve => {
    reader.onloadend = () => {
        let dataUrl = reader.result;
        let base64 = dataUrl.split(',')[1];
        resolve(dataUrl);
    };
    });
};
function urltoFile(url, filename, mimeType){
        return (fetch(url)
            .then(function(res){return res.arrayBuffer();})
            .then(function(buf){return new File([buf], filename,{type:mimeType});})
        );
}






