import { FetchAPI } from "@renedelangel/helpers";

import { urlServidor } from '../../helpers';


const GET_PRODUCTOCONLICENCIAPORUSUARIO = `
    query getProductosConLicenciaPorUsuario($filtro: InputGetProductosConLicenciaPorUsuario) {
        getProductosConLicenciaPorUsuario(filtro: $filtro){
            productoLicenciaID
            usuarioID
            productoID
            codigoProducto
            pagoActivo
            periodicidad
            fechaContratacion
            fechaUltimoPagoRegistrado
            tarjetaAsociadaID
            producto
            tipoPaquete
        }
    }
`;


const GETALL_PRODUCTOCONLICENCIAPORUSUARIO = `
    query getAllProductosConLicenciaPorUsuario($filtro: InputGetProductosConLicenciaPorUsuario) {
        getAllProductosConLicenciaPorUsuario(filtro: $filtro){
            productoLicenciaID
            usuarioID
            productoID
            codigoProducto
            pagoActivo
            periodicidad
            fechaContratacion
            fechaUltimoPagoRegistrado
            tarjetaAsociadaID
            producto
            tipoPaquete
        }
    }
`;

const FILTER_PRODUCTOCONLICENCIAPORUSUARIO = `
query filterProductosConLicenciaPorUsuario($filtro: InputFilterProductosConLicenciaPorUsuario) {
    filterProductosConLicenciaPorUsuario(filtro: $filtro){
            listado{
            productoLicenciaID
            usuarioID
            productoID
            codigoProducto
            pagoActivo
            periodicidad
            fechaContratacion
            fechaUltimoPagoRegistrado
            tarjetaAsociadaID}
            registros
                paginas
    }
}
`;

export const getProductosConLicenciaPorUsuario = async (variables, token) => {
    try {
        let { getProductosConLicenciaPorUsuario } = await FetchAPI.graphql({
            token,
            variables,
            url: urlServidor,
            query: GET_PRODUCTOCONLICENCIAPORUSUARIO
        })
        return getProductosConLicenciaPorUsuario;
    }
    catch {
        { message }
    } { throw new Error(message) }
}


export const getAllProductosConLicenciaPorUsuario = async (variables, token) => {
    try {
        let { getAllProductosConLicenciaPorUsuario } = await FetchAPI.graphql({
            token,
            variables,
            url: urlServidor,
            query: GETALL_PRODUCTOCONLICENCIAPORUSUARIO
        });
        return getAllProductosConLicenciaPorUsuario;
    }
    catch {
        { message }
    } { throw new Error(message) }
}

export const tablaProductosConLicenciaPorUsuario = async (variable, token) => {
    try {

        let{
            productoLicenciaID,
            usuarioID,
            productoID,
            codigoProducto,
            pagoActivo,
            periodicidad,
            fechaContratacion,
            fechaUltimoPagoRegistrado,
            tarjetaAsociadaID,
            pagina,
            limite 
        } = variable.filtro;


        let filtro = {
            productoLicenciaID: productoLicenciaID === null ? null : Number(productoLicenciaID),
            usuarioID,
            productoID,
            codigoProducto,
            pagoActivo,
            periodicidad,
            fechaContratacion,
            fechaUltimoPagoRegistrado,
            tarjetaAsociadaID,
            pagina,
            limite 
        }

        let variables = {filtro};

        let { filterProductosConLicenciaPorUsuario } = await FetchAPI.graphql({
            token,
            variables,
            url: urlServidor,
            query: FILTER_PRODUCTOCONLICENCIAPORUSUARIO
        });
        return filterProductosConLicenciaPorUsuario;
    } catch ({ message }) { throw new Error(message); }
}