import React from 'react';

// core components
import GridContainer from "../../../components/Grid/GridContainer.js";
import GridItem from "../../../components/Grid/GridItem.js";

import Inputs from '../index';

function Fieldset({ leyenda, inputs = [] }) {

    const contenedor = (inputs && Array.isArray(inputs)) &&  <GridContainer >

        { inputs.map(i => (<Inputs key={i.id} { ...i } />)) }

    </GridContainer>;

    return(
        <fieldset>
            { leyenda && <legend style={{ fontSize: "0.9em" }}>{leyenda}</legend> }
            { contenedor }
        </fieldset>
    )

}

export default Fieldset;
