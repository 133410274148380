import StateManager from 'react-select';
import { SET_CARRITO,
    AGREGAR_PRODUCTO,
    AGREGAR_PRODUCTO_CARRITO,
    AGREGAR_PRODUCTO_ERROR,
    ELIMINAR_PRODUCTO_CARRITO,
    MODIFICAR_PRODUCTO_CARRITO  } from '../../types';

const initialState = {
    carrito: [],
    loading: false,
    error: null,
}

export default function(state = initialState, action) {

    switch (action.type) {
        case SET_CARRITO:
            return { ...state,
                carrito: action.payload };
        case AGREGAR_PRODUCTO:
            return { ...state, loading: true };
        case AGREGAR_PRODUCTO_CARRITO:
            return { ...state,
                loading: false,
                carrito: [...state.carrito, action.payload] };
        case AGREGAR_PRODUCTO_ERROR:
            return { ...state,
                loading: false,
                error: action.payload };
        case ELIMINAR_PRODUCTO_CARRITO:
            return { ...state,
                carrito: state.carrito.filter(item => item.productoID !== action.payload)};
        case MODIFICAR_PRODUCTO_CARRITO:
            return { ...state,
                carrito: state.carrito.map(producto =>
                    producto.productoID === action.payload.productoID ? producto = action.payload : producto)
                }


        default:
            return state;
    }
}
