/*eslint-disable*/
import React, { useState, useRef, useEffect }  from "react";
import { NetworkError } from "../Metodos";

// @material-ui/icons
import AddAlert from "@material-ui/icons/AddAlert";

// core components
import SnackbarContent from "../../components/Snackbar/SnackbarContent.js";

export default function Notifications(prop) {

    let { message, close = false, closeNotification = () => cerrarNotificacion(), open = true, color = "info", icon = AddAlert, cerrar } = prop;
    let props = close ? { close: true } : {};

    const [openNoti, setOpenNoti] = useState(open)

    function cerrarNotificacion() {
        setOpenNoti(false);
    }

    useEffect(() => {
        async function ftEffect() {
                setOpenNoti(open)
        } ftEffect();
    }, [open]);

    return (
        <SnackbarContent
            message={NetworkError(message)}
            icon={icon}
            color={color}
            open={openNoti}
            close={close}
            closeNotification={closeNotification}
            { ...props }
        />
    );

}
