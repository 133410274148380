import React, { useState, useRef, useEffect } from 'react';
import { ExitStatus } from 'typescript';
import {
    enCamino,
    entregado,
    enProceso,
    noEnviado,
    noPagado,
    pagado,
    pendProceso,
    noEntregado,
    reembolsoProceso,
    reembolsoFinalizado,
    pedCancelado,
    solicitudReembolso,
    reembolsoPendFinalizar

} from '../../_layout/assets/svg/exportsvg';
import './estilos.css';


function LineaSeguimiento(props) {

    let {datosHistoria = [], estatusActivo = 1, reembolso = 0, ventaID = null}= props;

    let etapas = []
    let nomEstatus = 'NO PAGADO'   

    let activos = [
        {estatus:1, imagen: noPagado,  descEstatus: 'NO PAGADO', fecha:''},
        {estatus:2, imagen: pagado,  descEstatus:'PAGADO', fecha:''},
        {estatus:3, imagen: enProceso, descEstatus: 'EN PROCESO', fecha:'' },
        {estatus:4, imagen: enCamino, descEstatus: 'ENVIADO', fecha:'' },
        {estatus:5, imagen: entregado, descEstatus: 'ENTREGADO', fecha: '' },
        {estatus:6, imagen: pedCancelado, descEstatus: 'CANCELADO', fecha: '' },
        {estatus:7, imagen: reembolsoProceso, descEstatus: 'SOL.REEMBOLSO', fecha: '' },
        {estatus:8, imagen: reembolsoProceso, descEstatus: 'PROC. REEMBOLSO', fecha: '' },
        {estatus:9, imagen: reembolsoFinalizado, descEstatus: 'FINALIZADO', fecha: '' }
    ]
  
    let datosAux = []   
    if(datosHistoria.length > 0 ){
        datosHistoria.map((dato, index, key) => {

            let etapa = activos.find( item => item.estatus === dato.estatus );
            if(etapa){
                etapa.fecha = dato.fechaFormato;
                etapa.descEstatus = dato.nomEstatus
                datosAux.push(etapa);
            }     
        })
    }  
   
    return (<>   
    <div class="contenido-linea">
        <div class="linea" id="linea"></div>
        {
          datosAux.length > 0 ? datosAux.map((item, index) => {
           let etapa = "activo" //"inactivo"            
           // if(item.estatus == estatusActivo){            
               // etapa = "activo"
            //}           
            return (                 
                <div key = {index} class="contenidoEtapa"> 
                    <div class={etapa}>
                    <img src = {item.imagen} title={`${item.descEstatus}`}></img>                 
                    </div>
                    <div class="datosFecha">
                       {item.descEstatus}<br/>
                       <span>{item.fecha}</span>
                    </div>
                   
                </div>                             
                );
           }): <div></div>
        }
    </div>
       
      
    </>);
}
export default LineaSeguimiento;
