import { FetchAPI } from "@renedelangel/helpers";

import { urlServidor } from '../../helpers';

export const TABLA_SEGUIMIENTO_PEDIDO = `
        query filtro($filtro: InputFilterSeguimientoPedido){
            filterSeguimientoPedido(filtro: $filtro){
            registros
            paginas
            listado{
                partidaID
                ventaID
                usuarioID
                usuario
                numeroGuia
                detalleVenta
                total
                estatus
                nomEstatus
                fechaCompra
                reembolsoID
            
             }
            }
        }`;


export const UPDATE_SEGUIMIENTO_PEDIDO = `
mutation updateSeguimientoPedido($input: InputUpdateSeguimientoPedido) {
    updateSeguimientoPedido(input: $input) {
        ventaID
        estatus
    }
}`;

export const GET_SEGUIMIENTO_ESTATUS = `
query getSeguimientoEstatus($ventaID:ID! ) {
    getSeguimientoEstatus(id: $ventaID) {
        ventaID
        estatus
        nomEstatus
        fecha
        fechaFormato
        usuario
        comentarios
    }
}`;

export const getSeguimientoEstatus = async (variables, token) => {
    try {
        let { getSeguimientoEstatus } = await FetchAPI.graphql({
            token,
            variables,
            url: urlServidor,
            query: GET_SEGUIMIENTO_ESTATUS
        });      
        return getSeguimientoEstatus;

    } catch({message}) { throw new Error(message); }
}

export const tablaSeguimientoPedido = async (variables, token) => {
    try {
        let { filterSeguimientoPedido } = await FetchAPI.graphql({
            token,
            variables,
            url: urlServidor,
            query: TABLA_SEGUIMIENTO_PEDIDO
        });       
        
        return filterSeguimientoPedido;
    } catch ({ message }) { throw new Error(message); }
}

export const updateSeguimientoPedido = async (input, token) => {
    try{
         let {updateSeguimientoPedido} = await FetchAPI.graphql({
             token,
             variables: {input},
             url: urlServidor,
             query: UPDATE_SEGUIMIENTO_PEDIDO
         });
         return updateSeguimientoPedido;
     } catch ({message}) {throw new Error(message);}
 };
