import React, { useState, useEffect } from "react";
import {useHistory} from 'react-router-dom';

import PropTypes from "prop-types";

// core components
import CardHeader from "../../components/Card/CardHeader.js";

// media querys
import useMediaQuerys from "../../genericComponents/MediaQuerys";


//material ui
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';

// Redux
import { useDispatch,useSelector } from 'react-redux';
//import { setEntrega } from '../../redux/actions/entregaActions';

import { getDireccion } from '../../../querys/Direcciones/metodos';

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

import styles from "../../assets/jss/material-dashboard-pro-react/views/extendedListaStyle.js";

const useStyles = makeStyles(styles);

export default function MetodoPagoFormulario  (props)  {

    const classes = useStyles();
    const { usuario, token } = useSelector(state => state.login);
    const { errorStatePedido, recibeError, datosPedido, recibeDatos, disabled} = props;
    let imagenDefault =  '/imagenes/cajaAbierta.jpg';
    let { isTabletOrMobile, isTabletOrMobileDevice } = useMediaQuerys();
    let responsive = isTabletOrMobile || isTabletOrMobileDevice;

    const [datosPago, setDatosPago] = useState(datosPedido);
    const [errorState, setErrorState] = useState(errorStatePedido);

    function handleChange(e){
       /*  e = e = /^[0-9]+$/ ? e : null; */
        let valor = e.target.value;
        let isNumber = !isNaN(Number(valor));
        actualizaState(e.target.name, false, "");
        if((valor.length) > 3) {
            return;
        }
        if((isNumber === false)){
            return;
        }
        if(valor.length < 3){
        actualizaState(e.target.name, true, "El cvv debe ser de 3 dígitos");
        }
        setDatosPago({...datosPago, [e.target.name]: valor});
    }

    function validaFechaVencimiento(Valor){
    let validRegex = /^[0-9]+$/;

        if (Valor.length>5){
            return;
        }
        actualizaState("vencimientoTarjeta", false,  "")
        if(Valor === ""){
            setDatosPago({...datosPago, ["vencimientoTarjeta"]: Valor});
            //actualizaState("venimientoTarjeta", false, "")
            return;
        }
        Valor = Valor.replace("/","");
        if(!Valor.match(validRegex)){
            return;
        }
        let TarjetaVencida = false;
        if(Valor.length>=3){
            let MM = parseInt(Valor.substring(0, 2));
            let AA = parseInt(Valor.substring( 2, Valor.length===3 ? 3 : 4));

            let AuxMes = "" + MM;
            if(MM.toString().length === 1){
                AuxMes = '0' + MM;
            }
            Valor = AuxMes + "/" + AA;
            setDatosPago({...datosPago, ["vencimientoTarjeta"]: Valor});

            if(MM>12 || MM === 0){
                actualizaState("vencimientoTarjeta", true, "El mes de la tarjeta no es válido");
                return;
            }
            let AAAA = parseInt('20' + AA);
            let today = new Date();
            let ActualYear = today.getFullYear();
            let ActualMonth = today.getMonth() + 1;

            if(AAAA < ActualYear){
                TarjetaVencida = true;
            }
            if(AAAA === ActualYear && MM < ActualMonth){
                TarjetaVencida = true;
            }
            if(TarjetaVencida){
                actualizaState("vencimientoTarjeta", true,  "La tarjeta esta vencida")
                setDatosPago({...datosPago, ["vencimientoTarjeta"]: Valor});
                return;
            }
        }
        if (Valor.length === 5) {
            setErrorState(errorState => ({
                ...errorState,
                vencimientoTarjeta: { helperText: "" , error: false }
            }));
        } else {
            actualizaState("vencimientoTarjeta", true, "La fecha vencimiento de la tarjeta, no tiene un formato válido")
        }
        setDatosPago({...datosPago, ["vencimientoTarjeta"]: Valor});

    }
    function ValidarNumeroDeTarjeta(Valor){
        let validRegex = /^[0-9]+$/;
        Valor = Valor.trim();
        Valor = Valor.replace(/\s/g,"");

        if (Valor.length>16){
            return;
        }
        if(Valor === ""){
            setDatosPago({...datosPago, ["NumTarjeta"]: Valor});
            actualizaState("NumTarjeta", false, "")
            return;
        }
        if(!Valor.match(validRegex)){
            return;
        }
        if (Valor.length === 16) {
            actualizaState("NumTarjeta", false, "")
        } else {
            actualizaState("NumTarjeta", true, "El número de tarjeta no tiene un formato válido")
        }
        let ValorAux = "";
        for (let i = 0; i < Valor.length; i++) {
            let res = (i) % 4;

            if ( res === 0 ){
                ValorAux = ValorAux + " " + Valor[i];
            }else{
                ValorAux = ValorAux + Valor[i];
            }
        }
        setDatosPago({...datosPago, ["NumTarjeta"]: ValorAux});

    }
    useEffect(()=> {
        try{
            setErrorState(errorStatePedido);
        }catch({message}){
        console.log('Error', message);
        }
    },[errorStatePedido]);

    useEffect(()=> {
        try{
            recibeDatos({NumTarjeta:datosPago.NumTarjeta, cvv: datosPago.cvv, vencimientoTarjeta:datosPago.vencimientoTarjeta});
        }catch({message}){
        console.log('Error');
        }
    },[datosPago]);

    function actualizaState(key, error , msj){
        setErrorState(errorState => ({
            ...errorState,
            [key]: { helperText: msj , error: error }
        }));
        recibeError({...errorStatePedido, [key]: { helperText: msj , error: error }})
    }

    return (

            <Grid container >
                <Grid item xs={12} lg={12}>
                    <CardHeader>
                    {
                        responsive ?  <h5>MÉTODO DE PAGO</h5> :  <h4>MÉTODO DE PAGO SEGURO</h4>
                    }
                    </CardHeader>
                </Grid>

                <Grid item xs={12} lg={12} md={12}>
                <InputLabel >Número de tarjeta crédito/débito</InputLabel>
                    <TextField  className={classes.metodopago} variant="outlined" size="small"
                    value={datosPago.NumTarjeta}
                    name="NumTarjeta"
                    error={errorState.NumTarjeta.error}
                    inputProps={{ 'aria-label': 'description' , 'placeholder':'16 digitos',    fontSize: 11}}
                    onChange={(e) => ValidarNumeroDeTarjeta(e.target.value)}
                    helperText={errorState.NumTarjeta.helperText}
                    disabled = {disabled}
                    />
                </Grid>
                <Grid item xs={12} lg={6}>
                    <InputLabel>Mes/Año de vencimiento</InputLabel>
                    <TextField  className={classes.metodopago} variant="outlined" size="small"
                    value={datosPago.vencimientoTarjeta}
                    name="vencimientoTarjeta"
                    error={errorState.vencimientoTarjeta.error}
                    inputProps={{ 'aria-label': 'description' , 'placeholder':'MM/YY'}}
                    onChange={(e) => validaFechaVencimiento(e.target.value)}
                    helperText={errorState.vencimientoTarjeta.helperText}
                    disabled = {disabled}
                    />
                </Grid>

                <Grid item xs={12} lg={6}>
                <InputLabel >Código de seguridad</InputLabel>
                    <TextField 
                    className={classes.metodopago} variant="outlined" size="small"
                    value={datosPago.cvv}
                    name="cvv"
                    error={errorState.cvv.error}
                    helperText={errorState.cvv.helperText}
                    type = "number"
                    onWheel= {(e) => e.target.blur()}
                    onChange={(e) => handleChange(e)}
                    inputProps={{ 'aria-label': 'description' , 'placeholder':'CVV'}}
                    disabled = {disabled}
                    
                    />
                </Grid>
            </Grid>
    );
}
MetodoPagoFormulario.propTypes = {
    data: PropTypes.array.isRequired,
    hideHeader: PropTypes.bool,
    filter: PropTypes.array,
    iconTable: PropTypes.node,
    title: PropTypes.string,
    showHelp: PropTypes.bool,
    responsiveTitle: PropTypes.arrayOf(PropTypes.string),
    activeResponsive: PropTypes.number,
    alineacion: PropTypes.arrayOf(PropTypes.object),
    formato: PropTypes.arrayOf(PropTypes.object),
    paginacion: PropTypes.object,
    paginaActiva: PropTypes.number,
    rowColor: PropTypes.func,
    busqueda: PropTypes.object,
    loader: PropTypes.bool,
    error: PropTypes.object,
    handleSearch: PropTypes.func,
    handleRegistros: PropTypes.func
};
