import {useHistory} from 'react-router-dom';
import PropTypes from "prop-types";
import React, { useState, useEffect, useRef } from 'react';


import IconButton from '@material-ui/core/IconButton';
import CloseRounded from '@material-ui/icons/CloseRounded';
import {redondeo} from '../../helpers/regexp.js';


import './estilos.css';

import { toFixed } from '../../helpers/regexp.js';


export default function  ProductoCompra(props){

    let {datos = [], totales={}} = props;


    return (<>   
        <div className="c_general_preview_carrito_pedido_det">
            {
              datos.map(element=>{
                let subtotal = Number(parseFloat(toFixed(element.precioVentaOnline, 2))) * Number(element.cantidad);
                subtotal = redondeo(subtotal,2);
                subtotal = subtotal.toLocaleString('es-MX', {style: 'currency', currency:"MXN" });

                let precioformato = redondeo(element.precioVentaOnline,2)
                precioformato = precioformato.toLocaleString('es-MX', {style: 'currency', currency:"MXN" });

                return (
                    <>
                    <div className="c_each_preview_carrito_pedido">
                        <div className="c_carrito_pedido_imagen">
                            <img src={element.imagenPortada} />
                        </div>
                        <div className="c_carrito_nombre_producto">
                            <h6>{element.nombre}</h6>
                        </div>
                        <div className="c_carrito_subtotal">
                            <h6>${subtotal}</h6>
                        </div>
                    </div>                 
                    </>
                );
              }) 
            }
        </div>    
        <div className="c_informacion_totales">
            <h6>Subtotal</h6> <h6>{parseFloat(totales.subtotal).toLocaleString('es-MX', {style: 'currency', currency:"MXN" })}</h6>
            <h6>Envío</h6> <h6>${totales.envio}</h6>
            <h6>Descuento</h6> <h6>${'0.00'}</h6>
        </div>
        <div className="separador"></div>
        <div className="c_total_pedido">
            <h6>Total</h6> <h6>{parseFloat(totales.total).toLocaleString('es-MX', {style: 'currency', currency:"MXN" })}</h6>
        </div>
      
                
        </>);
   
}

