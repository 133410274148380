import React, { useState, useRef } from 'react';
import { Validaciones } from '@renedelangel/helpers';
import './estilos.css';
import bannerContacto from '../../_layout/assets/img/bannerContacto.png';
import logoFooter from '../../_layout/assets/svg/logotipo_interheco1.svg';
import Input from '../../_layout/NuevosComponentes/Input';
import Boton from '../../_layout/NuevosComponentes/Boton';
import { enviarCorreoContacto } from '../../querys/Correo';
import { handleErrorInputText } from '../../_layout/helpers/handles';
import SweetAlert from '../../_layout/genericComponents/ModalConfirmacion';
import LoaderLogoSoftwone2 from '_layout/assets/svg/Animaciones/logoSoftwone2';

const { trim } = Validaciones;
function Contacto() {
    const [loader, setLoader] = useState(false);
    const [disabled, setDisabled] = useState(false);
    const cleanState = { nombre: "", correoElectronico: "", telefono: "", mensaje: "" };
    const cleanErrorState = {
        nombre: { error: false, helperText: "" },
        correoElectronico: { error: false, helperText: "" },
        telefono: { error: false, helperText: "" },
        mensaje: { error: false, helperText: "" }
    };

    const [state, setState] = useState(cleanState);
    const [errorState, setErrorState] = useState(cleanErrorState);
    const [alert, setAlert] = useState(null);

    const nombreRef = useRef(null);
    const correoElectronicoRef = useRef(null);
    const telefonoRef = useRef(null);
    const mensajeRef = useRef(null);

    function ftErrorInputText({ condicion, ref, keyError, mensajeError }) {
        return handleErrorInputText({
            cleanErrorState, condicion, ref, keyError, mensajeError,
            loader: setLoader, disabled: setDisabled, errorState: setErrorState
        });
    }

    function handleValidaciones({ nombre, correoElectronico, telefono, mensaje }) {

        let error;
        let validaciones = [{
            condicion: trim(nombre) === "",
            ref: nombreRef, keyError: "nombre",
            mensajeError: "Capture su nombre"
        }, {
            condicion: trim(correoElectronico) === "",
            ref: correoElectronicoRef, keyError: "correoElectronico",
            mensajeError: "Capture un correo electrónico válido"
        }, {
            condicion: trim(telefono) === "",
            ref: telefonoRef, keyError: "telefono",
            mensajeError: "Capture un teléfono válido"
        }, {
            condicion: trim(mensaje) === "",
            ref: mensajeRef, keyError: "mensaje",
            mensajeError: "Capturé un mesaje sobre el tema en el que tiene interes"
        }];

        validaciones.forEach(({ condicion, ref, keyError, mensajeError }) => {
            //if (error) return;
            error = ftErrorInputText({ condicion, ref, keyError, mensajeError });
        });

        if (error) return error;

    }

    async function ftEnviarInfo() {
        setLoader(true);
        let error = await handleValidaciones({ ...state });
        if (error) {
            setLoader(false);
            return error;}
        let resp = await enviarCorreoContacto({ ...state });
        setLoader(false);
        setState({...cleanState});
        setAlert({
            descripcion: 'Uno de nuestros ejecutivos lo contactará a la brevedad',
            title: "¡Gracias!",
            tipo: "success",
            msjConfirmacion: "Aceptar",
            onConfirm: () => setAlert(null)
        });
    }

    return (
        <>
            <div className="cContenedorContacto">
                <div className="cBannerContacto">
                    <img className="cImgBannerContacto" src={bannerContacto} />
                    <div className="cContenidoBanner" >
                        <img className="cLogoBannerContacto" src={logoFooter} />
                        <div className="cContenidoTextoBanner" >
                            <div className="cTextoBanner" >Trabaja más rápido,</div>
                            <div className="cTextoBanner" >seguro y preciso</div>
                        </div>
                    </div>
                </div>
                <div className="cTextoContactanos">
                    CONTÁCTANOS
                </div>
                <div className="cSeccionFormularioContacto">
                    <div className="cInstruccionesContacto">
                        Completa el siguiente formulario y uno de nuestros ejecutivos se contactará a la brevedad
                    </div>
                    <div className="cFormularioContacto">
                        <div className="cItemContacto">
                            <Input
                                value={state.nombre}
                                claseAuxInput="cInputContacto"
                                placeholder="Nombre completo"
                                onChange={({ target: { value: nombre } }) => setState(state => ({ ...state, nombre }))}
                                error={errorState.nombre.error ? errorState.nombre.helperText : ""} />
                        </div>
                        <div className="cItemContacto">
                            <Input
                                value={state.correoElectronico}
                                claseAuxInput="cInputContacto"
                                placeholder="Correo electrónico"
                                onChange={({ target: { value: correoElectronico } }) => setState(state => ({ ...state, correoElectronico }))}
                                error={errorState.correoElectronico.error ? errorState.correoElectronico.helperText : ""} />
                            <Input
                                value={state.telefono}
                                claseAuxInput="cInputContacto"
                                placeholder="Teléfono"
                                onChange={({ target: { value: telefono } }) => setState(state => ({ ...state, telefono }))}
                                error={errorState.telefono.error ? errorState.telefono.helperText : ""} />
                        </div>
                        <div className="cItemContacto">
                            <Input
                                value={state.mensaje}
                                textarea={true}
                                id="mensajeTextArea"
                                claseAuxInput="cInputContacto textarea"
                                placeholder="Mensaje"
                                onChange={({ target: { value: mensaje } }) => setState(state => ({ ...state, mensaje }))}
                                error={errorState.mensaje.error ? errorState.mensaje.helperText : ""} />
                        </div>
                        <div className="cItemContacto cBotonContacto">
                            <Boton id="btnEnviarContacto" titulo="ENVIAR" colorFondo="var(--secondaryColor)" colorletra="var(--letraSecondaryColor)" onClick={() => ftEnviarInfo()} />
                        </div>
                    </div>
                </div>
            </div>
            {
                alert && <SweetAlert
                    title={alert.title}
                    descripcion={alert.descripcion}
                    tipo={alert.tipo}
                    msjConfirmacion={alert.msjConfirmacion}
                    msjCancelacion={alert.msjCancelacion}
                    onConfirm={alert.onConfirm}
                    showConfirm={alert.showConfirm}
                    showCancel={alert.showCancel}
                    onCancel={() => setAlert(null)}
                />
            }

            {
                loader ? 
                <LoaderLogoSoftwone2/>
                :
                <></>
            }
        </>);
}

export default Contacto;