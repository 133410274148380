import { FetchAPI } from "@renedelangel/helpers";

import { urlServidor } from '../../../helpers';

import { DELETE_SLIDER, UPDATE_SLIDERESTATUS} from '../index';

export const deleteSlider = async (variables, token) => {
    try {

        let { deleteSlider } = await FetchAPI.graphql({
            token,
            variables,
            url: urlServidor,
            query: DELETE_SLIDER
        });

        return deleteSlider;

    } catch({message}) { throw new Error(message); }
};

export const updateSliderEstatus = async (variables, token) => {
    try {

        let { updateSliderEstatus } = await FetchAPI.graphql({
            token,
            variables,
            url: urlServidor,
            query: UPDATE_SLIDERESTATUS
        });

        return updateSliderEstatus;

    } catch({message}) { throw new Error(message); }
};
