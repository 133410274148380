import React from 'react'

// Generic components
import Notifications from '../../../_layout/genericComponents/Notificaciones';

import GridContainer from "../../../_layout/components/Grid/GridContainer.js";
import GridItem from "../../../_layout/components/Grid/GridItem.js";

import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';

import { success } from '../../../_layout/helpers/colores';

import successImage from '../../../_layout/assets/svg/undraw_processing_qj6a.svg';

function ActivarCuenta() {

    const grid = { xs: 12, sm: 12, md: 12, lg: 12 };

    return (<GridContainer
            direction="column"
            justify="center"
            alignItems="center"
        >
            <GridItem { ...grid }>
                <h4>¡Registro éxitoso!</h4>
            </GridItem>
            <GridItem { ...grid }>
                <Notifications
                    message={`
                        El registro se realizó correctamente, favor de revisar su correo para poder confirmar que es un correo válido.
                        El enlace solo estará disponible por media hora, si no lo encuentra en la bandeja de entrada; revisar el spam
                    `}
                    icon={CheckCircleOutlineIcon}
                    color={success}
                    open={true}
                />
            </GridItem>
            <GridItem { ...grid }>
                <img src={successImage} style={{ maxHeight: "500px" }} alt={"Imagen de una persona en espera de finalizar el proceso"} />
            </GridItem>
        </GridContainer>);

}

export default ActivarCuenta;
