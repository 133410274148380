import { SET_BUSQUEDA } from '../../types';

export default function(state = null, action) {
    switch (action.type) {
        case SET_BUSQUEDA:
            return { ...state, busqueda: action.payload };
        default:
            return state;
    }
}
