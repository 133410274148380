import { FetchAPI } from "@renedelangel/helpers";
import { NetworkError } from "_layout/genericComponents/Metodos";
import { urlServidor } from '../../helpers';

export const getAllEstatus = async (variables, token) => {

    let query = `query getAllEstatus($filtro:InputGetEstatus){
        getAllEstatus(filtro:$filtro){              
            estatusID
            estatus
            descripcion
            tipoEntrega
            reembolso
            modificable                                        
            }
        }`;
    return await ejecutar(variables, token, query);
}

export const selectEstatus  = async (variables, token) => {
    try { 
        let estatus = await getAllEstatus(variables, token);     

        if(!Array.isArray(estatus) || estatus.length === 0) throw new Error("No se encontraron resultados")

        return estatus.map(({ estatus:value, descripcion:label }) => ({ value, label }));

    } catch({message:label}) {
        return [{
            label: NetworkError(label),
            value: null,
            disabled: true
        }]
    }
}

export const ejecutar = async (variables, token, query) => {
    try {    

        let  respuesta  = await FetchAPI.graphql({
            token,
            variables,
            url: urlServidor,
            query: query
        });          
        return respuesta[Object.keys(respuesta)[0]];

    } catch({message}) { throw new Error(message); }
}
