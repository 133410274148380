import {
  primaryColor,
  grayColor,
  dangerColor,
  whiteColor,
  warningColor,
} from "../../assets/jss/material-dashboard-pro-react.js";

const carruselStyle =  {
root: {
flexGrow: 1,
marginBottom: "20px"
},
expansionPanel: {
boxShadow: "none",
"&:before": {
    display: "none !important"
}
},  
panelPortada: {
    textAlign: "center",
    background: "white",
    border: "1px solid",
    borderColor: grayColor[4],
    height:"unset"

},
portada:{
    width:"auto",    
    maxHeight:"500px",
    padding:"10px",
    maxWidth: "500px",
    "@media (min-width: 300px)": {
       height:"300px"
    },
    "@media (min-width: 992px)": {
        height:"300px"
    },
    "@media (min-width: 1200px)": {
        height:"400px"
    },

},
carrusel:{
    height: "100px",
    flexDirection: "row",
    borderRightWidth: "solid 1px",
    borderColor: "black",
    textAlign: "center",
    backgroundColor:"white",  
    justifyContent: "center",
    "@media (min-width: 300px)": {
        display: "none"
    },
    "@media (min-width: 992px)": {
        display: "none"
    },
    "@media (min-width: 1200px)": {
        display:"flex"
    },

},
imagenDetalle:{
    width: "auto",
    height: "80px",
    margin: "10px",
    minHeight: "10px",
    minWidth: "80px",
    align:"center",
    padding: "5px",
    display: "inline-block",
    border: "1px solid",
    position: "relative",
    textAlign: "center",
    borderColor: grayColor[4],
    "&:hover": {
    border: "2px solid",
    borderColor:  grayColor[1],
    cursor:"pointer"
    }
},
textoImagen:{ 
    position: "absolute",
    top: "50%",
    left: "5px",
    transform: "translate(-50%, -50%)",  
    fontWeight: "600",
},  
contenedor:{
    position: "relative",
    texAlign: "center",
    height:"auto",
    alignItems:"center",
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
},
textoEncima:{
    position: "absolute",
    top: "25px",
    left: "15px",
    fontWeight: "600",
    background: "white",
    padding: "5px",
    borderRadius: "15px"
},
centrado:{
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)"
},
imgAnterior: {
    width: "30px",
    height: "400px",
    position: "absolute",
    fontSize: "40px",
    alignItems: "center",
    textAlign: "center",
    display: "table-cell",
    verticalAlign: "middle",
    background: "rgba(255, 255, 255, 0.158)",
    color: "rgba(82, 82, 82, 0.500)",
    zIndex: "3" 
},
imgSiguiente: {
    width: "30px",
    height: "400px",
    position: "absolute",
    fontSize: "40px",
    alignItems: "center",
    textAlign: "center",
    display: "table-cell",
    verticalAlign: "middle",
    background: "rgba(255, 255, 255, 0.158)",
    color: "rgba(82, 82, 82, 0.500)",
    zIndex: "3",   
    "@media (min-width: 300px)": {
        right:"10px",
    },
    "@media (min-width: 992px)": {
        right:"10px",
    },
    "@media (min-width: 1200px)": {
        right:"calc(60% + 10px)",
    },
    
},
iconoNext:{
    height: "40px",
    position: "relative",
    top: "calc(((100% - 40px)/2))",
    cursor:"pointer"
    /*"@media (min-width: 300px)": {
        display: "flex"
    },
    "@media (min-width: 992px)": {
        display: "none"
    },
    "@media (min-width: 1200px)": {
        display:"none"
    },*/
},


};

export default carruselStyle;
