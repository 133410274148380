export const GET_DESEO = `
query getDeseo($deseo:ID!) {
  getDeseo(id:$deseo) {
    deseosID
    clienteID
    productoID
    cantidad
    tallaID
    empresaID
    grupoID
    subgrupoID
    marcaID
    tipoProductoID
    cliproID
    unidadID
    unidadID2
    almacenID
    usuariobitID
    departamentoID
    divisaID
    estatusID
    codigoProducto
    codigoProdSat
    codigoProductoAnterior
    codigoBarras1
    codigoBarras2
    codigoBarras3
    claveUnidadSat
    numeroParte
    descripcion
    descripcionCorta
    fechaAlta
    fechaModificacion
    fechaUltcosto
    presentacion
    factor
    ubicacion
    rutaImagen
    imagen
    tipoDesctoSc1
    tipoDesctoSc2
    tipoDesctoSc3
    desctoSc1
    desctoSc2
    desctoSc3
    porcIva
    porcIvaComp
    porcIeps
    porcTua
    porcRetIsr
    porcRetIva
    sinComision
    comisionVenta
    comisionCobro
    inventariable
    servicio
    paquete
    produccion
    consignacion
    exento
    controlado
    importancia
    fechaIniDesctosc1
    fechaIniDesctosc2
    fechaIniDesctosc3
    fechaFinDesctosc1
    fechaFinDesctosc2
    fechaFinDesctosc3
    tipoDesctoSv1
  	tipoDesctoSv2
    tipoDesctoSv3
    desctoSv1
    desctoSv2
    desctoSv3
    fechaIniDesctoSv1
    fechaIniDesctoSv2
    fechaIniDesctoSv3
    fechaFinDesctoSv1
    fechaFinDesctoSv2
    fechaFinDesctoSv3
    flete
    precioListaSinIva
    precioListaConIva
    costouSinIva
    costouConIva
    precioNormalSinIva
    precioNormalConIva
    costouSinIva
    costouConIva
    precioNormalSinIva
    precioNormalConIva
    precioesp1SinIva
    precioesp2SinIva
    precioesp3SinIva
    precioesp1ConIva
    precioesp2ConIva
    precioesp3ConIva
    margenVta1
    margenVta2
    margenVta3
    margenVta4
    vntaExistCero
    cuentaContab
    maximo
    minimo
    reorden
    periodoSurtimiento
    core
    preciooMargen
    cantImprimir
    divPrecioListaxFactor
    tipoCambio
    pedimento
    loteFechaCaducidad
    margenUtilidadMinimo
    margenUtilidadMaximo
    noActualizaPrecios
    clipro
    grupo
    subgrupo
    marca
    tipoProducto
    divisa
    unidadEntrada
    unidadSalida
    pedimentoObligatorio
    error


  }
}

`;

export const TABLA_DESEO = `
query filterDeseo($filtro:InputFilterDeseo!) {
  filterDeseo(filtro:$filtro){
 	paginas
    registros
    listado{
      deseosID
      clienteID
      productoID
      imagenPortada
      descripcionCorta
      descripcion
      precioNormalConIva
      error
      codigoProducto
      precioVentaOnline
    }
  }
}
`;
