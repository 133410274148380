import React, {useEffect, useState} from 'react';
import './styles.css'
import {useHistory} from 'react-router-dom';

import { redondeo } from 'helpers/regexp';
import {  Encriptar } from '@renedelangel/helpers';
import Link from '@material-ui/core/Link';
import {
    arrowLeft,
    arrowRight,
    vectorArrowR,
    vectorArrowL
} from '../../assets/svg/exportsvg'
import { toFixed } from 'helpers/regexp';
import LoaderLogoSoftwone2 from '_layout/assets/svg/Animaciones/logoSoftwone2';
const {Encrypt,Decrypt}=Encriptar;

export default function CarritoNuevo(props){
    let { 
        data,
        actualizar,
        eliminarCarrito,
        actualizarCantidad,
        modificaCantidad,
        grabarPedido,
        metodoEntrega,
        actualizaMetodo} = props;

        const history = useHistory();

        const [vistaCarrito, setVistaCarrito] = useState([]);
        const [loader, setLoader] = useState(false);
        const noTruncarDecimales = {maximumFractionDigits: 2,  minimumFractionDigits: 2,};

        useEffect(() => {
            async function constructorCarrito(){
                setLoader(true);
                let aux = [];
                if(data){
                if(data.length > 0){

                    data.map((element, index) =>{
                        

                        let parametros = {"productoID": element.productoID, "carritoID": element.carritoID};              

                        let subtotal = Number(parseFloat(toFixed(element.precioVentaOnline, 2))) * Number(element.cantidad);
                                subtotal = redondeo(subtotal,2);
                                
                                subtotal = parseFloat(subtotal).toLocaleString('es-MX', noTruncarDecimales);
                                
                                let precioformato = redondeo(element.precioVentaOnline,2)
                                precioformato = parseFloat(precioformato).toLocaleString('es-MX', noTruncarDecimales);

                        let producto = (<>
                        <div className='c_general_producto_carrito'>
                            <div className='c_img_caracteristicas_producto_carrito'>
                                <div className='c_img_producto_carrito'>
                                <img src = {element.imagenPortada}/>
                                </div>
                                <div className='c_caracteristicas_producto_carrito'>
                                    <h6>
                                        {element.nombre}
                                    </h6>
                                    <h6>
                                        #{element.codigoProducto}
                                    </h6>
                                </div>
                                <div className='c_eliminar_producto_carrito' onClick={(e) => {eliminarCarrito(parametros, e)}}>
                                    <div className='eliminar_producto_carrito'>
                                    &#215;
                                    </div>
                                </div>
                            </div>
                            <div className='c_botones_cantidad_carrito'>
                            <div            
                                            style={{display: element.licenciaUnica == 0 ? 'flex' : 'none' }}
                                            className="button_less_detalleProducto unselectable"
                                            onClick={() => {
                                                actualizarCantidad(parametros, -1);
                                            }}
                                        >
                                            -
                                        </div>
                                        <div className="indicador_cantidad_detalle_producto">
                                            <input
                                                disabled={element.licenciaUnica !== 0 ? true  : false }
                                                min={1}
                                                type="number"
                                                value={element.cantidad}
                                                onChange={e =>
                                                    modificaCantidad(e, index, element.productoID)
                                                }
                                            />
                                        </div>
                                        <div
                                            style={{display: element.licenciaUnica == 0 ? 'flex' : 'none' }}
                                            className="button_more_detalle_producto unselectable"
                                            onClick={() => {
                                                actualizarCantidad(parametros, 1);
                                            }}
                                        >
                                            +
                                        </div>{" "}
                            </div>

                            <div className='c_costo_producto'>
                                <h5>$ {subtotal}</h5>
                            </div>
                        </div>
                        </>)

                        aux.push(producto);
                    })
                }}
                setLoader(false);
                setVistaCarrito(aux);
            }
            constructorCarrito();
        }, [data])



    return( 
        vistaCarrito.length > 0 ? <>
        {
            loader ? 
            <LoaderLogoSoftwone2/> :
            <></>
        }
    <div className= 'c_general_carrito'>
            <div className='c_interno_carrito'>
                <div className='c_titulo_carrito'>
                    <h3>CARRITO DE COMPRA</h3>
                </div>
    {vistaCarrito}
    </div>
    </div>
    <div className= 'c_general_acciones_carrito'>
        <div className='c_interno_acciones_carrito'>
            <div className='c_button_regresar' onClick={() => {history.push('/productos')}}>
                <h6>
                    IR A COMPRAR
                </h6>
                {vectorArrowL}
            </div>

            <div className='c_button_continuar' onClick={() => grabarPedido()}>
                <h6>
                    CONTINUAR
                </h6>
                {vectorArrowR}
            </div>
        </div>
    </div>
    </> : <>
    <div className='c_canasta_vacia'>
    <h6>Canasta vacia</h6>
    <div className='c_canasta_vacia_button_regresar' onClick={() => {history.push('/productos')}}>
                <h6>
                    IR A COMPRAR
                </h6>
                {vectorArrowL}
            </div>
    </div>
    </>)
}