import { FetchAPI } from "@renedelangel/helpers";
import { urlServidor, strUrlSArchivos } from '../../helpers';

export const getEmpresasConfig = async (variables, token) => {

    let Query = `
    query getEmpresasConfig($filtro: InputGetEmpresasConfig){
        getEmpresasConfig(filtro:$filtro) {
            empresaID
            nombre
            nombreCorto
            rfc
            direccion
            telefono
            mision
            vision
            nosotros
            piePagina
            logoEmpresa
            imgMision
            imgVision
            imgNosotros
            correoElectronico
            paginaInicio
            facebookID
            facebookLink
            primaryColor
            letraPrimaryColor
            secondaryColor
            letraSecondaryColor
            backgroundColor
            avisoLink
            politicaLink
            imgOferta
            mostrarOferta
            fechaInicioOferta
            fechaFinOferta           
        }
    }
    `;

    let resultado = await Ejecutar({ filtro: variables }, token, Query);

    if(resultado===null){
        return {};
    }

    for (const [key, value] of Object.entries(resultado)) {
        if (value == null) {
            resultado[key] = "";
        }
    }

    resultado["logoEmpresaSinConcatenar"] = resultado["logoEmpresa"];
    let foto1 = resultado.logoEmpresa ? `${strUrlSArchivos}${'/public/tonline'}${resultado.logoEmpresa}${'?'}${Math.random()}` : "";
    resultado["logoEmpresa"] = foto1;

    resultado["imgMisionSinConcatenar"] = resultado["imgMision"];
    let foto2 = resultado.imgMision ? `${strUrlSArchivos}${'/public/tonline'}${resultado.imgMision}${'?'}${Math.random()}` : "";
    resultado["imgMision"] = foto2;

    resultado["imgVisionSinConcatenar"] = resultado["imgVision"];
    let foto3 = resultado.imgVision ? `${strUrlSArchivos}${'/public/tonline'}${resultado.imgVision}${'?'}${Math.random()}` : "";
    resultado["imgVision"] = foto3;

    resultado["imgNosotrosSinConcatenar"] = resultado["imgNosotros"];
    let foto4 = resultado.imgNosotros ? `${strUrlSArchivos}${'/public/tonline'}${resultado.imgNosotros}${'?'}${Math.random()}` : "";
    resultado["imgNosotros"] = foto4;

    resultado["avisoLinkSinConcatenar"] = resultado["avisoLink"] ? resultado["avisoLink"] : "";
    let aviso = (resultado.avisoLink && resultado.avisoLink.substring(0, 6) !== "https:") ? 
        `${strUrlSArchivos}${'/public/tonline'}${resultado.avisoLink}${'?'}${Math.random()}` : "";
    resultado["avisoLink"] = aviso

    resultado["politicaLinkSinConcatenar"] = resultado["politicaLink"] ? resultado["politicaLink"] : "";
    let politica = (resultado.politicaLink && resultado.politicaLink.substring(0, 6) !== "https:") ? 
        `${strUrlSArchivos}${'/public/tonline'}${resultado.politicaLink}${'?'}${Math.random()}` : "";    
    resultado["politicaLink"] = politica

    resultado["imgOfertaSinConcatenar"] = resultado["imgOferta"] ? resultado["imgOferta"] : "";
    let foto5 = (resultado.imgOferta && resultado.imgOferta.substring(0, 6) !== "https:") ? 
        `${strUrlSArchivos}${'/public/tonline'}${resultado.imgOferta}${'?'}${Math.random()}` : "";    
    resultado["imgOferta"] = foto5

    return resultado;
}

export const updateEmpresasConfig = async (variables, token) => {
    let Query = `
    mutation updateEmpresasConfig($input: InputUpdateEmpresasConfig!) {
        updateEmpresasConfig(input: $input)
    }
    `;

    let tResult = await Ejecutar({ input: variables }, token, Query);
    tResult = JSON.parse(tResult);
    return tResult;
};

export const Ejecutar = async (variables, token, query) => {
    try {
        let Respuesta = await FetchAPI.graphql({
            token,
            variables,
            url: urlServidor,
            query: query
        });
        return Respuesta[Object.keys(Respuesta)[0]];

    } catch (error) { throw new Error(error.message);
    }
}
