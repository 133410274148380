import {useHistory} from 'react-router-dom';
import PropTypes from "prop-types";
import React, { useState, useEffect, useRef } from 'react';

// media querys
import useMediaQuerys from "../../genericComponents/MediaQuerys";


//material ui
import  TablaLista  from "../../genericComponents/Lista/componentes/tabla";


import IconButton from '@material-ui/core/IconButton';
import CloseRounded from '@material-ui/icons/CloseRounded';
import {redondeo} from '../../../helpers/regexp.js';


import './estilos.css';
                
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

import styles from "../../assets/jss/material-dashboard-pro-react/views/extendedListaStyle.js";
import { toFixed } from '../../../helpers/regexp.js';
const useStyles = makeStyles(styles);

export default function Producto  (props)  {

    const classes = useStyles();
    let imagenDefault =  '/imagenes/cajaAbierta.jpg';
    let { isTabletOrMobile, isTabletOrMobileDevice } = useMediaQuerys();
    let responsive = isTabletOrMobile || isTabletOrMobileDevice;
    const {data, descuentos=0, cupones=0, envio=0 } = props;
    const noTruncarDecimales = {maximumFractionDigits: 20,  minimumFractionDigits: 2, style: 'currency', currency: 'MXN'};
    let totales = [
        { nombre: "Importe", valor: 0,tipo:"moneda"},
        { nombre: "Descuentos", valor: descuentos, tipo:"moneda"},
        { nombre: "Cupones", valor: cupones, tipo:"moneda"},
        { nombre: "Envio", valor: envio, tipo:"moneda"},
        { nombre: "Importe Total", valor: 0, tipo:"moneda"},
    ];

    return (
        <>           
            <div className={classes.titulosCarrito}>                        
                <div className={classes.contenedorImagen}></div>
                <div className={classes.detalleProducto}>   
                <div className={classes.description}></div>                                           
                    <div className={classes.importesProducto} >
                    <div className={classes.contenedorImportes}>  
                        <div className={classes.titulosPrecios}>Importe</div>  
                        <div className={classes.titulosPrecios}>Precio</div>
                        <div className={classes.titulosPrecios}>Cantidad</div>               
                    </div>
                    </div>
                </div>
            </div>   
            {  
                data.map((item, index) => {                     
                    let subtotal = Number(parseFloat(toFixed(item.precioVentaOnline, 2))) * Number(item.cantidad);
                    subtotal = redondeo(subtotal,2);
                    subtotal = parseFloat(subtotal).toLocaleString('es-MX', noTruncarDecimales);

                    let precioformato = redondeo(parseFloat(toFixed(item.precioVentaOnline, 2)),2)
                    precioformato = parseFloat(precioformato).toLocaleString('es-MX', noTruncarDecimales);
                  
                    let importeProductos = Number(totales[0].valor) + (Number(parseFloat(toFixed(item.precioVentaOnline, 2))) * Number(item.cantidad));

                    let importe = importeProductos + totales[1].valor + totales[2].valor + totales[3].valor;
                    totales[0].valor = importeProductos;
                    totales[4].valor = importe;                    

                return(
                    <>
                    <div className={classes.panelProducto}>
                    <div ><img className={classes.imagenProducto} src = {item.imagenPortada ? item.imagenPortada : imagenDefault}/></div>
                    <div className={classes.detalleProducto} >
                        <div className={classes.description}>
                            {item.nombre}<br></br>
                            {item.descripcion}
                        </div>
                        <div className={classes.importesProducto} >
                            <div className={classes.contenedorImportes}>     
                                <div className={classes.precios}>{subtotal}</div>                                                
                                <div className={classes.precios}>{precioformato}</div>                         
                                <div className={classes.precios}>
                                    <input  className={classes.inputComponent} id="cantidad" type="text" value={item.cantidad}/>
                                </div>     
                            </div>
                        </div>
                    </div>
                    </div>
                    <hr></hr>                
                    </>                     
                    );             
                })              
            } 
            <TablaLista           
                rows={totales}         
            />

 
            {/* <div className="cPanelPrincipalProducto"> 
                    {data.map((item, index) => {                     
                    let subtotal = Number(item.precioVentaOnline) * Number(item.cantidad);
                    subtotal = redondeo(subtotal,2);
                    subtotal = subtotal.toLocaleString('en-US', noTruncarDecimales);

                    let precioformato = redondeo(item.precioVentaOnline,2)
                    precioformato = precioformato.toLocaleString('en-US', noTruncarDecimales);
                  
                    let importeProductos = Number(totales[0].valor) + (Number(item.precioVentaOnline) * Number(item.cantidad));                
                    let importe = importeProductos + totales[1].valor + totales[2].valor + totales[3].valor;
                    totales[0].valor = importeProductos;
                    totales[4].valor = importe;  
                    return(
                                        <div className="cPanelProducto">   
                                                            <div >
                                                            <img 
                                                            className={classes.imagenProducto} 
                                                            src = {item.imagenPortada ? item.imagenPortada : imagenDefault}/>
                                                            </div> 

                                                    <div className="cImportesProducto" >                                                  
                                                          
                                                            <div className="cTotalProducto"> $ {subtotal}</div>
                                                            <div className={classes.precioProducto}>        {precioformato}</div>


                                                            <div className="cDescription">
                                                                {item.nombre}<br></br>
                                                                {item.descripcion}                                             
                                                            </div>      
                                                    </div>
    
                                        </div>   
                                        );
                                        })   
                                    }   

                                    <div className="cPanelProductoOtros"> 
                                    <div className='cProductoTotalText'>
                                        Subtotal
                                    </div>
                                    <div className="cTextComodin">
                                    </div>
                                    <div className="cProductoTotalSumaText">
                                        $250
                                    </div>
                                    <div className='cProductoTotalText'>
                                        Envio
                                    </div>
                                    <div className="cTextComodin">
                                    </div>
                                    <div className="cProductoTotalSumaText">
                                        $250
                                    </div>
                                    <div className='cProductoTotalText'>
                                        Descuento
                                    </div>
                                    <div className="cTextComodin">
                                    </div>
                                    <div className="cProductoTotalSumaText">
                                        $0
                                    </div>
                                    </div>
                                    
                                    <div className="cPanelProductoTotal"> 
                                    <div className='cProductoTotalText'>
                                        Total
                                    </div>
                                    <div className="cTextComodin">
                                    </div>
                                    <div className="cProductoTotalSumaText">
                                        $150
                                    </div>
                                    </div>

                                    <div className="cPanelProducto">  

                                    <div className="cTextMedios">
                                    </div>
                                    <div className="cTextComodin">
                                    </div>
                                    <div className="cTextMedios">
                                    </div>
    
                                    </div>   



                                    </div>      */}  
        </>
    );
}
Producto.propTypes = {
    data: PropTypes.array.isRequired,
    hideHeader: PropTypes.bool,
    filter: PropTypes.array,
    iconTable: PropTypes.node,
    title: PropTypes.string,
    showHelp: PropTypes.bool,
    responsiveTitle: PropTypes.arrayOf(PropTypes.string),
    activeResponsive: PropTypes.number,
    alineacion: PropTypes.arrayOf(PropTypes.object),
    formato: PropTypes.arrayOf(PropTypes.object),
    paginacion: PropTypes.object,
    paginaActiva: PropTypes.number,
    rowColor: PropTypes.func,
    busqueda: PropTypes.object,
    loader: PropTypes.bool,
    error: PropTypes.object,
    handleSearch: PropTypes.func,
    handleRegistros: PropTypes.func
};
