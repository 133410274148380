
export const SET_TOKEN = 'SET_TOKEN';
export const SET_USUARIO = 'GET_USUARIO';
export const SET_BUSQUEDA = 'SET_BUSQUEDA';

export const SET_CARRITO = 'SET_CARRITO';
export const AGREGAR_PRODUCTO = 'AGREGAR_PRODUCTO';
export const AGREGAR_PRODUCTO_CARRITO = 'AGREGAR_PRODUCTO_CARRITO';
export const AGREGAR_PRODUCTO_ERROR = 'AGREGAR_PRODUCTO_ERROR';
export const ELIMINAR_PRODUCTO_CARRITO = 'ELIMINAR_PRODUCTO_CARRITO';
export const MODIFICAR_PRODUCTO_CARRITO = 'MODIFICAR_PRODUCTO_CARRITO';