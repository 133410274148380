import React from "react";
import './estilos.css';
import Close from '@material-ui/icons/Close';
import Boton from '../../../_layout/NuevosComponentes/Boton';
import imagenCorreo from '../../../_layout/assets/svg/ImagenRevisaCorreo.png'


export default function AlertaImagen(props) {
  let {open = false, titulo="", cerrar,  correo } = props;
  
  if (!cerrar){
    cerrar = () => {return};
  }
 
  return (
    <div className="divPrincipal">
        <div className="contenedorAlerta">         
            <div className="imagen">
                <img src={imagenCorreo} style={{ maxHeight: "120px" }} alt={"Alerta"} />  
            </div>
            <div className="titulo">{titulo}</div>
            <div className="descripcion">
                Te hemos enviado un correo a <br></br>
                <b>{` ${correo} `}</b><br></br>
                Con instrucciones para cambiar tu contraseña
            </div>
            <div className="nota">*Si no logras encontrarlo, revisa tu bandeja de SPAM</div>
            <div className="botones">
            <Boton titulo="Cerrar" colorFondo="var(--secondaryColor)" onClick={cerrar} />
            </div>
        </div>
    </div>

  );
}



