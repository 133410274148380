import { FetchAPI } from "@renedelangel/helpers";
import { urlServidor, strUrlSArchivos } from '../../../helpers';
import {  GET_SLIDER, TABLA_SLIDER,GET_ALL_SLIDERPUBLIC } from '../index';
import noImage from '../../../_layout/assets/svg/undraw_blank_canvas_3rbb.svg';

export const getSlider = async (variables, token) => {
    try {

        let { getSlider } = await FetchAPI.graphql({
            token,
            variables,
            url: urlServidor,
            query: GET_SLIDER
        });       
       let foto = getSlider.imagen ? `${strUrlSArchivos}${'/public/tonline'}${getSlider.imagen}${'?'}${Math.random()}`  : noImage;
       getSlider["urlImagen"] = foto
       getSlider["urlFoto"] = getSlider.imagen;
     
        return getSlider;

    } catch({message}) { throw new Error(message); }
}

export const getAllSliderPublic = async (variables, token) => {
    try {
        let {getAllSliderPublic} = await FetchAPI.graphql ({
            token,
            variables,
            url: urlServidor,
            query: GET_ALL_SLIDERPUBLIC,
        });
        let foto = getAllSliderPublic[0].imagen ? `${strUrlSArchivos}${'/public/tonline'}${getAllSliderPublic[0].imagen}${'?'}${Math.random()}`  : noImage;
        getAllSliderPublic[0]["imagen"] = foto;
        getAllSliderPublic[0]["title"] = getAllSliderPublic[0].titulo;

        return getAllSliderPublic;

    } catch({message}) {throw new Error(message);}

}

export const tablaSlider = async (variables, token) => {
    try {
        let { filterSlider } = await FetchAPI.graphql({
            token,
            variables,
            url: urlServidor,
            query: TABLA_SLIDER
        });

        let listado = filterSlider.listado;
        let listado2 = listado.map(({ imagen,  ...rest }) => {
                let slider =  imagen ?  `${strUrlSArchivos}${'/public/tonline'}${imagen}` : noImage;
                return {
                    ...rest,
                    imagen: slider,
                    urlImagen: imagen
                }
            })

        filterSlider.listado = listado2;
        return filterSlider;

    } catch({message}) { throw new Error(message); }
}
