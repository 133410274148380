export const GET_SLIDER = `
query getSlider($slider:ID!)  {
    getSlider(id:$slider){
      sliderID
      imagen
      titulo
      subtitulo
      texto
      textoEnlace
      enlace
      estatus
      fechaCreacion
    }
  }
  `;

  export const GET_ALL_SLIDERPUBLIC = `
  query getAllSliderPublic($estatus:Boolean, $dominio: String!){
    getAllSliderPublic(estatus:$estatus, dominio: $dominio){
        sliderID
        imagen
        titulo
        subtitulo
        texto
        textoEnlace
        enlace
        estatus
    }
  }

`;
  export const TABLA_SLIDER = `
  query filterSlider($filtro:InputFilterSlider!) {
    filterSlider(filtro:$filtro){
      paginas
      registros
      listado{
        sliderID
        imagen
        titulo
        subtitulo
        texto
        textoEnlace
        enlace
        fechaCreacion
        estatus
      }
    }
  }
  `;
