import { combineReducers } from 'redux';
// Reducers
import login from './reducers/loginReducer';
import busqueda from './reducers/busquedaReducer';
import carrito from './reducers/carritoReducer';

export default combineReducers({
    login,
    busqueda,
    carrito
});
