
import { FetchAPI } from "@renedelangel/helpers";

import { urlServidor } from '../../../helpers';
import {  GET_DIRECCION, TABLA_DIRECCION, TABLA_DIRECCION_SUCURSAL, GET_CODIGOPOSTAL, GET_TIPODIRECCION } from '../index';

export const getDireccion = async (variables, token) => {
    try {
        let { getDireccion } = await FetchAPI.graphql({
            token,
            variables,
            url: urlServidor,
            query: GET_DIRECCION
        });

        return getDireccion;

    } catch({message}) { throw new Error(message); }
}

export const tablaDireccion = async (variables, token) => {
    try {

        let { filterDireccion}  = await FetchAPI.graphql({
            token,
            variables,
            url: urlServidor,
            query: TABLA_DIRECCION
        });
        return filterDireccion;

    } catch({message}) { throw new Error(message); }
}
export const tablaDireccionSucursal = async (variables, token) => {
    try {

        let {filterDireccionSucursal}  = await FetchAPI.graphql({
            token,
            variables,
            url: urlServidor,
            query: TABLA_DIRECCION_SUCURSAL
        });
        return filterDireccionSucursal;

    } catch({message}) { throw new Error(message); }
}

export const getCodigoPostal = async (variables, token) => {
    try {

        let { getCodigoPostal } = await FetchAPI.graphql({
            token,
            variables,
            url: urlServidor,
            query: GET_CODIGOPOSTAL
        });

        return getCodigoPostal;

    } catch({message}) { throw new Error(message); }
}

export const getTipoDireccion = async (variables, token) => {
    try {

        let { getTipoDireccion } = await FetchAPI.graphql({
            token,
            variables,
            url: urlServidor,
            query: GET_TIPODIRECCION
        });

        return getTipoDireccion;

    } catch({message}) { throw new Error(message); }
}

export const selectTipoDireccion  = async (variables, token) => {
    try {

        let tipos = await getTipoDireccion(variables, token);

        if(!Array.isArray(tipos) || tipos.length === 0) throw new Error("No se encontraron resultados");

        return tipos.map(({ tipoID:value, descripcion:label }) => ({ value, label }));

    } catch({message:label}) {
        return [{
            label,
            value: "",
            disabled: true
        }]
     }
}

export const selectDirecciones  = async (variables, token) => {
    try {

        let direcciones = await tablaDireccion(variables, token);

        if(!Array.isArray(direcciones) || direcciones.length === 0) throw new Error("No se encontraron resultados");

        return direcciones.map(({ direccionID:value, nombreDireccion:label }) => ({ value, label }));

    } catch({message:label}) {
        return [{
            label,
            value: "",
            disabled: true
        }]
     }
}
