import React, {useState, useEffect} from 'react'
import './styles.css';
import ReactDOM from 'react-dom';
/* import PopUpAlert from 'componentes/General/PopUps/PopUpAlerta'; */
import { FetchAPI } from '@renedelangel/helpers';
import { urlServidor } from 'helpers';
import {ObtenerConektaID} from '../../../querys/TarjetasXUsuario/index';
import {publicKeyBASE64, privateKeyBASE64} from '../../../helpers/conekta';

export default function NotificacionCancelarTarjeta(props){

    let {datosTarjeta, open, token, actualizar, stateActualizar, propsSetAlert,
        propsSetOpenAlert, licencias} = props;

    const [stateTarjetaPagoActivo, setStateTarjetaPagoActivo] = useState(false);

    const [disabled, setDisabled] = useState(false);

    async function cancelarTarjeta(datosTarjeta){

        try{

           let indiceTarjeta;

           setDisabled(true);
            let resultado = await FetchAPI.send({
                url: `${urlServidor}/Conekta/CancelarTarjeta`,
                params: {
                    usuarioID: datosTarjeta.usuarioID,
                    tokenConekta: datosTarjeta.tokenID,
                    tarjetaID: datosTarjeta.tarjetaID,
                    token: token,
                    indiceTarjeta
                }
            });

            if(!resultado.ok){
                propsSetAlert({
                    descripcion: "No se pudo cancelar esta tarjeta",
                    title: "Ha ocurrido un error",
                    tipo: "danger",
                    msjConfirmacion: "Aceptar",
                    onConfirm: () => propsSetAlert(null),
                    showCancel: false,
                    msjCancelacion: "Cancelar",
                    onCancel: () => propsSetAlert(null)
                });
                open(false);
                return;
            }
            propsSetAlert({
                descripcion: "Se ha eliminado de forma correcta esta tarjeta",
                title: "¡Éxito!",
                tipo: "success",
                msjConfirmacion: "Aceptar",
                onConfirm: () => propsSetAlert(null),
                showCancel: false,
                msjCancelacion: "Cancelar",
                onCancel: () => propsSetAlert(null)
            });
            actualizar(!stateActualizar)
            open(false)
            return;

        }catch({message}){

            propsSetAlert({
                descripcion: "No se pudo cancelar esta tarjeta",
                title: "Ha ocurrido un error",
                tipo: "danger",
                msjConfirmacion: "Aceptar",
                onConfirm: () => propsSetAlert(null),
                showCancel: false,
                msjCancelacion: "Cancelar",
                onCancel: () => propsSetAlert(null)
            });
        }
    }



    useEffect(() => {
        async function ftValidarTarjetaPlanActivo(){
            if(licencias.length > 0){

                licencias.map(element => {
                    if(element.tarjetaAsociadaID == datosTarjeta.tarjetaID){
                        setStateTarjetaPagoActivo(true);
                    }
                })
            }
        }
        ftValidarTarjetaPlanActivo()
    }, [])




    return ReactDOM.createPortal (<>


        <div className='contenedorGeneralNotificacionCancelarTarjeta'>
            <div className='contenedorInternoNotificacionCancelarTarjeta'>

                { stateTarjetaPagoActivo?
                    <h2>
                     Detectamos que tiene pagos activos de licencias con esta tarjeta. <br/> Si la cancela no podremos procesar los pagos recurrentes de las licencias relacionadas. <br/> ¿Desea continuar con la cancelación de la tarjeta seleccionada?
                    </h2> 
                    :
                    <h2>
                        ¿Desea cancelar la tarjeta seleccionada?
                    </h2>
                }

                <div className='agrupadorNotificacionesCancelarTarjeta'>
                    <button onClick = {() => {cancelarTarjeta(datosTarjeta)}} disabled={disabled}>
                        Si
                    </button>
                    <button onClick = {() => {open(false); setStateTarjetaPagoActivo(false)}} disabled={disabled}>
                        No
                    </button>
                </div>
            </div>
        </div>

    </>, document.getElementById('alert'))
}