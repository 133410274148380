import React from "react";
import "../AnimacionesStyles/loadersoftwone.css";

export default function LoaderLogoSoftwone2(props) {
    const logoSofwone = (
        <svg
            id="c_logo_animacion_softwone"
            viewBox="50 50 1000 1000"
            /*  width="200"
            heigth="200" */
        >
            <defs>
                <linearGradient
                    id="linear-gradient"
                    x1="362.25"
                    y1="602.71"
                    x2="600.76"
                    y2="602.71"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset="0" stop-color="#0084c6" />
                    <stop offset="0.89" stop-color="#004ec3" />
                </linearGradient>
                <linearGradient
                    id="linear-gradient-2"
                    x1="601.17"
                    y1="621.47"
                    x2="719.75"
                    y2="621.47"
                    href="#linear-gradient"
                />
                <linearGradient
                    id="linear-gradient-3"
                    x1="601.4"
                    y1="533.42"
                    x2="619.93"
                    y2="533.42"
                    href="#linear-gradient"
                />
            </defs>

            <path
                class="cls-1"
                d="M550.25,510.29c-5.64,3.05-11,5.57-16,8.73-27.33,17.3-43.93,42.05-50.07,73.66-6,31-1.18,60.25,15.69,87.05,16.27,25.84,39.89,41.72,69.41,49a110.6,110.6,0,0,0,29,3.44c1.88,0,2.53.49,2.52,2.41q-.06,38.89,0,77.78c0,2.55-1.23,2.68-3.14,2.62a106.91,106.91,0,0,1-64.53-23.55A161.36,161.36,0,0,1,519.83,779c-1.3-1.28-2.13-1.57-3.86-.78a45.84,45.84,0,0,1-63.7-29.35,72.08,72.08,0,0,1-1.75-11.18c-.15-1.28-.47-1.78-1.8-2-20.93-3-36.6-18.79-39.32-39.8a7.54,7.54,0,0,0-3.34-5.6c-23.23-17.71-38.1-40.81-42.36-69.76-6.4-43.5,8.36-78.89,43-106a6,6,0,0,0,2.59-4.75c1.47-19,17.66-36.56,36.51-39.89,4.54-.8,4.53-.8,5-6,1.22-12.51,6.68-22.94,16.45-30.72,14.92-11.88,31.4-13.61,48.86-6.11a2.7,2.7,0,0,0,3.68-.78,108.51,108.51,0,0,1,64.8-34.8c4.45-.7,9-.68,13.5-1.12,2.06-.21,2.62.57,2.61,2.56-.07,14.78,0,29.56,0,44.34,0,11,0,22,0,32.95,0,2-.56,2.71-2.56,3.1-10.39,2-16.38,9.38-16.39,19.95q0,40.1,0,80.2c0,10.7,6.63,18.37,17.25,19.94,1.28.18,1.69.66,1.65,1.81,0,.48,0,1,0,1.45v92.07c0,3.43,0,3.57-3.49,3.33-26-1.78-47.18-12-60.6-35.29-6.16-10.68-8.65-22.46-9.23-34.71-.86-17.88,2-34.91,11.86-50.2,2.86-4.43,6.7-8.24,10-12.39a5.42,5.42,0,0,0,1.31-2.91c.08-14.54.06-29.08,0-43.61A24.42,24.42,0,0,0,550.25,510.29Z"
                transform="translate(-23 -24.71)"
            />
            <path
                class="cls-2"
                d="M651.79,510.92c1,.43,1.93.76,2.79,1.16,33.92,15.89,54.76,42.25,62.3,78.83,4.9,23.77,3.73,47.35-5,70.17-10.51,27.38-30,46.46-56.3,58.76a125.82,125.82,0,0,1-45.39,11.66c-2.87.18-5.74.34-8.79.52-.07-1.18-.16-2-.16-2.75,0-11.22,0-22.44-.05-33.66,0-2.15.52-2.87,2.8-3,17.11-.57,32.67-5.56,45.84-16.86,12.41-10.65,19.77-24.33,23.05-40.17,3.65-17.67,2.74-35.1-4.12-52a65.46,65.46,0,0,0-15.82-22.95,4.48,4.48,0,0,1-1.34-2.86c-.08-15.1-.06-30.19-.05-45.29A11.54,11.54,0,0,1,651.79,510.92Z"
                transform="translate(-23 -24.71)"
            />
            <path
                class="cls-3"
                d="M601.4,473.61c10.75.45,18.41,8.29,18.45,19.2q.17,40.7,0,81.38c0,9.95-6.79,17.47-16.61,19a14,14,0,0,1-1.85,0Z"
                transform="translate(-23 -24.71)"
            />
        </svg>
    );


    const indicadoresSoftwone = (
        <svg
            id="c_indicadores_animacion"
            viewBox="0 -50 1200 1200"
            /* width="200"
            heigth="200" */
        >
            <rect
                class="fill-1"
                x="552.23"
                width="51.54"
                height="200.79"
                rx="25.77"
                style={{ "--j": 1 }}
            />
            <rect
                class="fill-5"
                x="1052.83"
                y="496.56"
                width="51.54"
                height="200.79"
                rx="25.77"
                transform="translate(458.66 1650.85) rotate(-90)"
                style={{ "--j": 5 }}
            />
            <rect
                class="fill-13"
                x="97.62"
                y="497.62"
                width="51.54"
                height="200.79"
                rx="25.77"
                transform="translate(-497.62 696.69) rotate(-90)"
                style={{ "--j": 13 }}
            />
            <rect
                class="fil-3"
                x="910.65"
                y="163.31"
                width="51.54"
                height="200.79"
                rx="25.77"
                transform="translate(1389.1 1087.61) rotate(-135)"
                style={{ "--j": 3 }}
            />
            <rect
                class="fill-11"
                x="236.09"
                y="837.86"
                width="51.54"
                height="200.79"
                rx="25.77"
                transform="translate(-239.42 1762.16) rotate(-135)"
                style={{ "--j": 11 }}
            />
            <rect
                class="fill-10"
                x="390.64"
                y="942.41"
                width="51.54"
                height="200.79"
                rx="25.77"
                transform="translate(375.76 2140.83) rotate(-157.33)"
                style={{ "--j": 10 }}
            />
            <rect
                class="fill-8"
                x="758.83"
                y="942.41"
                width="51.54"
                height="200.79"
                rx="25.77"
                transform="translate(-364.28 358.22) rotate(-22.67)"
                style={{ "--j": 8 }}
            />
            <rect
                class="fill-16"
                x="390.64"
                y="62.23"
                width="51.54"
                height="200.79"
                rx="25.77"
                transform="translate(840.33 127.49) rotate(157.33)"
                style={{ "--j": 16 }}
            />
            <rect
                class="fill-2"
                x="758.83"
                y="62.23"
                width="51.54"
                height="200.79"
                rx="25.77"
                transform="translate(100.28 -314.53) rotate(22.67)"
                style={{ "--j": 2 }}
            />
            <rect
                class="fill-15"
                x="236.09"
                y="163.31"
                width="51.54"
                height="200.79"
                rx="25.77"
                transform="translate(-132.77 237.69) rotate(-45)"
                style={{ "--j": 15 }}
            />
            <rect
                class="fill-7"
                x="910.65"
                y="837.86"
                width="51.54"
                height="200.79"
                rx="25.77"
                transform="translate(-412.18 912.24) rotate(-45)"
                style={{ "--j": 7 }}
            />
            <rect
                class="fill-6"
                x="1012.6"
                y="691.91"
                width="51.54"
                height="200.79"
                rx="25.77"
                transform="translate(-161.94 1333.2) rotate(-63.01)"
                style={{ "--j": 6 }}
            />
            <rect
                class="fill-14"
                x="134.66"
                y="687.28"
                width="51.54"
                height="200.79"
                rx="25.77"
                transform="translate(766.46 262.47) rotate(63.01)"
                style={{ "--j": 14 }}
            />
            <rect
                class="fill-3"
                x="1012.6"
                y="318.94"
                width="51.54"
                height="200.79"
                rx="25.77"
                transform="translate(917.7 -720.96) rotate(63.01)"
                style={{ "--j": 3 }}
            />
            <rect
                class="fill-14"
                x="134.66"
                y="323.58"
                width="51.54"
                height="200.79"
                rx="25.77"
                transform="translate(-313.17 349.77) rotate(-63.01)"
                style={{ "--j": 14 }}
            />
            <rect
                class="fill-9"
                x="552.23"
                y="955.21"
                width="51.54"
                height="200.79"
                rx="25.77"
                style={{ "--j": 9 }}
            />
        </svg>
    );

    return (
        <>
        <div className="c_general_animacion"> 
            <div className="c_contenedor_animacion_2">
                <div className="i_contenedor_animacion_2">
                    {indicadoresSoftwone}
                </div>
                <div className="i_contenedor_logo_animacion_2">
                    {logoSofwone}
                </div>
            </div>
            </div>
        </>
    );
}