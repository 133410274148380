import React, { useState, useEffect } from "react";
import "./styles.css";
import LoaderProcesandoPago from "../../LoaderProcesandoPago/index";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import {
    getTarjetasXUsuario,
    getAllTarjetasXUsuario,
    filterTarjetasXUsuario
} from "../../../../querys/TarjetasXUsuario";
import { tablaDireccion } from "../../../../querys/Direcciones/metodos";
import { useSelector } from "react-redux";
import { monthsShort } from "moment";
import {
    arrowLeft,
    arrowRight,
    vectorArrowR,
    vectorArrowL,
    vectorSaveIcon
} from "../../../../_layout/assets/svg/exportsvg";

import conektaHelper from '../../../../helpers/conekta';
import FetchAPI from '@renedelangel/helpers/lib/FetchAPI';
import { urlServidor } from "helpers";
import { set } from "@arskang/enumsjs";
import LoaderLogoSoftwone2 from "_layout/assets/svg/Animaciones/logoSoftwone2";
import {  getAllRegimenFiscal, getAllUsoCfdi } from "../../../../querys/CatalogosErp";
import PopUpAgregarDireccion from "../../../../_layout/NuevosComponentes/PopUpAgregarDireccion";
import { rgxCorreo, rgxPassword, rgxNombre, rgxPhoneNumber } from '../../../../helpers/regexp';
import { Validaciones } from "@renedelangel/helpers";

const { trim } = Validaciones;

export default function InformacionPago(props) {
    const { usuario, token } = useSelector(datosPedido => datosPedido.login);
    const history = useHistory();

    let {
        continuarCompra,
        setContinuarCompra,
        datos,
        pageActiva,
        paqueteria,
        datosPedido,
        recibeDatos,
        totales,
        grabarPedido,
        volver,
        recibeError,
        aplicaEnvio,
        errorState,
        propsDireccionSuc,
        propsSetAlert,
        propsSetSeleccionarTarjeta
    } = props;

    const cleanState = {
        nameCard: "",
        cardNumber: "",
        expMonth: "",
        expYear: "",
        cvv: "",
        tarjetaID: datosPedido.datosTarjeta? datosPedido.datosTarjeta.tarjetaID : ''
    };

    const cleanErrorS = {
        nameCard: { error: false, mensaje: "" },
        cardNumber: { error: false, mensaje: "" },
        expMonth: { error: false, mensaje: "" },
        expYear: { error: false, mensaje: "" },
        cvv: { error: false, mensaje: "" },
        tarjetaID: {error: false, mensaje: ''}
        
    };
    const [state, setState] = useState(cleanState);
    const [errorS, setErrorS] = useState(cleanErrorS);
    const [listaTarjetas, setListaTarjetas] = useState([]);
    const [agregarTarjeta, setAgregarTarjeta] = useState(false);
    const [disabled, setDisabled] = useState(false);
    const [actualizar, setActualizar] = useState(false);
    const [loader, setLoader] = useState(false);
    const [openAgregarDireccion, setOpenAgregarDireccion] = useState(false);

    

    useEffect( () =>{
        async function ftEffect(){
    
            if(usuario){
            let tarjetas = await getAllTarjetasXUsuario({filtro: {usuarioID: usuario.usuarioID}}, token);
    
            tarjetas.length !== 0 ? 
            (
                setListaTarjetas(tarjetas),
                setAgregarTarjeta(false)
            )
            : 
            (
                setListaTarjetas([]),
                setAgregarTarjeta(true)
            )
            }
    
        }
        ftEffect()},
        [actualizar])
    

    const [auxState, setAuxState] = useState({
        selectDireccionesFacturacion: "",
        mes: "",
        year: ""
    });

    const [direccionesFacturacion, setDireccionesFacturacion] = useState([]);
    const [listadoRegimen, setListadoRegimen] = useState([]);
    const [listadoUsoCfdi, setListadoCfdi]= useState([])

    useEffect(() => {
        async function ftEffect() {
            if(usuario){
            let direccionesFactura = await tablaDireccion(
                {
                    filtro: {
                        pagina: 1,
                        limite: 100000,
                        usuarioID: usuario.usuarioID,
                        tipoDireccion: 2
                    }
                },
                token
            );
            let regimenes = await getAllRegimenFiscal({},
                token
            );
            let listCfdi = await getAllUsoCfdi({},
                token
            );
         
            setDireccionesFacturacion(direccionesFactura.listado);
            setListadoRegimen(regimenes)
            setListadoCfdi(listCfdi)
        }
        }
        ftEffect();
    }, [actualizar]);

    function handleChange(e) {     
        const { value, name } = e.target;    
        recibeError({[name]: {helperText:"", error:false}})        
        recibeDatos({ [name]: value });       
    }
    

    async function handleChangeTarjeta(e) {
        const { name, value } = e.target;
        setState(prevState => {
            return {
                ...prevState,
                [name]: value
            };
        });

        if(name == 'tarjetaID'){
            listaTarjetas.map(element => {
                if(element.tarjetaID == value){
                    recibeDatos({datosTarjeta: element})
                    propsSetSeleccionarTarjeta(true)
                }
            })
        }
    }

    useEffect(() => {
        if (auxState.selectDireccionesFacturacion !== "") {
            direccionesFacturacion.map(element => {
                if ( element.direccionID == auxState.selectDireccionesFacturacion) {
                    recibeDatos({
                        idDireccionFac: parseInt(element.direccionID),
                        direccionFactura: element.direccionFormateada
                    });
                }
            });
        }
        recibeError({idDireccionFac: {helperText:"", error:false}})   
    }, [auxState.selectDireccionesFacturacion, actualizar]);

    


    useEffect(() => {
        async function ftFacturacionDisabled() {
            if (datosPedido.requiereFactura == false) {
                recibeDatos({
                    nombreFactura: "",
                    rfc: "",
                    idDireccionFac: null,
                    direccionFactura: "",
                    clave_regimenFiscal: ""
                });
                setAuxState({ ...auxState, selectDireccionesFacturacion: "" });
                recibeError({
                    nombreFactura: {helperText:"", error:false},
                    rfc: {helperText:"", error:false},
                    idDireccionFac: {helperText:"", error:false},
                    direccionFactura: {helperText:"", error:false},
                    clave_regimenFiscal: {helperText:"", error:false}
                })
            }
        }
        ftFacturacionDisabled();
    }, [datosPedido.requiereFactura]);

    async function conektaGenerarToken() {

        setLoader(true);

        

        if(state.nameCard == ''){
            setErrorS({...cleanErrorS,
                nameCard:{
                mensaje: 'Falta el nombre del tarjetahabiente',
                error: true}
            })
            setLoader(false);
            return;
        }

        if(!rgxNombre.test(state.nameCard) || trim(state.nameCard) === ""){
            setErrorS({...cleanErrorS,
                nameCard:{
                mensaje: 'Verifique el nombre del tarjetahabiente',
                error: true}
            })
            setLoader(false);
            return;
        }

        if(state.cardNumber == '' || state.cardNumber?.length < 15){
            setErrorS({...cleanErrorS,
                cardNumber:{
                mensaje: 'Verifique el número de la tarjeta',
                error: true}
            })
            setLoader(false);
            return;
        }

        if(state.expMonth == ''){
            setErrorS({...cleanErrorS,
                expMonth:{
                mensaje: 'Debe seleccionar un mes',
                error: true}
            })
            setLoader(false);
            return;
        }

        if(state.expYear == ''){
            setErrorS({...cleanErrorS,
                expYear:{
                mensaje: 'Debe seleccionar un año',
                error: true}
            })
            setLoader(false);
            return;
        }

        if(state.cvv == '' || state.cvv?.length < 3){

            setErrorS({
                ...cleanErrorS,
                cvv:{
                    mensaje: 'Debe registrar un cvv valido',
                    error: true
                }
            })
            setLoader(false);
            return;
        }



        conektaHelper.initConekta();
        let AuxVencimiento = [state.expMonth, state.expYear];
        conektaHelper.tokenize(state.cardNumber , state.nameCard, AuxVencimiento[0], AuxVencimiento[1], state.cvv, AgregarTarjeta, errorTokenConekta);
        ///Hasta aquí si se generó el token de conekta correctamente, se contnua en la función TokenConektaGenerado, sino se continua en el errorTokenConekta.
    }

    async function AgregarTarjeta(TokenConekta){

        setDisabled(true);

        let resultado = await FetchAPI.send({
            url: `${urlServidor}/Conekta/AgregarTarjeta`,
            
            params: {
                usuarioID: JSON.stringify(usuario.usuarioID),
                tokenConekta: JSON.stringify(TokenConekta),
                state: JSON.stringify(state),
                token: JSON.stringify(token),
                datosUsuario: JSON.stringify({
                    nombre: usuario.nombre,
                    correo: usuario.correo
                })
            }
        });

        setLoader(false);

        if (resultado.ok == true) {

            setActualizar(!actualizar);
            setState({...cleanState});
            setErrorS({...cleanErrorS});
            setAgregarTarjeta(false);
            propsSetAlert({
                descripcion: "Se ha registrado de forma correcta la tarjeta",
                title: "¡Éxito!",
                tipo: "success",
                msjConfirmacion: "Aceptar",
                onConfirm: () => {
                    propsSetAlert(null);
                },
                showCancel: false,
                msjCancelacion: "Cancelar",
                onCancel: () => setAlert(null)
            });
            setDisabled(false);
        } else {
            propsSetAlert({
                descripcion: "Ha ocurrido un error al intentar registrar la tarjeta",
                title: "¡Error!",
                tipo: "error",
                msjConfirmacion: "Aceptar",
                onConfirm: () => {
                    propsSetAlert(null);
                },
                showCancel: false,
                msjCancelacion: "Cancelar",
                onCancel: () => setAlert(null)
            });
            setDisabled(false);
        }
    }

    

    function errorTokenConekta(Token) {
        setLoader(false);

        propsSetAlert({
            descripcion: 'Revisa los datos de tu tarjeta',
            title: "¡Error!",
            tipo: "Error",
                msjConfirmacion: "Aceptar",
                onConfirm: () => {
                    propsSetAlert(null);
                },
                showCancel: false,
                msjCancelacion: "Cancelar",
                onCancel: () => setAlert(null)
        });
    };

    useEffect(() => {
        async function scrollTop(){
            window.scrollTo(0, 0);
        }
        scrollTop()
    }, [agregarTarjeta])


    function generateValidCardYear(){


        let year = new Date().getFullYear();
        let options = [
        <option value={""} disabled>
        Seleccione el año
        </option>
        ];
        for(let i = 0; i < 11; i++){
            options.push(
                <option value={`${(year + i)}`.slice(-2)}selected="selected">{`${year  + i}`}</option>
            )
        }

        let years = <select
        disabled={disabled}
        id={"year_informacion_pago"}
        value={state.expYear}
        name={"expYear"}
        onChange={e => {
            handleChangeTarjeta(e);
        }}
    >
                {options}        
    </select>

        return(years)
    }

    return (
        <>
            <div className="c_general_confirmacion">
                {aplicaEnvio && !agregarTarjeta ? (
                    <div className="c_general_informacion_envio">
                        <div className="c_titulo_informacion_envio">
                            <h6>Información de envío</h6>
                        </div>
                        <div className="c_informacion_envio">
                            <div className="i_informacion_envio">
                                <h6>Contacto</h6> <h6>{usuario?.correo}</h6>{" "}
                                {/* <h6>Cambiar</h6> */} <div></div>
                            </div>
                            <div className="i_informacion_envio">
                                <h6>Dirección</h6>{" "}
                                <h6>
                                    {datosPedido.tipoEntrega == 2
                                        ? propsDireccionSuc.listado[0]
                                              .direccionFormateada
                                        : datosPedido.direccionEntrega}
                                </h6>{" "}
                                <h6
                                    onClick={() => {
                                        setContinuarCompra(false);
                                        recibeDatos({ tipoEntrega: 1 });
                                    }}
                                >
                                    Cambiar
                                </h6>
                            </div>

                            {/* <div className="i_informacion_envio">
                                <h6>Envío</h6> <h6>{}</h6> <div></div>
                            </div> */}
                        </div>
                    </div>
                ) : (
                    <></>
                )}

                {   !agregarTarjeta ?
                    <div className="c_general_requiere_factura">
                    <div className="c_titulo_requiere_factura">
                        <h6>Facturación</h6>
                    </div>
                    <div className="c_interno_requiere_factura">
                        <div className="c_checkbox_factura">
                            <input
                                type={"radio"}
                                id={"checkBoxRequiereFactura"}
                                checked={datosPedido.requiereFactura}
                                name={"requiereFactura"}
                                value={!datosPedido.requiereFactura}
                                onClick={e => {
                                    let { value, name } = e.target;
                                    let aux = {
                                        target: {
                                            name: name,
                                            value:
                                                value == "true" ? true : false
                                        }
                                    };

                                    handleChange(aux);
                                }}
                            />
                            <label for="checkBoxRequiereFactura">
                                <h6>Requiere factura?</h6>
                            </label>
                        </div>

                        {datosPedido.requiereFactura == true ? (
                            <div className="c_general_datos_facturacion">
                                <div className="cg_df">
                                    <div className="df_nombre_razon_social">
                                        <label for="nombreFactura">
                                            <h6>Nombre o razón social*</h6>
                                        </label>
                                        <input
                                            placeholder="Nombre o razón social"
                                            name={"nombreFactura"}
                                            value={datosPedido.nombreFactura}
                                            onChange={e => {
                                                handleChange(e);
                                            }}
                                        />
                                        <label
                                            for="nombreFactura"
                                            style={{
                                                color: errorState.nombreFactura
                                                    .error
                                                    ? "red"
                                                    : "var(--secondaryColor)"
                                            }}
                                        >
                                            <h6>
                                                {
                                                    errorState.nombreFactura
                                                        .helperText
                                                }
                                            </h6>
                                        </label>
                                    </div>
                                </div>

                                <div className="cg_df">
                                    <div className="df_rfc">
                                        <label for="rfc">
                                            <h6>RFC*</h6>
                                        </label>
                                        <input
                                            placeholder="RFC"
                                            name={"rfc"}
                                            value={datosPedido.rfc}
                                            onChange={e => {
                                                handleChange(e);
                                            }}
                                        />
                                        <label
                                            for="rfc"
                                            style={{
                                                color: errorState.rfc.error
                                                    ? "red"
                                                    : "var(--secondaryColor)"
                                            }}
                                        >
                                            <h6>{errorState.rfc.helperText}</h6>
                                        </label>
                                    </div>
                                </div>

                                <div className="cg_df">
                                    <div className="cg_direccion_facturacion">
                                        <label for="selectDireccionesFacturacion">
                                            <h6>Dirección de facturación*</h6>
                                        </label>
                                        <select
                                            id="selectDireccionesFacturacion"
                                            onChange={e => {
                                                setAuxState({
                                                    ...auxState,
                                                    selectDireccionesFacturacion:
                                                        e.target.value
                                                });
                                            }}
                                        >
                                            <option
                                                value={""}
                                                disabled
                                                selected
                                            >
                                                Seleccione la dirección del
                                                domicilio
                                            </option>
                                            {direccionesFacturacion.map(
                                                element => {
                                                    return (
                                                        <option
                                                            value={
                                                                element.direccionID
                                                            }
                                                        >
                                                            {
                                                                element.nombreDireccion
                                                            }
                                                        </option>
                                                    );
                                                }
                                            )}
                                        </select>

                                        <label
                                            for="selectDireccionesFacturacion"
                                            style={{
                                                color: errorState.idDireccionFac
                                                    .error
                                                    ? "red"
                                                    : "var(--secondaryColor)"
                                            }}
                                        >
                                            <h6>
                                                {
                                                    errorState.idDireccionFac
                                                        .helperText
                                                }
                                            </h6>
                                        </label>
                                    </div>
                                    <div className='c_button_popup_agregarDireccionFact unselectable' onClick={() => {setOpenAgregarDireccion(true)}}>
                                        <h6>Agregar nueva dirección</h6>
                                        </div>
                                </div>

                                <div className="cg_df">
                                    <div className="cg_direccion_facturacion">
                                        <label for="selectRegimenFiscal">
                                            <h6>Regimen fiscal*</h6>
                                        </label>
                                        <select id="selectRegimenFiscal" name="regimenFiscal"  onChange={e => { handleChange(e) }}
                                        >
                                            <option value={""} disabled selected> Seleccione el regimen fiscal</option>
                                            {listadoRegimen.map(element => {
                                                let label = `${element.clave} - ${element.descripcion}`
                                                    return (
                                                        <option value={element.clave}>
                                                            {label}
                                                        </option>
                                                    );
                                                }
                                            )}
                                        </select>

                                        <label
                                            for="selectRegimenFiscal"
                                            style={{
                                                color: errorState.regimenFiscal.error ? "red": "var(--secondaryColor)"
                                            }}
                                        >
                                            <h6> { errorState.regimenFiscal.helperText }
                                            </h6>
                                        </label>
                                    </div>
                                </div>

                                <div className="cg_df">
                                    <div className="cg_direccion_facturacion">
                                        <label for="selectUsoCFDI">
                                            <h6>Uso de CFDI*</h6>
                                        </label>
                                        <select id="selectUsoCFDI" name="usoCFDI" onChange={e => { handleChange(e) }} >
                                            <option value={""} disabled selected> Seleccione el regimen fiscal</option>
                                            {listadoUsoCfdi.map(element => {
                                                let label = `${element.claveSAT} - ${element.descripcion}`
                                                    return (
                                                        <option value={element.claveSAT}>
                                                            {label}
                                                        </option>
                                                    );
                                                }
                                            )}
                                        </select>

                                        <label
                                            for="selectUsoCFDI"
                                            style={{
                                                color: errorState.usoCFDI.error ? "red": "var(--secondaryColor)"
                                            }}
                                        >
                                            <h6> { errorState.usoCFDI.helperText }
                                            </h6>
                                        </label>
                                    </div>
                                </div>
                            </div>
                        ) : (
                            <></>
                        )}
                    </div>
                </div>
                :
                <></>
                }




                {  agregarTarjeta == true ?
                    <>
                    <form className="c_informacion_pago">
                    <div className="i_titulo_informacion_pago">
                        <h6>Agregar nueva tarjeta</h6>
                    </div>
                    <div className="c_informacion_tarjetahabiente">
                        <label for={"tarjetahabiente"}>
                            <h6>Nombre de Tarjetahabiente</h6>
                        </label>
                        <input
                            disabled={disabled}
                            id={"tarjetahabiente"}
                            type={"text"}
                            placeholder={"Nombre de tarjetahabiente"}
                            value={state.nameCard}
                            name={"nameCard"}
                            onChange={e => {
                                handleChangeTarjeta(e);
                            }}
                        />
                        <label
                            for="tarjetahabiente"
                            style={{
                                color: errorS.nameCard.error
                                    ? "red"
                                    : "var(--secondaryColor)"
                            }}
                        >
                            <h6>{errorS.nameCard.mensaje}</h6>
                        </label>
                    </div>
                    <div className="c_informacion_pago_tarjeta">
                        <label for={"numeroTarjeta"}>
                            <h6>Número de tarjeta</h6>
                        </label>
                        <input
                            disabled={disabled}
                            id={"numeroTarjeta"}
                            type={"text"}
                            placeholder={"Número de tarjeta"}
                            value={state.cardNumber}
                            name={"cardNumber"}
                            onChange={e => handleChangeTarjeta(e)}
                            inputMode="numeric"
                            pattern="[0-9\s]{13,19}"
                            autoComplete="cc-number"
                            maxLength="19"
                        />

                        <label
                            for="numeroTarjeta"
                            style={{
                                color: errorS.cardNumber.error
                                    ? "red"
                                    : "var(--secondaryColor)"
                            }}
                        >
                            <h6>{errorS.cardNumber.mensaje}</h6>
                        </label>
                    </div>
                    <div className="c_informacion_pago_mes_year_cvv">
                        <div className="c_informacion_pago_mes">
                            <label for={"mes_informacion_pago"}>
                                <h6>Seleccione el mes</h6>
                            </label>
                            <select
                                disabled={disabled}
                                id="mes_informacion_pago"
                                value={state.expMonth}
                                name={"expMonth"}
                                onChange={e => {
                                    handleChangeTarjeta(e);
                                }}
                            >
                                <option value={""} disabled>
                                    {" "}
                                    Seleccione el mes
                                </option>
                                <option value={"01"} selected="selected">
                                    01
                                </option>
                                <option value={"02"}>02</option>
                                <option value={"03"}>03</option>
                                <option value={"04"}>04</option>
                                <option value={"05"}>05</option>
                                <option value={"06"}>06</option>
                                <option value={"07"}>07</option>
                                <option value={"08"}>08</option>
                                <option value={"09"}>09</option>
                                <option value={"10"}>10</option>
                                <option value={"11"}>11</option>
                                <option value={"12"}>12</option>
                            </select>
                            <label
                                for=""
                                style={{
                                    color: errorS.expMonth.error
                                        ? "red"
                                        : "var(--secondaryColor)"
                                }}
                            >
                                <h6>
                                    {errorS.expMonth.mensaje}
                                </h6>
                            </label>
                        </div>

                        <div className="c_informacion_pago_year">
                            <label for={"year_informacion_pago"}>
                                <h6>Seleccione el año</h6>
                            </label>
                            {generateValidCardYear()}
                            <label
                                for=""
                                style={{
                                    color: errorS.expYear.error
                                        ? "red"
                                        : "var(--secondaryColor)"
                                }}
                            >
                                <h6>
                                    {errorS.expYear.mensaje}
                                </h6>
                            </label>
                        </div>

                        <div className="c_informacion_pago_cvv">
                            <label for={"cvv_informacion_pago"}>
                                <h6>Número CVV</h6>
                            </label>
                            <input
                                disabled={disabled}
                                id={"cvv_informacion_pago"}
                                type={"text"}
                                placeholder="Ingrese el CVV"
                                value={state.cvv}
                                name={"cvv"}
                                onChange={e => {
                                    handleChangeTarjeta(e);
                                }}
                                maxLength={4}
                            />
                            <label
                                for=""
                                style={{
                                    color: errorS.cvv.error
                                        ? "red"
                                        : "var(--secondaryColor)"
                                }}
                            >
                                <h6>{errorS.cvv.mensaje}</h6>
                            </label>
                        </div>
                    </div>
                    </form>
                    <div className='c_acciones_agregar_informacion_pago'>

                        <div className='button_regresar_informacion_pago' 
                        onClick={() =>{
                            listaTarjetas < 1 ? 
                            setContinuarCompra(false) :

                            setAgregarTarjeta(false)
                            setState({...cleanState})
                            setErrorS({...cleanErrorS})
                        }}
                        >
                            <h6>
                                Regresar
                            </h6>
                            {vectorArrowL}
                        </div>
                            <div className='button_agregar_tarjeta_informacion_pago' onClick= { () => {conektaGenerarToken()}}>
                                <h6>
                                    Guardar tarjeta
                                </h6>
                                {vectorSaveIcon}
                                </div>
                    </div>
                    </>
                    :
                    <div className = "c_seleccionar_tarjeta">
                        <div className='c_titulo_seleccionar_tarjeta'>
                            <h6>Método de pago</h6>
                            </div>
                        <div className='i_seleccionar_tarjeta'>

                            <div className='c_agrupador_tarjeta'>
                            <label for = {'tarjetaSelect'}>
                                <h6>Tarjetas guardadas</h6>
                            </label>
                                <select
                                 id="tarjetaSelect"
                                 value={state.tarjetaID}
                                 name={"tarjetaID"}
                                 onChange={e => {
                                    handleChangeTarjeta(e)
                                    }}>
                                <option
                                                value={""}
                                                disabled
                                                selected
                                            >
                                                Seleccione la tarjeta para realizar el pago
                                            </option>
                                    {listaTarjetas.map(element =>{
                                        return(<option
                                            value={
                                                element.tarjetaID
                                            }
                                        >
                                            {
                                                `****${element.referencia}`
                                            }
                                        </option>)
                                    })}
                                </select>

                                <div className='c_text_seleccionar_tarjeta'>
                                    <h6> 
                                        ¿No encuentras la tarjeta que quieres usar?
                                        <br/>
                                        <b onClick={() => 
                                            {setAgregarTarjeta(true)
                                            propsSetSeleccionarTarjeta(false)
                                            }}>
                                            Agregar una tarjeta
                                        </b>
                                    </h6>
                                    
                                    </div>
                            </div>
                            </div>
                        </div>
                }
                <div className="c_acciones_informacion_pago" style={{display: !agregarTarjeta ? 'flex' : 'none'}}>
                    <div
                        className="button_regresar_informacion_pago"
                        onClick={() => {
                            aplicaEnvio
                                ? (setContinuarCompra(false))
                                : (history.push("/carrito"));
                                
                        }}
                    >
                        <h6>REGRESAR</h6>
                        {vectorArrowL}
                    </div>
                </div>
            </div>
            {
                <PopUpAgregarDireccion
                openAgregarDireccion = {openAgregarDireccion}
                setOpenAgregarDireccion = {setOpenAgregarDireccion}
                usuario = {usuario}
                token = {token}
                actualizar = {actualizar}
                setActualizar = {setActualizar}
                propsSetAlert = {propsSetAlert}
                />
            }
            {
                loader ? 
                <LoaderLogoSoftwone2/> : 
                <></>
            }
        </>
    );
}
