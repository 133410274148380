
export const UPDATE_ESTATUS = `
    mutation updateEstatus($estatus: Boolean!) {
        updateEstatus(estatus: $estatus)
    }
`;

export const UPDATE_PASSWORD = `
    mutation updatePassword($password: String!) {
        updatePassword(password: $password)
    }
`;
