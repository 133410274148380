import React, { useEffect, useState } from "react";
import styles from './estilo.js';
import { makeStyles } from '@material-ui/core/styles';
import GridContainer from "../Grid/GridContainer";
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';

const gridContainer = { direction: "row", justify: "center", alignItems: "center" };

const useStyles = makeStyles(styles);


function CarruselDetalle({ transitionTime = 10000, imagenes = [], portada }) {

    const classes = useStyles();
    const [principal, setPrincipal] = useState({ imagen: null, leyenda: "0/0", orden: 0 });
    const [touchStart, setTouchStart] = useState(0);
    const [touchEnd, setTouchEnd] = useState(0);

    function CambiarImagenDetalle(nImagen) {

        let index = principal.orden;
        index = index + nImagen;
        let num = imagenes ? imagenes.length - 1 : 0;

        if (nImagen == 1 && principal.orden == num) {
            index = 0;
        }
        if (nImagen == -1 && principal.orden == 0) {
            index = num;
        }
        setPrincipal({ imagen: imagenes[index].imagen, leyenda: imagenes[index].leyenda, orden: imagenes[index].orden });
    }

    function handleTouchStart(e) {
        setTouchStart(e.targetTouches[0].clientX);
    }
    function handleTouchMove(e) {
        setTouchEnd(e.targetTouches[0].clientX);
    }

    function handleTouchEnd() {
        if (touchStart - touchEnd > 60) {
            CambiarImagenDetalle(1)
        }
        if (touchStart - touchEnd < -60) {
            CambiarImagenDetalle(-1)
        }
    }
    function AgregarSwipeDetalle() {
        let container = document.getElementById("contenedorImagen");
        if (container);
        container.addEventListener("touchstart", handleTouchStart, false);
        container.addEventListener("touchmove", handleTouchMove, false);
    }

    function ftEffect() {
        async function effect() {
            try {
                setPrincipal({ imagen: portada.imagen, leyenda: portada.leyenda, orden: 0 });
                AgregarSwipeDetalle();
            } catch ({ message }) {
                console.error('Error en  el efect principal', message);
            }
        } effect();
    } useEffect(ftEffect, [portada]);

    return (<>

        <div key="panelPortada" className={classes.panelPortada} >

            <div className={classes.imgAnterior} onClick={() => CambiarImagenDetalle(-1)}>
                <div className={classes.iconoNext}><ArrowBackIosIcon style={{ fontSize: 40 }}></ArrowBackIosIcon></div>
            </div>
            <div className={classes.imgSiguiente} onClick={() => CambiarImagenDetalle(1)}>
                <div className={classes.iconoNext}><ArrowForwardIosIcon style={{ fontSize: 40 }}></ArrowForwardIosIcon></div>
            </div>

            <div onTouchEnd={() => handleTouchEnd()} key="contenedorImagen" id="contenedorImagen" className={classes.contenedor}>
                <img className={classes.portada} align="center" src={principal.imagen}></img>

                <div key="leyenda" className={classes.textoEncima}>{principal.leyenda}</div>
            </div>
        </div>
        <div className={classes.carrusel} align="center">
            {
                imagenes && imagenes.map(({ imagen, leyenda }, key) => (

                    <img key={`imagen${key}`} className={classes.imagenDetalle} src={imagen} onClick={() => setPrincipal({ imagen: imagen, leyenda: leyenda, orden: key })} />
                ))
            }
        </div>

    </>)
}

export default CarruselDetalle;
