export const ADD_DIRECCION = `
mutation addDireccion($input: InputAddDireccion! )  {
    addDireccion(input: $input) {
      direccionID
      usuarioID
      nombreDireccion
      nombreResponsable
      pais
      codigoPostalID
      estado
      municipio
      ciudad
      zona
      tipoAsentamiento
      asentamiento
      calle
      numeroExterior
      numeroInterior
      edificio
      entreCalle
      yCalle
      celular
      telefonoParticular
      telefonoOficina
      tipoDireccion
    }
  }

`;

export const UPDATE_DIRECCION = `
mutation updateDireccion($input:InputUpdateDireccion!) {
    updateDireccion(input:$input){
      direccionID
      usuarioID
      nombreDireccion
      nombreResponsable
      pais
      codigoPostalID
      estado
      municipio
      ciudad
      zona
      tipoAsentamiento
      asentamiento
      calle
      numeroExterior
      numeroInterior
      edificio
      entreCalle
      yCalle
      celular
      telefonoParticular
      telefonoOficina
      tipoDireccion

    }
  }

`;

export const DELETE_DIRECCION = `
mutation deleteDirecciones($direccionID: ID!) {
    deleteDirecciones(id:$direccionID)
  }
`;


export const UPDATE_CAMBIOTIPODIRECCION = `
mutation updateCambioTipoDireccion($input:InputUpdateCambioTipoDireccion!) {
	updateCambioTipoDireccion(input:$input) {
    descripcion
    tipoID
  }
}
`;
