import buttonGroupStyle from "../buttonGroupStyle.js";
import customCheckboxRadioSwitch from "../customCheckboxRadioSwitch.js";
import {
  cardTitle,
  grayColor,
  dangerColor,
  facebookColor,
  whiteColor,
  infoColor,
  warningColor,
  successColor

} from "../../material-dashboard-pro-react.js";

const extendedListaStyle = {
  ...customCheckboxRadioSwitch,
  ...buttonGroupStyle,
  right: {
    textAlign: "right"
  },
  center: {
    textAlign: "center"
  },
 

inputText: {
    color: 'rgba(0,0,0,0.87)',
    fontSize: '16px',
    lineHeight: '28px',
    textAlign: 'rigth',
},

 
   form: {
    width: '250px',
    maxWidth: 360,
    textAlign: "left",
    fontWeight: "200",
    fontSize: "1em"
  },
   link: {
    width: '250px',
    maxWidth: 360,
    textAlign: "left",
    marginLeft: "5px",
    fontWeight: "400",
    fontSize: "1em"
  },
   mensaje: {
    width: '100px',
    maxWidth: 100,
    textAlign: "rigth",
    marginLeft: "250px",
    fontWeight: "400",
    fontSize: "1em"
  },

 
  cantidad: {
    width: '80px',
    maxWidth: 80,
    textAlign: "right",
    padding: "0px",
    heigth:30,
  },

  actionButton: {
  //  color:dangerColor[0],
    width: "80px",
    maxHeight: 35,
    textAlign:"center",
    fontSize: "10px"
  },
  icon: {
    verticalAlign: "middle",
    width: "135px",
    height: "135px",
    top: "-1px",
    position: "relative"
  },
  imgContainer: {
    width: "250px",
    maxHeight: "160px",
    overflow: "hidden",
    display: "block"
  },
  img: {
    width: "100%",
    height: "auto",
    verticalAlign: "middle",
    border: "0"
  },
  tdName: {
    minWidth: "200px",
    fontWeight: "400",
    fontSize: "1.5em"
  },
  tdNameAnchor: {
    color: grayColor[2]
  },
  tdNameSmall: {
    color: grayColor[0],
    fontSize: "0.75em",
    fontWeight: "300"
  },
  tdNumber: {
    textAlign: "right",
    minWidth: "145px",
    fontWeight: "300",
    fontSize: "1.3em !important"
  },
  tdNumberSmall: {
    marginRight: "3px"
  },
  tdNumberAndButtonGroup: {
    lineHeight: "1 !important"
  },
  positionAbsolute: {
    position: "absolute",
    right: "0",
    top: "0"
  },
  customFont: {
    fontSize: "16px !important"
  },
  actionButtonRound: {
    width: "auto",
    height: "auto",
    minWidth: "auto"
  },
  cardIconTitle: {
    ...cardTitle,
    marginTop: "15px",
    marginBottom: "0px",
    fontSize: "1.3em !important" 
  },
  panelPaper: {
    display: 'flex',
    flexWrap: 'wrap',
    marginTop: "15px",
    marginBottom: "0px",
    width: "100%",
    height: "auto",
  },
  panelProducto: {
    display: 'flex',
    flexWrap: 'wrap',    
    marginBottom: "0px",
    width: "100%",
    height: "auto",
    padding: "5px",
  },
  contenedorImagen:{
    width:'80px',
    maxWidth: '80px',  
  },
  imagenProducto:{
    width:'80px',
    maxWidth: '80px',  
    height: '80px',
    maxWidth: '80px'
  },
  detalleProducto:{
    width: 'calc(100% - 80px)',
    height: 'auto',
    displayFlex: "row"
  },
  description: {
    textAlign: "left",
    marginLeft: "5px",
    fontSize: "12px",
    paddingLeft: '10px',
    fontWeight: "400",  
    width: 'calc(100% - 5px)',
  },
  precios: {
    width: '25%',
    textAlign: "right",
    padding: "0px",
    float: "right",
    verticalAlign: "center",
    height: "42px",
    lineHeight: "42px",
    "@media (max-width: 500px)": {
      width: '32%',
      marginLeft:"0%",
    },
  },
  titulosPrecios: {
    width: '25%',
    textAlign: "right",
    padding: "0px",
    float: "right",
    verticalAlign: "center",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    fontWeight: "600",
    "@media (max-width: 500px)": {
      width: '32%',
      marginLeft:"0%",
    },
  },
  precioProducto: {
    width: '25%',
    textAlign: "right",
    padding: "0px",
    float: "right",
    height: "42px",
    lineHeight: "42px",
    verticalAlign: "center",
    "@media (max-width: 500px)": {
      display: "none"
    },
  },
  importesProducto:{  
    width: 'calc(100% - 20%)',
    marginLeft: "20%",
    height: 'auto',
    display: "flex",
    flexDirection: "row",  
    alignItems: "right",
    "@media (max-width: 400px)": {
      width: '100%',
      marginLeft:"0%",
    },     
  },
  inputComponent: {
    width: "40%",
    minWidth: "50px",
    maxWidth: "60px",
    padding: "5px",
    backgroundColor: '#FFFFFF',
    fontWeight: "400",
    border: "solid 1px",
    textAlign: "right",
  },
  contenedorImportes:{  
    width: "100%",
    float: "right",
    verticalAlign:"center",
  },  
  titulosCarrito:{  
    display: 'flex',   
    marginBottom: "0px",
    width: "100%",
    padding: "15px",
    borderBottom: "solid 1px",
    borderColor: grayColor[0],
    "@media (max-width: 500px)": {
      display:"none"
     },
    "@media (min-width: 501px)": {
      display:"flex"
     },
  },
  
  panelCarrito:{
    width: "100%",
    backgroundColor: "white"
  },
  panelfinal: {
    display: "block"
  },
  customCardContentClass: {
    paddingLeft: "100",
    paddingRight: "100",
    textAlign: "rigth",
    position: "relative",
    display: "block"
  },
  botonAccion: {
    paddingLeft: "15px",
    paddingRight: "15px",
    textAlign: "center",
    marginLeft: "5px",
    marginRight: "10px",
    marginTop: "5px",
    height: "120px",
    width: "120px"
  },
  cantidadCarrito: {
    textAlign: "right",
    height: "40px",
    width: "60px",
    padding: "5px",
    border:  "solid 0.1em",
    borderColor: grayColor[0],
    textColor:"black",
    fontWeight: "400"

  },
  botonReducir: {
    height: "40px",
    border: "solid 1px",
    borderColor:  grayColor[0],
    //borderRadius: "0%",
    width: "40px",
    //borderBottomLeftRadius: "10px 5px",
    //borderTopLeftRadius: "10px 5px",
    padding: "0px",
    minWidth: "unset",
    "@media (max-width: 750px)": {
        display: "none"
     },
     "@media (min-width: 1200px)": {
        display: "unset"
   },
    
  },
  botonIncremento: {
    height: "40px",
    border: "1px solid",
    borderColor:  grayColor[0],
    //borderRadius: "0%",
    width: "40px",
    //borderBottomRightRadius: "10px 5px",
    //borderTopRightRadius: "10px 5px",
    minWidth: "unset",
    "@media (max-width: 750px)": {
      display: "none"
   },
   "@media (min-width: 1200px)": {
    display: "unset"
 },
  },
  botonPagar: {
    height: "40px",
    border: "1px solid",
    borderRadius: "0%",
    width: "100%",
    minWidth: "unset",
    borderRadius: "5px",
    color: whiteColor,
    backgroundColor:facebookColor,
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginLeft:"5px",
    letterSpacing: "5px",
    "&:hover": {
      backgroundColor:   successColor[0],
    }
  },
  botonVolver: {
    minWidth: "unset",
    borderRadius: "5px",
    backgroundColor:grayColor[4],
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    width: "100%",
  },
  barraBotones:{
    display: "flex",
    width:"100%",
  },
  barraBotonesAux:{
    display: "flex",
    width:"50%",
    marginLeft: "50%",
    marginTop: "10px",
    "@media (min-width: 300px)": {
    width:"100%",
    marginLeft:"0%",
    },
    "@media (min-width: 992px)": {
    width:"40%",
    marginLeft: "60%",
    },
    "@media (min-width: 1200px)": {
     width:"25%",
     marginLeft: "75%"
    },
  },
  tablaTotales: {
    marginLeft: "50%",
    position: "absolute",
    right: "0",
    top: "0",
  },

  metodopago:
  {
    width: "100%",
    fontSize: "0.5em",
    padding: "10px",
    display: 'flex',
    flexWrap: 'wrap',
    "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
     "-webkit-appearance": "none"},

  },
  gridPedido: {
    padding: "0 5px !important",
    flexDirection: "column",
     "@media (min-width: 768px)": {
     flexDirection: "column",
    },
    "@media (min-width: 992px)": {
     flexDirection: "column",
    },
    "@media (min-width: 1200px)": {
     flexDirection: "row",
     display: "flex"
    },
  },
   fabProgress: {
    color: "white",
    display: 'block',
    top: '50%',
    left: '50%',
    height: '80%',

  },
  backdrop: {
    zIndex: "10",
    color: '#fff',
  },
  panelPrincipal:{
    backgroundColor:"white",
    width: "100%",
    padding: "10px",
    height: "100%"
  }





};

export default extendedListaStyle;
