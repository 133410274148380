import React, { useState, useEffect } from "react";
import "./styles.css";
import AgregarTarjetas from "./PopUpAgregarTarjeta";
import NotificacionCancelarTarjeta from "./NotificacionCancelarTarjeta/index";
import closeSVG from "./iconoPopUpLogin/close.svg";
/* import PopUpAlert from "componentes/General/PopUps/PopUpAlerta"; */
import { vectorBin } from "../../_layout/assets/svg/vectores";
import { getAllTarjetasXUsuario } from "../../querys/TarjetasXUsuario";
import { useDispatch, useSelector } from "react-redux";
import { getAllProductosConLicenciaPorUsuario } from "querys/ProductosConLicenciaPorUsuario";
import SweetAlert from '../../_layout/genericComponents/ModalConfirmacion';
import { info, danger, success, primary, warning } from '../../_layout/helpers/colores';

export default function Wallet() {
    const { usuario, token } = useSelector(state => state.login);
    const [openAddCard, setOpenAddCard] = useState(false);
    const [openCancelarTarjeta, setOpenCancelarTarjeta] = useState(false);
    const [cardID, setCardID] = useState();
    const [tarjetas, setTarjetas] = useState([]);
    const [actualizar, setActualizar] = useState(false);
    const [licencias, setLicencias] = useState([]);

    const [openAlert, setOpenAlert] = useState(false);
    const [alert, setAlert] = useState(null);
    const delay = ms => new Promise(resolve => setTimeout(resolve, ms));

    useEffect(() => {
        async function ftGetTarjetas() {
            let auxTarjetas = await getAllTarjetasXUsuario(
                { filtro: { usuarioID: usuario.usuarioID } },
                token
            );

            let auxLicencias = await getAllProductosConLicenciaPorUsuario(
                { filtro: { usuarioID: usuario.usuarioID, pagoActivo: 1 } },
                token
            );

            if (auxTarjetas.length > 0) {
                setTarjetas(auxTarjetas);
            }else{
                setTarjetas([]);
            }

            if (auxLicencias.length > 0) {
                setLicencias(auxLicencias);
            }else{
                setLicencias([]);
            }
        }
        ftGetTarjetas();
    }, [actualizar]);

    function generadorViewTarjetas() {
        let aux = [];
        if (tarjetas.length > 0) {
            return (
                <>
                    <div className="agrupadorListaTarjetas">
                        {tarjetas.map(element => {
                            return (
                                <>
                                    <div className="tarjetaIndividual">
                                        <div className="numeroTarjetaIndividual">
                                            <h3>{element.derechohabiente}</h3>
                                            <h3>
                                                {element.tipoTarjeta == "credit"
                                                    ? "crédito"
                                                    : "debito"}
                                            </h3>

                                            <h3>{element.marca}</h3>

                                            <h3>
                                                {" "}
                                                **** **** ****{" "}
                                                {element.referencia}
                                            </h3>
                                            <h3>{element.expira}</h3>
                                        </div>
                                        <div
                                            className="eliminarTarjetaIndividual"
                                            onClick={() => {
                                                cancelarTarjeta(element);
                                            }}
                                        >
                                            {vectorBin}
                                        </div>
                                    </div>
                                </>
                            );
                        })}
                    </div>
                </>
            );
        }
    }

    useEffect(() => {
        async function ftCerrarAlertas() {
            await delay(3000);
            setOpenAlert(false);
        }
        ftCerrarAlertas();
    }, [openAlert]);

    function cancelarTarjeta(id) {
        setCardID(id);
        setOpenCancelarTarjeta(true);
    }

    return (
        <>
            <div className="contenedorGeneralTarjetas">
                <div
                    className="contenedorInternoTarjetas"
                    onClick={e => {
                        e.stopPropagation();
                    }}
                >
                    <div className="encabezadoContenedorTarjetas">
                        <h3>TARJETAS GUARDADAS </h3>
                    </div>
                    {
                        tarjetas.length > 0 ?
                        <>{generadorViewTarjetas()}</> : <></>
                    }
                    <div
                        className="cButtonAddNewCard"
                        onClick={() => {
                            setOpenAddCard(true);
                        }}
                    >
                        Agregar tarjeta
                    </div>
                </div>
            </div>

            {openAddCard ? (
                <AgregarTarjetas
                    open={setOpenAddCard}
                    actualizar={setActualizar}
                    stateActualizar={actualizar}
                    propsSetAlert={setAlert}
                    propsSetOpenAlert={setOpenAlert}
                />
            ) : (
                <></>
            )}

            {openCancelarTarjeta ? (
                <NotificacionCancelarTarjeta
                    datosTarjeta={cardID}
                    open={setOpenCancelarTarjeta}
                    token={token}
                    actualizar={setActualizar}
                    stateActualizar={actualizar}
                    propsSetAlert={setAlert}
                    propsSetOpenAlert={setOpenAlert}
                    licencias={licencias}
                />
            ) : (
                <></>
            )}

        {alert && <SweetAlert
            title={alert.title}
            descripcion={alert.descripcion}
            tipo={alert.tipo}
            msjConfirmacion={alert.msjConfirmacion}
            msjCancelacion={alert.msjCancelacion}
            onConfirm={alert.onConfirm}
            showConfirm={alert.showConfirm}
            showCancel={alert.showCancel}
            onCancel={() => setAlert(null)}
        />}
        </>
    );
}
