
export const ADD_USUARIO = `
    mutation addUsuario($input: InputAddUsuario!) {
        addUsuario(input: $input) {
            usuarioID
            usuario
        }
    }
`;

export const UPDATE_USUARIO = `
    mutation updateUsuario($input: InputUpdateUsuario!) {
        updateUsuario(input: $input) {
            usuarioID
            usuario
        }
    }
`;

export const REGISTRAR_USUARIO = `
    mutation registrarUsuario($input: InputAddUsuario!) {
        registrarUsuario(input: $input) {
            usuarioID
            usuario
        }
    }
`;

export const ADD_USUARIOS = `
mutation addUsuario($input:InputAddUsuario!){
    addUsuario(input:$input){
      nombre
      usuario
      password
      fechaAlta
      ultimoLogin
      perfilID
      perfil
      perfilDescripcion
      estatus
      generoID
      sexo
      cumpleanios
      telefono
    }
  }
`;

export const UPDATE_USUARIOS = `
mutation updateUsuario($input:InputUpdateUsuario!){
    updateUsuario(input:$input){
      usuarioID
     nombre
      usuario
      password
      fechaAlta
      ultimoLogin
      perfilID
      perfil
      perfilDescripcion
      estatus
      generoID
      sexo
      cumpleanios

    }
  }
`;

export const UPDATE_USUARIOSESTATUS = `
mutation updateUsuariosEstatus($input:InputUpdateUsuariosEstatus!) {
    updateUsuariosEstatus(input:$input)
  }
`;

export const DELETE_USUARIOS = `
mutation deleteUsuario($usuarioID: ID!){
    deleteUsuario(id:$usuarioID)
  }
`;


export const ADD_USUARIOSADMI = `
mutation addUsuarioPorAdmin($input:InputAddUsuario!){
    addUsuarioPorAdmin(input:$input){
      nombre
      usuario
      password
      fechaAlta
      ultimoLogin
      perfilID
      perfil
      perfilDescripcion
      estatus
      generoID
      sexo
      cumpleanios
    }
  }
`;
