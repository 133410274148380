import React from "react";
import '../base.css';
import './estilos.css';
import AccountCircle from '@material-ui/icons/AccountCircle';
import Tooltip from '@material-ui/core/Tooltip';

function MiniBoton(props) {
  let { titulo, colorFondo, dgrid = "", icono, onClick, style, children = [] } = props;

  if (!icono) {
    icono = (<AccountCircle style={{ fontSize: 26, color: 'white' }} />);
  }
  
  let claseAux = "contenedorcMiniBoton " + dgrid;
  return (
    <div className={claseAux} style={style}>

      {
      titulo?
      <Tooltip title={titulo}>
        <div tabIndex="0" className="cMiniBoton" onClick={onClick} style={{ background: colorFondo }}>
          {icono}
          {children}
        </div>
      </Tooltip>
      :
      <div tabIndex="0" className="cMiniBoton" onClick={onClick} style={{ background: colorFondo }}>
          {icono}
          {children}
        </div>
      }
    </div>)
}

export default MiniBoton;