import { FetchAPI } from "@renedelangel/helpers";

import { urlServidor, urlServidorScrum, urlServidorWebSoftwone } from '../../../helpers';
import {  GET_USUARIO, TABLA_USUARIOS, CORREO_ACTIVA_CUENTA } from '../index';

export const getUsuarios = async (variables, token) => {
    try {

        let { getUsuarios } = await FetchAPI.graphql({
            token,
            variables,
            url: urlServidor,
            query: GET_USUARIO
        });

        return getUsuarios;

    } catch({message}) { throw new Error(message); }
}

export const tablaUsuarios = async (variables, token) => {
    try {
        let { filterUsuarios } = await FetchAPI.graphql({
            token,
            variables,
            url: urlServidor,
            query: TABLA_USUARIOS
        });

        return filterUsuarios;

    } catch({message}) { throw new Error(message); }
}


export const correoActivaCuenta = async (variables) => {
    try {

        let { correoActivaCuenta } = await FetchAPI.graphql({
            variables,
            url: urlServidor,
            query: CORREO_ACTIVA_CUENTA
        });

        return correoActivaCuenta;

    } catch({message}) { throw new Error(message); }
};



export async function RegistrarCuenta(datos) {
    let Query = `mutation RegistrarCuentaCliente($inputKey: [String], $inputValues: [String]) {
        RegistrarCuentaCliente(inputKey: $inputKey,
                               inputValues: $inputValues)
    }
    `;
    return await Ejecutar({inputKey: Object.keys(datos),
                           inputValues: Object.values(datos)}, null, Query);
}


export const Ejecutar = async (variables, token, query) => {
    try {

        let Respuesta = await FetchAPI.graphql({
            token,
            variables,
            url: urlServidorScrum,
            query: query
        });

        //Se devuelve el primer elemento del objeto respuesta, para que todo lo demás se quede igual

        return Respuesta[Object.keys(Respuesta)[0]];

    } catch({message}) {throw new Error(message); }
}