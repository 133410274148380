import React, {useState, useEffect} from 'react';
import ReactDOM from 'react-dom';
import compraSVG from './ImagenPagoExitoso/compra-27.svg';
import './styles.css';

export default function PagoExitoso(props){






    return /* ReactDOM.createPortal */(<>

    <div className= 'c_general_pago_exitoso'>
        <div className='c_interno_pago_exitoso'>
            <img src={compraSVG}/>
        </div>

        <div className='c_texto_pago_exitoso'>
            <h6>
                Se realizó tu compra con éxito
            </h6>
            <h5>
                Pronto recibirás un correo electrónico con el resumen de tu pedido.
            </h5>
        </div>

    </div>
    </>/* , document.getElementById('aux') */)
}