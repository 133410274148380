import React, { useState, useRef } from "react";
import "./styles.css";
import ReactDOM, { createPortal } from "react-dom";
import { useSelector } from "react-redux";
/* import PopUpAlert from "componentes/General/PopUps/PopUpAlerta"; */
import { urlServidor } from "helpers";
import conektaHelper from "../../../helpers/conekta";
import FetchAPI from "@renedelangel/helpers/lib/FetchAPI";
import closeSVG from "./iconoPopUpLogin/close.svg";
import LoaderLogoSoftwone from "_layout/assets/svg/Animaciones/logoSoftwone";
import Validaciones from "@renedelangel/helpers/lib/Validaciones";
import { rgxNombre } from "helpers/regexp";
import { info, danger, success, primary, warning } from '../../../_layout/helpers/colores';



const {trim} = Validaciones;

export default function AgregarTarjetas(props) {
    let {
        open,
        actualizar,
        stateActualizar,
        propsSetAlert,
        propsSetOpenAlert
    } = props;
    const { usuario, token } = useSelector(state => state.login);

    const delay = ms => new Promise(resolve => setTimeout(resolve, ms));

    const clearState = {
        nameCard: "",
        cardNumber: "",
        expMonth: "",
        expYear: "",
        cvv: ""
    };

    const [state, setState] = useState(clearState);

    const [disabled, setDisabled] = useState(false);
    const [loading, setLoading] = useState(false);

    async function conektaGenerarToken() {
        setLoading(true);
        conektaHelper.initConekta();

        if (!rgxNombre.test(nameCard) || trim(nameCard) === '') {
            propsSetAlert({
                descripcion: "Verifica el nombre del tarjetahabiente",
                title: "¡Error!",
                tipo: danger,
                msjConfirmacion: "Aceptar",
                onConfirm: () => propsSetAlert(null)
            });
            setLoading(false);
            return;
        }

        if (!conektaHelper.validateCardNumber(state.cardNumber)) {
            propsSetAlert({
                descripcion: "Verifique el número de la tarjeta",
                title: "¡Error!",
                tipo: danger,
                msjConfirmacion: "Aceptar",
                onConfirm: () => propsSetAlert(null)
            });
            setLoading(false);
            return;
        }

        if (expMonth == "") {
            propsSetAlert({
                descripcion: "Debe seleccionar un mes",
                title: "¡Error!",
                tipo: danger,
                msjConfirmacion: "Aceptar",
                onConfirm: () => propsSetAlert(null)
            });
            setLoading(false);
            return;
        }

        if (expYear == "") {
            propsSetAlert({
                descripcion: "Debe seleccionar un año",
                title: "¡Error!",
                tipo: danger,
                msjConfirmacion: "Aceptar",
                onConfirm: () => propsSetAlert(null)
            });
            setLoading(false);
            return;
        }

        if (!conektaHelper.validateExpirationDate(expMonth, expYear)) {
            propsSetAlert({
                descripcion: "Valida la vigencia del año y mes",
                title: "¡Error!",
                tipo: danger,
                msjConfirmacion: "Aceptar",
                onConfirm: () => propsSetAlert(null)
            });
            setLoading(false);
            return;
        }

        if (!conektaHelper.validateCvc(cvv)) {
            propsSetAlert({
                descripcion: "Debe registrar un cvv valido",
                title: "¡Error!",
                tipo: danger,
                msjConfirmacion: "Aceptar",
                onConfirm: () => propsSetAlert(null)
            });
            setLoading(false);
            return;
        }

        conektaHelper.initConekta();
        let AuxVencimiento = [state.expMonth, state.expYear];
        conektaHelper.tokenize(
            state.cardNumber,
            state.nameCard,
            AuxVencimiento[0],
            AuxVencimiento[1],
            state.cvv,
            AgregarTarjeta,
            errorTokenConekta
        );
        ///Hasta aquí si se generó el token de conekta correctamente, se contnua en la función TokenConektaGenerado, sino se continua en el errorTokenConekta.
    }

    async function AgregarTarjeta(TokenConekta) {
        setDisabled(true);

        let resultado = await FetchAPI.send({
            url: `${urlServidor}/Conekta/AgregarTarjeta`,
            params: {
                usuarioID: JSON.stringify(usuario.usuarioID),
                tokenConekta: JSON.stringify(TokenConekta),
                state: JSON.stringify(state),
                token: JSON.stringify(token),
                datosUsuario: JSON.stringify({
                    nombre: usuario.nombre,
                    correo: usuario.correo
                })
            }
        });

        // setLoader(false);
        if (resultado.ok == true) {
            setLoading(false);

            propsSetAlert({
                descripcion: "Se ha registrado de forma correcta la tarjeta",
                title: "¡Éxito!",
                tipo: "success",
                msjConfirmacion: "Aceptar",
                onConfirm: () => propsSetAlert(null),
                showCancel: false,
                msjCancelacion: "Cancelar",
                onCancel: () => propsSetAlert(null)
            });
            actualizar(!stateActualizar);
            open(false);
        } else {
            setLoading(false);
            propsSetAlert({
                descripcion: "Ha ocurrido un error al registrar la tarjeta, vuelva a intentarlo",
                title: "¡Error!",
                tipo: danger,
                msjConfirmacion: "Aceptar",
                onConfirm: () => propsSetAlert(null)
            });
            setDisabled(false);
        }
    }

    function errorTokenConekta(token) {
        setLoading(false);
        propsSetAlert({
            descripcion: 'Revisa tu información',
            title: "¡Error!",
            tipo: danger,
            msjConfirmacion: "Aceptar",
            onConfirm: () => propsSetAlert(null)
        });
    }

    function handleChange(e) {
        const { name, value } = e.target;
        setState(prevState => {
            return {
                ...prevState,
                [name]: value
            };
        });
    }

    let { nameCard, cardNumber, expMonth, expYear, cvv } = state;


    function generateValidCardYear(){


        let year = new Date().getFullYear();
        let options = [
        <option value={""} disabled>
        Seleccione el año
        </option>
        ];
        for(let i = 0; i < 11; i++){
            options.push(
                <option value={`${(year + i)}`.slice(-2)}selected="selected">{`${year  + i}`}</option>
            )
        }

        let years = <select
        className="yearTarjeta"
        name="expYear"
        value={expYear}
        onChange={e => {
            handleChange(e);
        }}
            >
                {options}        
    </select>

        return(years)
    }

    return ReactDOM.createPortal(
        <>
            <div
                className="contenedorGeneralAgregarTarjetas"
                onClick={() => {
                    open(false);
                }}
            >
                <div
                    className="contenedorInternoAgregarTarjetas"
                    onClick={e => e.stopPropagation()}
                >
                    <div
                        className="c_cerrar_lista_tarjetas"
                        onClick={() => {
                            open(false);
                        }}
                    >
                        <img src={closeSVG} />
                    </div>

                    <div className="tituloAgregarTarjetas">
                        <h3>Datos de la tarjeta</h3>
                    </div>

                    <form className="formTarjetas">
                        <div className="contenedorNombreTarjeta">
                            <label htmlFor={"nameCard"}>
                                Nombre de tarjetahabiente
                            </label>
                            <input
                                id={"nameCard"}
                                placeholder="Nombre"
                                name="nameCard"
                                value={nameCard}
                                onChange={e => {
                                    handleChange(e);
                                }}
                            />
                        </div>
                        <div className="contenedorNumeroTarjeta">
                            <label htmlFor="numeroTarjetaID">
                                Número de tarjeta
                            </label>
                            <input
                                className="numeroTarjeta"
                                id="numeroTarjetaID"
                                type="tel"
                                inputMode="numeric"
                                pattern="[0-9\s]{13,19}"
                                autoComplete="cc-number"
                                maxLength="19"
                                placeholder="XXXX - XXXX - XXXX - XXXX"
                                name="cardNumber"
                                value={cardNumber}
                                onChange={e => {
                                    handleChange(e);
                                }}
                            />
                        </div>

                        <div className="agrupadorDatos">
                            <div className="contenedorFechaTarjeta">
                                <label>Fecha de vencimiento</label>
                                <div className="agrupadorFechas">
                                    <div className="agrupadorMesTarjeta">
                                        <select
                                            className="mesTarjeta"
                                            name="expMonth"
                                            value={expMonth}
                                            onChange={e => {
                                                handleChange(e);
                                            }}
                                        >
                                            <option value={""} disabled>
                                                {" "}
                                                Seleccione el mes
                                            </option>
                                            <option
                                                value={"01"}
                                                selected="selected"
                                            >
                                                01
                                            </option>
                                            <option value={"02"}>02</option>
                                            <option value={"03"}>03</option>
                                            <option value={"04"}>04</option>
                                            <option value={"05"}>05</option>
                                            <option value={"06"}>06</option>
                                            <option value={"07"}>07</option>
                                            <option value={"08"}>08</option>
                                            <option value={"09"}>09</option>
                                            <option value={"10"}>10</option>
                                            <option value={"11"}>11</option>
                                            <option value={"12"}>12</option>
                                        </select>
                                    </div>
                                    <div className="agrupadorYearTarjeta">
                                        {generateValidCardYear()}
                                    </div>
                                </div>
                            </div>

                           
                        </div>

                        <div className="agrupadorCvv">
                                <label htmlFor="cvvTarjeta">Código de seguridad</label>
                                <input
                                    id="cvvTarjeta"
                                    className="cvvTarjeta"
                                    maxLength={3}
                                    placeholder="CVV"
                                    name="cvv"
                                    value={cvv}
                                    onChange={e => {
                                        handleChange(e);
                                    }}
                                />
                            </div>
                    </form>

                    <button
                        onClick={() => {
                            conektaGenerarToken();
                        }}
                        className="buttonSubmitAddTarjeta"
                        disabled={disabled}
                    >
                        Agregar tarjeta
                    </button>
                </div>
            </div>

            {loading ? <LoaderLogoSoftwone /> : <></>}
        </>,
        document.getElementById("alert")
    );
}
