
export const ENUM_SEXO = `
    query EnumSexo($dominio: String!) {
        getEnum(enum: "EnumSexo", dominio: $dominio) {
            value
            name
            description
        }
    }
`;

export const ENUM_PERFIL = `
    query EnumPerfil($dominio: String!) {
        getEnum(enum: "EnumPerfil", dominio: $dominio) {
            value
            name
            description
        }
    }
`;
