import React, { useState, useRef, useEffect } from 'react';
import { Switch, Redirect, useParams, Link, useHistory } from 'react-router-dom';
import { Validaciones } from '@renedelangel/helpers';
import { Encriptar } from '@renedelangel/helpers';

// master components
import List from '@material-ui/core/List';
import { makeStyles } from "@material-ui/core/styles";
import LocationOnIcon from '@mui/icons-material/LocationOn';

// Redux
import { useSelector } from "react-redux";

//generic components
import SweetAlert from '../../_layout/genericComponents/ModalConfirmacion';
import Preloader from '../../_layout/genericComponents/Preloader';
import CapturaReembolsos from '../Reembolsos'
import LineaSeguimiento from "../SeguimientoEstatus";

import {
    vectorArrowL,
    iconoReembolso
} from '../../_layout/assets/svg/exportsvg';

import './estilos.css';
import ProductoCompra from '../ProductoCompra';

import { getCompra, getDetalleCompra } from '../../querys/Compras';
import { getSeguimientoEstatus  } from '../../querys/SeguimientoPedido';

import SyncIcon from '@material-ui/icons/Sync';


const { Encrypt, Decrypt } = Encriptar;
const { trim } = Validaciones;

function DetallePedido() {

    const { usuario, token } = useSelector(state => state.login);
    const history = useHistory();
    const perfil = usuario ? usuario.perfil : 'Usuario';

    let { id } = useParams();
    let url = id.replace(/&/g, "/");
    let seed = "softwone";
    let datos = Decrypt({ data: url, seed }) !== "" ? JSON.parse(Decrypt({ data: url, seed })) : null;
    let ventaID = datos.id;

    const cleanNotificaciones = { mensaje: "", color: null, open: false };
    const cleanState = {
        ventaID: null,
        motivoReembolso: "",
        razonReembolso: "",
        tipoSolucion: "",
        resolucionReembolso: "",
        fechaAlta: "",
        fechaResolucion: "",
        estatusReembolso: "",
    };
    const columns = [{ nombre: 'Subtotal', width: 90 },
    { nombre: 'Envio', width: 150 },
    { nombre: 'Total', width: 150 },];

    const cleanStateDetalle = [];
    const cleanErrorState = {
        ventaID: { error: false, helperText: "" },
        motivoReembolso: { error: false, helperText: "" },
        razonReembolso: { error: false, helperText: "" },
        tipoSolucion: { error: false, helperText: "" },
        resolucionReembolso: { error: false, helperText: "" },
        fechaAlta: { error: false, helperText: "" },
        fechaResolucion: { error: false, helperText: "" }
    }
    let titleModal = perfil == "Administrador" ? `Revisar reembolso ` : `Solicitar reembolso `;
    titleModal = titleModal + `(${perfil})`


    const [state, setState] = useState(cleanState);
    const [stateTotales, setStateTotales] = useState({ subtotal: 0, envio: 0, total: 0 });
    const [stateDetalle, setStateDetalle] = useState(cleanStateDetalle);
    const [error, setError] = useState(false);
    const [open, setOpen] = useState(false);
    const [alert, setAlert] = useState(null);
    const [notificaciones, setNotificaciones] = useState(cleanNotificaciones);
    const [errorState, setErrorState] = useState(cleanErrorState);
    const [disabled, setDisabled] = useState(false);
    const [loader, setLoader] = useState(false);
    const [actualizar, setActualizar] = useState(false);
    const [reembolso, setReembolso] = useState({visible:false, leyenda: ""});
    const [camposDeshabilitados, setCamposDeshabilitados] = useState(true);
    const [segEstatus, setSegEstatus] = useState({});


    const rows = [{ nombre: 'Subtotal', valor: stateTotales.subtotal, campo: "Subtotal", tipo: "moneda" },
    { nombre: 'Costo de envío', valor: stateTotales.envio, campo: "Envio", tipo: "moneda" },
    { nombre: 'Total con Envío', valor: stateTotales.total, campo: "Total", tipo: "moneda" }];

    function handleClose() {
        setOpen(false);
        setDisabled(false);
        setLoader(false);
        setNotificaciones({ ...cleanNotificaciones });
        setErrorState({ ...cleanErrorState });
        setActualizar(true)
    }
    useEffect(() => {
        async function ftEffect() {
            try {
                let compra = await getCompra({ compraID: ventaID }, token)
                let detalle = await getDetalleCompra({ compraID: ventaID }, token);
                let seguimiento = await getSeguimientoEstatus({ ventaID: ventaID }, token);
                
                let esServicio = false;
                detalle.map(item => {
                    let servicio = item.servicio;
                    if (servicio == true) {
                        esServicio = true;
                    }
                })  
                setSegEstatus(seguimiento)
               
                if(esServicio == false){
                    if(perfil=="Administrador"){
                        let leyendaReembolso =  compra.reembolsoID != null ? "VER REEMBOLSO" : ""
                        let aplica = compra.reembolsoID != null ? true : false
                        setReembolso({aplica:aplica, leyenda:leyendaReembolso});

                        if(compra.estatus >1 && compra.estatus <=5 ){
                            setCamposDeshabilitados(false)
                        }
                    }
                    if(perfil == "Usuario"){
                        if(compra.reembolsoID ){
                            let leyendaReembolso = "VER REEMBOLSO"
                            setReembolso({aplica:true, leyenda:leyendaReembolso});
                        }else{
                            if(compra.estatus >1 && compra.estatus <=5 ){            
                                let leyendaReembolso =  "SOLICITO REEMBOLSO" 
                                setReembolso({aplica:true, leyenda:leyendaReembolso});
                            }
                        }
                        
                    }
                }                     
                setState(compra);
                setStateDetalle(detalle);
                setStateTotales({ subtotal: compra.subtotalCompra, envio: compra.costoPaqueteria, total: compra.totalCompra })

            } catch ({ message }) {
                console.log('error', message)
            }
        } ftEffect();
    }, [open]);

    

    return ((token && !error) ? (usuario.usuarioID ? <>

        <div class="contenidoDetCompra">
            <div class="datosPedido">
                <div class="titulo">Resumen</div>
                <div class="estatus"> {state.nomEstatus}</div>
                {
                    state.direccionEnvio ? 
                    <div class="seguimiento">
                        <LineaSeguimiento
                        datosHistoria = {segEstatus}
                        estatusActivo = {state.estatus}
                        reembolso = {state.reembolsoID}
                        ></LineaSeguimiento>
                    </div>
                    : <div></div>
                }
             

                <div class="resumen">                  
                        <div class="campo">
                            <div class="campo">
                                <b>Folio pedido</b>
                                <p>{state.ventaID}</p>
                            </div>
                            <div class="campo">
                                <b>Usuario</b>
                                <p>{state.nombreUsuario}</p>
                            </div>
                        </div>
                        <div class="campo">
                            <b>Fecha compra</b>
                            <p>{state.fechaCompra}</p>
                        </div>                  
                </div>
                <br></br>
                {
                    state.direccionEnvio ?
                    <>
                    <div class="estatus"> ENTREGA </div>
                    <div class="entrega">
                        <div><b>{state.tipoEntrega = 1 ? " Entrega a Domicilio" : " Recoger en Sucursal"}</b></div>
                        <div>{state.direccionEnvio}</div>
                    </div>
                    <div class="resumen">
                        <div class="datos">
                            <div class="campo">
                                <b>Paqueteria</b>
                                <p><input disabled={camposDeshabilitados} type="text" value={state.paqueteriaDescripcion}></input></p>
                            </div>
                            <div class="campo">
                                <b>Número de guia</b>
                                <p><input disabled={camposDeshabilitados}  type="text" value={state.numeroGuia}></input></p>
                            </div>
                        </div>
                    </div>
                    </> : <div></div>
                }
                
                <br></br>
                <div class="panel-botones">
                    {reembolso.aplica ?
                        <div className='boton-reembolso' onClick={()=>setOpen(true)}>
                            {reembolso.leyenda} {iconoReembolso}
                        </div> : <div></div>
                    }
                    <div className='boton-regresar' onClick={() => { history.push('/seguimientoPedido') }}>
                        REGRESAR {vectorArrowL}
                    </div>
                </div>

            </div>
            <div class="listadoProductos">
                {
                    !stateDetalle ? "No hay productos" :
                        <ProductoCompra
                            datos={stateDetalle}
                            totales={stateTotales}
                        />
                }
            </div>
        </div>
        {open && <CapturaReembolsos
            onClose={() => handleClose()}
            openFormulario={open}
            ventaID={ventaID}
            reembolsoID={state.reembolsoID}
        />
        }
        {
            alert && <SweetAlert title={alert.title}
                descripcion={alert.descripcion}
                tipo={alert.tipo}
                msjConfirmacion={alert.msjConfirmacion}
                msjCancelacion={alert.msjCancelacion}
                onConfirm={alert.onConfirm}
                showConfirm={alert.showConfirm}
                showCancel={alert.showCancel}
                onCancel={
                    () => setAlert(null)
                }
            />}

    </> : <Preloader />) : <><Switch><Redirect fromn="*" to={"/"} /></Switch></>);
}
export default DetallePedido;
