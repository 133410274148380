
export const DELETE_SLIDER = `
mutation deleteSlider($sliderID: ID!) {
    deleteSlider(id:$sliderID)
  }`;


  export const UPDATE_SLIDERESTATUS = `
  mutation updateSliderEstatus($input:InputUpdateSliderEstatus!) {
      updateSliderEstatus(input:$input)
    }`;
