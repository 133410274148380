export const GET_PAQUETERIA = `
query getPaqueteria($paqueteriaID:ID!) {
    getPaqueteria(id:$paqueteriaID){
      paqueteriaID
      paqueteria
      descripcion
      costo
      ventaMinima
      estatus
    }
  }`;

  export const TABLA_PAQUETERIA = `
  query filterPaqueteria ($filtro:InputFilterPaqueteria!) {
    filterPaqueteria(filtro:$filtro) {
      registros
      paginas
      listado{
        paqueteriaID
        paqueteria
        descripcion
        costo
        ventaMinima
        estatus
        predeterminada

      }
    }
  }`;
