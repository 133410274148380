import React from 'react';

// Generic components
import Notifications from '../../_layout/genericComponents/Notificaciones';

import GridContainer from "../../_layout/components/Grid/GridContainer.js";
import GridItem from "../../_layout/components/Grid/GridItem.js";

import WarningIcon from '@material-ui/icons/Warning';

import { warning } from '../../_layout/helpers/colores';

import error404Image from '../../_layout/assets/svg/undraw_page_not_found_su7k.svg';

function Error404() {

    const grid = { xs: 12, sm: 12, md: 12, lg: 12 };

    return (<GridContainer
            direction="column"
            justify="center"
            alignItems="center"
        >
            <GridItem { ...grid }>
                <h4>¡Error 404!</h4>
            </GridItem>
            <GridItem { ...grid }>
                <Notifications
                    message={`La página a la que intenta acceder no esta disponible o no cuenta con los permisos correspondientes para poder visualizarla.`}
                    icon={WarningIcon}
                    color={warning}
                    open={true}
                />
            </GridItem>
            <GridItem { ...grid }>
                <img src={error404Image} style={{ maxHeight: "500px", width: "100%" }} alt={"Imagen de un error 404"} />
            </GridItem>
        </GridContainer>);

}

export default Error404;
