import React from 'react';
// material-ui components
import { makeStyles } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from "@material-ui/core/FormHelperText";

import classNames from "classnames";

import styles from '../../../assets/jss/material-dashboard-pro-react/customSelectStyle.js';

const useStyles = makeStyles(styles);

export default function SelectInput (props) {

    let { id, title, data, value, multiple, onChange, placeholder, error, success, helperText='error', inputRef, disabled = false } = props;

    let ph = placeholder ? [{
        value: "",
        label: placeholder,
        disabled: true
    }] : [];

    const opciones = Array.isArray(data) && data.length > 0 ? [...ph, ...data] : [{ value: 0, label: "No se encontraron registros", disabled: true }];

    const classes = useStyles();

    const underlineClasses = classNames({
        [classes.underlineError]: error,
        [classes.underlineSuccess]: success && !error,
        [classes.underline]: true
    });

    let helpTextClasses = classNames({
        [classes.labelRootError]: error,
        [classes.labelRootSuccess]: success && !error
    });

	return (
        <FormControl fullWidth className={classes.selectFormControl + " " + underlineClasses}>
            <InputLabel htmlFor={id} className={classes.selectLabel}>
                {title}
            </InputLabel>
            <Select
                ref={inputRef}
                multiple={multiple}
                value={value}
                onChange={onChange}
                MenuProps={{
                    className: classes.selectMenu,
                    classes: multiple ? {
                        paper: classes.selectPaper
                    } : {}
                }}
                classes={{ select: classes.select }}
                inputProps={{ name: id, id, disabled }}
            >
                {
                    opciones && opciones.map(({value, label, disabled}, index) => (<MenuItem
                            key={index}
                            disabled={disabled}
                            classes={{
                                root: classes.selectMenuItem,
                                selected: !disabled && (multiple ? classes.selectMenuItemSelectedMultiple : classes.selectMenuItemSelected)
                            }}
                            value={value}
                        >
                            {label}
                        </MenuItem>
                    ))
                }
            </Select>
            {error && helperText !== undefined ? (
                <FormHelperText id={id + "-text"} className={helpTextClasses}>
                    {helperText}
                </FormHelperText>
            ) : null}
        </FormControl>
	);
}
