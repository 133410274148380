import React,{useEffect} from "react";
import './estilos.css';
import imageCancel from './cancelar_suscripcion.png'
import { useParams } from 'react-router-dom';
import { Encriptar } from '@renedelangel/helpers';
import { deleteSuscripcion } from "../../querys/Suscripciones/metodos";
const { Decrypt } = Encriptar;

export default function SucripcionCancelada() {
    let { data } = useParams();
    let url = data.replace(/&/g, "/");
    let seed = "c29mdHdvbmVAdG9ubGluZQ==";
    let datos = Decrypt({ data: url, seed }) !== "" ? JSON.parse(Decrypt({ data: url, seed })) : null;
    const {suscripcionID, dominio} = datos;

    useEffect(() => {
        async function ftEffect() {
            try {
               await deleteSuscripcion({input: {suscripcionID,dominio}})
            } catch ({ message }) {
                console.log('error', message)
            }
        } ftEffect();
    }, []);

    return (
        <div>
            <div className="scContainer">
                <h4 className="h4Cancel">TU SUSCRIPCIÓN HA SIDO CANCELADA</h4>
                <img className="imgCancel" src={imageCancel}  />
                <p className="pTextCancel">
                    Lamentamos que ya no quieras recibir nuestras noticias,
                    <br /> ¡vamos a extrañarte!
                    <br/>
                    <br/>
                    Recuerda que puedes volver a suscribirte en el momento que lo desees,
                    <br />¡te estaremos esperando!
                </p>
            </div>
        </div>
    );
}