import Enums from '@arskang/enumsjs';
import { enumSexo, enumPerfil } from '../querys/Enums/metodos';

const ftGenerarEnums = async (metodo) => {

    let dataEnum = await metodo();

    return Enums.set(dataEnum);

};

// Listado de Enumeraciones
// Se utiliza la función y se le manda el método creado en EnumService para consultar los datos
export const EnumSexo = async () => await ftGenerarEnums(enumSexo);
export const EnumPerfil = async () => await ftGenerarEnums(enumPerfil);

// Ejemplo de uso:
/*

// Se importa la enumeración

import { EnumExample } from '../enums';

// Se asigna a una variable, debe estar dentro de un método asyncrono

const en = await EnumExample;

// Ya puede utilizarse

en.ADMIN.getName() || en.ADMIN.toString(); // retorna 'Admin'
en.ADMIN.getValue() || en.ADMIN.toNumber();; //retorna 1
en.ADMIN.getDescription(); // retorna 'Administrador'

// Revisar .isEqual() y .convert() en:
// https://www.npmjs.com/package/@arskang/enumsjs

*/
