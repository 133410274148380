import { FetchAPI } from "@renedelangel/helpers";

import { urlServidor } from '../../../helpers';
import {  GET_POST, TABLA_POST } from '../index';

// import { convertToRaw, convertFromRaw } from "draft-js";
// import draftToMarkdown from 'draftjs-to-markdown';

export const getPost = async (variables, token) => {
    try {
        let { getPost } = await FetchAPI.graphql({
            token,
            variables,
            url: urlServidor,
            query: GET_POST
        });

        // console.log(getPost.contenidoPost)
        // console.log(JSON.parse(getPost.contenidoPost))
        // console.log(convertFromRaw({"entityMap":{},"blocks":[{"key":"637gr","text":"Initialized from content state.","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}]}))

      /*  return {
            ...getPost,
            contenidoPost
        };*/

        return getPost;

    } catch({message}) { throw new Error(message); }
}

export const tablaPost = async (variables, token) => {
    try {
        let { filterPost } = await FetchAPI.graphql({
            token,
            variables,
            url: urlServidor,
            query: TABLA_POST
        });
        return filterPost

    } catch({message}) { throw new Error(message); }
}

