import React, { useState, useEffect, useRef } from "react";

// Redux
import { useSelector } from "react-redux";

// Icons
import Add from "@material-ui/icons/Add";
import Edit from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import SyncIcon from "@material-ui/icons/Sync";
import SaveIcon from "@material-ui/icons/Save";
import AllInboxIcon from "@material-ui/icons/AllInbox";
import RadioButtonUncheckedIcon from "@material-ui/icons/RadioButtonUnchecked";
import CloseIcon from "@material-ui/icons/Close";
import {
    info,
    danger,
    success,
    primary,
    black
} from "../../_layout/helpers/colores";
import MarkunreadMailboxIcon from "@material-ui/icons/MarkunreadMailbox";

import {
    handleModificar,
    handleGuardar,
    handleFocus,
    handleErrorInputText,
    handleEliminar
} from "_layout/helpers/handles";
import { actualizaArchivo } from "../../helpers/serverStorage";
import { urlServidor } from "helpers";

// Graphql
import {
    filterBoletines,
    getBoletin,
    deleteBoletin,
    updateBoletin,
    addBoletin,
    updateStatusBoletin
} from "../../querys/Suscripciones/metodos";

// master components
import Tabla from "../../_layout/masterComponents/Tabla";
import FormularioModal from "../../_layout/masterComponents/FormularioModal";
import moment from "moment";
import { handleAlertMutationGeneric, handleAlertGeneric, handelAlertEliminar } from "../../_layout/helpers/handles";
import SweetAlert from '../../_layout/genericComponents/ModalConfirmacion';

const Index = () => {
    const [loading, setLoading] = useState(false);
    const { usuario, token } = useSelector(state => state.login);
    const cleanState = {
        boletinID: null,
        asunto: "",
        contenido: "",
        estatus: "",
        fechaCreacion: null,
        fechaEnviado: null,
        archivoAdj: "",
        imagen: "",
        boletinImg: "",
        storageID: null
    };

    const cleanErrorState = {
        paqueteria: { error: false, helperText: "" },
        descripcion: { error: false, helperText: "" },
        costo: { error: false, helperText: "" },
        ventaMinima: { error: false, helperText: "" },
        estatus: { error: false, helperText: "" },
        boletinImg: { error: false, helperText: "" },
        asunto: { error: false, helperText: "" },
        contenido: { error: false, helperText: "" }
    };
    const cleanNotificaciones = { mensaje: "", color: null, open: false };

    const [state, setState] = useState(cleanState);
    const [errorState, setErrorState] = useState(cleanErrorState);
    const [notificaciones, setNotificaciones] = useState(cleanNotificaciones);
    const [open, setOpen] = useState(false);
    const [disabled, setDisabled] = useState(false);
    const [alert, setAlert] = useState(null);
    const [loader, setLoader] = useState(false);
    const [actualizar, setActualizar] = useState(false);
    const [imgBoletin, setImgBoletin] = useState({
        valor: "",
        file: "",
        eliminar: false
    });
    let remover = false;

    let boletinRef = useRef(null);
    let descripcionRef = useRef(null);
    let boletinImgRef = useRef(null);
    let contenidoRef = useRef(null);
    let asuntoRef = useRef(null);

    const acciones = [
        {
            id: 'modificar',
            icono: Edit,
            color: info,
            descripcion: "Modificar",
            parametros: [{ campo: "boletinID" }],
            disabled: { multiseleccion: true },
            onClick: (parametros) =>
                {handleModificar({
                    parametros,
                    token,
                    setState,
                    setOpen,
                    setAlert,
                    asyncGet: getBoletin
                }),
                setImgBoletin({ valor: "", file: "", eliminar: false })
              }
        },
        {
            icono: MarkunreadMailboxIcon,
            sx: { color: black[500] },
            id: "enviar",
            descripcion: "Enviar newsletter",
            parametros: [
                { campo: "boletinID" },
                { campo: "asunto" },
                { campo: "contenido" },
                { campo: "ruta" }
            ],
            disabled: { multiseleccion: true },
            onClick: parametros => {
                handleAlertGeneric({
                    setAlert,
                    mensaje: {
                        title: 'Envio de boletin',
                        descripcion: `¿Deseas enviar el boletin a los suscriptores?`,
                        msjConfirmacion: 'Si',
                        msjCancelacion: 'No'
                    },
                    onCancel: () => setAlert(null),
                    onConfirm: () => handleAlertMutationGeneric({
                        token, setAlert, setActualizar, actualizar,
                        parametros,
                        asyncMutation: () => {enviarBoletin(parametros)},
                        mensajes: {
                            msjEspera: {
                                title: `Espere por favor`,
                                descripcion: `Enviando correos...`
                            },
                            msjCorrecto: {
                                title: `Correcto`,
                                descripcion: `Se enviaron los correos de forma correcta`
                            }
                        }
                    })
                })
                
            }
        },
        {
            icono: DeleteIcon,
            id: 'deleteBoletin',
            descripcion: "Eliminar",
            disabled: { multiseleccion: true },
            parametros: [{ campo: "boletinID"} , { campo: "asunto" }],
            onClick: ({boletinID, asunto}) => {
                handelAlertEliminar({
                    setAlert,
                    mensaje: `el boletin con el asunto: "${asunto}"`,
                    onCancel: () => setAlert(null),
                    onConfirm: () => handleEliminar({
                        token, setAlert, setActualizar, actualizar,
                        parametros: { boletinID }, 
                        asyncDelete: deleteBoletin
                    })
                })
            }

        }
    ];

    async function enviarBoletin(parametros) {
        let datosCompletos = [parametros, usuario.dominio];
        let myHeaders = new Headers();
        myHeaders.append("token", token);
        myHeaders.append("mimeType", "multipart/form-data");

        let formdata = new FormData();
        formdata.append("datos", JSON.stringify(datosCompletos));

        let requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: formdata
        };

        let boletinID = parametros.boletinID;

        await updateStatusBoletin({boletinID}, token);

        await fetch(`${urlServidor}/suscripcion/envioBoletin`, requestOptions)
            .then(response => {
                if (!response.ok){
                    return response.json();
                }
                else{
                    setActualizar(!actualizar);
                }
            })
            .catch(error => {
                setLoading(false);
                console.error("Error:", error);
            });


    }

    const botones = [
        {
            icono: Add,
            color: success,
            descripcion: "Agregar",
            onClick: () => setOpen(true),
            disabled: { multiseleccion: true }
        },
        {
            icono: SyncIcon,
            color: info,
            descripcion: "Actualizar",
            onClick: () => setActualizar(!actualizar),
            disabled: { multiseleccion: true }
        }
    ];

    const infoTabla = {
        botones,
        acciones,
        actualizar,
        id: "tblboletin",
        color: primary,
        title: "Boletines",
        iconTable: <AllInboxIcon />,
        headers: [
            { variable: "boletinID", descripcion: "ID", hide: true },
            { variable: "asunto", descripcion: "Asunto" },
            { variable: "contenido", descripcion: "Contenido" },
            { variable: "ruta", descripcion: "Imagen" },
            { variable: "estatus", descripcion: "Estatus" },
            { variable: "fechaCreacion", descripcion: "Fecha de creación"},
            { variable: "fechaEnviado", descripcion: "Fecha de Envio"}
        ],
        responsiveTitle: [/* "boletinID", */ "asunto"/* , "contenido" */],
        formato: [{ columnas: ["ruta"], tipo: "imagen", url: "", token }],
        accionesEffect: [
            {
                botones: ["enviar", 'modificar', 'deleteBoletin'],
                ocultar: data =>
                    data["estatus"].value == "Enviado" ? true : false
            }
        ],
        alineacion: [{ columnas: [0, 1, 2 , 3, 4], alineacion: "center" }],
    };

    function handleValidaciones({ asunto, contenido, boletinImg }) {
        let error;
        let validaciones = [
            {
                condicion: asunto == '' || !asunto,
                ref: asuntoRef,
                keyError: "asunto",
                mensajeError: "Considere no dejar el asunto en blanco"
            },
            {
                condicion: contenido == '' || !contenido,
                ref: contenidoRef,
                keyError: "contenido",
                mensajeError: "Considere agregar contenido al correo"
            }
        ];

        validaciones.forEach(({ condicion, ref, keyError, mensajeError }) => {
            if (error) return;
            error = ftErrorInputText({
                condicion,
                ref,
                keyError,
                mensajeError
            });
        });

        if (error) return error;
    }

    function ftErrorInputText({ condicion, ref, keyError, mensajeError }) {
        return handleErrorInputText({
            cleanErrorState,
            condicion,
            ref,
            keyError,
            mensajeError,
            loader: setLoader,
            disabled: setDisabled,
            errorState: setErrorState
        });
    }

    const inputs = [
        {
            disabled,
            id: "asunto",
            value: state.asunto,
            error: errorState.asunto.error,
            success: state.asunto && !errorState.error ? true : undefined,
            helperText: errorState.asunto.helperText,
            inputRef: asuntoRef,
            title: "Asunto*",
            placeholder: "Capture el asunto",
            grid: { md: 8, lg: 8, sm: 8, xs: 8 },
            onChange: ({ target: { value } }) => handleChange(value, "asunto")
        },
        {
            disabled,
            id: "contenido",
            value: state.contenido,
            error: errorState.contenido.error,
            success: state.contenido && !errorState.error ? true : undefined,
            helperText: errorState.contenido.helperText,
            inputRef: contenidoRef,
            title: "Contenido*",
            placeholder: "Capture el contenido",
            multiline: true,
            grid: { md: 6, lg: 6, sm: 6, xs: 6 },
            onChange: ({ target: { value } }) =>
                handleChange(value, "contenido")
        },
        {
            disabled,
            id: "boletinImg",
            value: state.boletinImg,
            title: "Imagen boletin",
            placeholder: "Agregue la imagen del boletin",
            grid: { md: 6, lg: 6, sm: 6, xs: 6 },
            onChange: (file, base) => {
                if (file === null) {
                    remover = true;
                }
                setState(state => ({
                    ...state,
                    boletinImg: base
                }));

                setImgBoletin({ valor: base, file: file, eliminar: remover });
                return;
            },
            tipo: "imageupload",
            removeImage: open
        }
    ];



    const accionesFormulario = [
        {
            loader,
            disabled,
            icono: SaveIcon,
            color: info,
            descripcion: "Guardar",
            onClick: () =>
                handleGuardar({
                    setLoader,
                    setDisabled,
                    setActualizar,
                    actualizar,
                    setNotificaciones,
                    handleClose,
                    handleFocus,
                    refFocus: boletinRef,
                    mensajeCorrecto: `El boletín informativo se ${
                        state.boletinID ? "modifico" : "agrego"
                    } correctamente`,
                    asyncCallback: ftGuardar
                    /*setErrorToken*/
                })
            // inputRef: accederRef,
        },
        {
            disabled,
            icono: CloseIcon,
            color: danger,
            descripcion: "Cancelar",
            onClick: handleClose
        }
    ];

    function ftGuardar() {
        async function ftGuardar() {
            let { boletinID, asunto, contenido, boletinImg, storageID, fechaCreacion } = state;

            let error = handleValidaciones({ asunto, contenido, boletinImg });

            if(error){ return error;}

            let condicionError = storageID == null ? imgBoletin.valor == '' || imgBoletin.valor == undefined  : boletinImg == '' || boletinImg == undefined

            if(condicionError ) throw new Error('Considere agregar una imagen antes de guardar el boletin');

            let auxBoletin = {};
            let datos = {};
            if (boletinID)
                await updateBoletin(
                    {
                        input: {
                            boletinID,
                            asunto,
                            contenido,
                            fechaCreacion,
                            fechaEnviado: null,
                            imagen: null,
                            archivoAdj: null,
                            estatus: 'Pendiente'
                        }
                    },
                    token
                );
            else {
                auxBoletin = await addBoletin(
                    {
                        input: {
                            asunto,
                            contenido,
                            fechaCreacion: moment().format("YYYY-MM-DD"),
                            fechaEnviado: null,
                            imagen: null,
                            archivoAdj: null,
                            estatus: "Pendiente"
                        }
                    },
                    token
                );

                datos = {
                    ...datos,
                    serverStorageID: null,
                    origenID: auxBoletin.boletinID
                };
            }

            datos = {
                serverStorageID: state.storageID,
                origenID:
                    boletinID == null
                        ? parseInt(auxBoletin.boletinID)
                        : boletinID,
                tabla: "BOLETIN",
                tipo: "BOLETIN",
                clasificacion: "BOLETIN",
                nombreArchivo: imgBoletin.eliminar ? "" : imgBoletin.file.name,
                ruta: state.ruta
            };

            if(imgBoletin.file || imgBoletin.eliminar){
            try {
                await actualizaArchivo(
                    (boletinID == null ? auxBoletin.boletinID : boletinID) +
                        "/tonline/boletin/",
                    imgBoletin.file,
                    0,
                    datos.serverStorageID,
                    token,
                    imgBoletin.eliminar,
                    true,
                    datos
                );
            } catch (error) {
                setNotificaciones({
                    mensaje: error,
                    color: danger,
                    open: true
                });
            }}
        }
        return ftGuardar();
    }

    function handleClose() {
        setOpen(false);
        setDisabled(false);
        setLoader(false);
        setNotificaciones({ ...cleanNotificaciones });
        setState({ ...cleanState });
        setErrorState({ ...cleanErrorState });
        setImgBoletin({
            valor: "",
            file: "",
            eliminar: false
        });
    }

    function handleChange(value, key) {
        setState({ ...state, [key]: value });
    }

    return (
        token && (
            <>
                <Tabla
                    infoTabla={infoTabla}
                    asyncData={filterBoletines}
                    token={token}
                />

                <FormularioModal
                    open={open}
                    title={`${
                        state.boletinID ? "Modificar" : "Agregar"
                    } Boletin`}
                    onClose={handleClose}
                    inputs={inputs}
                    acciones={accionesFormulario}
                    notificaciones={notificaciones}
                    closeNotification={() =>
                        setNotificaciones({ ...cleanNotificaciones })
                    }
                />

                {alert && (
                    <SweetAlert
                        title={alert.title}
                        descripcion={alert.descripcion}
                        tipo={alert.tipo}
                        msjConfirmacion={alert.msjConfirmacion}
                        msjCancelacion={alert.msjCancelacion}
                        onConfirm={alert.onConfirm}
                        showConfirm={alert.showConfirm}
                        showCancel={alert.showCancel}
                        onCancel={() => setAlert(null)}
                    />
                )}
            </>
        )
    );
};

export default Index;
