import React, { useState, useEffect } from 'react';
import { Route, Switch, Redirect, useHistory } from 'react-router-dom';
import { Encriptar, FetchAPI } from '@renedelangel/helpers';
import { makeStyles } from '@material-ui/core/styles';


// Redux
import { useDispatch, useSelector } from 'react-redux';
import { setCarrito, eliminarDelCarritoRedux, modificarProductoRedux } from '../../redux/actions/carritoActions';

// hooks
import { useTitle } from '../../hooks/title';
import Login from '../Login';
import Modal from '../../_layout/genericComponents/Modal';
import PedidoFormulario from '../../_layout/masterComponents/Pedido';
import { redondeo } from '../../helpers/regexp.js';
import { info, success, danger } from '../../_layout/helpers/colores';

//generic components
import SweetAlert from '../../_layout/genericComponents/ModalConfirmacion';
import CardFilter from '../../_layout/masterComponents/CardFilter'
import { NetworkError } from '../../_layout/genericComponents/Metodos'
import conektaHelper from '../../helpers/conekta'
import { urlServidor } from '../../helpers';

// material-ui icons

import DeleteIcon from '@material-ui/icons/Delete';

import { deleteCarrito, upsertCarrito } from "../../querys/Carrito/index";
import { upsertCompra, actualizaEstatusCompra } from "../../querys/Compras"
import { tablaPaqueteria } from '../../querys/Paqueterias/metodos';
import { getCarrito } from '../../querys/Carrito';
import { RegistrarCuenta } from "../../querys/Usuario/metodos";
import { getUsuarios } from "../../querys/Usuario/metodos";

import PedidoNuevo from '../../_layout/NuevosComponentes/PedidoNuevo';
import LoaderProcesandoPago from '../../_layout/NuevosComponentes/LoaderProcesandoPago';
import PagoExitoso from '../../_layout/NuevosComponentes/PagoExitoso';
import { toFixed } from '../../helpers/regexp.js';
import LoaderLogoSoftwone2 from '_layout/assets/svg/Animaciones/logoSoftwone2';
import { defaultProps } from 'react-select/async/dist/react-select.cjs.prod';
import { getDireccion } from '../../querys/Direcciones/metodos';

const { GenerarToken } = Encriptar;

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        maxWidth: '36ch',
        backgroundColor: theme.palette.background.paper,
    },
    inline: {
        display: 'inline',
    },
}));

function Pedido() {

    const seedToken = "c29mdHdvbmVAc2VndW1leA==";
    const tokenTemporal = GenerarToken({
        data: { usuario: "temporal" },
        expiresIn: "1m",
        seed: seedToken
    });

    // useTitle(`Listado de productos - ${process.env.REACT_APP_TIENDA_NAME}`);
    const classes = useStyles();
    const { usuario, token } = useSelector(state => state.login);
    const { nombre, perfilID, usuarioID } = usuario ? usuario : {};
    const { carrito } = useSelector(state => state.carrito);

    const dispatch = useDispatch();
    const eliminarItemRedux = (item) => dispatch(eliminarDelCarritoRedux(item));
    const modificarItemRedux = (item) => dispatch(modificarProductoRedux(item));
    const setCarritoRedux = (carritoUsuario) => dispatch(setCarrito(carritoUsuario));

    let subtotalPedido = 0;
    let envio = 0;
    let totalPedido = 0;

    const acciones = [{
        icono: <DeleteIcon />,
        descripcion: "Eliminar del carrito",
        parametros: [{ campo: "productoID", variable: "productoID" }, { campo: "usuarioID", variable: "usuarioID" }],
        onClick: (parametros) => eliminardelCarrito(parametros)
    }];

    const noTruncarDecimales = { maximumFractionDigits: 20 };

    const cleanOpenOpciones = { open: false, formulario: "", descripcion: "" };
    const paqueteriaInicial = {
        costo: 0,
        descripcion: "",
        estatus: true,
        paqueteria: "Ninguna",
        paqueteriaID: null,
        predeterminada: true,
        ventaMinima: 100
    }
    const datosInicial = {
        ventaID: null,
        tipoEntrega: 1, //1 adomicilio 2 en Tienda
        costoEnvio: 0.00,
        direccionEntrega: "",
        idDireccion: null,
        NumTarjeta: "",
        vencimientoTarjeta: "",
        cvv: "",
        total: 0.00,
        requiereFactura: false,
        requiereDireccion: false,
        nombreFactura: "",
        rfc: "",
        idDireccionFac: null,
        direccionFactura: "",
        usoCFDI : "",
        regimenFiscal: "",

    }
    const nombreDatos = {
        tipoEntrega: "Tipo de entrega a domicilio o en Tienda",
        direccionEntrega: "Dirección de entrega",
        NumTarjeta: "Número de tarjeta",
        vencimientoTarjeta: "Fecha de vencimiento de la tarjeta",
        cvv: "Código de seguridad de la tarjeta",
        nombreFactura: "Razón social para la factura",
        rfc: "RFC para la factura",
        idDireccionFac: "Dirección de facturación",
        direccionFactura: "Dirección para la facturación",
        idDireccion: "dirección de entrega",
        regimenFiscal: "Régimen fiscal de la factura",
        usoCFDI: "uso CFDI para la factura",
    }
    const cleanErrorState = {
        vencimientoTarjeta: { error: false, helperText: "" },
        NumTarjeta: { error: false, helperText: "" },
        cvv: { error: false, helperText: "" },
        rfc: { error: false, helperText: "" },
        nombreFactura: { error: false, helperText: "" },
        idDireccionFac: { error: false, helperText: "" },
        direccionFactura: { error: false, helperText: "" },
        idDireccion: { error: false, helperText: "" },
        regimenFiscal : {error: false, helperText:""},
        usoCFDI : {error: false, helperText:""}
    }

    const [alert, setAlert] = useState(null);
    const [redirigir, setRedirigir] = useState(null);
    const [openOpciones, setOpenOpciones] = useState(cleanOpenOpciones);
    const [envioPaqueteria, setEnvioPaqueteria] = useState(paqueteriaInicial);
    const [datosPedido, setDatosPedido] = useState(datosInicial);
    const [datosVenta, setDatosVenta] = useState({});
    const [errorState, setErrorState] = useState(cleanErrorState);
    const [loading, setLoading] = useState(false);
    const [statePage, setStatePage] = useState(1);
    const [pagoExitoso, setPagoExitoso] = useState(false);
    const [dataDireccion, setDataDireccion] = useState(null);

    const delay = ms => new Promise(resolve => setTimeout(resolve, ms));

    const [totales, setTotales] = useState({ "subtotal": 0, "envio": 0, "total": 0 });

    function eliminardelCarrito(parametros) {
        let articulo = parametros.productoID;
        if (usuario !== null) {
            ftEliminarItem(parametros.carritoID);
        }
        eliminarItemRedux(articulo);
        actualizaTotales();
    }

    async function grabarPedido() {
        if (!token) {
            setAlert({
                descripcion: "Su inicio de sesión caducó, ingrese nuevamente",
                title: "Lo sentimos",
                tipo: "warning",
                msjConfirmacion: "Ok",
                onConfirm: () => setRedirigir(getRederigir("/carrito")),
                showCancel: true,
                msjCancelacion: "Cancelar",
                onCancel: () => setAlert(null)
            });
        }

        setLoading(true);
        if (await DatosValidos() === false) {
            setLoading(false);
            return;
        }

        let esCarritoValido = await validarProductosCarrito();

        if(!esCarritoValido){
            setLoading(false);
            setAlert({
                descripcion: 'Detectamos que hubo un cambio en tu carrito, revisa el nuevo desglose y costos antes de realizar tu pago',
                title: "Alerta",
                tipo: 'warning',
                showCancel: false,
                msjConfirmacion: 'Aceptar',
                onConfirm: () => setAlert(null)
            });
            return;
        }

        if(esCarritoValido == 'carritoVacio'){
            setLoading(false);
            setAlert({
                descripcion: "Detectamos que no tiene productos en su carrito, le regresaremos a la sección de carrito",
                title: "Lo sentimos",
                tipo: "warning",
                msjConfirmacion: "Aceptar",
                onConfirm: () => setRedirigir(getRederigir("/carrito")),
                showCancel: false
            });
            return;
        }


            conektaGenerarToken();
    }

    async function validarProductosCarrito(){

        let carritoConsulta = await getCarrito({
            usuarioID: Number(usuario.usuarioID)
        }, token);

        let isCorrecto = true;

        
        if(carritoConsulta.listado.length < 1){
            setCarritoRedux(carritoConsulta.listado);
            isCorrecto = 'carritoVacio';
            return isCorrecto;
        }

        if(carritoConsulta.listado.length !== carrito.length){
            setCarritoRedux(carritoConsulta.listado);
            isCorrecto = false;
            return isCorrecto;
        }

        for(let i = 0; i < carritoConsulta.listado.length; i++){
            
            let isValid = carrito.some(element => element.codigoProducto == carritoConsulta.listado[i].codigoProducto);

            if(!isValid){
                setCarritoRedux(carritoConsulta.listado);
                isCorrecto = false;
                return isCorrecto;
            }
        }

        return isCorrecto;


    }

    async function conektaGenerarToken() {
        try {
            setAlert(null)
            TokenConektaGenerado();
            /* generarUsuarioScrum(); */
        } catch (error) {
            setLoading(false);
        }
    }

    async function generarUsuarioScrum() {
        try {
            let getUsuario = await getUsuarios({ usuario: usuarioID }, token)

            for (const property in carrito) {
                let item = carrito[property];
                let codigo = item.codigoProducto ? item.codigoProducto : "SCRUM01";
                if (codigo.includes("SCRUM")) {
                    let datos = {
                        NombreCompleto: getUsuario.nombre,
                        NombreUsuario: getUsuario.nombre,
                        CorreoElectronico: getUsuario.correo,
                        Password: "Password",
                        CodigoProducto: codigo
                    }

                    let x = await RegistrarCuenta(datos, tokenTemporal);
                }
            }
        } catch (error) {
            setLoading(false);
        }
    }

    async function TokenConektaGenerado(TokenConekta) {
        let Result = await GuardarPedido(TokenConekta);
        if (!Result) {
            setLoading(false);
            setAlert({
                descripcion: 'Error al procesar la compra',
                title: "Ocurrió un problema, no se ha realizado su pago",
                tipo: "warning",
                msjConfirmacion: "Reintentar",
                onConfirm: () => conektaGenerarToken(),
                showCancel: true,
                msjCancelacion: "Cancelar",
                onCancel: () => setAlert(null)
            });
            return;
        }
        let venta = Result.ventaID;
        let ClienteConekta = [];
        ClienteConekta["Nombre"] = usuario.nombre;
        ClienteConekta["Correo"] = usuario.correo;
        ClienteConekta["usuarioID"] = usuario.usuarioID;
        let datosTarjeta = datosPedido.datosTarjeta;
        
        //Con el token de conekta crear al cliente y procesar el pago

        let costo = Result.totalCompra;
        ClienteConekta['ventaID'] = venta;
        ClienteConekta['cuentaCteID'] = datosTarjeta.cuentaCteID;
        ClienteConekta['TokenConekta'] = datosTarjeta.tokenID;
        ClienteConekta['costo'] = costo;
        if(datosPedido.tipoEntrega == 1){
        ClienteConekta['aplicaEnvio'] = true;
        ClienteConekta["calle"] = `${dataDireccion.calle} ${dataDireccion.numeroExterior}`;
        ClienteConekta["ciudad"] = dataDireccion.municipio;
        ClienteConekta["estado"] = dataDireccion.estado;
        ClienteConekta["entreCalles"] = `${dataDireccion.entreCalle} y ${dataDireccion.yCalle}`
        ClienteConekta["recibe"] = dataDireccion.nombreResponsable;
        ClienteConekta["celular"] = dataDireccion.celular;
        ClienteConekta["codigoPostal"] = dataDireccion.codigoPostal;
        ClienteConekta['costoEnvio'] = envioPaqueteria.costo;
        ClienteConekta['paqueteria'] = envioPaqueteria.paqueteria;
        }
        
        RegistrarCuentaConekta(ClienteConekta, venta, Result.subtotalCompra, Result.totalCompra);
    }

    async function GuardarPedido(TokenConekta) {

        /* let tarjeta = datosPedido.NumTarjeta;
        let cuatro = tarjeta.substr(tarjeta.length - 4, tarjeta.length);
        */ 
        let datosTarjeta = datosPedido.datosTarjeta;
        let refPago = " ID Usuario:" + usuario.usuarioID + " cliente:" + usuario.usuario + " total:" + totales.total;

        let partidas = [];

        for (const property in carrito) {

            let item = carrito[property];
            let nom = item.nombre ? item.nombre : "";
            let codigo = item.codigoProducto ? item.codigoProducto : ""
            let desc = item.descripcion ? item.descripcion : ""


            let partida = {
                ventaID: null,
                partidaID: null,
                productoID: item.productoID,
                codigoProducto: codigo,
                nombre: nom,
                descripcion: desc,
                cantidad: item.cantidad,
                costoUnitario: item.precioVentaOnlineSinIva ,
                iva: item.IVA,
                costoTotal: item.precioVentaOnline
            }
            partidas.push(partida);
        }
        //let subtotal = totales.subtotal.replace(/,/g,"");
        //let total = totales.total.replace(/,/g,"");
        let subtotal = parseFloat((totales.subtotal)).toFixed(2);
        let total = parseFloat((totales.total)).toFixed(2);

        try {
            let result = await upsertCompra({
                input: {
                    ventaID: datosPedido.ventaID,
                    metodoPago: 1,
                    tipoTarjeta: 1,
                    subtotalCompra: parseFloat(subtotal),
                    costoPaqueteria: totales.envio,
                    totalCompra: parseFloat(total),
                    numeroGuia: null,
                    fotoPaquete: null,
                    direccionEnvio: datosPedido.direccionEntrega,
                    paqueteria: 1,
                    paqueteriaDescripcion: envioPaqueteria.paqueteria,
                    ult4Tarjeta: datosTarjeta.referencia,
                    conektatokenID: datosTarjeta.tokenID,
                    estatus: 1,
                    estatusCompra: 1,
                    referenciaPago: refPago,
                    fechaExpiracion: datosPedido.vencimientoTarjeta,
                    partida: partidas,
                    nombre: datosPedido.requiereFactura ? datosPedido.nombreFactura : "",
                    RFC: datosPedido.requiereFactura ? datosPedido.rfc : "",
                    direccionFacturacion: datosPedido.requiereFactura ? datosPedido.direccionFactura : "",
                    tipoEntrega: datosPedido.tipoEntrega,
                    direccionid_entrega: Number(datosPedido.idDireccion),
                    direccionid_factura: Number(datosPedido.idDireccionFac),
                    usoCFDI:  datosPedido.usoCFDI,
                    regimenFiscal: datosPedido.regimenFiscal
                }
            }, token);
            
            datosPedido["ventaID"] = result.ventaID;
            datosVenta["ventaID"] = result.ventaID;
            datosVenta["subtotalCompra"] = result.subtotalCompra;
            datosVenta["costoPaqueteria"] = result.costoPaqueteria;
            datosVenta["totalCompra"] = result.totalCompra;
            datosVenta["IDDireccionEntrega"] = Number(datosPedido.idDireccion);
            datosVenta["tipoEntrega"] = datosPedido.tipoEntrega;
            datosVenta["partidas"]= partidas;
            return result;

        } catch (error) {
            setAlert({
                descripcion: error.message,
                title: "Datos incorrectos",
                tipo: danger,
                //msjConfirmacion: "Aceptar",
                onConfirm: () => setAlert(null)
            });

            return null;
        }
    }

    function errorTokenConekta(token) {
        setLoading(false);
        setAlert({
            descripcion: token.message_to_purchaser,
            title: "Problemas al generar el token",
            tipo: danger,
            onConfirm: () => setAlert(null)
        });
    };

    async function RegistrarCuentaConekta(ClienteConekta) {

        let resultado = await FetchAPI.send({
            url: `${urlServidor}/Conekta/RegistrarCuentaConekta/${token}`,
            params: {
                ...ClienteConekta
            }
        });
        
        if (resultado.ok === true) {
            confirmaVenta(ClienteConekta.TokenConekta);
        } else {
            setLoading(false);
            setAlert({
                descripcion: "No se ha realizado su pago",
                title: "Ocurrió un problema",
                tipo: "warning",
                msjConfirmacion: "Reintentar",
                onConfirm: () => conektaGenerarToken(),
                showCancel: true,
                msjCancelacion: "Cancelar",
                onCancel: () => setAlert(null)
            });
        }
    }

    async function confirmaVenta(conektatokenID) {
        let alerta = false;
        let mensaje = "";
        try {

            let datosTarjeta = datosPedido.datosTarjeta;            
            let resultado = await actualizaEstatusCompra({ input: { ventaID: datosPedido.ventaID, estatus: 2, usuarioID: usuario.usuarioID, conektatokenID: datosTarjeta.tokenID, ult4Tarjeta: datosTarjeta.referencia } }, token);
            
            if (resultado) {             
                let {ok, mensajeError} = await enviarCorreoConfirmacion();              
                if(mensajeError == "Correo enviado" ){
                    setLoading(false);
                    ftGetCarrito();
                    setPagoExitoso(true);          
                    await delay(5000);
                    setRedirigir(getRederigir("/seguimientoPedido"))
                }
            }
        } catch (error) {
            setLoading(false);           
            mensaje = "Error al completar la compra";
            alerta = true;

        }
        if(alerta){
            setAlert({
                descripcion: mensaje,
                title: "Error ocurrido",
                tipo: danger,//warning
                msjConfirmacion: "Reintentar",
                onConfirm: () => confirmaVenta(),
                showCancel: true,
                msjCancelacion: "Ok",
                onCancel: () => setAlert(null)
            })
        }
    }

    async function enviarCorreoConfirmacion(){        
       
        let result = {}
        await fetch(
            `${urlServidor}/emailConfirmaPedido/${token}`,    
            { 
                method: "POST",             
                body: JSON.stringify(datosVenta),
                headers:{
                    token,
                    'Content-Type': 'application/json'
                },
                data: datosVenta,
                dataType : 'json'
            }
        ).then(res => res.json())
        .catch(error => { 
           let { mensaje } = response;     
           result = {ok: false, mensajeError: mensaje};                     
        })
        .then(response => {         
           let { mensaje } = response; 
           result = {ok: true, mensajeError: mensaje};              
        })  
        return result;          
  
    }

    async function DatosValidos() {
        try {
            let error = false;
            let msjError = '';
            let datosValidar = [];

            datosValidar["tipoEntrega"] = datosPedido.tipoEntrega;
            datosValidar["costoEnvio"] = totales.envio;
            if (datosPedido.tipoEntrega == 1 && statePage == 1) {
                datosValidar["idDireccion"] = datosPedido.idDireccion
            }
            if (datosPedido.requiereFactura === true) {
                datosValidar["nombreFactura"] = datosPedido.nombreFactura;
                datosValidar["rfc"] = datosPedido.rfc;
                datosValidar["idDireccionFac"] = datosPedido.idDireccionFac;
                datosValidar["regimenFiscal"] = datosPedido.regimenFiscal;
                datosValidar["usoCFDI"] = datosPedido.usoCFDI;     
            }

            for (const property in datosValidar) {
             
                if ((datosValidar[property] === "" || datosValidar[property] === null)) {
                    msjError = 'Falta ' + nombreDatos[property];
                    error = true;
                    if (cleanErrorState[property]) {
                        errorState[property] = { helperText: msjError, error: true }                        
                    }
                    break;
                } else {
                    if (errorState[property]) {                    
                        if (errorState[property].error == true) {
                            error = true;
                            msjError = errorState[property].helperText;
                            break;
                        }else{
                            errorState[property] = { helperText: "", error: false }        
                        }
                    }
                }
            }          
            if (error === true) {
                setLoading(false);
                setErrorState(errorState)                
                setAlert({
                    descripcion: msjError,
                    title: "Datos incorrectos",
                    tipo: danger,
                    onConfirm: () => { setAlert(null);  }//setErrorState(cleanErrorState)
                });
            }
            return !(error || msjError);
        } catch (error) {
            console.log(error);
        }
    }

    function ftGetCarrito() {
        async function ftGetCarrito() {

            let carritoSelec = await getCarrito({
                usuarioID: Number(usuario.usuarioID)
            }, token);
            setCarritoRedux(carritoSelec.listado);
        }
        return ftGetCarrito();
    }

    function ftEliminarItem(carritoID) {
        async function eliminarItem() {

            let carritoSelec = await deleteCarrito({
                carritoID: Number(carritoID)
            }, token);
            return carritoSelec;
        }
        return eliminarItem();
    }
    function actualizaTotales() {

        subtotalPedido = 0; envio = 0;
        let serviciosE = 0;
        let productosE = 0;
        carrito.map((item) => {
            subtotalPedido = subtotalPedido + (Number(item.cantidad) * Number(parseFloat(toFixed(item.precioVentaOnline, 2))));
            if (item.servicio == 1) {
                serviciosE = serviciosE + 1;
            } else {
                productosE = productosE + 1;
            }
        });
        if (serviciosE == carrito.length) {
            setStatePage(2)
        }
        if (subtotalPedido > 0) {
            envio = datosPedido.tipoEntrega == 1 ? envioPaqueteria.costo : 0;
        }
        totalPedido = subtotalPedido + envio;
        setTotales({ subtotal: subtotalPedido, envio: envio, total: totalPedido });
    }
    function getRederigir(url = "/") {
        return (<><Switch><Redirect from="/pedido" to={url} /></Switch></>);
    }
    function volverCarrito() {
        setRedirigir(getRederigir("/carrito"));
    }

    useEffect(() => {
        async function ftEffect() {
            try {
                if (!token) {
                    setAlert({
                        descripcion: "Su inicio de sesión caducó, ingrese nuevamente",
                        title: "Lo sentimos",
                        tipo: "warning",
                        msjConfirmacion: "Ok",
                        onConfirm: () => setRedirigir(getRederigir("/carrito")),
                        showCancel: false,
                        msjCancelacion: "Cancelar",
                        onCancel: () => setAlert(null)
                    });   
                 }
                 if(carrito.length < 1 ){
                    setRedirigir(getRederigir("/carrito"));                       
                }
                if (envioPaqueteria.paqueteriaID == null) {
                    const paqueterias = await tablaPaqueteria({ filtro: { predeterminada: true, pagina: 1, limite: 1 } }, token);
                    let paqueteriaSelec = paqueterias.listado[0];
                    if (paqueteriaSelec) {
                        setEnvioPaqueteria(envioPaqueteria => (paqueteriaSelec));
                    }
                }
                actualizaTotales();
            } catch ({ message }) {
                console.log('error', message)
            }
        } ftEffect();
    }, [window.location.pathname, token, usuario]);

    useEffect(() => {
        async function ftEffect() {
            try {               
               
                actualizaTotales();
                
            } catch ({ message }) {
                console.log('error', message)
            }
        } ftEffect();
    }, [datosPedido]);


    useEffect( () => {
        async function ftDireccionEnvio(){
            if(datosPedido.idDireccion !== null){
                let auxDireccionEntrega = await getDireccion({direccionID: datosPedido.idDireccion}, token)
                setDataDireccion(auxDireccionEntrega);
            }
        }
        ftDireccionEnvio();
    }, [datosPedido.idDireccion])

    useEffect(() => {
        async function scrollTop(){
            window.scrollTo(0, 0);
        }
        scrollTop()
    }, [])


    return (<>
        {redirigir}

        {
            pagoExitoso ?


                <PagoExitoso /> :

                <PedidoNuevo
                    datos={carrito}
                    pageActiva={statePage}
                    paqueteria={envioPaqueteria}
                    datosPedido={datosPedido}
                    recibeDatos={(datos) =>
                        setDatosPedido(datosPedido => ({ ...datosPedido, ...datos }))
                    }
                    totales={totales}
                    grabarPedido={grabarPedido}
                    volver={volverCarrito}
                    errorState={errorState}
                    recibeError={(datos) =>
                        setErrorState(errorState => ({ ...errorState, ...datos }))
                    }
                    pagando={loading ? true : false}
                    propsSetAlert={setAlert}
                />
        }


       
        <Modal
            title={openOpciones.descripcion}
            open={openOpciones.open}
            onClose={() => setOpenOpciones(cleanOpenOpciones)}
        >
            <Login onClose={() => setOpenOpciones(cleanOpenOpciones)} />
        </Modal>
        {alert && <SweetAlert
            title={alert.title}
            descripcion={alert.descripcion}
            tipo={alert.tipo}
            msjConfirmacion={alert.msjConfirmacion}
            msjCancelacion={alert.msjCancelacion}
            onConfirm={alert.onConfirm}
            showConfirm={alert.showConfirm}
            showCancel={alert.showCancel}
            onCancel={() => setAlert(null)}
        />}

        {loading ?
            <LoaderLogoSoftwone2 /> : <></>
        }


    </>);

}

export default Pedido;
