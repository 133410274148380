import React from 'react';
import PropTypes from "prop-types";
import {  Encriptar } from '@renedelangel/helpers';

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";

// core components
import Button from "../../components/CustomButtons/Button.js";
import Card from "../../components/Card/Card.js";
import CardHeader from "../../components/Card/CardHeader.js";
import CardBody from "../../components/Card/CardBody.js";
import CardAvatar from "../../components/Card/CardAvatar";
import CardFooter from "../../components/Card/CardFooter.js";
import ListaDeseos from "../../../componentes/Productos/listaDeseos.js";
import CardMedia from '@material-ui/core/CardMedia';

import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';

import styles from "../../assets/jss/material-dashboard-pro-react/views/dashboardStyle.js";

const useStyles = makeStyles(styles);
const {Encrypt,Decrypt}=Encriptar;

function CardItemImage(props) {

    const classes = useStyles();

    const { id, deseosID, valor1=null, valor2=null, title, descripcion, imagen, onClick, onChange, actions = [], textFooterLeft, textFooterRight, style = { height: "auto", width: "250px" }, leyenda=null, leyenda2=null } = props,
        tooltipProps = { id: "tooltip-top", placement: "bottom" };
    let href = `/producto/${id}`;


        let seed = "softwone";
        const parametros = `{"id": "${id}"}`;
        let url = Encrypt({data: parametros, seed}).replace(/\//g, "&");
        href = `/producto/${url}`,

    function evtOnClick(e, ) {
        //e.preventDefault();
        if(onClick) onClick(e, id, onClick);
    }
    function handleClick() {
        //e.preventDefault();
      //  const id = event.target.id;
       if(onClick) onClick(e);
    }

    return (
        <Card product>
            {
                imagen && <CardHeader image className={classes.contenedorImg} >
                <div className="contenedor">
                    <a href={href}>
                        <img className={classes.cardImagen} src={ imagen } alt={title} title={title} />
                    </a>

                </div>
            </CardHeader>
            }
            <CardFooter product  className={classes.cardProductTitle} >
                <div >
                    <h5 className={classes.cardTitulo}>
                        <a href={href}  >
                            { title }
                        </a>
                        <br></br>
                        <div className={classes.existencia}>
                        {
                            valor2  ? leyenda2 : leyenda ? `${leyenda}: ${valor1}` : ''
                        }
                </div>
                    </h5>
                </div>
            </CardFooter >

            <CardFooter className={classes.price}>

                { textFooterLeft }
                <div className={classes.price}>
                    {
                        Array.isArray(actions) && actions.length ? actions.map(({color, descripcion,idIcono, onClick, onChange, ...rest}, key) =>
                        <Tooltip
                            key={key}
                            title={descripcion}
                            classes={{ tooltip: classes.tooltip }}
                            { ...tooltipProps }
                        >
                        <Button className={classes.icono} color={color} simple justIcon {...rest }   onClick={(e) => onClick({'id':id, 'deseosID':deseosID},e)}   >
                            {
                                idIcono ? <ListaDeseos variables={{ descripcion, color, deseosID, id, key}}/> : <rest.icono  id={idIcono === 1 ? id : 0} className={classes.underChartIcons}  />
                            }
                        </Button>
                        </Tooltip>
                        ) : ""
                    }

                </div>

            </CardFooter>
        </Card>
    );
}

CardItemImage.propTypes = {
    id: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    imagen: PropTypes.string.isRequired,
    descripcion: PropTypes.string,
    onClick: PropTypes.func,
    onChange: PropTypes.func,
    textFooterLeft: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    textFooterRight: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    actions: PropTypes.arrayOf(PropTypes.shape({
        color: PropTypes.oneOf(["transparent", "danger", "info", "warning", "success"]).isRequired,
        descripcion: PropTypes.string.isRequired,
        icono: PropTypes.object.isRequired,
        onClick: PropTypes.func,
        onChange: PropTypes.func
    }))
};

export default CardItemImage;
