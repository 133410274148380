import React, { useState } from "react";
import '../base.css';
import './estilos.css';
function Input(props) {
  let { id = "",
    titulo,
    placeholder,
    dgrid = "",
    claseAuxInput = "",
    password = false,
    onKeyEnter,
    onChange,
    onBlur,
    value,
    disabled = false,
    innerRef,
    onEndTyping,
    icono,
    posIcono = 'setRight',
    onClickIcono = () => { },
    estructuraCta,
    number = false,
    error,
    textarea = false } = props;

  const [stValorAnterior, setValorAnterior] = useState("");
  const [stClickIcono, setClickIcono] = useState(false);

  let claseAux = "contenedorcInput " + dgrid;
  let tipo = "input";
  if (password) {
    tipo = "password";
  }
  if (number) {
    tipo = "number";
  }

  function handleKeyDown(event) {
    clearTimeout(typingTimer);
    if (!onKeyEnter) {
      return;
    }
    if (event.key === 'Enter') {
      if (value === stValorAnterior && !stClickIcono ) {
        return;
      }
      onKeyEnter();
    }
  }

  let typingTimer;
  let endTypingInterval = 500; //Segundos después del último cambio
  function handleKeyUp() {
    clearTimeout(typingTimer);
    typingTimer = setTimeout(endTyping, endTypingInterval);
  }

  function endTyping() {
    if (onEndTyping) {
      if (value === stValorAnterior) {
        return;
      }
      onEndTyping();
    }
  }

  function handleValue() {
    if (estructuraCta) {
      //let valorFormateado = ftEstructuraCuentaContable(estructuraCta, value, false);
      return valorFormateado;
    }
    return value;
  }

  function handleOnChange(e) {
    if (estructuraCta) {
      //e.target.value = ftEstructuraCuentaContable(estructuraCta, e.target.value, false);
    }
    onChange(e);
  }

  function handleOnBlur() {
    if (!onBlur) {
      return;
    }

    if (value === stValorAnterior) {
      return;
    }

    onBlur();
  }

  return (<div className={claseAux}>
    {titulo &&
      <div className="tituloInput">
        {titulo}
      </div>}

    <div className={"cAuxInput " + (disabled ? " disabled" : "")}>
      {textarea &&
        <textarea
          id={id}
          ref={innerRef}
          onChange={(e) => handleOnChange(e)}
          onBlur={handleOnBlur}
          className={"cInput " + claseAuxInput}
          type={tipo}
          placeholder={placeholder}
          onKeyDown={(e) => handleKeyDown(e)}
          onKeyUp={(e) => handleKeyUp(e)}
          value={handleValue()}
          onFocus={() => setValorAnterior(value)}
          disabled={disabled} />}
      {!textarea &&
        <input
          id={id}
          ref={innerRef}
          onChange={(e) => handleOnChange(e)}
          onBlur={handleOnBlur}
          className={"cInput " + claseAuxInput}
          type={tipo}
          placeholder={placeholder}
          onKeyDown={(e) => handleKeyDown(e)}
          onKeyUp={(e) => handleKeyUp(e)}
          value={handleValue()}
          onFocus={() => setValorAnterior(value)}
          disabled={disabled} />
      }
      {error && <div className="cMensajeErrorInput">{error}</div>}
      {icono &&
        <div className={"iconoInput " + posIcono} onClick={() => {
          onClickIcono();
          setClickIcono(true)
          }}>
          {icono}
        </div>}
    </div>

  </div>)
}

export default Input;