import { FetchAPI } from "@renedelangel/helpers";

import { urlServidor } from '../../../helpers';

import {DELETE_DESEOS, UPSERT_DESEOS } from '../index';

export const deleteDeseos = async (variables, token) => {
    try {
        let { deleteDeseos } = await FetchAPI.graphql({
            token,
            variables,
            url: urlServidor,
            query: DELETE_DESEOS
        });
        return deleteDeseos;
    } catch({message}) { throw new Error(message); }

};

export const upsertDeseos = async (variables, token) => {
    try {
        let {addDeseos}  = await FetchAPI.graphql({
            token,
            variables,
            url: urlServidor,
            query: UPSERT_DESEOS
        });
        return addDeseos.deseosID;
    } catch({message}) { throw new Error(message); }

};
