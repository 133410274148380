import React, { useState, useEffect } from 'react';
import { Route, Switch, Redirect, useHistory } from 'react-router-dom';
import PropTypes from "prop-types";

// Redux
import { useDispatch,useSelector } from 'react-redux';
import { setBusqueda } from '../../redux/actions/busquedaAction';


// hooks
import { useTitle } from '../../hooks/title';
import Login from '../Login';
import Modal from '../../_layout/genericComponents/Modal';
import AlertaGenerica from '../../_layout/genericComponents/AlertaGenerica';

//material ui /componentes
import { makeStyles } from "@material-ui/core/styles";

//generic components
import SweetAlert from '../../_layout/genericComponents/ModalConfirmacion';
import CardFilter from '../../_layout/masterComponents/CardFilter'
import { NetworkError } from '../../_layout/genericComponents/Metodos'

// material-ui icons
import AddShoppingCartIcon from '@material-ui/icons/AddShoppingCart';
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder';
import VisibilityIcon from '@material-ui/icons/Visibility';
import ShoppingBasketIcon from '@material-ui/icons/ShoppingBasket';
import FavoriteOutlinedIcon from '@material-ui/icons/FavoriteOutlined';
import Tooltip from "@material-ui/core/Tooltip";
//core componentes
import Button from "../../_layout/components/CustomButtons/Button.js";

import { upsertDeseos } from "../../mutations/Deseos/metodos";
import { isToken } from '../../querys/Login/metodos';

import styles from "../../_layout/assets/jss/material-dashboard-pro-react/views/dashboardStyle.js";

const useStyles = makeStyles(styles);


function ListaDeseos({variables}) {

    let { descripcion, color, deseosID, id, key}  = variables;
    const  tooltipProps = { id: "tooltip-top", placement: "bottom" };

    const cleanOpenOpciones = { open: false, formulario: "", descripcion: "" };

    const classes = useStyles();
    const { usuario, token } = useSelector(state => state.login);
    const defaultMensaje = {
        visible: false,
        descripcion: '',
        tipo: 'error' // Opciones: error, warning, info, success
    };
    const [openOpciones, setOpenOpciones] = useState(cleanOpenOpciones);
    const [alertGenerica, setAlertGenerica] = useState(defaultMensaje);

     let productoSelec = {
        descripcion: descripcion,
        color: color,
        deseosID: deseosID,
        productoID: id
    }
    const [producto, setProducto] = useState(productoSelec);
    function ftAgregar(productoID, deseosID) {
        async function ftAgregar() {
            try{
                let lista = deseosID ? 0 : 1;
                let deseo = await upsertDeseos({
                    input: {deseosID:deseosID, productoID: productoID, clienteID: usuario.usuarioID}
                }, token);
                setProducto({...producto, deseosID: deseo})
                let msj = deseo ? `Producto agregado a sus favoritos` : `Se quitó el producto de sus favoritos`;
                setAlertGenerica({
                        visible:true,
                        descripcion: msj,
                        tipo: "success",
                });
            }catch(error){
                setAlertGenerica({
                    visible:true,
                    descripcion: `Error: ${error}`,
                    tipo: "error",
                });
            }
        }
        return ftAgregar();
    }
    function agregarListaDeseos(datos)
    {
        let {deseosID, id} = datos;
        if (usuario != null)
        {
            ftAgregar(id, deseosID);
        }
    }
    const handleCloseMensaje = (event, reason) => {
        if (reason === 'clickaway') return;
        setAlertGenerica({
            ...alertGenerica,
            visible:false
        });
    };

    function ftEffect() {
        async function effect() {
            try {
              let productoSelec = {
                  descripcion: descripcion,
                  color: color,
                  deseosID, deseosID,
                  productoID: id
              }
              setProducto(productoSelec);

            } catch({ message }) {
                setAlert({
                    descripcion: `Algunos datos necesarios para funcionar no se cargaron correctamente, intenta actualizar la página o verifica tu conexión a internet; a continuación se muestrán más detalles del error: ${NetworkError(message)}`,
                    title: "¡Una disculpa!",
                    tipo: danger,
                    msjConfirmacion: "Aceptar",
                    onConfirm: () => setAlert(null)
                });
            }
        } effect();
    }
    useEffect(() => {
    }, [window.location.href]);

    useEffect(ftEffect, []);

    return (
    <>
        <Tooltip
            key={key}
            title={descripcion}
            classes={{ tooltip: classes.tooltip }}
            { ...tooltipProps }
         >
         <Button  color={color} simple justIcon   onClick={(e) => agregarListaDeseos({'id':producto.productoID, 'deseosID':producto.deseosID},e)}  >
         {
             producto.deseosID ? <FavoriteOutlinedIcon className={classes.underChartIcons}/> : <FavoriteBorderIcon className={classes.underChartIcons}/>
         }
                </Button>
        </Tooltip>

        <Modal
            title={openOpciones.descripcion}
            open={openOpciones.open}
            onClose={() => setOpenOpciones(cleanOpenOpciones)}
        >
           <Login onClose={() => setOpenOpciones(cleanOpenOpciones)} />
        </Modal>
        { alertGenerica && <AlertaGenerica
            visible={alertGenerica.visible}
            descripcion={alertGenerica.descripcion}
            tipo={alertGenerica.tipo}
            handleClose={handleCloseMensaje}
        /> }



    </>);

}

export default ListaDeseos;
