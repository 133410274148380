export const GET_PRODUCTO = `
query getProducto($productoID:ID!, $dominio: String!) {
    getProducto(id:$productoID, dominio: $dominio){
    productoID
    empresaID
    grupoID
    subgrupoID
    marcaID
    tipoProductoID
    cliproID
    unidadID
    unidadID2
    almacenID
    usuariobitID
    departamentoID
    divisaID
    estatusID
    codigoProducto
    codigoProdSat
    codigoProductoAnterior
    codigoBarras1
    codigoBarras2
    codigoBarras3
    claveUnidadSat
    numeroParte
    descripcion
    descripcionCorta
    fechaAlta
    fechaModificacion
    fechaUltcosto
    presentacion
    factor
    ubicacion
    rutaImagen    
    tipoDesctoSc1
    tipoDesctoSc2
    tipoDesctoSc3
    desctoSc1
    desctoSc2
    desctoSc3
    porcIva
    porcIvaComp
    porcIeps
    porcTua
    porcRetIsr
    porcRetIva
    sinComision
    comisionVenta
    comisionCobro
    inventariable
    servicio
    paquete
    produccion
    consignacion
    exento
    controlado
    importancia
    fechaIniDesctosc1
    fechaIniDesctosc2
    fechaIniDesctosc3
    fechaFinDesctosc1
    fechaFinDesctosc2
    fechaFinDesctosc3
    tipoDesctoSv1
    tipoDesctoSv2
    tipoDesctoSc3
    desctoSv1
    desctoSv2
    desctoSv3
    fechaIniDesctoSv1
    fechaIniDesctoSv2
    fechaIniDesctoSv3
    fechaFinDesctoSv1
    fechaFinDesctoSv2
    fechaFinDesctoSv3
    flete
    precioListaSinIva
    precioListaConIva
    costouSinIva
    costouConIva
    precioNormalSinIva
    precioNormalConIva
    precioesp1SinIva
    precioesp2SinIva
    precioesp3SinIva
    precioesp1ConIva
    precioesp2ConIva
    precioesp3ConIva
    margenVta1
    margenVta2
    margenVta3
    margenVta4
    vntaExistCero
    cuentaContab
    maximo
    minimo
    reorden
    periodoSurtimiento
    core
    preciooMargen
    cantImprimir
    divPrecioListaxFactor
    tipoCambio
    pedimento
    loteFechaCaducidad
    margenUtilidadMinimo
    margenUtilidadMaximo
    noActualizaPrecios
    clipro
    grupo
    subgrupo
    marca
    tipoProducto
    divisa
    unidadEntrada
    unidadSalida
    pedimentoObligatorio
    tiempoEntrega
    ventaOnline
    entregaDomicilio
    IDimagen1
    nombreImagen1
    urlImagen1
    esLink1
    IDimagen2
    nombreImagen2
    urlImagen2
    esLink2
    IDimagen3
    nombreImagen3
    urlImagen3
    esLink3
    IDimagen4
    nombreImagen4
    urlImagen4
    esLink4
    mostrarPaginaPrincipal
    deseosID
    existencia
    licenciaUnica
    producto
    tipoPaquete
    plazoPaquete
    fichaTecnica
    precioVentaOnline
    precioVentaOnlineSinIva
    mostrarLeyenda
    }
}
`;


export const TABLA_PRODUCTOS = `
    query filterProducto($filtro: InputFilterProducto!) {
        filterProducto(filtro: $filtro) {
            registros
            paginas
            listado {
                productoID
                codigoProducto
                grupo
                subgrupo
                divisa
                unidadSalida
                descripcionCorta
                descripcion
                rutaImagen
                precioNormalConIva
                imagen,
                deseosID
                ventaOnline
                tiempoEntrega
                entregaDomicilio
                imagenPortada
                esLink1
                existencia
                vntaExistCero
                servicio
                precioVentaOnline
                precioVentaOnlineSinIva
                marca
                oferta
            }
        }
    }
`;

export const TABLA_PRODUCTOS_ALTERNATIVOS = `
    query filterAlternativos($filtro: InputFilterAlternativos!) {
        filterAlternativos(filtro: $filtro) {
            registros
            paginas
            listado {
                productoID
                codigoProducto
                grupo
                subgrupo
                divisa
                unidadSalida
                descripcionCorta
                descripcion
                rutaImagen
                precioNormalConIva
                imagen,
                deseosID
                ventaOnline
                tiempoEntrega
                entregaDomicilio
                imagenPortada
                esLink1
                
            }
        }
    }
`;


export const UPDATE_PRODUCTO =  `
  mutation updateProducto($input: InputUpdateProducto!) {
    updateProducto(input:$input) {
    productoID
    vntaExistCero
    ventaOnline
    tiempoEntrega
    entregaDomicilio
    IDimagen1
    nombreImagen1
    urlImagen1
    IDimagen2
    nombreImagen2
    urlImagen2
    IDimagen3
    nombreImagen3
    urlImagen3
    IDimagen4
    nombreImagen4
    urlImagen4
    }
}
`;
export const UPDATE_IMAGENES =  `
  mutation updateImagenes($input: InputUpsertImagenes!) {
    updateImagenes(input:$input) {
    productoID
    vntaExistCero
    ventaOnline
    tiempoEntrega
    entregaDomicilio
    IDimagen1
    nombreImagen1
    urlImagen1
    IDimagen2
    nombreImagen2
    urlImagen2
    IDimagen3
    nombreImagen3
    urlImagen3
    IDimagen4
    nombreImagen4
    urlImagen4
    }
}
`;


