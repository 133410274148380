// EJSA
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import CircularProgress from '@material-ui/core/CircularProgress';
import Backdrop from '@material-ui/core/Backdrop';


// core components
import GridContainer from "../../components/Grid/GridContainer.js";
import GridItem from "../../components/Grid/GridItem.js";
import Card from "../../components/Card/Card.js";
import CardBody from "../../components/Card/CardBody.js";
import CardIcon from "../../components/Card/CardIcon.js";
import CardHeader from "../../components/Card/CardHeader.js";
import Producto from './producto.js'



//lista
import List from '@material-ui/core/List';
import ListAltIcon from '@material-ui/icons/ListAlt';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';

// generic components
import Loader from "../../genericComponents/Loader";
import Notificaciones from "../../genericComponents/Notificaciones";

import  TablaLista  from "../../genericComponents/Lista/componentes/tabla";

// Componentes Custom
import {redondeo} from '../../../helpers/regexp.js';

// media querys
import useMediaQuerys from "../../genericComponents/MediaQuerys";

import { ftPaginacionGetComponentData } from "../../helpers/components";

import styles from "../../assets/jss/material-dashboard-pro-react/views/extendedListaStyle.js";
import MetodoEntregaFormulario from "./metodoEntrega.js";
import MetodoPagoFormulario from "./metodoPago.js";

const useStyles = makeStyles(styles);

export default function PedidoFormulario (props) {

    const classes = useStyles();

    let imagenDefault =  '/imagenes/cajaAbierta.jpg';

    const { datos, columns, rows,
         paqueteria,  grabarPedido,  volver, color = "info", loader = false, error = {},direccionesCliente, 
         direccionSucursal, datosPedido, totales, errorState, recibeError, recibeDatos, pagando, pageActiva} = props;

         

    const [redirigir, setRedirigir] = useState(null);
    
    const noTruncarDecimales = {maximumFractionDigits: 20};

    let container = {
        direction: "row",
        justify: "center",
        alignItems: "center"
    };
    let { isTabletOrMobile, isTabletOrMobileDevice } = useMediaQuerys();
    let responsive = isTabletOrMobile || isTabletOrMobileDevice;

    if(typeof paginacion === "object") pages = ftPaginacionGetComponentData(paginacion, paginaActiva);

    function recibeErrorPedido(errorState){
    recibeError(errorState);
    }
    function recibeDatosPedido(datos){
    recibeDatos(datos);
    }
    return (
        <>
        <Backdrop className={classes.backdrop} open={pagando} >
             { <CircularProgress size={44} align="center" className={classes.fabProgress} />}
        </Backdrop>

        <GridContainer
            direction={container.direction}
            justify={container.justify}
            alignItems={container.alignItems}
        >

            <GridItem xs={12} lg={12} md={12} className={classes.gridPedido}>
                <Card>
                    <CardBody width={200}>
                        <MetodoEntregaFormulario
                        pageActiva = {pageActiva}
                        datosPedido ={datosPedido}
                        paqueteria={paqueteria}
                        recibeDatos={recibeDatosPedido}
                        direccionesCliente={direccionesCliente}
                        direccionSucursal ={direccionSucursal}
                        errorStatePedido={errorState}
                        recibeError={recibeErrorPedido}
                        disabled = {pagando}
                    />
                    </CardBody>
                    </Card>
                    <Card width={100}>
                    <CardBody>
                    <MetodoPagoFormulario
                        datosPedido ={datosPedido}
                        totales={totales}
                        errorStatePedido={errorState}
                        recibeError={recibeErrorPedido}
                        recibeDatos={recibeDatosPedido}
                        disabled = {pagando}
                    />
                    </CardBody>
                    </Card>
            </GridItem>

            <GridItem xs={12} lg={12} md={12}>
                <Card>
                <CardBody>
                    <CardHeader color={color} icon>
                    <h4 className={classes.cardIconTitle}>Detalle de venta</h4>
                    </CardHeader>
                    {
                        loader ? <Loader /> : <>
                            {                            
                                !datos ? "No hay productos" : 
                                <Producto
                                    data={datos}
                                />                                                                   
                            }
                        </>
                    }                        
                    <br/>
                    <div className={classes.barraBotones}>
                        <div className={classes.barraBotonesAux}>
                            <Button disabled={pagando}
                            className={classes.botonVolver} onClick={() => volver()} ><ShoppingCartIcon></ShoppingCartIcon>Volver al carrito</Button>
                            <Button disabled={pagando} className={classes.botonPagar} onClick={() => grabarPedido()}>FINALIZAR COMPRA</Button>
                        </div>
                    </div>
                </CardBody>
                </Card>
            </GridItem>
        </GridContainer>


        </>
    );
}

PedidoFormulario.propTypes = {
    data: PropTypes.array.isRequired,
    hideHeader: PropTypes.bool,
    filter: PropTypes.array,
    iconTable: PropTypes.node,
    title: PropTypes.string,
    showHelp: PropTypes.bool,
    responsiveTitle: PropTypes.arrayOf(PropTypes.string),
    activeResponsive: PropTypes.number,
    alineacion: PropTypes.arrayOf(PropTypes.object),
    formato: PropTypes.arrayOf(PropTypes.object),
    paginacion: PropTypes.object,
    paginaActiva: PropTypes.number,
    color: PropTypes.oneOf([
        "warning",
        "primary",
        "danger",
        "success",
        "info"
    ]),
    rowColor: PropTypes.func,
    busqueda: PropTypes.object,
    loader: PropTypes.bool,
    error: PropTypes.object,
    handleSearch: PropTypes.func,
    handleRegistros: PropTypes.func
};
