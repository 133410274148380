import React from "react";
import '../base.css';
import './estilos.css';
import Close from '@material-ui/icons/Close';

function Modal(props) {
  let { children = [], titulo, cerrar, cModalChico = false, style, claseAux = "" } = props;

  if (!cerrar) {
    cerrar = () => console.log("No hay funcion de cerrar");
  }

  let icono = (<Close style={{ fontSize: 26, color: 'inherit' }} />);

  return (
    <div className={"cModal " + claseAux}>
      <div className={cModalChico ? "cModalAux cModalChico" : "cModalAux"}>
        <div className="cModalForm" style={style}>
          <div className="contenedorTituloModalForm">
            <div className="contenedorIconoModalForm">
              <div tabIndex={0} className="contenedorIconoModalFormAux" onClick={cerrar}>
                {icono}
              </div>
            </div>
            <div className={"tituloModalForm"}>
              {titulo}
            </div>
          </div>
          {children}
        </div>
      </div>
    </div>)
}

export default Modal;