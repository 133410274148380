
export const GET_USUARIO = `
    query getUsuarios($usuario: ID!) {
        getUsuarios(id: $usuario) {
            usuarioID
            nombre
            usuario
            password
            fechaAlta
            perfilID
            perfil
            estatus
            sexo
            generoID
            cumpleanios
            perfilDescripcion
            correo
            telefono
        }
    }
`;

export const TABLA_USUARIOS = `
query filterUsuarios($filtro:InputFilterUsuario!)  {
    filterUsuarios(filtro:$filtro){
      paginas
      registros
      listado{
        usuarioID
        nombre
        usuario
        password
        fechaAlta
        perfilID
        perfil
        estatus
        sexo
        cumpleanios
      }
    }}
`;

export const CORREO_ACTIVA_CUENTA = `
query correoActivaCuenta($usuario: ID, $dominio: String, $origin: String)  {
    correoActivaCuenta(id:$usuario, dominio: $dominio, origin: $origin)
  }
`;
