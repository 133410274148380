import React from 'react';

import FileUpload from "../../../components/CustomUpload/FileUpload.js";

import { makeStyles } from "@material-ui/core/styles";

const styles = {
  leyenda: {
  fontSize: "1em"
  }
};
const useStyles = makeStyles(styles);

export default function Archivo({ title = "", onChange = (evt) => console.log(evt), avatar, removeImage, value, disabled, sizeMax = null }) {

    let newProps = {};
    if(avatar) newProps = { avatar: true };
    newProps = { tamañoMax: sizeMax };
    const classes = useStyles();

    return (<div id="img upload" style={{ textAlign: "center", width:"100%" }}>
        <legend className={classes.leyenda}>{title}</legend>
        <FileUpload
            { ...newProps }
            removeImage={removeImage}
            onChange={onChange}
            value={value}
            disabled={disabled}
            addButtonProps={{
                color: "rose",
                round: true
            }}
            changeButtonProps={{
                color: "rose",
                round: true
            }}
            removeButtonProps={{
                color: "tumblr",
                round: true
            }}
        />
    </div>);

}
