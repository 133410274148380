import React from 'react';
import './styles.css';
import {
    model1,
    ship1,
    ship2
} from './ImagenesNosotros/imagenes';


export default function Nosotros(props){








    return(<>
    <div className = 'contenedorGeneralNosotros'>
        <div className='bannerPrincipalNosotros'>
        <img src={ship1}/>
        </div>
        <div className='textBannerPrincipalNosotros'>
            <h4>
                ¿QUIENES SOMOS?
            </h4>
        </div>

        <div className = 'textWhiteNosotros'>
            <h5>
                Somos una empresa mexicana constituida en el año 2004 con enfoque de comercialización internacional de productos mexicanos e importados.
            </h5>
        </div>


        <div className='bannersNosotros'>
            <div className='contenedorImagenBanner'>
                <img src={model1} />
            </div>
            <div className='textBannerNosotros'>
                    <div className='headerTextBannerNosotros'>
                        <h2>
                            NUESTRA MISIÓN
                        </h2>
                    </div>
                    <div className='bodyTextBannerNosotros'>
                        <h2>
                        Brindar soluciones prácticas y servir con pasión a nuestros clientes.
                        </h2>
                    </div>
                </div>
        </div>


        <div className='bannersNosotros'>
            <div className='contenedorImagenBanner'>
                <img src={ship2} />
            </div>
            <div className='textBannerNosotros'>
                    <div className='headerTextBannerNosotros'>
                        <h2>
                            NUESTRA VISIÓN
                        </h2>
                    </div>
                    <div className='bodyTextBannerNosotros'>
                        <h2>
                        Ser reconocidos por nuestros clientes como proveedor de soluciones.
                        </h2>
                    </div>
                </div>
        </div>
    </div>
    </>)
}