import React from 'react'

import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder';
import FavoriteOutlinedIcon from '@material-ui/icons/FavoriteOutlined';

import './styles.css'

import { redondeo, toFixed } from "../../../helpers/regexp.js";


const CardProducto = ({element,handleViewProducto,agregarListaDeseos}) => {
    
    const noTruncarDecimales = { maximumFractionDigits: 20,  style: 'currency', currency: 'MXN' };
    let precio = parseFloat(toFixed(element.precioVentaOnline, 2));
    precio = redondeo(precio, 2);
    precio = parseFloat(precio).toLocaleString("es-MX", noTruncarDecimales);
    precio = `${precio}`;


  return (
    <>
        <div className="cardProductos">
            
            <div className="contenedorImagenPortadaProducto" id='contenImg'>
                <img src={element.imagenPortada} onClick={()=> handleViewProducto(element.productoID)}/>
                <p id='precio'> { precio } </p>
                
            </div>
            <div className="descripcionProducto">
                {element.descripcionCorta}
            </div>
            <div
                className="buttonProducto"
                onClick={() => {
                    handleViewProducto(element.productoID);
                }}
            >
                Agregar al carrito
            </div>
            <div id="heart" className="favButton"
            onClick={() => agregarListaDeseos({ productoID: element.productoID, deseosID: element.deseosID })} 
            >
            {element.deseosID ?
                            <FavoriteOutlinedIcon style={{ fontSize: 'inherit', color: "var(--primaryColor)", width: '25px', height: '25px' }} /> :
                            <FavoriteBorderIcon style={{ fontSize: 'inherit', color: "var(--primaryColor)", width: '25px', height: '25px'  }} />}
            </div>
        </div>
    </>
  )
}



export default CardProducto;