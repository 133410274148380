import { FetchAPI } from "@renedelangel/helpers";

import { urlServidor } from '../../helpers';

export const enviarCorreoContacto = async (variables, token) => {
    let Query = `
    query enviarCorreoContacto($input: String!) {
        enviarCorreoContacto(input: $input)
    }
    `;

    let tResult = await Ejecutar({input: JSON.stringify(variables)}, token, Query);

    return tResult;
}

export const Ejecutar = async (variables, token, query) => {
    try {
        let Respuesta = await FetchAPI.graphql({
            token,
            variables,
            url: urlServidor,
            query
        });
        return Respuesta[Object.keys(Respuesta)[0]];
    } catch (error) {
        throw new Error(error.message);
    }
}