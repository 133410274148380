
export const IS_TOKEN = ` query isToken { isToken } `;

export const IS_USERNAME = `
    query isUsername($username: String!) {
        isUsername(username: $username)
    }
`;

export const GET_LOGIN = `
    query getLogin($username: String!, $password: String!, $dominio: String!) {
        getLogin(username: $username, password: $password, dominio: $dominio)
    }
`;

export const GET_NEWPASSWORD = `
    query getNewPassword($username: String!, $dominio: String!, $href: String!){
        getNewPassword(username: $username, dominio: $dominio, href: $href)
    }
`;


