import React, { useState, useEffect } from 'react';
import { Route, Switch, Redirect, useHistory } from 'react-router-dom';
import {  Encriptar } from '@renedelangel/helpers';

// Redux
import { useDispatch,useSelector } from 'react-redux';
import { setBusqueda } from '../../redux/actions/busquedaAction';

// hooks
import { useTitle } from '../../hooks/title';
import Login from '../Login';
import Modal from '../../_layout/genericComponents/Modal';


//generic components
import SweetAlert from '../../_layout/genericComponents/ModalConfirmacion';
import CardFilter from '../../_layout/masterComponents/CardFilter'
import { NetworkError } from '../../_layout/genericComponents/Metodos'

// material-ui icons
import AddShoppingCartIcon from '@material-ui/icons/AddShoppingCart';
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder';
import VisibilityIcon from '@material-ui/icons/Visibility';
import ShoppingBasketIcon from '@material-ui/icons/ShoppingBasket';
import FavoriteOutlinedIcon from '@material-ui/icons/FavoriteOutlined';

import { tablaProductos, getProducto, listadoProductos} from "../../querys/Productos/metodos";

import { upsertDeseos } from "../../mutations/Deseos/metodos";
import { selectGrupoERP,selectSubGrupoERP } from "../../querys/Departamentos/metodos";

import { success, info, danger } from "../../_layout/helpers/colores";
import { handleModificar } from "../../_layout/helpers/handles";
import { useEnumPerfil } from "../../hooks/enums";
import { handleGuardar } from '../../_layout/helpers/handles';
import { useParams } from "react-router-dom";
import { useLocation } from "react-router-dom";
import TablaProductos from '../../_layout/NuevosComponentes/TablaProductos'
import PopUpLogin from '_layout/NuevosComponentes/PopUpLogin';
const {Encrypt,Decrypt}=Encriptar;

function Productos() {
    let {state} = useLocation();
    let grupoParam = "";
    let subgrupoParam = ""
    if(state){
        grupoParam = state.grupoParam ? state.grupoParam : "";
    }
    // useTitle(`Listado de productos - ${process.env.REACT_APP_TIENDA_NAME}`);
    const { busqueda } = useSelector(state => state.busqueda);
    let tokenFijo = 'c29mdHdvbmUyMDIwQCM=';

    const { usuario, token } = useSelector(state => state.login);
    const { nombre, perfilID } = usuario ? usuario : {};
    const dispatch = useDispatch();
    const cleanOpenOpciones = { open: false, formulario: "", descripcion: "" };

    const [filtro, setFiltro] = useState({});
    const [alert, setAlert] = useState(null);
    const [redirigir, setRedirigir] = useState(null);
    const [openOpciones, setOpenOpciones] = useState(cleanOpenOpciones);
    const [actualizar, setActualizar] = useState(false);
    const [actualizarCard, setActualizarCard] = useState({id:0, like: 0});
    const [gruposSelec, setGruposSelec] = useState([]);

    const infoSeccion = {
        title: "Productos",
        icon: <ShoppingBasketIcon />,
        actualizar,
        actualizarCard,
        leyenda: "Disponibles",
        leyenda2: "Disponible",
        props: {
            id: "productoID",
            title: "descripcionCorta",
            descripcion: "descripcion",
            imagen: "imagenPortada",
            textFooterLeft: "precioVentaOnline",
            deseosID: "deseosID",
            valor1: "existencia",
            valor2: "vntaExistCero"
        },
        actions: [
            /*{
                idIcono: 0,
                color: info,
                descripcion: "Detalles",
                icono: VisibilityIcon,
                onClick:(datos) => direccionar(datos)
            },*/
            {
                idIcono:1,
                color: danger,
                descripcion: "Agregar a lista de deseos",
                icono: {"id":FavoriteOutlinedIcon ,"sin": FavoriteBorderIcon},
               // onChange: ({target:{icono}}) => handleChange(icono, "comprador"),
                onClick:(datos) => agregarListaDeseos(datos),
            }
        ],
        filtro: {
            gruposIDs: {
                title: "Departamentos",
                data: filtro.grupos
            } ,

            subgruposIDs: {
                title: "Categorías",
                data: filtro.subGrupos
            }

        },
        busqueda: { busqueda },
        formato: [{ columnas: ["precioVentaOnline"], tipo: "moneda" }],
        msjSinRegistros: "Al parecer no hemos encontrado ningún producto con las características que estás buscando",
        grupoInicial: grupoParam,
        subgrupoInicial: subgrupoParam
    };

    const getRoutes = (routes) => {
		return routes.map((prop, key) => {
			return <Route path={prop.path} component={prop.component} key={key} />;
		});
    };

    function getRederigir(url = "/") {

        return (<><Switch><Redirect from="*" to={url} /></Switch></>);
    }
    function direccionar(datos)
    {
        let seed = "softwone";
        const parametros = `{"id": "${datos.id}"}`;
        let url = Encrypt({data: parametros, seed}).replace(/\//g, "&")
        setRedirigir(getRederigir(`producto/${url}`))
    }

    function agregarListaDeseos(datos){
    let {deseosID, id} = datos;

        if (usuario == null)
        {
            setOpenOpciones(openOpciones => ({
                    ...openOpciones,
                    open: !openOpciones.open,
                    formulario: "login",
                    descripcion: "Iniciar sesión"
            }));
        }

    }

    function ftEffect() {
        async function effect() {
            try { 
                let gruposSeleccionados = null;
                const grupos = await selectGrupoERP({}, tokenFijo);
                if(grupoParam){                        
                    gruposSeleccionados = grupoParam;                       
                } 
                const subGrupos = await selectSubGrupoERP({grupoID:null, gruposIDS: gruposSeleccionados}, tokenFijo);
                setFiltro(filtro => ({ ...filtro, grupos,subGrupos  }));
            } catch({ message }) {
                setAlert({
                    descripcion: `Algunos datos necesarios para funcionar no se cargaron correctamente, intenta actualizar la página o verifica tu conexión a internet; a continuación se muestrán más detalles del error: ${NetworkError(message)}`,
                    title: "¡Una disculpa!",
                    tipo: danger,
                    msjConfirmacion: "Aceptar",
                    onConfirm: () => setAlert(null)
                });
            }
        } effect();
    }
    useEffect(ftEffect, []);

    useEffect(() => {

        setBusqueda(window.location.search.replace(/^(.+)?[\?\&]q=([^&]+)(&?.*)$/g, "$2") ? window.location.search.replace(/^(.+)?[\?\&]q=([^&]+)(&?.*)$/g, "$2") : null);

    }, [window.location.href]);    

    function ftEffectGrupos() {
        async function effect() {
            try {               
                let gruposSeleccionados = null;
                if(gruposSelec ){
                    if(gruposSelec.length >0){                        
                        gruposSeleccionados = gruposSelec;                       
                    }                                          
                }
                const subGrupos = await selectSubGrupoERP({grupoID:null, gruposIDS: gruposSeleccionados}, tokenFijo);                       
                setFiltro(filtro => ({ ...filtro, subGrupos  }));                                                      
            } catch ({ message }) {
                setAlert({
                    descripcion: `Algunos datos necesarios para funcionar no se cargaron correctamente, intenta actualizar la página o verifica tu conexión a internet; a continuación se muestrán más detalles del error: ${NetworkError(message)}`,
                    title: "¡Una disculpa!",
                    tipo: danger,
                    msjConfirmacion: "Aceptar",
                    onConfirm: () => setAlert(null)
                });
            }
        } effect();
    }
    useEffect(ftEffectGrupos, [gruposSelec]);

    return (<>
        { redirigir }

        <TablaProductos 
        mostrarFiltros={true}
        asyncData={tablaProductos}
        infoSeccion={infoSeccion}
        token={token}
        parametrosFijos={{ventaOnline: 1,dominio: window.location.hostname, conImagenes: 1}}
        actualizar={actualizar}
        gruposIDS={(datos) => setGruposSelec(datos)}
        flechasCambioPagina={true}
        />
        
        <PopUpLogin 
            openOpciones={openOpciones}
            setOpenOpciones={setOpenOpciones}
            />
        { alert && <SweetAlert
            title={alert.title}
            descripcion={alert.descripcion}
            tipo={alert.tipo}
            msjConfirmacion={alert.msjConfirmacion}
            msjCancelacion={alert.msjCancelacion}
            onConfirm={alert.onConfirm}
            showConfirm={alert.showConfirm}
            showCancel={alert.showCancel}
            onCancel={() => setAlert(null)}
        /> }

        
    </>);
}

export default Productos;
