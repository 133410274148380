import { FetchAPI } from "@renedelangel/helpers";

import { urlServidor } from '../../helpers';

export const TABLA_REEMBOLSOS = `
        query filterReembolsos($filtro: InputFilterReembolsos){
            filterReembolsos(filtro: $filtro){
            registros
            paginas
            listado{               
                ventaID
                reembolsoID
                usuario
                fechaCompra
                totalCompra
                motivoReembolso
                razonReembolso
                estatusReembolso
                estatusPedido             
                fechaSolucion                         
             }
            }
        }`;


export const getReembolso = async (variables, token) => {

    let query = `query getReembolso($reembolsoID: ID!){
        getReembolso(id: $reembolsoID){              
            ventaID
            reembolsoID
            usuario
            fechaCompra
            totalCompra
            motivoReembolso
            razonReembolso
            estatusReembolso
            tipoSolucion
            solucionReembolso
            fechaAlta
            fechaSolucion
                  
                                    
            }
        }`;
    return await ejecutar(variables, token, query);
}


export const tablaReembolsos = async (variables, token) => {
    try {
        let { filterReembolsos } = await FetchAPI.graphql({
            token,
            variables,
            url: urlServidor,
            query: TABLA_REEMBOLSOS
        });
        return filterReembolsos;
    } catch ({ message }) { throw new Error(message); }
}

export const ejecutar = async (variables, token, query) => {
    try {
    
        let  respuesta  = await FetchAPI.graphql({
            token,
            variables,
            url: urlServidor,
            query: query
        });     
        return respuesta[Object.keys(respuesta)[0]];

    } catch({message}) { throw new Error(message); }
}
