import { SET_TOKEN, SET_USUARIO } from '../../types';

export default function(state = null, action) {
    switch (action.type) {
        case SET_TOKEN:
            return { ...state, token: action.payload };
        case SET_USUARIO:
            return { ...state, usuario: action.payload };
        default:
            return state;
    }
}
