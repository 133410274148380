import React from 'react';
import PropTypes from "prop-types";

// core components
import Button from "../../../components/CustomButtons/Button.js";

// material-ui components
import Tooltip from '@material-ui/core/Tooltip';

// estilos
import { makeStyles } from "@material-ui/core/styles";
import styles from "../../../assets/jss/material-dashboard-pro-react/views/extendedTablesStyle.js";
const useStyles = makeStyles(styles);

export default function MiniBoton (props) {

    let { color = "primary", onClick = (evt) => console.log(evt), descripcion, disabled = false, inputRef } = props;
    let newDescripcion = descripcion ? descripcion : "";

    const classes = useStyles();

    let none = () => { return; };
    return (<Tooltip
        title={newDescripcion}
        arial-label={newDescripcion}
    >
        <Button
            round
            color={color}
            className={classes.actionButton + " " + classes.actionButtonRound}
            onClick={disabled ? none : onClick}
            disabled={disabled}
            ref={inputRef}
        >
            <props.Icono className={classes.icon} />
        </Button>
    </Tooltip>);

}

MiniBoton.propTypes = {
    color: PropTypes.oneOf([
        "primary",
        "info",
        "success",
        "warning",
        "danger",
        "rose",
        "white",
        "twitter",
        "facebook",
        "google",
        "linkedin",
        "pinterest",
        "youtube",
        "tumblr",
        "github",
        "behance",
        "dribbble",
        "reddit",
        "transparent"
    ]),
    descripcion: PropTypes.string,
    Icono: PropTypes.elementType.isRequired,
    onClick: PropTypes.func
}
