import { FetchAPI } from "@renedelangel/helpers";

import { urlServidor } from '../../../helpers';

import { DELETE_POST} from '../index';


export const deletePost = async (variables, token) => {
    try {

        let { deletePost } = await FetchAPI.graphql({
            token,
            variables,
            url: urlServidor,
            query: DELETE_POST
        });

        return deletePost;

    } catch({message}) { throw new Error(message); }

};

