import { FetchAPI } from "@renedelangel/helpers";
import { urlServidor, strUrlSArchivos } from '../../../helpers';
import { GET_PRODUCTO, TABLA_PRODUCTOS,UPDATE_PRODUCTO, UPDATE_IMAGENES, TABLA_PRODUCTOS_ALTERNATIVOS} from '../index';
import noImage from '../../../_layout/assets/svg/undraw_blank_canvas_3rbb.svg';

export const getProducto = async (variables, token) => {
    try {
        let dominio =  window.location.hostname
        variables = {...variables, dominio: dominio};
        let { getProducto } = await FetchAPI.graphql({
            token,
            variables,
            url: urlServidor,
            query: GET_PRODUCTO
        });

        let foto1 = getProducto.esLink1 == 0 ? getProducto.urlImagen1 ? `${strUrlSArchivos}${'/public/tonline'}${getProducto.urlImagen1}${'?'}${Math.random()}` : ""  : getProducto.urlImagen1;

        getProducto["urlImagen1"] = foto1;

        let foto2 = getProducto.esLink2 == 0 ?  getProducto.urlImagen2 ? `${strUrlSArchivos}${'/public/tonline'}${getProducto.urlImagen2}${'?'}${Math.random()}` : "" : getProducto.urlImagen2;
        getProducto["urlImagen2"] = foto2;

        let foto3 = getProducto.esLink3 == 0?  getProducto.urlImagen3 ? `${strUrlSArchivos}${'/public/tonline'}${getProducto.urlImagen3}${'?'}${Math.random()}` : "" : getProducto.urlImagen3;
        getProducto["urlImagen3"] = foto3;

        let foto4 = getProducto.esLink4 == 0 ? getProducto.urlImagen4 ?  `${strUrlSArchivos}${'/public/tonline'}${getProducto.urlImagen4}${'?'}${Math.random()}` : "" : getProducto.urlImagen4;
        getProducto["urlImagen4"] = foto4;

        return getProducto;

    } catch({message}) { throw new Error(message); }
}

export const updateProducto  = async (variables, token) => {
    try {
        let { updateProducto } = await FetchAPI.graphql({
            token,
            variables,
            url: urlServidor,
            query: UPDATE_PRODUCTO
        });

        return updateProducto;

    } catch({message}) { throw new Error(message); }
}
export const updateImagenes  = async (variables, token) => {
    try {
        let { updateImagenes } = await FetchAPI.graphql({
            token,
            variables,
            url: urlServidor,
            query: UPDATE_IMAGENES
        });

        return updateImagenes;

    } catch({message}) { throw new Error(message); }
}

export const tablaProductos = async (variables, token) => {
    try {   
        let {filterProducto } = await FetchAPI.graphql({
            token,
            variables,
            url: urlServidor,
            query: TABLA_PRODUCTOS
        });

        let listado = filterProducto.listado;
        let listado2 = listado.map(({ imagenPortada, esLink1, ...rest }) => {
                let imagen =  esLink1 ? imagenPortada : `${strUrlSArchivos}${'/public/tonline'}${imagenPortada}`;
                let portada = imagenPortada ? imagen : noImage;
                return {
                    ...rest,
                    imagenPortada: portada
                }
            })
        filterProducto.listado = listado2;
        
        return filterProducto;

    } catch({message}) {
        throw new Error(message);
    }
}
export const tablaProductosAlternativos = async (variables, token) => {
    try {
        let {filterAlternativos } = await FetchAPI.graphql({
            token,
            variables,
            url: urlServidor,
            query: TABLA_PRODUCTOS_ALTERNATIVOS
        });
        let listado = filterAlternativos.listado;
        let listado2 = listado.map(({ imagenPortada,  ...rest }) => {
                let imagen =  imagenPortada ? `${strUrlSArchivos}${'/public/tonline'}${imagenPortada}` : noImage;

                return {
                    ...rest,
                    imagenPortada: imagen
                }
            })
        filterAlternativos.listado = listado2;
        return filterAlternativos;

    } catch({message}) { throw new Error(message); }
}
export const listadoProductos  = async (variables, token) => {
    try {
        let productos = await tablaProductos(variables, token);

        if(!Array.isArray(productos) || productos.length === 0) throw new Error("No se encontraron resultados");
    
        return productos;

    } catch({message:label}) {
        return [{
            label: label,
            value: null,
            disabled: true
        }]
    }
}
