import React, { useState, useEffect } from "react";
import "./styles.css";
import LoaderProcesandoPago from "../LoaderProcesandoPago/index";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import DireccionEntrega from "./DireccionEntrega";
import InformacionPago from "./InformacionPago";
import { redondeo } from "helpers/regexp";
import {
    arrowLeft,
    arrowRight,
    vectorArrowR,
    vectorArrowL,
    vectorSaveIcon
} from '../../../_layout/assets/svg/exportsvg'
import { toFixed } from "helpers/regexp";

export default function FormularioPedidoNuevo(props) {
    let {
        datos,
        pageActiva,
        paqueteria,
        datosPedido,
        recibeDatos,
        totales,
        grabarPedido,
        volver,
        errorState,
        recibeError,
        propsSetAlert
    } = props;

    const [previewCarrito, setPreviewCarrito] = useState([]);
    const [stateDireccion, setStateDireccion] = useState(cleanStateDireccion);
    const [continuarCompra, setContinuarCompra] = useState(true);
    const noTruncarDecimales = {maximumFractionDigits: 20,  minimumFractionDigits: 2};
    const [direccionSuc, setDireccionSuc] = useState();
    const [tarjetaSeleccionada, setTarjetaSeleccionada] = useState(false);

    const history = useHistory();

    const cleanStateDireccion = {
        calleNumero: "",
        colonia: "",
        estado: "",
        ciudad: "",
        cp: ""
    }

    const [totalesFormateados, setTotalesFormateados] = useState({
        subtotal: '',
        envio: '',
        descuento: ''
    })

    const [aplicaEnvio, setAplicaEnvio] = useState(false);


    //arreglar aqui el error
    useEffect(() => {
        async function ftValidarEnvio() {
            
            datos?.map(element =>{
                if(element.servicio == 0){
                    setAplicaEnvio(true)
                    setContinuarCompra(false);
                }
            }
            )
        }
        ftValidarEnvio()
    }, [datos])

    useEffect(() =>{
        async function ftRedondearTotales() {

            let subtotal = redondeo(totales.subtotal, 1);
            let envio = redondeo(totales.envio, 1);
            let total = redondeo(totales.total, 1);
            setTotalesFormateados({...totalesFormateados, subtotal, envio, total})

        }
        ftRedondearTotales();
    }, [totales])



    useEffect(() => {
        async function ftEffect() {
            if (datos?.length > 0) {
                let aux = datos.map(element => {
                    let subtotal = Number(parseFloat(toFixed(element.precioVentaOnline, 2))) * Number(element.cantidad);
                        subtotal = subtotal.toLocaleString('es-MX', {style: 'currency', currency:"MXN" });

                                let precioformato = redondeo(element.precioVentaOnline,2)
                                precioformato = precioformato.toLocaleString('es-MX', {style: 'currency', currency:"MXN" });
                    return (
                        <>
                            <div className="c_each_preview_carrito_pedido">
                                <div className="c_carrito_pedido_imagen">
                                    <img src={element.imagenPortada} />
                                </div>
                                <div className="c_carrito_nombre_producto">
                                    <h6>{element.nombre}</h6>
                                </div>
                                <div className="c_carrito_subtotal">
                                    <h6>{subtotal}</h6>
                                </div>
                            </div>
                        </>
                    );
                });

                setPreviewCarrito(aux);
            }
        }
        ftEffect();
    }, [datos]);

    useEffect(() => {
        async function scrollTop(){
            window.scrollTo(0, 0);
        }
        scrollTop()
    }, [continuarCompra])

    return (
        <>
            <div className="c_general_formualario_pedido_nuevo">
                <div className="c_interno_formulario_pedido_nuevo">
                    {!continuarCompra && aplicaEnvio ? (
                        <DireccionEntrega
                            continuarCompra={continuarCompra}
                            setContinuarCompra={setContinuarCompra}
                            datos= {datos}
                            pageActiva= {pageActiva}
                            paqueteria= {paqueteria}
                            datosPedido= {datosPedido}
                            recibeDatos= {recibeDatos}
                            totales= {totales}
                            grabarPedido= {grabarPedido}
                            volver= {volver}
                            errorState= {errorState}
                            recibeError= {recibeError}
                            propsSetDireccionSuc = {setDireccionSuc}
                            propsSetAlert = {propsSetAlert}
                        />
                    ) : (
                        <InformacionPago
                            continuarCompra={continuarCompra}
                            setContinuarCompra={setContinuarCompra}
                            datos= {datos}
                            pageActiva= {pageActiva}
                            paqueteria= {paqueteria}
                            datosPedido= {datosPedido}
                            recibeDatos= {recibeDatos}
                            totales= {totales}
                            grabarPedido= {grabarPedido}
                            volver= {volver}
                            errorState= {errorState}
                            recibeError= {recibeError}
                            aplicaEnvio = {aplicaEnvio}
                            propsDireccionSuc = {direccionSuc}
                            propsSetAlert = {propsSetAlert}
                            propsSetSeleccionarTarjeta = {setTarjetaSeleccionada}
                        />
                    )}

                    <div className="division"></div>

                    <div className="c_general_preview_carrito_pedido">
                        {previewCarrito}
                        <div className="c_informacion_totales">
                            <h6>Subtotal</h6> <h6>{parseFloat(totalesFormateados.subtotal).toLocaleString('es-MX', {style: 'currency', currency:"MXN" })}</h6>
                            <h6>Envío</h6> <h6>{parseFloat(totalesFormateados.envio).toLocaleString('es-MX', {style: 'currency', currency:"MXN" })}</h6>
                            <h6>Descuento</h6> <h6>${'0.00'}</h6>
                        </div>
                        <div className="separador"></div>
                        <div className="c_total_pedido">
                            <h6>Total</h6> <h6>{parseFloat(totalesFormateados.total).toLocaleString('es-MX', {style: 'currency', currency:"MXN" })}</h6>
                        </div>
                        <div className="c_button_pagar_formulario_pedido" style={{display: continuarCompra && tarjetaSeleccionada ? 'flex' : 'none'}} onClick= {() => {grabarPedido()}}>
                            <div className="button_pagar_formulario_pedido">
                                <h6>PAGAR</h6>
                                {vectorArrowR}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
