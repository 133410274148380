export const GET_DIRECCION = `
query getDireccion($direccionID:ID!) {
    getDireccion(id:$direccionID){
      direccionID
      usuarioID
      nombreDireccion
      nombreResponsable
      pais
      codigoPostal
      codigoPostalID
      estado
      municipio
      ciudad
      zona
      tipoAsentamiento
      asentamiento
      calle
      numeroExterior
      numeroInterior
      edificio
      entreCalle
      yCalle
      celular
      telefonoParticular
      telefonoOficina
      tipoDireccion
      direccionFormateada
      cambioTipo
    }
  }
`;

export const TABLA_DIRECCION = `
query filterDireccion($filtro: InputFilterDireccion!)  {
    filterDireccion(filtro:$filtro) {
      paginas
      registros
      listado{
        direccionID
        usuarioID
        nombreDireccion
        nombreResponsable
        direccionFormateada
        pais
        codigoPostal
        codigoPostalID
        estado
        municipio
        ciudad
        zona
        tipoAsentamiento
        asentamiento
        calle
        numeroExterior
        numeroInterior
        edificio
        entreCalle
        yCalle
        celular
        telefonoParticular
        telefonoOficina
        tipoDireccion
        cambioTipo
      }
    }
  }
`;

export const TABLA_DIRECCION_SUCURSAL = `
query filterDireccionSucursal($filtro: InputFilterSucursal!)  {
    filterDireccionSucursal(filtro:$filtro) {
      paginas
      registros
      listado{        
        direccionFormateada
        pais
        codigoPostal
        estado
        municipio           
        asentamiento
        calle
        numeroExterior
        numeroInterior       
      }
    }
  }
`;

export const GET_CODIGOPOSTAL = `
query getCodigoPostal($cp: String!) {
    getCodigoPostal(cp:$cp) {
        codigoPostalID
        colonias {
            idAsentamientoCpcons
            dTipoAsentamiento
            dAsentamiento
          }
          dMunicipio
          cMunicipio
          cEstado
          dEstado
          dZona
          dCiudad
          cTipoAsentamiento

        }
      }
`;

export const GET_TIPODIRECCION = `
query getTipoDireccion ($tipodireccion: ID )  {
    getTipoDireccion(id:$tipodireccion) {
      tipoID
      descripcion
    }
  }
  `;
