import React, { useState, useRef } from 'react';
import { Validaciones } from '@renedelangel/helpers';
import './estilos.css';

// generic components
import SweetAlert from '../../_layout/genericComponents/ModalConfirmacion';
import Notifications from '../../_layout/genericComponents/Notificaciones';
import { handleFocus, handleErrorInputText } from '../../_layout/helpers/handles';
import Input from '../../_layout/NuevosComponentes/Input';
import Boton from '../../_layout/NuevosComponentes/Boton';

// material-ui icons
import { EnumPerfil } from '../../enums';
import { registrarUsuario } from '../../mutations/Usuario/metodos';
import { rgxCorreo, rgxPassword, rgxNombre, rgxPhoneNumber } from '../../helpers/regexp';
import PersonIcon from '@mui/icons-material/Person';
import LockIcon from '@mui/icons-material/Lock';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import LoaderLogoSoftwone2 from '_layout/assets/svg/Animaciones/logoSoftwone2';

const { trim } = Validaciones;

function Registro({ onClose }) {


    const regexpCorreo = /^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/;


    const cleanState = { nombre: "", user: "", username: "", telefono: "", password: "", confirmPassword: "" };
    const cleanErrorState = {
        nombre: { error: false, helperText: "" },
        user: { error: false, helperText: "" },
        username: { error: false, helperText: "" },
        telefono: { error: false, helperText: "" },
        password: { error: false, helperText: "" },
        confirmPassword: { error: false, helperText: "" }
    };

    const cleanNotificaciones = { mensaje: "", color: null, open: false };
    const [notificaciones, setNotificaciones] = useState(cleanNotificaciones);
    const [state, setState] = useState(cleanState);
    const [errorState, setErrorState] = useState(cleanErrorState);
    const [disabled, setDisabled] = useState(false);
    const [loader, setLoader] = useState(false);
    const [alert, setAlert] = useState(null);

    const grid = { xs: 12, sm: 12, md: 5, lg: 4 };

    const nombreRef = useRef(null);
    const userRef = useRef(null);
    const usernameRef = useRef(null);
    const telefonoRef = useRef(null);
    const passwordRef = useRef(null);
    const confirmPasswordRef = useRef(null);

    function ftErrorInputText({ condicion, ref, keyError, mensajeError }) {
        return handleErrorInputText({
            cleanErrorState, condicion, ref, keyError, mensajeError,
            loader: setLoader, disabled: setDisabled, errorState: setErrorState
        });
    }

    function handleValidaciones({ nombre, user, username, password, confirmPassword, telefono }) {

        let error;
        let validaciones = [{
            condicion: !rgxNombre.test(nombre) || trim(nombre) === "",
            ref: nombreRef, keyError: "nombre",
            mensajeError: "Captura un nombre válido"
        }, {
            condicion: !rgxNombre.test(nombre) || trim(nombre) === "",
            ref: nombreRef, keyError: "nombre",
            mensajeError: "Captura un nombre válido"
        },
        {
            condicion: user == null || user == '',
            ref: userRef,
            keyError: 'user',
            mensajeError: "Debe indicar un usuario"
        },
        {
            condicion: user.length < 8,
            ref: userRef,
            keyError: 'user',
            mensajeError: "El usuario debe ser de por lo menos 8 caracteres"
        }, {
            condicion: !regexpCorreo.test(username) || trim(username) === "",
            ref: usernameRef, keyError: "username",
            mensajeError: "Captura un correo electrónico válido"
        }, {
            condicion: !rgxPhoneNumber.test(telefono) || trim(telefono) === "",
            ref: usernameRef, keyError: "telefono",
            mensajeError: "Capture un télefono válido"
        }, {
            condicion: !rgxPassword.test(password) || trim(password) === "",
            ref: passwordRef, keyError: "password",
            mensajeError: "Captura una contraseña válida, debe ser de por lo menos 8 caracteres, contener una mayúscula, un símbolo y/o un número."
        }, {
            condicion: !rgxPassword.test(confirmPassword) || trim(confirmPassword) === "" || confirmPassword !== password,
            ref: confirmPasswordRef, keyError: "confirmPassword",
            mensajeError: "Para que podamos funcionar bien necesitamos que tus contraseñas coincidan"
        }];

        validaciones.forEach(({ condicion, ref, keyError, mensajeError }) => {
            //if (error) return;
            error = ftErrorInputText({ condicion, ref, keyError, mensajeError });
        });

        if (error) return error;

    }

    function ftRegistrar() {
        async function ftRegistrar() {
            setLoader(true);
            try {
                const ep = await EnumPerfil();
                let { nombre, user, username, password, telefono } = state;
                let error = await handleValidaciones({ ...state });

                if (error){ 
                    setLoader(false);
                    return error;}

                await registrarUsuario({
                    input: {
                        nombre,
                        user,
                        usuario: username,
                        password,
                        perfilID: ep.USUARIO.getValue(),
                        estatus: false,
                        dominio: window.location.hostname,
                        href: window.location.origin,
                        telefono
                    }
                });

                setLoader(false);
                setDisabled(true);
                setNotificaciones(
                    {
                        mensaje: ` El registro se realizó correctamente, favor de revisar su correo para poder confirmar que es un correo válido.
                                    El enlace solo estará disponible por media hora, si no lo encuentra en la bandeja de entrada; revisar el spam.`, color: 'success', open: true
                    });
                setTimeout(() => { setNotificaciones(cleanNotificaciones); onClose() }, 3000);
            } catch ({ message }) {
                setLoader(false);
                setNotificaciones({ mensaje: message, color: 'danger', open: true })
            }
        }
        return ftRegistrar();
    }

    return (
        <div className="cContenedorRegistro">
            {
                notificaciones.open && <div className="cContenedorNotificaciones">
                    <Notifications
                        close={true}
                        message={notificaciones.mensaje}
                        color={notificaciones.color}
                        open={notificaciones.open}
                        closeNotification={() => setNotificaciones(cleanNotificaciones)} />
                </div>
            }
            <div className="cInputsRegistro">
                <Input
                    claseAuxInput="cInputRegistro"
                    placeholder="Nombre completo*"
                    icono={<PersonIcon style={{ display: "flex", margin: "auto", color: "var(--letraPrimaryColor)", fontSize: "inherit" }} />}
                    posIcono="left"
                    onChange={({ target: { value: nombre } }) => setState(state => ({ ...state, nombre }))}
                    error={errorState.nombre.error ? errorState.nombre.helperText : ""}
                    onKeyDown={(evt) => handleFocus(evt, nombreRef)} />
                <Input
                    claseAuxInput="cInputRegistro"
                    placeholder="Usuario*"
                    icono={<PersonIcon style={{ display: "flex", margin: "auto", color: "var(--letraPrimaryColor)", fontSize: "inherit" }} />}
                    posIcono="left"
                    onChange={({ target: { value: user } }) => setState(state => ({ ...state, user }))}
                    error={errorState.user.error ? errorState.user.helperText : ""}
                    onKeyDown={(evt) => handleFocus(evt, userRef)} />
                <Input
                    claseAuxInput="cInputRegistro"
                    placeholder="Correo electrónico*"
                    error={errorState.username.error ? errorState.username.helperText : ""}
                    icono={<MailOutlineIcon style={{ display: "flex", margin: "auto", color: "var(--letraPrimaryColor)", fontSize: "inherit" }} />}
                    posIcono="left"
                    onChange={({ target: { value: username } }) => setState(state => ({ ...state, username }))}
                    onKeyDown={(evt) => handleFocus(evt, usernameRef)} />
                <Input
                    claseAuxInput="cInputRegistro"
                    placeholder="Teléfono*"
                    error={errorState.telefono.error ? errorState.telefono.helperText : ""}
                    icono={<LocalPhoneIcon style={{ display: "flex", margin: "auto", color: "var(--letraPrimaryColor)", fontSize: "inherit" }} />}
                    posIcono="left"
                    onChange={({ target: { value: telefono } }) => setState(state => ({ ...state, telefono }))}
                    onKeyDown={(evt) => handleFocus(evt, telefonoRef)} />
                <Input
                    claseAuxInput="cInputRegistro"
                    placeholder="Contraseña*"
                    error={errorState.password.error ? errorState.password.helperText : ""}
                    icono={<LockIcon style={{ display: "flex", margin: "auto", color: "var(--letraPrimaryColor)", fontSize: "inherit" }} />}
                    posIcono="left"
                    password={true}
                    onChange={({ target: { value: password } }) => setState(state => ({ ...state, password }))}
                    onKeyDown={(evt) => handleFocus(evt, confirmPasswordRef)} />
                <Input
                    claseAuxInput="cInputRegistro"
                    placeholder="Confirmar contraseña*"
                    error={errorState.confirmPassword.error ? errorState.confirmPassword.helperText : ""}
                    icono={<LockIcon style={{ display: "flex", margin: "auto", color: "var(--letraPrimaryColor)", fontSize: "inherit" }} />}
                    posIcono="left"
                    password={true}
                    onChange={({ target: { value: confirmPassword } }) => setState(state => ({ ...state, confirmPassword }))}
                    onKeyDown={(evt) => handleFocus(evt, passwordRef)} />
            </div>
            <div className="cContenedorBotonesRegistro">
                <Boton disabled={disabled} titulo="Registrarse" colorFondo="var(--secondaryColor)" onClick={() => ftRegistrar()} />
            </div>
            {
                alert && <SweetAlert
                    title={alert.title}
                    descripcion={alert.descripcion}
                    tipo={alert.tipo}
                    msjConfirmacion={alert.msjConfirmacion}
                    msjCancelacion={alert.msjCancelacion}
                    onConfirm={alert.onConfirm}
                    showConfirm={alert.showConfirm}
                    showCancel={alert.showCancel}
                    onCancel={() => setAlert(null)}
                />
            }

            {
                loader ? 
                <LoaderLogoSoftwone2/> : 
                <></>
            }
        </div>);

}

export default Registro;
