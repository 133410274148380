import React from 'react';

import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import RemoveIcon from '@material-ui/icons/Remove';

import RowEditable from './componentes/Editable';
import './styles.css';

// Helpers
import { Fechas, Utilidades } from "@renedelangel/helpers";

const { DateToSpanishDate } = Fechas;
const { NumberToMoney } = Utilidades;

export default function RowComponentCustom ({ valor, tipo, token, url, onlyDate = false, simbolo = "$", callback = () => console.log, id, onEnter, row, parametros, onDobleClic, titulo="" }) {

    let componente = "";

    switch (tipo) {
        case "fecha":
            componente = valor ? DateToSpanishDate(valor, false, onlyDate) : "";
            break;
        case "imagen":
            componente = valor ? <img src={`${url}${valor}?token=${token}`} alt={valor} title={titulo} style={{ maxHeight: "100px", width: "auto" }} /> : "";
            break;
        case "estatus":
            componente = valor ? <CheckCircleIcon /> : <RemoveIcon />;
            break;
        case "moneda":
            componente = valor ? NumberToMoney(valor, simbolo) : "";
            break;
        case "custom":
            componente = callback(valor, row);
            break;
        case "editable":
            componente = <RowEditable id={id} valor={valor} row={row} onEnter={onEnter} onDobleClic={onDobleClic} parametros={parametros} />
            break;
        case "table":
            let auxValue = valor.split('\r');
            auxValue.pop();
            let auxData = [];

            auxValue.map(el => {

                let auxIndexDescripcion = el.search('Descripcion')
                let auxIndexCantidad = el.search('Cantidad')

                let codigo = el.slice(0, auxIndexDescripcion).replace('Codigo: ','');
                let descripcion = el.slice(auxIndexDescripcion, auxIndexCantidad).replace('Descripcion: ','')
                let cantidad = el.slice(auxIndexCantidad, el.length).replace('Cantidad: ','')

                auxData.push({
                    codigo,
                    descripcion,
                    cantidad
                })
            })
            
            componente = (<>
                <div className='cTablaPedidoDetallado'>
                    <table>
                        <thead>
                            <th>
                                Código
                            </th>
                            <th>
                                Descripción
                            </th>
                            <th>
                                Cantidad
                            </th>
                        </thead>
                        <tbody>
                            {
                                auxData.map(({codigo,
                                    descripcion,
                                    cantidad}) => {

                                        return(<>
                                        <tr>
                                        <td>{codigo}</td>
                                        <td>{descripcion}</td>
                                        <td>{cantidad}</td>
                                        </tr>
                                        </>)
                                    })
                            }
                        </tbody>
                    </table>
                </div>
                </>)

        break;
        default:
            componente = `${valor}`;
            break;
    }

    return componente;
}
