import React, { useState, useRef, useEffect } from 'react';
import { Switch, Redirect } from 'react-router-dom';
import { Validaciones } from '@renedelangel/helpers';
// master components
import Tabla from '../../_layout/masterComponents/Tabla';
import FormularioModal from '../../_layout/masterComponents/FormularioModal';

// Redux
import { useSelector } from "react-redux";

//generic components
import SweetAlert from '../../_layout/genericComponents/ModalConfirmacion';
import { NetworkError } from "../../_layout/genericComponents/Metodos";
import Preloader from '../../_layout/genericComponents/Preloader';

// material-ui icons
import Add from "@material-ui/icons/Add";
import Edit from "@material-ui/icons/Edit";
import DeleteIcon from '@material-ui/icons/Delete';
import SyncIcon from '@material-ui/icons/Sync';
import SaveIcon from '@material-ui/icons/Save';
import DirectionsIcon from '@material-ui/icons/Directions';
import TouchAppIcon from '@material-ui/icons/TouchApp';
import CloseIcon from '@material-ui/icons/Close';

import { getDireccion, tablaDireccion, getCodigoPostal, selectTipoDireccion } from '../../querys/Direcciones/metodos';
import { addDireccion, updateDireccion, deleteDireccion, updateCambioTipoDireccion } from '../../mutations/Direcciones/metodos';

import { handleFocus, handleErrorInputText, handleModificar, handelAlertEliminar, handleEliminar, handleGuardar } from '../../_layout/helpers/handles';
import { info, danger, success, primary, rose } from '../../_layout/helpers/colores';

import { rgxNumeros } from '../../helpers/regexp';
import { rgxNumerosMaximo10 } from '../../helpers/regexp';
import { Delete } from '@material-ui/icons';


const { trim } = Validaciones;

function Direcciones() {

    const { usuario, token } = useSelector(state => state.login);

    const cleanState = { direccionID: null, usuarioID: null, nombreDireccion: "", nombreResponsable: "", codigoPostal: "", codigoPostalID: "", asentamiento: "", pais: "México", estado: "", municipio: "", ciudad: "", zona: "", tipoAsentamiento: "", calle: "", numeroExterior: "", numeroInterior: "", entreCalle: "", yCalle: "", celular: "", telefonoParticular: "", telefonoOficina: "", tipoDireccion: "", };
    const cleanStateTipoDireccion = { direccionID: null, nombreDireccion: "", tipoDireccion: "" };
    const cleanErrorState = {
        usuarioID: { error: false, helperText: "" },
        nombreDireccion: { error: false, helperText: "" },
        nombreResponsable: { error: false, helperText: "" },
        codigoPostal: { error: false, helperText: "" },
        codigoPostalID: { error: false, helperText: "" },
        asentamiento: { error: false, helperText: "" },
        pais: { error: false, helperText: "" },
        estado: { error: false, helperText: "" },
        municipio: { error: false, helperText: "" },
        ciudad: { error: false, helperText: "" },
        zona: { error: false, helperText: "" },
        tipoAsentamiento: { error: false, helperText: "" },
        calle: { error: false, helperText: "" },
        numeroExterior: { error: false, helperText: "" },
        numeroInterior: { error: false, helperText: "" },
        entreCalle: { error: false, helperText: "" },
        yCalle: { error: false, helperText: "" },
        celular: { error: false, helperText: "" },
        telefonoParticular: { error: false, helperText: "" },
        telefonoOficina: { error: false, helperText: "" },
        tipoDireccion: { error: false, helperText: "" },
        tipoID: { error: false, helperText: "" },


    }
    const cleanNotificaciones = { mensaje: "", color: null, open: false };

    const [seleccionables, setSeleccionables] = useState({});

    const [state, setState] = useState(cleanState);
    const [error, setError] = useState(false);
    const [statetipoDireccion, setStateTipoDireccion] = useState(cleanStateTipoDireccion);
    const [errorState, setErrorState] = useState(cleanErrorState);
    const [notificaciones, setNotificaciones] = useState(cleanNotificaciones);
    const [open, setOpen] = useState(false);
    const [disabled, setDisabled] = useState(false);
    const [alert, setAlert] = useState(null);
    const [loader, setLoader] = useState(false);
    const [actualizar, setActualizar] = useState(false);

    // const [errorToken, setErrorToken] = useState(null);
    // const tokenError = useErrorToken(errorToken);



    let nombreDireccionRef = useRef(null);
    let nombreResponsableRef = useRef(null);
    let codigoPostalRef = useRef(null);
    let codigoPostalIDRef = useRef(null);
    let paisRef = useRef(null);
    let estadoRef = useRef(null);
    let municipioRef = useRef(null);
    let ciudadRef = useRef(null);
    let zonaRef = useRef(null);
    let tipoAsentamientoRef = useRef(null);
    let calleRef = useRef(null);
    let numeroExteriorRef = useRef(null);
    let numeroInteriorRef = useRef(null);
    let entreCalleRef = useRef(null);
    let yCalleRef = useRef(null);
    let celularRef = useRef(null);
    let telefonoParticularRef = useRef(null);
    let telefonoOficinaRef = useRef(null);
    let tipoIDRef = useRef(null);
    let tipoDireccionRef = useRef(null);



    const acciones = [{
        icono: Edit,
        color: info,
        descripcion: "Modificar",
        parametros: [{ campo: "direccionID" }],
        disabled: { multiseleccion: true },
        onClick: (parametros) => handleModificar({
            parametros, token, setState, setOpen, setAlert,
            asyncGet: getDireccion /*setErrorToken*/
        }, async ({ codigoPostal: value }) => {

            let { cMunicipio, dMunicipio, dEstado, cEstado, dZona, dCiudad, cTipoAsentamiento, colonias } = await getCodigoPostal({ cp: value }, token);

            setState(state => ({
                ...state,
                claveMunicipio: cMunicipio ? cMunicipio : "",
                municipio: dMunicipio ? dMunicipio : "",
                estado: dEstado ? dEstado : "",
                claveEstado: cEstado ? cEstado : "",
                zona: dZona ? dZona : "",
                ciudad: dCiudad ? dCiudad : "",
                tipoAsentamiento: cTipoAsentamiento ? cTipoAsentamiento : ""
            }));

            const newColonias = colonias.map(({ idAsentamientoCpcons: value, dAsentamiento: label, dTipoAsentamiento }) => ({ value, label, dTipoAsentamiento }));

            setSeleccionables(seleccionables => ({
                ...seleccionables,
                colonias: newColonias
            }))
        })
    }, {
        icono: DeleteIcon,
        color: danger,
        descripcion: "Eliminar",
        parametros: [{ campo: "direccionID" }, { campo: "usuarioID" }, { campo: "nombreDireccion" }, { campo: "nombreResponsable" },
        { campo: "codigoPostalID" }, { campo: "codigoPostal" }, { campo: "pais" }, { campo: "estado" },
        { campo: "municipio" }, { campo: "ciudad" }, { campo: "zona" }, { campo: "tipoAsentamiento" },
        { campo: "calle" }, { campo: "numeroExterior" }, { campo: "numeroInterior" }, { campo: "entreCalle" },
        { campo: "yCalle" }, { campo: "celular" }, { campo: "telefonoParticular" }, { campo: "telefonoOficina" },
        { campo: "tipoDireccion" },],
        disabled: { multiseleccion: true },
        onClick: ({ direccionID, nombreDireccion = "seleccionado" }) => handelAlertEliminar({
            setAlert,
            mensaje: `a la dirección ${nombreDireccion}`,
            onCancel: () => setAlert(null),
            onConfirm: () => handleEliminar({
                token, setAlert, setActualizar, actualizar,
                parametros: { direccionID }, asyncDelete: deleteDireccion,
                msjCorrecto : { title: `Se elimino el registro`, descripcion: `Se ha logrado eliminar la dirección de entrega de forma correcta`, tipo: success, msjConfirmacion: `Aceptar` }
            })
        })
    }];

    const botones = [{
        icono: Add,
        color: success,
        descripcion: "Agregar",
        onClick: () => setOpen(true),
        disabled: { multiseleccion: true }
    }, {
        icono: SyncIcon,
        color: info,
        descripcion: "Actualizar",
        onClick: () => setActualizar(!actualizar),
        disabled: { multiseleccion: true }
    }];

    const infoTabla = {
        botones,
        acciones,
        actualizar,
        id: "direccionID",
        color: primary,
        title: "Direcciones de entrega",
        iconTable: <DirectionsIcon />,
        headers: [
            { variable: "usuarioID", hide: true },
            { variable: "direccionID", descripcion: "ID", hide: true },
            { variable: "nombreResponsable", descripcion: "Responsable" },
            { variable: "nombreDireccion", descripcion: "Nombre de Dirección" },
            { variable: "direccionFormateada", descripcion: "Dirección" },
            { variable: "codigoPostalID", hide: true },
            { variable: "colonia", hide: true },
            { variable: "pais", hide: true },
            { variable: "estado", hide: true },
            { variable: "municipio", hide: true },
            { variable: "ciudad", hide: true },
            { variable: "calle", hide: true },
            { variable: "numeroExterior", hide: true },
            { variable: "numeroInterior", hide: true },
            { variable: "entreCalle", hide: true },
            { variable: "yCalle", hide: true },
            { variable: "celular", hide: true },
            { variable: "telefonoParticular", hide: true },
            { variable: "telefonoOficina", hide: true },
            { variable: "tipoDireccion", hide: true },
            { variable: "cambioTipo", descripcion: "Tipo", hide: true },

        ],
        responsiveTitle: ["nombreDireccion"],
        filter: [

            { campo: "nombreDireccion" },
            { campo: "nombreResponsable" },


        ],
        alineacion: [{ columnas: [0, 6], alineacion: "center" }],
        formato: [{ columnas: ["direccionID"], tipo: "direccionID" }],


    }

    const inputs = [{
        disabled,
        id: "nombreDireccion",
        value: state.nombreDireccion,
        error: errorState.nombreDireccion.error,
        success: state.nombreDireccion && !errorState.error ? true : undefined,
        helperText: errorState.nombreDireccion.helperText,
        inputRef: nombreDireccionRef,
        title: "Nombre de la dirección de entrega*",
        placeholder: "Capture el nombre de la dirección de entrega",
        grid: { md: 6, lg: 6 },
        onChange: ({ target: { value } }) => handleChange(value, "nombreDireccion"),
        onKeyDown: (evt) => handleFocus(evt, nombreDireccionRef)
    }, {
        disabled,
        id: "nombreResponsable",
        value: state.nombreResponsable,
        error: errorState.nombreResponsable.error,
        success: state.nombreResponsable && !errorState.error ? true : undefined,
        helperText: errorState.nombreResponsable.helperText,
        inputRef: nombreResponsableRef,
        title: "Responsable",
        placeholder: "Capture el nombre del responsable",
        grid: { md: 6, lg: 6 },
        onChange: ({ target: { value } }) => handleChange(value, "nombreResponsable"),
        onKeyDown: (evt) => handleFocus(evt, nombreResponsableRef)
    }, {
        disabled,
        id: "codigoPostal",
        value: state.codigoPostal,
        error: errorState.codigoPostal.error,
        success: state.codigoPostal && !errorState.error ? true : undefined,
        helperText: errorState.codigoPostal.helperText,
        inputRef: codigoPostalRef,
        title: "Código Postal*",
        placeholder: "Capture el código postal",
        grid: { md: 6, lg: 6 },
        onChange: ({ target: { value } }) => {
            async function cp() {
                setState(state => ({
                    ...state,
                    claveMunicipio: "",
                    municipio: "",
                    estado: "",
                    claveEstado: "",
                    zona: "",
                    ciudad: "",
                    tipoAsentamiento: "",
                    colonias: ""

                }));
                if (value.length > 5) return;
                handleChange(value, "codigoPostal");
                if (value.length === 5) {

                    let { cMunicipio, dMunicipio, dEstado, cEstado, dZona, dCiudad, cTipoAsentamiento, colonias } = await getCodigoPostal({ cp: value }, token);

                    setState(state => ({
                        ...state,
                        claveMunicipio: cMunicipio ? cMunicipio : "",
                        municipio: dMunicipio ? dMunicipio : "",
                        estado: dEstado ? dEstado : "",
                        claveEstado: cEstado ? cEstado : "",
                        zona: dZona ? dZona : "",
                        ciudad: dCiudad ? dCiudad : "",
                        tipoAsentamiento: cTipoAsentamiento ? cTipoAsentamiento : ""
                    }));

                    const newColonias = colonias.map(({ idAsentamientoCpcons: value, dAsentamiento: label, dTipoAsentamiento }) => ({ value, label, dTipoAsentamiento }));

                    setSeleccionables(seleccionables => ({
                        ...seleccionables,
                        colonias: newColonias
                    }));

                }

            } cp();
        },
        onKeyDown: () => { return; }
    }, {
        disabled,
        id: "codigoPostalID",
        value: state.codigoPostalID,
        error: errorState.codigoPostalID.error,
        success: state.codigoPostalID && !errorState.error ? true : undefined,
        helperText: errorState.codigoPostalID.helperText,
        inputRef: codigoPostalIDRef,
        title: "Colonia",
        placeholder: "Seleccionar colonia",
        tipo: "select",
        data: seleccionables.colonias,
        grid: { md: 6, lg: 6 },
        onChange: ({ target: { value } }) => {

            let { colonias } = seleccionables;

            let [{ label: asentamiento }] = colonias.filter(col => Number(col.value) === Number(value))


            setState({ ...state, codigoPostalID: value, asentamiento })
        }

    }, {
        disabled: true,
        id: "pais",
        value: state.pais,
        error: errorState.pais.error,
        success: state.pais && !errorState.error ? true : undefined,
        helperText: errorState.pais.helperText,
        inputRef: paisRef,
        title: "País",
        placeholder: "Capture pais",
        grid: { md: 6, lg: 6 },
        onChange: ({ target: { value } }) => handleChange(value, "pais"),
        onKeyDown: (evt) => handleFocus(evt, paisRef)
    }, {
        disabled: true,
        id: "estado",
        value: state.estado,
        error: errorState.estado.error,
        success: state.estado && !errorState.error ? true : undefined,
        helperText: errorState.estado.helperText,
        inputRef: estadoRef,
        title: "Estado",
        placeholder: "Capture el estado",
        grid: { md: 6, lg: 6 },
        onChange: ({ target: { value } }) => handleChange(value, "estado"),
        onKeyDown: (evt) => handleFocus(evt, estadoRef)
    }, {
        disabled: true,
        id: "municipio",
        value: state.municipio,
        error: errorState.municipio.error,
        success: state.municipio && !errorState.error ? true : undefined,
        helperText: errorState.municipio.helperText,
        inputRef: municipioRef,
        title: "Municipio",
        placeholder: "Capture el municipio",
        grid: { md: 6, lg: 6 },
        onChange: ({ target: { value } }) => handleChange(value, "municipio"),
        onKeyDown: (evt) => handleFocus(evt, municipioRef)
    },
    {
        disabled,
        id: "calle",
        value: state.calle,
        error: errorState.calle.error,
        success: state.calle && !errorState.error ? true : undefined,
        helperText: errorState.calle.helperText,
        inputRef: calleRef,
        title: "Calle*",
        placeholder: "Capture la calle",
        grid: { md: 6, lg: 6 },
        onChange: ({ target: { value } }) => handleChange(value, "calle"),
        onKeyDown: (evt) => handleFocus(evt, calleRef)
    }, {
        disabled,
        id: "numeroExterior",
        value: state.numeroExterior,
        error: errorState.numeroExterior.error,
        success: state.numeroExterior && !errorState.error ? true : undefined,
        helperText: errorState.numeroExterior.helperText,
        inputRef: numeroExteriorRef,
        title: "N° Ext",
        placeholder: "Capture el número exterior",
        grid: { md: 6, lg: 6 },
        onChange: ({ target: { value } }) => handleChange(value, "numeroExterior"),
        onKeyDown: (evt) => handleFocus(evt, numeroExteriorRef)
    }, {
        disabled,
        id: "numeroInterior",
        value: state.numeroInterior,
        error: errorState.numeroInterior.error,
        success: state.numeroInterior && !errorState.error ? true : undefined,
        helperText: errorState.numeroInterior.helperText,
        inputRef: numeroInteriorRef,
        title: "N° Int",
        placeholder: "Capture el número interior",
        grid: { md: 6, lg: 6 },
        onChange: ({ target: { value } }) => handleChange(value, "numeroInterior"),
        onKeyDown: (evt) => handleFocus(evt, numeroInteriorRef)
    }, {
        disabled,
        id: "entreCalle",
        value: state.entreCalle,
        error: errorState.entreCalle.error,
        success: state.entreCalle && !errorState.error ? true : undefined,
        helperText: errorState.entreCalle.helperText,
        inputRef: entreCalleRef,
        title: "Entre Calle*",
        placeholder: "Capture la entre calle",
        grid: { md: 6, lg: 6 },
        onChange: ({ target: { value } }) => handleChange(value, "entreCalle"),
        onKeyDown: (evt) => handleFocus(evt, entreCalleRef)
    }, {
        disabled,
        id: "yCalle",
        value: state.yCalle,
        error: errorState.yCalle.error,
        success: state.yCalle && !errorState.error ? true : undefined,
        helperText: errorState.yCalle.helperText,
        inputRef: yCalleRef,
        title: "Y calle*",
        placeholder: "Capture la calle",
        grid: { md: 6, lg: 6 },
        onChange: ({ target: { value } }) => handleChange(value, "yCalle"),
        onKeyDown: (evt) => handleFocus(evt, yCalleRef)
    }, {
        disabled,
        id: "celular",
        value: state.celular,
        error: errorState.celular.error,
        success: state.celular && !errorState.error ? true : undefined,
        helperText: errorState.celular.helperText,
        inputRef: celularRef,
        title: "Celular*",
        placeholder: "Capture los dígitos",
        grid: { md: 6, lg: 6 },
        onChange: ({ target: { value } }) => handleChange(value, "celular"),
        onKeyDown: (evt) => handleFocus(evt, celularRef)
    }, {
        disabled,
        id: "telefonoParticular",
        value: state.telefonoParticular,
        error: errorState.telefonoParticular.error,
        success: state.telefonoParticular && !errorState.error ? true : undefined,
        helperText: errorState.telefonoParticular.helperText,
        inputRef: telefonoParticularRef,
        title: "Tel Particular",
        placeholder: "Capture los dígitos",
        grid: { md: 6, lg: 6 },
        onChange: ({ target: { value } }) => handleChange(value, "telefonoParticular"),
        onKeyDown: (evt) => handleFocus(evt, telefonoParticularRef)
    }
    ];

    const inputsTipoDireccion = [{
        disabled,
        tipo: "label",
        id: "labelNombreDireccion",
        title: statetipoDireccion.nombreDireccion,
        error: errorState.nombreDireccion.error,
        success: state.nombreDireccion && !errorState.error ? true : undefined,
        helperText: errorState.nombreDireccion.helperText,
        inputRef: nombreDireccionRef,
        grid: { md: 5, lg: 7 },
    }
    ];

    const accionesFormulario = [{
        loader,
        disabled,
        icono: SaveIcon,
        color: info,
        descripcion: "Guardar",
        onClick: () => handleGuardar({
            setLoader,
            setDisabled,
            setActualizar,
            actualizar,
            setNotificaciones,
            handleClose,
            handleFocus,
            refFocus: nombreDireccionRef,
            mensajeCorrecto: `La dirección se ${state.direccionID ? "modificó" : "agregó"} correctamente`,
            asyncCallback: ftGuardar,
            /*setErrorToken*/
        }),
        // inputRef: accederRef,
        // inputRef: accederRef,
    },
    {
        disabled,
        icono: CloseIcon,
        color: danger,
        descripcion: "Cancelar",
        onClick: handleClose
    }];

    const FormularioAccionesTipoDireccion = [{
        loader,
        disabled,
        icono: SaveIcon,
        color: info,
        descripcion: "Guardar",
        onClick: () => handleGuardar({
            setLoader,
            setDisabled,
            setActualizar,
            actualizar,
            setNotificaciones,
            handleClose,
            handleFocus,
            refFocus: tipoIDRef,
            mensajeCorrecto: `El tipo de direccion se ${state.tipoID ? "agregó" : "modificó"} correctamente`,
            asyncCallback: ftModificar,
            /*setErrorToken*/
        }),
        // inputRef: accederRef,
        // inputRef: accederRef,
    },
    {
        disabled,
        icono: Delete,
        color: danger,
        descripcion: "Cancelar",
        onClick: handleClose
    }];



    function ftErrorInputText({ condicion, ref, keyError, mensajeError }) {
        return handleErrorInputText({
            cleanErrorState, condicion, ref, keyError, mensajeError,
            loader: setLoader, disabled: setDisabled, errorState: setErrorState
        });
    }

    function handleValidaciones({ nombreDireccion, nombreResponsable, codigoPostalID, codigoPostal, pais, estado, municipio, tipoAsentamiento, calle, entreCalle, yCalle, celular, telefonoParticular, telefonoOficina, tipoDireccion, tipoID, tipoValidacion }) {

        let error;
        let validaciones = tipoValidacion === 2 ? [{
            condicion: !nombreDireccion || trim(nombreDireccion) === "",
            ref: nombreDireccionRef, keyError: "nombreDireccion",
            mensajeError: "Para que podamos funcionar bien necesitamos que le definas un nombre a la dirección "
        },
        {
            condicion: !rgxNumeros.test(codigoPostal),
            keyError: "codigoPostal",
            mensajeError: "Para que podamos funcionar bien necesitamos que definas un código postal válido. Solo acepta datos de tipo numérico"
        }, {
            condicion: !rgxNumeros.test(codigoPostalID),
            keyError: "codigoPostalID",
            mensajeError: "Solo acepta datos de tipo numérico"
        },
        {
            condicion: !pais || trim(pais) === "",
            ref: paisRef, keyError: "pais",
            mensajeError: "Para que podamos funcionar bien necesitamos que definas el País"
        }, {
            condicion: !estado || trim(estado) === "",
            ref: estadoRef, keyError: "estado",
            mensajeError: "Para que podamos funcionar bien necesitamos que definas el Estado"
        }, {
            condicion: !municipio || trim(municipio) === "",
            ref: municipioRef, keyError: "municipio",
            mensajeError: "Para que podamos funcionar bien necesitamos que definas el municipio"
        }, {
            condicion: !tipoAsentamiento || trim(tipoAsentamiento) === "",
            ref: tipoAsentamientoRef, keyError: "tipoAsentamiento",
            mensajeError: "Para que podamos funcionar bien necesitamos que definas el tipo de asentamiento"
        }, {
            condicion: !calle || trim(calle) === "",
            ref: calleRef, keyError: "calle",
            mensajeError: "Para que podamos funcionar bien necesitamos que definas la calle"
        }, {
            condicion: !entreCalle || trim(entreCalle) === "",
            ref: entreCalleRef, keyError: "entreCalle",
            mensajeError: "Para que podamos funcionar bien necesitamos que definas la entre calle"
        }, {
            condicion: !yCalle || trim(yCalle) === "",
            ref: yCalleRef, keyError: "yCalle",
            mensajeError: "Para que podamos funcionar bien necesitamos que definas la calle"
        }, {
            condicion: !rgxNumerosMaximo10.test(celular),
            keyError: "celular",
            mensajeError: "Solo acepta datos de tipo numérico y deben ser 10 dígitos"
        }, {
            condicion: telefonoParticular && !rgxNumerosMaximo10.test(telefonoParticular),
            keyError: "telefonoParticular",
            mensajeError: "El teléfono particular solo permite ingresar 10 números"
        }, {
            condicion: telefonoOficina && !rgxNumerosMaximo10.test(telefonoOficina),
            keyError: "telefonoOficina",
            mensajeError: "El teléfono de oficina solo permite ingresar 10 números"
        }, /* {
            condicion: !rgxNumeros.test(tipoDireccion),
            keyError: "tipoDireccion",
            mensajeError: "Para que podamos funcionar bien necesitamos que definas el tipo de dirección"
        } */

        ] : [/* {
            condicion: !rgxNumeros.test(tipoDireccion),
            keyError: "tipoDireccion",
            mensajeError: "Para que podamos funcionar bien necesitamos que definas el tipo de dirección"
        } */
            ]

        validaciones.forEach(({ condicion, ref, keyError, mensajeError }) => {
            if (error) return;
            error = ftErrorInputText({ condicion, ref, keyError, mensajeError });
        });

        if (error) return error;

    }

    function ftGuardar() {
        async function ftGuardar() {

            let { direccionID, nombreDireccion, nombreResponsable, pais, codigoPostalID, estado, municipio, ciudad, zona, tipoAsentamiento, calle, numeroExterior, numeroInterior, entreCalle, yCalle, celular, telefonoParticular, telefonoOficina, tipoDireccion, asentamiento, codigoPostal } = state;

            let error = await handleValidaciones({ direccionID, nombreDireccion, nombreResponsable, pais, codigoPostalID, estado, municipio, ciudad, zona, tipoAsentamiento, calle, numeroExterior, numeroInterior, entreCalle, yCalle, celular, telefonoParticular, telefonoOficina, tipoDireccion, tipoValidacion: 2, codigoPostal });

            if (error) return error;

            if (direccionID) await updateDireccion(
                {
                    input: { direccionID, nombreDireccion, nombreResponsable, pais, codigoPostalID: Number(codigoPostalID), estado, municipio, ciudad, zona, tipoAsentamiento, calle, numeroExterior, numeroInterior, entreCalle, yCalle, celular, telefonoParticular, telefonoOficina, tipoDireccion: 1, asentamiento, }
                }, token);
            else await addDireccion(
                {
                    input: { nombreDireccion, nombreResponsable, pais, codigoPostalID: Number(codigoPostalID), estado, municipio, ciudad, zona, tipoAsentamiento, calle, numeroExterior, numeroInterior, entreCalle, yCalle, celular, telefonoParticular, telefonoOficina, tipoDireccion: 1, asentamiento, }
                }, token);
        }
        return ftGuardar();
    }

    function ftModificar() {
        async function ftModificar() {
            let { tipoDireccion, direccionID } = statetipoDireccion;


            let error = await handleValidaciones({ tipoDireccion, tipoValidacion: 1 });

            if (error) return error;

            await updateCambioTipoDireccion(
                {
                    input: { tipoDireccion, direccionID }
                }, token);
        }
        return ftModificar();
    }

    function handleClose() {
        setOpen(false);
        setDisabled(false);
        setLoader(false);
        setNotificaciones({ ...cleanNotificaciones });
        setState({ ...cleanState });
        setErrorState({ ...cleanErrorState });
        setStateTipoDireccion(cleanStateTipoDireccion);
        setSeleccionables(seleccionables => ({
            ...seleccionables,
            colonias: []
        }));
    }

    function handleChange(value, key) { setState({ ...state, [key]: value }); }
    function handleChangeTipoDireccion(value, key) { setStateTipoDireccion({ ...statetipoDireccion, [key]: value }); }

    function ftEffect() {
        async function effect() {
            try {

                let tipoDireccion = await selectTipoDireccion({ tipodireccion: null }, token);

                setSeleccionables(seleccionables => ({ ...seleccionables, tipoDireccion }));

            } catch ({ message }) {
                setError(true);
                setAlert({
                    descripcion: `Algunos datos necesarios para funcionar no se cargaron correctamente, intenta actualizar la página o verifica tu conexión a internet; a continuación se muestrán más detalles del error: ${NetworkError(message)}`,
                    title: "¡Una disculpa!",
                    tipo: danger,
                    msjConfirmacion: "Aceptar",
                    onConfirm: () => setAlert(null)
                });
                /* setErrorToken(message);*/
            }
        } effect();
    }

    useEffect(ftEffect, [actualizar]);

    return ((token && !error) ? (usuario.usuarioID ? <>
        <Tabla
            infoTabla={infoTabla}
            asyncData={tablaDireccion}
            token={token}
            parametrosFijos={{ usuarioID: usuario.usuarioID, tipoDireccion: 1 }}
        />
        <FormularioModal
            open={open}
            title={statetipoDireccion.direccionID ? "Modificar tipo de dirección" : `${state.direccionID ? "Modificar" : "Agregar"} Dirección`}
            onClose={handleClose}
            notificaciones={notificaciones}
            closeNotification={() => setNotificaciones({ ...cleanNotificaciones })}
            inputs={statetipoDireccion.direccionID ? inputsTipoDireccion : inputs}
            acciones={statetipoDireccion.direccionID ? FormularioAccionesTipoDireccion : accionesFormulario}

        />
        { alert && <SweetAlert
            title={alert.title}
            descripcion={alert.descripcion}
            tipo={alert.tipo}
            msjConfirmacion={alert.msjConfirmacion}
            msjCancelacion={alert.msjCancelacion}
            onConfirm={alert.onConfirm}
            showConfirm={alert.showConfirm}
            showCancel={alert.showCancel}
            onCancel={() => setAlert(null)}
        />}
    </> : <Preloader />) : <><Switch><Redirect fromn="*" to={"/"} /></Switch></>);

}

export default Direcciones;
