import { FetchAPI } from "@renedelangel/helpers";

import { urlServidor } from '../../../helpers';

import {ADD_PAQUETERIA, UPDATE_PAQUETERIA, DELETE_PAQUETERIA, UPDATE_PAQUETERIAESTATUS} from '../index';

export const addPaqueteria = async (variables, token) => {
    try {

        let { addPaqueteria } = await FetchAPI.graphql({
            token,
            variables,
            url: urlServidor,
            query: ADD_PAQUETERIA
        });

        return addPaqueteria;

    } catch({message}) { throw new Error(message); }
};

export const updatePaqueteria = async (variables, token) => {
    try {

        let { updatePaqueteria } = await FetchAPI.graphql({
            token,
            variables,
            url: urlServidor,
            query: UPDATE_PAQUETERIA
        });

        return updatePaqueteria;

    } catch({message}) { throw new Error(message); }
};

export const deletePaqueteria = async (variables, token) => {
    try {

        let { deletePaqueteria } = await FetchAPI.graphql({
            token,
            variables,
            url: urlServidor,
            query: DELETE_PAQUETERIA
        });

        return deletePaqueteria;

    } catch({message}) { throw new Error(message); }
};

export const updatePaqueteriaEstatus = async (variables, token) => {
    try {

        let { updatePaqueteriaEstatus } = await FetchAPI.graphql({
            token,
            variables,
            url: urlServidor,
            query: UPDATE_PAQUETERIAESTATUS
        });

        return updatePaqueteriaEstatus;

    } catch({message}) { throw new Error(message); }
};
