// EJSA
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import {  Encriptar } from '@renedelangel/helpers';

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// material-ui icons
import ErrorIcon from '@material-ui/icons/Error';
import ShoppingBasketIcon from '@material-ui/icons/ShoppingBasket';
import { red, green, blue } from '@material-ui/core/colors'

// core components
import Link from '@material-ui/core/Link';

//lista

import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';

// generic components
import Loader from "../Loader";
import Notificaciones from "../Notificaciones";
import  TablaLista  from "./componentes/tabla";

// Componentes Custom
import {redondeo} from '../../../helpers/regexp.js';

// media querys
import useMediaQuerys from "../MediaQuerys";

import { ftPaginacionGetComponentData } from "../../helpers/components";

import styles from "../../assets/jss/material-dashboard-pro-react/views/extendedListaStyle.js";
const {Encrypt,Decrypt}=Encriptar;


const useStyles = makeStyles(styles);

export default function Lista (props) {

    const classes = useStyles();
    const colorLink = "primary";

    let imagenDefault =  '/imagenes/cajaAbierta.jpg';

    const { datos, columns, rows, rowsLista, filter = [], iconTable, title, hideHeader = false, responsiveTitle, showHelp = false, activeResponsive = -1, accionesEffect = [],
        multiseleccion = [], id, multiCheck, multiCheckAllSelected, onChangeSwitch, alineacion, formato = [], paginacion, paginaActiva = 1, color = "info", rowColor,
        busqueda, loader = false, error = {}, botones = [], acciones = [], disabledMultiseleccion, disabledNormal, disabledLoader, handleSearch = (evt) => console.log(evt),
        handleRegistros = (evt) => console.log(evt), rangoFechas, seleccionFiltro, token, paqueteria,  eliminarCarrito,  actualizarCantidad, modificaCantidad, actualizar, grabarPedido, metodoEntrega, actualizaMetodo} = props;

    const [datosCarrito, setDatosCarrito] = useState(datos);
    const [datosEntrega, setDatosEntrega] = useState(metodoEntrega);

    const noTruncarDecimales = {maximumFractionDigits: 20,  minimumFractionDigits: 2};

    let container = {
        direction: "row",
        justify: "center",
        alignItems: "center"
    };

    let { isTabletOrMobile, isTabletOrMobileDevice } = useMediaQuerys();
    let responsive = isTabletOrMobile || isTabletOrMobileDevice;

    if(typeof paginacion === "object") pages = ftPaginacionGetComponentData(paginacion, paginaActiva);
    let dataResponsive = datosCarrito;

    function modifica(e, index, productoID){

        let valor = datosCarrito[index];
        let cant =   Number(e.target.value);
        cant = cant > 1 ? cant : 1;

        if (cant >= 1000)
        {
            cant = 0
        }
        else {
        /* console.log("cant:", cant); */

        valor = {...valor, cantidad: Number(cant)}

        const nuevos = datosCarrito.map(p => {
        if (p.productoID === productoID) {
                p.cantidad = Number(e.target.value)
                return p;
        }
        
        return p;
        });
        setDatosCarrito(nuevos);
        modificaCantidad(cant, index, productoID);
        }
    }

    useEffect(() => {
        let seed = "softwone";
        if(datos){
            datos.map((item, index) => {
                let subtotal = Number(item.cantidad) * Number(item.precioVentaOnline);
                subtotal = redondeo(subtotal, 2);
                subtotal = subtotal.toLocaleString('en-US', noTruncarDecimales);
                item.subtotal = subtotal;

                let precioArt = redondeo(item.precioVentaOnline, 2);
                precioArt = precioArt.toLocaleString('en-US', noTruncarDecimales);
                item.precio = precioArt;

                let parametrosUrl = `{"id": "${item.productoID}"}`;               
                let url = Encrypt({data: parametrosUrl, seed}).replace(/\//g, "&");
                url = `/producto/${url}`;
                item.urlProducto = url;
            });
        }
    setDatosCarrito(datos);
    }, [datos]);

    return (<>
               <div className={classes.panelPrincipal}>
                   {
                       datosCarrito ? datosCarrito.length > 0 ?
                        <>
                        <div className={classes.titulosCarrito}>                        
                            <div className={classes.contenedorImagen}></div>
                            <div className={classes.detalleProducto}>   
                            <div className={classes.description}></div>                                           
                                <div className={classes.importesProducto} >
                                    <div className={classes.titulosPrecios}>Cantidad</div>
                                    <div className={classes.titulosPrecios}>Precio</div>
                                    <div className={classes.titulosPrecios}>Importe</div>
                                    <div className={classes.titulosPrecios}></div>
                                </div>
                            </div>
                        </div>     
                        <div></div> </> : 
                        <div>
                            <div>Canasta Vacia</div>
                            <p>
                                <Link to={{
                                    pathname: "/productos",    
                                    state: {grupoParam: null, subgrupoParam: null}                           
                                }}>Ver productos</Link>
                            </p>
                        </div>  : 
                        <div>
                            <div>Canasta Vacia</div>
                            <p>
                                <Link to={{
                                    pathname: "/productos",     
                                    state: {grupoParam: null, subgrupoParam: null}                               
                                }}>Ver productos</Link>
                            </p>
                        </div>     
                    }     
                    {
                        loader ? <Loader /> : <>
                        {  
                                datosCarrito ? datosCarrito.map((item, index) => {

                                let parametros = {"productoID": item.productoID, "carritoID": item.carritoID};                             
                                let subtotal = Number(item.precioVentaOnline) * Number(item.cantidad);
                                subtotal = redondeo(subtotal,2);
                                subtotal = subtotal.toLocaleString('en-US', noTruncarDecimales);

                                let precioformato = redondeo(item.precioVentaOnline,2)
                                precioformato = precioformato.toLocaleString('en-US', noTruncarDecimales);
                                return(
                                    <div>
                                        <div className={classes.panelProducto}>                                            
                                            <div ><img className={classes.imagenProducto} src = {item.imagenPortada ? item.imagenPortada : imagenDefault}/></div>
                                            <div className={classes.detalleProducto} >
                                                <div className={classes.description}>
                                                    {item.nombre}<br></br>
                                                    {item.descripcion}                                             
                                                </div>
                                                <div className={classes.importesProducto} >
                                                    <div className={classes.contenedorImportes}>   
                                                        <div className={classes.precios}>
                                                        <IconButton title="Eliminar del carrito"  onClick={(e) => eliminarCarrito(parametros, e)}>
                                                            <DeleteIcon style={{ color: red[500] }}/>
                                                        </IconButton>
                                                        </div>                                                  
                                                      
                                                        <div className={classes.precios}>{subtotal}</div>
                                                        <div className={classes.precioProducto}>{precioformato}</div>
                                                        <div className={classes.precios}>

                                                        <input type="button"
                                                            title="Reducir"
                                                            className={classes.botonReducir}
                                                            onClick={(e) => actualizarCantidad(parametros, -1)}
                                                            value = "-"
                                                         /> 
                                                            <input  className={classes.cantidadCarrito} id={item.productoID} type="text" 
                                                            value={item.cantidad}
                                                            onChange ={(e) => modificaCantidad(e, index, item.productoID)}
                                                            />
                                                         <input type="button"
                                                            title="Reducir"
                                                            className={classes.botonIncremento}
                                                            onClick={(e) => actualizarCantidad(parametros, 1)}
                                                            value = "+" />
                                                        </div>                                                        
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <hr></hr>  
                                    </div>     
                                );       
                            })  : <div></div>               

                            }
                            { datosCarrito ? datosCarrito.length > 0 ?
                                <>
                                <div className={classes.barraBotones}>
                                <div className={classes.barraBotonesAux}>
                                <Button className={classes.botonPagar} onClick={() => grabarPedido()}>P A G A R </Button>
                                </div>
                                </div>
                                </> : <div></div> : <div></div>
                            }
                        </>         
                                      
                       
                    }
               </div>    
            </> 
    );
}

Lista.propTypes = {
    data: PropTypes.array.isRequired,
    hideHeader: PropTypes.bool,
    filter: PropTypes.array,
    iconTable: PropTypes.node,
    title: PropTypes.string,
    showHelp: PropTypes.bool,
    responsiveTitle: PropTypes.arrayOf(PropTypes.string),
    activeResponsive: PropTypes.number,
    alineacion: PropTypes.arrayOf(PropTypes.object),
    formato: PropTypes.arrayOf(PropTypes.object),
    paginacion: PropTypes.object,
    paginaActiva: PropTypes.number,
    color: PropTypes.oneOf([
        "warning",
        "primary",
        "danger",
        "success",
        "info"
    ]),
    rowColor: PropTypes.func,
    busqueda: PropTypes.object,
    loader: PropTypes.bool,
    error: PropTypes.object,
    handleSearch: PropTypes.func,
    handleRegistros: PropTypes.func
};
