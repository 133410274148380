import React, { useState, useRef, useEffect } from 'react';
import { Switch, Redirect } from 'react-router-dom';
import { Validaciones } from '@renedelangel/helpers';

// master components
import Tabla from '../../_layout/masterComponents/Tabla';
import FormularioModal from '../../_layout/masterComponents/FormularioModal';

// Redux
import { useSelector } from "react-redux";

//generic components
import SweetAlert from '../../_layout/genericComponents/ModalConfirmacion';
import { NetworkError } from "../../_layout/genericComponents/Metodos";
import Preloader from '../../_layout/genericComponents/Preloader';

// material-ui icons
import Add from "@material-ui/icons/Add";
import Edit from "@material-ui/icons/Edit";
import DeleteIcon from '@material-ui/icons/Delete';
import CloseIcon from '@material-ui/icons/Close';
import SyncIcon from '@material-ui/icons/Sync';
import SaveIcon from '@material-ui/icons/Save';
import CategoryIcon from '@material-ui/icons/Category';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import PeopleOutlineIcon from '@material-ui/icons/PeopleOutline';

import { getDepartamento, tablaDepartamento, selectGrupoERP, getDepartamentoGrupo } from '../../querys/Departamentos/metodos';
import { addDepartamento, updateDepartamento, deleteDepartamento, updateDepartamentoEstatus, updateDepartamentoGrupo } from '../../mutations/Departamentos/metodos';

import { handleFocus, handleErrorInputText, handleModificar, handleAlertGeneric, handleAlertMutationGeneric, handelAlertEliminar, handleEliminar, handleGuardar } from '../../_layout/helpers/handles';
import { info, danger, success, primary, rose } from '../../_layout/helpers/colores';

const { trim } = Validaciones;

function Departamentos() {

    const { usuario, token } = useSelector(state => state.login);

    const cleanState = { departamentoID: null, descripcion: "", estatus: 'Activo' };
    const cleanErrorState = {
        descripcion: { error: false, helperText: "" },
        estatus: { error: false, helperText: "" },
        departamentoID: { error: false, helperText: "" },
        grupoID: { error: false, helperText: "" },
        grupoErpID: { error: false, helperText: "" },
    }
    const cleanStateGrupo = { departamentoID: null, grupoID: null, grupoErpID: null };
    const cleanNotificaciones = { mensaje: "", color: null, open: false };

    const [seleccionables, setSeleccionables] = useState({});

    const [state, setState] = useState(cleanState);
    const [error, setError] = useState(false);
    const [stateGrupo, setStateGrupo] = useState(cleanStateGrupo);
    const [errorState, setErrorState] = useState(cleanErrorState);
    const [notificaciones, setNotificaciones] = useState(cleanNotificaciones);
    const [open, setOpen] = useState(false);
    const [disabled, setDisabled] = useState(false);
    const [alert, setAlert] = useState(null);
    const [loader, setLoader] = useState(false);
    const [actualizar, setActualizar] = useState(false);

    // const [errorToken, setErrorToken] = useState(null);
    // const tokenError = useErrorToken(errorToken);



    let descripcionRef = useRef(null);
    let grupoIDRef = useRef(null);
    let grupoErpIDRef = useRef(null);



    const acciones = [{
        icono: Edit,
        color: info,
        descripcion: "Modificar",
        parametros: [{ campo: "departamentoID" }],
        disabled: { multiseleccion: true },
        onClick: (parametros) => handleModificar({
            parametros, token, setState, setOpen, setAlert,
            asyncGet: getDepartamento /*setErrorToken*/
        })
    }, {
        icono: DeleteIcon,
        color: danger,
        descripcion: "Eliminar",
        parametros: [{ campo: "departamentoID" }, { campo: "descripcion" }],
        disabled: { multiseleccion: true },
        onClick: ({ departamentoID, descripcion = "seleccionado" }) => handelAlertEliminar({
            setAlert,
            mensaje: ` la Departamento ${descripcion}`,
            onCancel: () => setAlert(null),
            onConfirm: () => handleEliminar({
                token, setAlert, setActualizar, actualizar,
                parametros: { departamentoID }, asyncDelete: deleteDepartamento /*setErrorToken*/
            })
        })
    }, {
        icono: RadioButtonUncheckedIcon,
        color: success,
        descripcion: "Cambiar Estatus",
        parametros: [{ campo: "estatus" }, { campo: "departamentoID" }],
        disabled: { multiseleccion: true },
        onClick: ({ departamentoID, estatus }) => handleAlertGeneric({
            setAlert,
            mensaje: {
                title: "¿Estas seguro?",
                descripcion: `Estás a punto de ${estatus ? "desactivar" : "activar"} el estatus de la categoría, ¿Deseas continuar con el proceso?`,
                msjConfirmacion: `¡Si, ${estatus ? "desactivalo" : "activalo"}!`,
                msjCancelacion: "Cancelar"
            },
            onCancel: () => setAlert(null),
            onConfirm: () => handleAlertMutationGeneric({
                token, setAlert, setActualizar, actualizar,
                mensajes: {
                    msjEspera: { descripcion: "Espere unos momentos estamos a punto de cambiar el estatus" },
                    msjCorrecto: {
                        title: "¡Se cambio el estatus!",
                        descripcion: `El estatus se cambio exitosamente a ${estatus ? "desactivado" : "activado"}`,
                        msjConfirmacion: "Aceptar"
                    }
                },
                parametros: { input: { departamentoID, estatus: !estatus } }, asyncMutation: updateDepartamentoEstatus /*setErrorToken*/
            })
        })
    }, {


        icono: PeopleOutlineIcon,
        color: rose,
        descripcion: "Asignar grupo",
        parametros: [{ campo: "departamentoID" }],
        disabled: { multiseleccion: true },
        onClick: (parametros) => handleModificar({
            parametros, token, setState: setStateGrupo, setOpen, setAlert,
            asyncGet: getDepartamentoGrupo /*setErrorToken*/
        })

    }];



    const botones = [{
        icono: Add,
        color: success,
        descripcion: "Agregar",
        onClick: () => setOpen(true),
        disabled: { multiseleccion: true }
    }, {
        icono: SyncIcon,
        color: info,
        descripcion: "Actualizar",
        onClick: () => setActualizar(!actualizar),
        disabled: { multiseleccion: true }
    }];

    const infoTabla = {
        botones,
        acciones,
        actualizar,
        id: "departamentoID",
        color: primary,
        title: "Departamentos",
        iconTable: <CategoryIcon />,
        headers: [
            { variable: "departamentoID", descripcion: "ID" },
            { variable: "descripcion", descripcion: "Descripción" },
            { variable: "estatus", descripcion: "Estatus" },

        ],
        responsiveTitle: ["departamentoID", "descripcion", "estatus"],
        filter: [
            { campo: "descripcion" }
        ],
        selectFilter: [
            {
                campo: "estatus", placeholder: "Seleccionar estado del slider", retorna: "boolean",
                data: [
                    { value: null, label: "Mostrar todos" },
                    { value: "true", label: "Activos" },
                    { value: "false", label: "Inactivos" }
                ]
            }
        ],
        alineacion: [{ columnas: [0, 1, 2, 3, 4], alineacion: "center" }],
        formato: [{ columnas: ["estatus"], tipo: "estatus" }],


    }

    const inputs = [{
        disabled,
        id: "descripcion",
        value: state.descripcion,
        error: errorState.descripcion.error,
        success: state.descripcion && !errorState.descripcion.error ? true : undefined,
        helperText: errorState.descripcion.helperText,
        inputRef: descripcionRef,
        title: "Categoría *",
        placeholder: "Capture el nombre de la categoría",
        grid: { md: 6, lg: 6 },
        onChange: ({ target: { value } }) => handleChange(value, "descripcion"),
        onKeyDown: (evt) => handleFocus(evt, descripcionRef)
    },
    {
        disabled,
        id: "estatus",
        value: state.estatus,
        checked: state.estatus,
        error: errorState.estatus.error,
        success: state.estatus && !errorState.estatus.error ? true : undefined,
        helperText: errorState.estatus.helperText,
        title: "Estatus ",
        placeholder: "Seleccionar el estatus",
        tipo: "switch",
        data: seleccionables.estatus,
        grid: { md: 6, lg: 5 },
        onChange: () => setState(state => ({ ...state, estatus: !state.estatus }))
    }];

    const inputsGrupos = [{
        disabled,
        tipo: "label",
        id: "labeLdescripcion",
        title: stateGrupo.descripcion,
        error: errorState.descripcion.error,
        success: state.descripcion && !errorState.error ? true : undefined,
        helperText: errorState.descripcion.helperText,
        inputRef: descripcionRef,
        grid: { md: 5, lg: 6 },
    }, {

        disabled: !!stateGrupo.grupoErpID,
        id: "grupoID",
        value: stateGrupo.grupoID,
        error: errorState.grupoID.error,
        success: stateGrupo.grupo && !errorState.error ? true : undefined,
        helperText: errorState.grupoID.helperText,
        inputRef: grupoIDRef,
        title: "Grupos",
        tipo: "autocomplete",
        multiple: true,
        placeholder: "seleccionar grupo",
        data: seleccionables.grupoID,
        onChange: (info) => handleMultiple("grupoID", info),


    }, {
        disabled: !!stateGrupo.grupoID,
        id: "grupoErpID",
        value: stateGrupo.grupoErpID,
        error: errorState.grupoErpID.error,
        success: stateGrupo.grupoErpID && !errorState.error ? true : undefined,
        helperText: errorState.grupoErpID.helperText,
        inputRef: grupoErpIDRef,
        title: "Grupos ERP",
        tipo: "autocomplete",
        multiple: true,
        placeholder: "seleccionar grupo ERP",
        data: seleccionables.grupoErpID,
        onChange: (info) => handleMultiple("grupoErpID", info),
        // onKeyDown: (evt) => handleFocus(evt,grupoErpIDRef)
    }];

    function handleMultiple(id, info) {
        let data = [];
        info.forEach(({ value }) => {
            data = [...data, value]
        });
        data = data.length === 0 ? null : data;
        setStateGrupo(state => ({ ...state, [id]: data }));
    }

    const accionesFormulario = [{
        loader,
        disabled,
        icono: SaveIcon,
        color: info,
        descripcion: "Guardar",
        onClick: () => handleGuardar({
            setLoader,
            setDisabled,
            setActualizar,
            actualizar,
            setNotificaciones,
            handleClose,
            handleFocus,
            refFocus: descripcionRef,
            mensajeCorrecto: stateGrupo.departamentoID ? "La asignación se realizo correctamente" : `La categoría se ${state.departamentoID ? "modificó" : "agregó"} correctamente`,
            asyncCallback: stateGrupo.departamentoID ? ftAsignar : ftGuardar,
            /*setErrorToken*/
        }),
        // inputRef: accederRef,
    }, {
        disabled,
        icono: CloseIcon,
        color: danger,
        descripcion: "Cancelar",
        onClick: handleClose
    }];



    function ftErrorInputText({ condicion, ref, keyError, mensajeError }) {
        return handleErrorInputText({
            cleanErrorState, condicion, ref, keyError, mensajeError,
            loader: setLoader, disabled: setDisabled, errorState: setErrorState
        });
    }

    function handleValidaciones({ descripcion, grupoID, grupoErpID, tipoValidacion }) {

        let error;
        let validaciones = tipoValidacion === 2 ? [{
            condicion: !descripcion || trim(descripcion) === "",
            ref: descripcionRef, keyError: "descripcion",
            mensajeError: "Para que podamos funcionar bien necesitamos que le definas un nombre a la Departamento"
        },

        ] : [{
            condicion: !grupoErpID || trim(grupoErpID) === "",
            ref: grupoErpIDRef, keyError: "grupoErpID",
            mensajeError: "Para que podamos funcionar bien necesitamos que seleciones un grupo"
        }]

        validaciones.forEach(({ condicion, ref, keyError, mensajeError }) => {
            if (error) return;
            error = ftErrorInputText({ condicion, ref, keyError, mensajeError });
        });

        if (error) return error;

    }

    function ftGuardar() {
        async function ftGuardar() {

            let { departamentoID, descripcion, estatus } = state;

            let error = await handleValidaciones({ descripcion, tipoValidacion: 2 });

            if (error) return error;

            if (departamentoID) await updateDepartamento({
                input: { departamentoID, descripcion: trim(descripcion), estatus }
            }, token);
            else await addDepartamento({
                input: { descripcion: trim(descripcion), estatus }
            }, token);

        }
        return ftGuardar();
    }

    function ftAsignar() {
        async function ftAsignar() {
            let { departamentoID, grupoID, grupoErpID } = stateGrupo;

            let error = await handleValidaciones({ grupoID, grupoErpID, tipoValidacion: 1 });

            if (error) return error;
            if (grupoID) await updateDepartamentoGrupo(
                {
                    input: { departamentoID, grupoID, grupoErpID }
                }, token);
        }
        return ftAsignar();
    }

    function handleClose() {
        setOpen(false);
        setDisabled(false);
        setLoader(false);
        setNotificaciones({ ...cleanNotificaciones });
        setState({ ...cleanState });
        setErrorState({ ...cleanErrorState });
        setStateGrupo(cleanStateGrupo);
    }

    function handleChange(value, key) { setState({ ...state, [key]: value }); }




    function ftEffect() {
        async function effect() {
            try {

                let grupoID, grupoErpID = await selectGrupoERP({ grupoID: null, grupoErpID: null }, token);

                setSeleccionables(seleccionables => ({ ...seleccionables, grupoID, grupoErpID }));



            } catch ({ message }) {
                setError(true);
                setAlert({
                    descripcion: `Algunos datos necesarios para funcionar no se cargaron correctamente, intenta actualizar la página o verifica tu conexión a internet; a continuación se muestrán más detalles del error: ${NetworkError(message)}`,
                    title: "¡Una disculpa!",
                    tipo: danger,
                    msjConfirmacion: "Aceptar",
                    onConfirm: () => setAlert(null)
                });
                /* setErrorToken(message);*/
            }
        } effect();
    }

    useEffect(ftEffect, [actualizar]);

    return ((token && !error) ? (usuario.usuarioID ? <>
        <Tabla
            infoTabla={infoTabla}
            asyncData={tablaDepartamento}
            token={token}
        />
        <FormularioModal
            open={open}
            title={stateGrupo.departamentoID ? "Asignar grupos a categorías" : `${state.departamentoID ? "Modificar" : "Agregar"} Departamento`}
            onClose={handleClose}
            notificaciones={notificaciones}
            closeNotification={() => setNotificaciones({ ...cleanNotificaciones })}
            inputs={stateGrupo.departamentoID ? inputsGrupos : inputs}
            acciones={accionesFormulario}
            focus={descripcionRef}
        />
        { alert && <SweetAlert
            title={alert.title}
            descripcion={alert.descripcion}
            tipo={alert.tipo}
            msjConfirmacion={alert.msjConfirmacion}
            msjCancelacion={alert.msjCancelacion}
            onConfirm={alert.onConfirm}
            showConfirm={alert.showConfirm}
            showCancel={alert.showCancel}
            onCancel={() => setAlert(null)}
        />}
    </> : <Preloader />) : <><Switch><Redirect fromn="*" to={"/"} /></Switch></>);

}

export default Departamentos;
