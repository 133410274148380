import React, { useState, useEffect } from "react";
import "./styles.css";
import LoaderProcesandoPago from "../../LoaderProcesandoPago/index";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import {
    getDireccion,
    tablaDireccion,
    getCodigoPostal,
    selectTipoDireccion,
    selectDirecciones
} from "../../../../querys/Direcciones/metodos";
import {
    addDireccion,
    updateDireccion,
    deleteDireccion,
    updateCambioTipoDireccion
} from "mutations/Direcciones/metodos";
import { useSelector } from "react-redux";
import { tablaDireccionSucursal } from "../../../../querys/Direcciones/metodos";

import { rgxNumeros, rgxNumerosMaximo10 } from "../../../../helpers/regexp";
import { Validaciones } from "@renedelangel/helpers";
import {
    arrowLeft,
    arrowRight,
    vectorArrowR,
    vectorArrowL,
    vectorSaveIcon
} from '../../../assets/svg/exportsvg'

import LoaderLogoSoftwone2 from "_layout/assets/svg/Animaciones/logoSoftwone2";

const { trim } = Validaciones;

export default function DireccionEntrega(props) {
    const { usuario, token } = useSelector(state => state.login);
    const history = useHistory();

    let { continuarCompra,
        setContinuarCompra,
        datos,
        pageActiva,
        paqueteria,
        datosPedido,
        recibeDatos,
        totales,
        grabarPedido,
        volver,
        recibeError,
        propsSetDireccionSuc,
        propsSetAlert
    } = props;






    const [tipoEntrega, setTipoEntrega] = useState(1); //tipo entrega 1 para domicilio

    const [nuevaDireccion, setNuevaDireccion] = useState(1); //1 para no permitir

    const [seleccionDireccion, setSeleccionDireccion] = useState("");
    const [colonias, setColonias] = useState([]);
    const [actualizar, setActualizar] = useState(false);
    const [loader, setLoader] = useState(false);

    const clearState = {
        asentamiento: "",
        calle: "",
        cambioTipo: "",
        celular: "",
        ciudad: "",
        codigoPostal: "",
        codigoPostalID: "",
        direccionFormateada: "",
        direccionID: "",
        edificio: "",
        entreCalle: "",
        estado: "",
        municipio: "",
        nombreDireccion: "",
        nombreResponsable: "",
        numeroExterior: "",
        numeroInterior: "",
        pais: "México",
        telefonoOficina: "",
        telefonoParticular: "",
        tipoAsentamiento: "",
        tipoDireccion: "",
        usuarioID: "",
        yCalle: "",
        zona: ""
    };

    const [state, setState] = useState(clearState);
    const [direcciones, setDirecciones] = useState([]);
    const [direccionesSuc, setDireccionesSuc] = useState([]);

    const [direccionSelect, setDireccionSelect] = useState({});

    const cleanErrorState = {
        asentamientoError: { error: false, mensaje: "" },
        calleError: { error: false, mensaje: "" },
        cambioTipoError: { error: false, mensaje: "" },
        celularError: { error: false, mensaje: "" },
        ciudadError: { error: false, mensaje: "" },
        codigoPostalError: { error: false, mensaje: "" },
        codigoPostalIDError: { error: false, mensaje: "" },
        direccionFormateadaError: { error: false, mensaje: "" },
        direccionIDError: { error: false, mensaje: "" },
        edificioError: { error: false, mensaje: "" },
        entreCalleError: { error: false, mensaje: "" },
        estadoError: { error: false, mensaje: "" },
        municipioError: { error: false, mensaje: "" },
        nombreDireccionError: { error: false, mensaje: "" },
        nombreResponsableError: { error: false, mensaje: "" },
        numeroExteriorError: { error: false, mensaje: "" },
        numeroInteriorError: { error: false, mensaje: "" },
        paisError: { error: false, mensaje: "" },
        telefonoOficinaError: { error: false, mensaje: "" },
        telefonoParticularError: { error: false, mensaje: "" },
        tipoAsentamientoError: { error: false, mensaje: "" },
        tipoDireccionError: { error: false, mensaje: "" },
        usuarioIDError: { error: false, mensaje: "" },
        yCalleError: { error: false, mensaje: "" },
        zonaError: { error: false, mensaje: "" }
    };

    const [errorState, setErrorState] = useState(cleanErrorState);

    const cleanErrorStateSeleccionDireccion = {
        seleccionDireccion: { error: false, mensaje: "" }
    };

    const [
        errorStateSeleccionDireccion,
        setErrorStateSeleccionDireccion
    ] = useState(cleanErrorStateSeleccionDireccion);

    useEffect(() => {
        async function ftEffect() {
            if (usuario) {
                let direcciones = await tablaDireccion(
                    {
                        filtro: {
                            pagina: 1,
                            limite: 100000,
                            usuarioID: usuario.usuarioID
                        }
                    },
                    token
                );

                let direccionSuc = await tablaDireccionSucursal(
                    { filtro: { pagina: 1, limite: 1 } },
                    token
                );

                let aux = [];
                if (direcciones.listado.length > 0) {
                    direcciones.listado.map(element => {
                        if (
                            element.tipoDireccion == 1 ||
                            element.tipoDireccion == 3
                        ) {
                            aux.push(element);
                        }
                    });
                }

                setDirecciones(aux);
                setDireccionesSuc(direccionSuc);
                propsSetDireccionSuc(direccionSuc);
            }
        }
        ftEffect();
    }, [nuevaDireccion, actualizar]);

    useEffect(() => {
        async function infoCP() {
            if (
                state.codigoPostal.length < 5 ||
                state.codigoPostal.length > 5
            ) {
                setState(state => ({
                    ...state,
                    claveMunicipio: "",
                    municipio: "",
                    estado: "",
                    claveEstado: "",
                    zona: "",
                    ciudad: "",
                    tipoAsentamiento: "",
                    colonias: "",
                    codigoPostalID: ""
                }));
            }

            if (state.codigoPostal.length === 5) {
                let aux = await getCodigoPostal(
                    { cp: state.codigoPostal },
                    token
                );

                if (aux.codigoPostalID !== null) {
                    let {
                        dCiudad,
                        dEstado,
                        dMunicipio,
                        dZona,
                        cMunicipio,
                        cEstado,
                        cTipoAsentamiento,
                        colonias,
                        codigoPostalID
                    } = aux;
                    setState({
                        ...state,
                        claveMunicipio: cMunicipio ? cMunicipio : "",
                        municipio: dMunicipio ? dMunicipio : "",
                        estado: dEstado ? dEstado : "",
                        claveEstado: cEstado ? cEstado : "",
                        zona: dZona ? dZona : "",
                        ciudad: dCiudad ? dCiudad : "",
                        tipoAsentamiento: cTipoAsentamiento
                            ? cTipoAsentamiento
                            : "",
                        codigoPostalID: codigoPostalID
                    });

                    const newColonias = colonias.map(
                        ({
                            idAsentamientoCpcons: value,
                            dAsentamiento: label,
                            dTipoAsentamiento
                        }) => ({ value, label, dTipoAsentamiento })
                    );

                    setColonias(newColonias);
                }
            }
        }
        infoCP();
    }, [state.codigoPostal]);

    useEffect(() => {
        async function ftSetDireccion() {
            if (seleccionDireccion !== "") {
                let resultado = {};
                direcciones.map((element, index) => {
                    if (element.direccionID == seleccionDireccion) {
                        resultado = element;
                    }
                });
                recibeDatos({ idDireccion: parseInt(resultado.direccionID), direccionEntrega: resultado.direccionFormateada });
                setDireccionSelect(resultado);
                setErrorStateSeleccionDireccion(
                    cleanErrorStateSeleccionDireccion
                );
            } else {
                setDireccionSelect("");
            }
        }
        ftSetDireccion();
    }, [seleccionDireccion, tipoEntrega]);

    function handleChangeTipoEntrega(e) {
        const { value } = e.target;
        setTipoEntrega(prevState => {
            return value;
        });

        if (value == 2) {
            recibeDatos({ idDireccion: null, direccionEntrega: '', tipoEntrega: 2 });
        }
    }

    function handleChange(e) {
        const { name, value } = e.target;
        setState(prevState => {
            return {
                ...prevState,
                [name]: value
            };
        });
    }

    async function handleSelect(e) {
        let { value, name } = e.target;
        setSeleccionDireccion(value);
    }

    let {
        asentamiento,
        calle,
        cambioTipo,
        celular,
        ciudad,
        codigoPostal,
        codigoPostalID,
        direccionFormateada,
        direccionID,
        edificio,
        entreCalle,
        estado,
        municipio,
        nombreDireccion,
        nombreResponsable,
        numeroExterior,
        numeroInterior,
        pais,
        telefonoOficina,
        telefonoParticular,
        tipoAsentamiento,
        tipoDireccion,
        usuarioID,
        yCalle,
        zona
    } = state;

    let {
        asentamientoError,
        calleError,
        cambioTipoError,
        celularError,
        ciudadError,
        codigoPostalError,
        codigoPostalIDError,
        direccionFormateadaError,
        direccionIDError,
        edificioError,
        entreCalleError,
        estadoError,
        municipioError,
        nombreDireccionError,
        nombreResponsableError,
        numeroExteriorError,
        numeroInteriorError,
        paisError,
        telefonoOficinaError,
        telefonoParticularError,
        tipoAsentamientoError,
        tipoDireccionError,
        usuarioIDError,
        yCalleError,
        zonaError
    } = errorState;

    function handleValidaciones({
        nombreDireccion,
        nombreResponsable,
        codigoPostalID,
        codigoPostal,
        pais,
        estado,
        municipio,
        tipoAsentamiento,
        calle,
        entreCalle,
        yCalle,
        celular,
        telefonoParticular,
        telefonoOficina,
        tipoDireccion
    }) {
        let error = 0;
        let validaciones = [
            {
                condicion: !nombreDireccion || trim(nombreDireccion) === "",
                keyError: "nombreDireccion",
                mensajeError:
                    "Para que podamos funcionar bien necesitamos que le definas un nombre a la dirección "
            },
            /* {
                condicion: !nombreResponsable || trim(nombreResponsable) === "",
                keyError: "nombreResponsable",
                mensajeError:
                    "Para que podamos funcionar bien necesitamos que ingreses un nombre "
            }, */
            {
                condicion: !rgxNumeros.test(codigoPostal),
                keyError: "codigoPostal",
                mensajeError:
                    "Para que podamos funcionar bien necesitamos que definas un código postal válido. Solo acepta datos de tipo numérico"
            },
            {
                condicion: !asentamiento || asentamiento == "",
                keyError: "asentamiento",
                mensajeError: "Debe seleccionar una colonia"
            },
            {
                condicion: !pais || trim(pais) === "",
                keyError: "pais",
                mensajeError:
                    "Para que podamos funcionar bien necesitamos que definas el País"
            },
            {
                condicion: !estado || trim(estado) === "",
                keyError: "estado",
                mensajeError:
                    "Para que podamos funcionar bien necesitamos que definas el Estado"
            },
            {
                condicion: !municipio || trim(municipio) === "",
                keyError: "municipio",
                mensajeError:
                    "Para que podamos funcionar bien necesitamos que definas el municipio"
            },
            {
                condicion: !tipoAsentamiento || trim(tipoAsentamiento) === "",
                keyError: "tipoAsentamiento",
                mensajeError:
                    "Para que podamos funcionar bien necesitamos que definas el tipo de asentamiento"
            },
            {
                condicion: !calle || trim(calle) === "",
                keyError: "calle",
                mensajeError:
                    "Para que podamos funcionar bien necesitamos que definas la calle"
            },
            {
                condicion: !entreCalle || trim(entreCalle) === "",
                keyError: "entreCalle",
                mensajeError:
                    "Para que podamos funcionar bien necesitamos que definas la entre calle"
            },
            {
                condicion: !yCalle || trim(yCalle) === "",
                keyError: "yCalle",
                mensajeError:
                    "Para que podamos funcionar bien necesitamos que definas la calle"
            },
            {
                condicion: !rgxNumerosMaximo10.test(celular),
                keyError: "celular",
                mensajeError:
                    "Solo acepta datos de tipo numérico y deben ser 10 dígitos"
            },
            {
                condicion:
                    telefonoParticular &&
                    !rgxNumerosMaximo10.test(telefonoParticular),
                keyError: "telefonoParticular",
                mensajeError:
                    "El teléfono particular solo permite ingresar 10 números"
            },
            {
                condicion:
                    telefonoOficina &&
                    !rgxNumerosMaximo10.test(telefonoOficina),
                keyError: "telefonoOficina",
                mensajeError:
                    "El teléfono de oficina solo permite ingresar 10 números"
            }
        ];

        validaciones.reverse();

        validaciones.forEach(({ condicion, ref, keyError, mensajeError }) => {
            if (condicion) {
                setErrorState({
                    ...cleanErrorState,
                    [`${keyError}Error`]: { error: true, mensaje: mensajeError }
                });
                return (error = 1);
            }
        });

        return error;
    }

    function ftGuardar() {
        async function ftGuardar() {
            setLoader(true);
            let error = 0;
            error = handleValidaciones({
                nombreDireccion,
                nombreResponsable,
                codigoPostalID,
                codigoPostal,
                pais,
                estado,
                municipio,
                tipoAsentamiento,
                calle,
                entreCalle,
                yCalle,
                celular,
                telefonoParticular,
                telefonoOficina,
                tipoDireccion
            });

            if (error == 0) {
                try {
                    await addDireccion(
                        {
                            input: {
                                nombreDireccion,
                                nombreResponsable,
                                pais,
                                codigoPostalID: Number(codigoPostalID),
                                estado,
                                municipio,
                                ciudad,
                                zona,
                                tipoAsentamiento,
                                calle,
                                numeroExterior,
                                numeroInterior,
                                entreCalle,
                                yCalle,
                                celular,
                                telefonoParticular,
                                telefonoOficina,
                                tipoDireccion: 1,
                                asentamiento
                            }
                        },
                        token
                    );
                    setLoader(false);
                    setState(clearState);
                    setActualizar(!actualizar);
                    propsSetAlert({
                        descripcion: "Se ha guardado de forma correcta su dirección",
                        title: "¡Éxito!",
                        tipo: "success",
                        msjConfirmacion: "Aceptar",
                        onConfirm: () => {
                            propsSetAlert(null);
                            setNuevaDireccion(1);


                        },
                        showCancel: false,
                        msjCancelacion: "Cancelar",
                        onCancel: () => setAlert(null)
                    });


                } catch ({ message }) {
                    setLoader(false);
                    throw new Error(message);
                }
            } else {
                setLoader(false);
                return;
            }
        }
        ftGuardar();
    }

    useEffect(() => {
        async function scrollTop(){
            window.scrollTo(0, 0);
        }
        scrollTop()
    }, [nuevaDireccion])

    return (
        <>
            <div className="c_general_direccion_entrega">
                <div className="c_direccion_entrega_titulo">
                    <h6>Dirección de entrega</h6>
                </div>

                <div className="c_direccion_entrega_checkbox_first">
                    <div className="c_first_checkbox">
                        <input
                            type={"radio"}
                            id={"checkBoxDomicilio"}
                            checked={tipoEntrega == 1 ? true : false}
                            name={"domicilio"}
                            value={1}
                            onClick={e => {
                                handleChangeTipoEntrega(e);
                            }}
                        />
                        <label for="checkBoxDomicilio">
                            <h6>Enviar a domicilio</h6>
                        </label>
                    </div>

                    <div className="c_first_checkbox">
                        <input
                            type={"radio"}
                            id={"checkBoxTienda"}
                            checked={tipoEntrega == 2 ? true : false}
                            name={"tienda"}
                            value={2}
                            onClick={e => {
                                handleChangeTipoEntrega(e);
                                setSeleccionDireccion("");
                                setState({ ...clearState });
                                setErrorState({ ...cleanErrorState });
                                setNuevaDireccion(1);
                            }}
                        />
                        <label for="checkBoxTienda">
                            <h6>Recoger en tienda</h6>
                        </label>
                    </div>
                </div>

                {tipoEntrega == 1 ? (
                    direcciones.length > 0 && nuevaDireccion == 1 ? (
                        <div className="c_seleccion_direccion">
                            <select
                                onChange={e => {
                                    handleSelect(e);
                                }}
                            >
                                <option value={""} disabled selected>
                                    Seleccione la dirección del domicilio
                                </option>
                                {direcciones.map(element => {
                                    return (
                                        <option value={element.direccionID}>
                                            {element.nombreDireccion}
                                        </option>
                                    );
                                })}
                            </select>

                            <label>
                                <h6
                                    style={{
                                        color: errorStateSeleccionDireccion
                                            .seleccionDireccion.error
                                            ? "red"
                                            : "var(--secondaryColor)"
                                    }}
                                >
                                    {errorStateSeleccionDireccion
                                        .seleccionDireccion.error
                                        ? "Debe seleccionar una dirección de entrega o una sucursal"
                                        : ""}
                                </h6>
                            </label>

                            <div className="c_seleccion_direccion">
                                <textarea
                                    value={""}
                                    disabled={true}
                                    placeholder={
                                        direccionSelect.direccionFormateada
                                    }
                                />
                            </div>
                            <h6>
                                ¿No esta la dirección que desea? <br />
                                <b
                                    onClick={() => {
                                        setNuevaDireccion(2);
                                    }}
                                >
                                    AGREGAR UNA DIRECCIÓN
                                </b>
                            </h6>
                        </div>
                    ) : (
                        <>
                            <div className="c_campos_formulario">
                                <div className="c_titulo_formulario">
                                    <h6>Agregar una dirección de entrega</h6>
                                </div>

                                <div className="cf_fr">
                                    <div className="fr_calle_nombre_direccion">
                                        <label for="nombreDireccion">
                                            <h6>Nombre de la dirección de entrega*</h6>
                                        </label>
                                        <input
                                            id="nombreDireccion"
                                            placeholder="Nombre de la dirección de entrega *"
                                            name={"nombreDireccion"}
                                            value={nombreDireccion}
                                            onChange={e => {
                                                handleChange(e);
                                            }}
                                        />
                                        <label>
                                            <h6
                                                style={{
                                                    color: nombreDireccionError.error
                                                        ? "red"
                                                        : "var(--secondaryColor)"
                                                }}
                                            >
                                                {nombreDireccionError.error
                                                    ? nombreDireccionError.mensaje
                                                    : ""}
                                            </h6>
                                        </label>
                                    </div>

                                    <div className="fr_responsable">
                                        <label for="nombreResponsable">
                                            <h6>Responsable</h6>
                                        </label>
                                        <input
                                            placeholder="Responsable"
                                            name={"nombreResponsable"}
                                            value={nombreResponsable}
                                            onChange={e => {
                                                handleChange(e);
                                            }}
                                        />

                                        <label>
                                            <h6
                                                style={{
                                                    color: nombreResponsableError.error
                                                        ? "red"
                                                        : "var(--secondaryColor)"
                                                }}
                                            >
                                                {nombreResponsableError.error
                                                    ? nombreResponsableError.mensaje
                                                    : ""}
                                            </h6>
                                        </label>
                                    </div>
                                </div>

                                <div className="cf_fr">
                                    <div className="fr_cp">
                                        <label for="codigoPostal">
                                            <h6>Código postal*</h6>
                                        </label>
                                        <input
                                            placeholder="Código postal"
                                            name={"codigoPostal"}
                                            value={codigoPostal}
                                            onChange={e => {
                                                handleChange(e);
                                            }}
                                        />
                                        <label>
                                            <h6
                                                style={{
                                                    color: codigoPostalError.error
                                                        ? "red"
                                                        : "var(--secondaryColor)"
                                                }}
                                            >
                                                {codigoPostalError.error
                                                    ? codigoPostalError.mensaje
                                                    : ""}
                                            </h6>
                                        </label>
                                    </div>

                                    <div className="fr_colonia">
                                        <label for="asentamiento">
                                            <h6>Colonia*</h6>
                                        </label>
                                        <select
                                            id="asentamiento"
                                            name={"asentamiento"}
                                            onChange={e => {
                                                handleChange(e);
                                            }}
                                        >
                                            <option
                                                value={""}
                                                disabled
                                                selected
                                            >
                                                Seleccione la colonia
                                            </option>
                                            {colonias.map(element => {
                                                return (
                                                    <option
                                                        value={element.label}
                                                    >
                                                        {element.label}
                                                    </option>
                                                );
                                            })}
                                        </select>
                                        <label>
                                            <h6
                                                style={{
                                                    color: asentamientoError.error
                                                        ? "red"
                                                        : "var(--secondaryColor)"
                                                }}
                                            >
                                                {asentamientoError.error
                                                    ? asentamientoError.mensaje
                                                    : ""}
                                            </h6>
                                        </label>
                                    </div>
                                </div>

                                <div className="cf_fr">
                                    <div className="fr_pais">
                                        <label for="pais">
                                            <h6>Pais*</h6>
                                        </label>
                                        <input
                                            disabled={true}
                                            placeholder="Pais"
                                            name={"pais"}
                                            value={pais}
                                            onChange={e => {
                                                handleChange(e);
                                            }}
                                        />
                                    </div>
                                    <div className="fr_estado">
                                        <label for="estado">
                                            <h6>Estado</h6>
                                        </label>
                                        <input
                                            disabled={true}
                                            id={"estado"}
                                            placeholder="Estado"
                                            name={"estado"}
                                            value={estado}
                                            onChange={e => {
                                                handleChange(e);
                                            }}
                                        />
                                    </div>
                                </div>

                                <div className="cf_fr">
                                    <div className="fr_municipio">
                                        <label for="municipio">
                                            <h6>Municipio</h6>
                                        </label>
                                        <input
                                            disabled={true}
                                            placeholder="Municipio"
                                            name={"municipio"}
                                            value={municipio}
                                            onChange={e => {
                                                handleChange(e);
                                            }}
                                        />
                                    </div>
                                    <div className="fr_ciudad">
                                        <label for="ciudad">
                                            <h6>Ciudad</h6>
                                        </label>
                                        <input
                                            disabled={true}
                                            placeholder="Ciudad"
                                            name={"ciudad"}
                                            value={ciudad}
                                            onChange={e => {
                                                handleChange(e);
                                            }}
                                        />
                                    </div>
                                </div>

                                {/* <div className="cf_fr">
                                    <div className="fr_zona">
                                        <label for="zona">
                                            <h6>Zona</h6>
                                        </label>
                                        <input
                                            disabled={true}
                                            placeholder="Zona"
                                            name={"zona"}
                                            value={zona}
                                            onChange={e => {
                                                handleChange(e);
                                            }}
                                        />
                                    </div>
                                    <div className="fr_tipo_asentamiento">
                                        <label for="tipoAsentamiento">
                                            <h6>Tipo de Asentamiento</h6>
                                        </label>
                                        <input
                                            disabled={true}
                                            placeholder="Tipo de Asentamiento"
                                            name={"tipoAsentamiento"}
                                            value={tipoAsentamiento}
                                            onChange={e => {
                                                handleChange(e);
                                            }}
                                        />
                                    </div>
                                </div> */}

                                <div className="cf_fr">
                                    <div className="fr_calle">
                                        <label for="calle">
                                            <h6>Calle*</h6>
                                        </label>
                                        <input
                                            placeholder="Calle*"
                                            name={"calle"}
                                            value={calle}
                                            onChange={e => {
                                                handleChange(e);
                                            }}
                                        />
                                        <label>
                                            <h6
                                                style={{
                                                    color: calleError.error
                                                        ? "red"
                                                        : "var(--secondaryColor)"
                                                }}
                                            >
                                                {calleError.error
                                                    ? calleError.mensaje
                                                    : ""}
                                            </h6>
                                        </label>
                                    </div>
                                    <div className="fr_numero_exterior">
                                        <label for="numeroExterior">
                                            <h6>Número Exterior*</h6>
                                        </label>
                                        <input
                                            placeholder="Número Exterior"
                                            name={"numeroExterior"}
                                            value={numeroExterior}
                                            onChange={e => {
                                                handleChange(e);
                                            }}
                                        />
                                        <label>
                                            <h6
                                                style={{
                                                    color: numeroExteriorError.error
                                                        ? "red"
                                                        : "var(--secondaryColor)"
                                                }}
                                            >
                                                {numeroExteriorError.error
                                                    ? numeroExteriorError.mensaje
                                                    : ""}
                                            </h6>
                                        </label>
                                    </div>
                                </div>

                                <div className="cf_fr">
                                    <div className="fr_numero_interior">
                                        <label for="numeroInterior">
                                            <h6>Número Interior</h6>
                                        </label>
                                        <input
                                            placeholder="Número Interior"
                                            name={"numeroInterior"}
                                            value={numeroInterior}
                                            onChange={e => {
                                                handleChange(e);
                                            }}
                                        />
                                        <label>
                                            <h6
                                                style={{
                                                    color: numeroInteriorError.error
                                                        ? "red"
                                                        : "var(--secondaryColor)"
                                                }}
                                            >
                                                {numeroInteriorError.error
                                                    ? numeroInteriorError.mensaje
                                                    : ""}
                                            </h6>
                                        </label>
                                    </div>
                                    <div className="fr_entre_calle">
                                        <label for="entreCalle">
                                            <h6>Entre Calle*</h6>
                                        </label>
                                        <input
                                            placeholder="Entre Calle*"
                                            name={"entreCalle"}
                                            value={entreCalle}
                                            onChange={e => {
                                                handleChange(e);
                                            }}
                                        />
                                        <label>
                                            <h6
                                                style={{
                                                    color: entreCalleError.error
                                                        ? "red"
                                                        : "var(--secondaryColor)"
                                                }}
                                            >
                                                {entreCalleError.error
                                                    ? entreCalleError.mensaje
                                                    : ""}
                                            </h6>
                                        </label>
                                    </div>
                                </div>

                                <div className="cf_fr">
                                    <div className="fr_y_calle">
                                        <label for="yCalle">
                                            <h6>Y Calle*</h6>
                                        </label>
                                        <input
                                            placeholder="Y calle*"
                                            name={"yCalle"}
                                            value={yCalle}
                                            onChange={e => {
                                                handleChange(e);
                                            }}
                                        />
                                        <label>
                                            <h6
                                                style={{
                                                    color: yCalleError.error
                                                        ? "red"
                                                        : "var(--secondaryColor)"
                                                }}
                                            >
                                                {yCalleError.error
                                                    ? yCalleError.mensaje
                                                    : ""}
                                            </h6>
                                        </label>
                                    </div>
                                    <div className="fr_celular">
                                        <label for="celular">
                                            <h6>Celular*</h6>
                                        </label>
                                        <input
                                            placeholder="Celular*"
                                            name={"celular"}
                                            value={celular}
                                            onChange={e => {
                                                handleChange(e);
                                            }}
                                        />
                                        <label>
                                            <h6
                                                style={{
                                                    color: celularError.error
                                                        ? "red"
                                                        : "var(--secondaryColor)"
                                                }}
                                            >
                                                {celularError.error
                                                    ? celularError.mensaje
                                                    : ""}
                                            </h6>
                                        </label>
                                    </div>
                                </div>

                                <div className="cf_fr">
                                    <div className="fr_tel_particular">
                                        <label for="telefonoParticular">
                                            <h6>Tel. Particular</h6>
                                        </label>
                                        <input
                                            placeholder="Tel. Particular"
                                            name={"telefonoParticular"}
                                            value={telefonoParticular}
                                            onChange={e => {
                                                handleChange(e);
                                            }}
                                        />
                                        <label>
                                            <h6
                                                style={{
                                                    color: telefonoParticularError.error
                                                        ? "red"
                                                        : "var(--secondaryColor)"
                                                }}
                                            >
                                                {telefonoParticularError.error
                                                    ? telefonoParticularError.mensaje
                                                    : ""}
                                            </h6>
                                        </label>
                                    </div>
                                    <div className="fr_tel_oficina">
                                        <label for="telefonoOficina">
                                            <h6>Tel. Oficina</h6>
                                        </label>
                                        <input
                                            placeholder="Tel. Oficina"
                                            name={"telefonoOficina"}
                                            value={telefonoOficina}
                                            onChange={e => {
                                                handleChange(e);
                                            }}
                                        />
                                        <label>
                                            <h6
                                                style={{
                                                    color: telefonoOficinaError.error
                                                        ? "red"
                                                        : "var(--secondaryColor)"
                                                }}
                                            >
                                                {telefonoOficinaError.error
                                                    ? telefonoOficinaError.mensaje
                                                    : ""}
                                            </h6>
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </>
                    )
                ) : (
                    <div className="c_seleccion_sucursal">
                        <textarea
                            value={""}
                            disabled={true}
                            placeholder={
                                direccionesSuc.listado[0].direccionFormateada
                            }
                        />
                    </div>
                )}

                {direcciones.length > 0 && nuevaDireccion == 1 || tipoEntrega == 2 ? (
                    <div className="c_acciones_formulario_pedido">
                        <div
                            className="button_regresar_formulario_pedido"
                            onClick={() => {
                                history.push("/carrito");
                            }}
                        >
                            <h6>REGRESAR</h6>
                            {vectorArrowL}
                        </div>

                        <div
                            className="button_continuar_formulario_pedido"
                            onClick={() => {
                                direccionSelect == "" && tipoEntrega == 1
                                    ? setErrorStateSeleccionDireccion({
                                        ...seleccionDireccion,
                                        seleccionDireccion: { error: true }
                                    })
                                    : nuevaDireccion == 2
                                        ? setNuevaDireccion(1)
                                        : setContinuarCompra(true);
                            }}
                        >
                            <h6> CONTINUAR</h6>
                            {vectorArrowR}
                        </div>
                    </div>
                ) : (
                    <div className="c_acciones_formulario_agregar_direccion">
                        <div
                            className="button_regresar_seccion"
                            onClick={() => {
                                setNuevaDireccion(1);
                                setState({ ...clearState });
                                setErrorState({ ...cleanErrorState });
                            }}
                            style={{ display: direcciones.length > 0 ? 'flex' : 'none' }}
                        >
                            <h6>
                                REGRESAR A SELECCION
                            </h6>
                            {vectorArrowL}
                        </div>

                        <div
                            className="button_guardar_direccion"
                            onClick={() => {
                                ftGuardar();
                            }}
                        >
                            <h6>
                                GUARDAR DIRECCION
                            </h6>
                            {vectorSaveIcon}
                        </div>
                    </div>
                )}
                
                {
                loader ? 
                <LoaderLogoSoftwone2/> :
                <></>
            }
            </div>
        </>
    );
}
