
import { FetchAPI } from "@renedelangel/helpers";
import { getDetallePedido } from "querys/SeguimientoPedido";
import { urlServidor, strUrlSArchivos } from '../../helpers';
import noImage from '../../_layout/assets/svg/undraw_blank_canvas_3rbb.svg';

//CONSULTAS


export const getCompra = async (variables, token) => {

    let query = `query getCompra($compraID: ID!){
        getCompra(id: $compraID){              
                ventaID,
                usuarioID,
                nombreUsuario,
                usuario, 
                metodoPago,
                tipoTarjeta
                subtotalCompra,
                costoPaqueteria,
                totalCompra,
                fechaAlta,
                fechaCompra,
                numeroGuia,
                fotoPaquete,
                tipoEntrega,
                direccionEnvio,
                direccionFacturacion,
                paqueteria,
                estatus,
                nomEstatus,
                paqueteriaDescripcion,
                ultimos4Tarjeta,               
                reembolsoID
                                    
            }
        }`;
    return await ejecutar(variables, token, query);
}
//MUTATION
export const actualizaEstatusCompra = async (variables, token) => {

    let query = `mutation actualizaEstatusCompra($input: InputActualizaEstatusCompra!) {
        actualizaEstatusCompra(input: $input){
        ventaID
        usuarioID
        subtotalCompra
        costoPaqueteria
        totalCompra
        fechaCompra
        numeroGuia
        direccionEnvio
        cuentaCteID
       }
    }`;

    return await ejecutar(variables, token, query);
}
export const upsertCompra = async (variables, token) => {

    let query = `mutation upsertCompras($input: InputUpsertCompras!) {
        upsertCompras(input: $input){
        ventaID
        usuarioID
        subtotalCompra
        costoPaqueteria
        totalCompra
        fechaCompra
        numeroGuia
        direccionEnvio
        cuentaCteID
       }
    }
    `;
    return await ejecutar(variables, token, query);
}
// FIN MUTATION
export const getDetalleCompra = async (variables, token) => {

    let query = `query getDetalleCompra($compraID: ID!){
        getDetalleCompra(id: $compraID){              
            ventaID
            partida
            productoID
            nombre
            descripcion            
            cantidad
            precioUnitario
            IVA
            costoTotal
            imagenPortada  
            servicio
            inventariable             
        }
    }`;
    let resultado = await ejecutar(variables, token, query);
    let datos = []
    datos = resultado.map(({ imagenPortada, costoTotal, ...rest }) => {
        let portada = imagenPortada ? `${strUrlSArchivos}${'/public/tonline'}${imagenPortada}${'?'}${Math.random()}` : noImage;

        return {
            ...rest,
            imagenPortada: portada,
            precioVentaOnline: costoTotal
        }
    })

    return datos;

}
export const tablaCompras = async (variables, token) => {

    let query = `query filterCompra($filtro: InputFilterCompra!){
        filterCompra(filtro: $filtro){     
            registros
            paginas
            listado {         
                ventaID
                usuarioID
                estatus
                fechaCompra
                nombreUsuario
                usuario
                subtotalCompra
                costoPaqueteria
                totalCompra
                nomEstatus
                tipoEntrega
                nomEntrega
                numeroGuia
                reembolsoID
                pagoDomiciliado
            }
        }
    }`;
    let resultado = await ejecutar(variables, token, query);

    return resultado;

}


export const ejecutar = async (variables, token, query) => {
    try {
        let respuesta = await FetchAPI.graphql({
            token,
            variables,
            url: urlServidor,
            query: query
        });
        return respuesta[Object.keys(respuesta)[0]];

    } catch ({ message }) { throw new Error(message); }
}

