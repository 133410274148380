import React from 'react';
import PropTypes from "prop-types";

// core components
import GridContainer from "../../components/Grid/GridContainer.js";
import GridItem from "../../components/Grid/GridItem.js";
import Paginations from "../../components/Pagination/Pagination.js";

// generic components
import Inputs from "../Inputs"
import CardItemImage from '../CardItemImage';
import CardMedia from '@material-ui/core/CardMedia';
import { makeStyles } from "@material-ui/core/styles";

import Center from "../Center";

import { ftPaginacionGetComponentData } from "../../helpers/components";

const styles = {
  cardProductList: {
    // margin: "0 -15px",
     paddingTop: "10px",
     //width: "calc(100% + 30px)"
    // '&:before,&:after':{
    //   display: 'table',
    //   content: '" "',
    // },
    // '&:after':{
    //   clear: 'both',
    // }
  }
};
const useStyles = makeStyles(styles);

function CardListItemImage(props) {

    let { data, paginacion, paginaActiva = 1, color = "info", ocultarListaRegistros, handleRegistros = (evt) => console.log(evt), leyenda, leyenda2 } = props, pages = [];
    const classes = useStyles();

    let grid = { sm: 12, md: 12, md: 12, lg: 12 };
    let container = {
        direction: "row",
        justify: "center",
        alignItems: "center"
    };

    if(typeof paginacion === "object") pages = ftPaginacionGetComponentData(paginacion, paginaActiva);

    let titleRegistros = "Máximo número de registros a mostrar";

    const registros = {
        id: "registrosTabla",
        tipo: "select",
        title: titleRegistros,
        data: [
            {
                value: undefined,
                disabled: true,
                label: titleRegistros
            },
            { value: 16, label: "16 registros" },
            { value: 32, label: "32 registros" },
            { value: 48, label: "48 registros" },
            { value: 96, label: "96 registros" },
            { value: 120, label: "120 registros" }
        ]
    };

    return (
        <GridContainer>
            {
                Array.isArray(data) && data.length > 0 && data.map(({ grid = {}, ...rest}, key) =>
                <GridItem className={classes.cardProductList} { ...grid } key={key}>
                    <CardItemImage { ...rest } />
                </GridItem>)
            }
            {
                !ocultarListaRegistros && <GridContainer
                    direction={container.direction}
                    justify={container.justify}
                    alignItems={container.alignItems}
                >
                    <Inputs
                        id={registros.id}
                        tipo={registros.tipo}
                        title={registros.title}
                        value={paginacion.limite}
                        grid={{ xs: 12, sm: 7, md: 4, lg: 3 }}
                        data={registros.data}
                        onChange={handleRegistros}
                    />
                </GridContainer>
            }
            {
                (paginacion && paginacion.paginas > 1) && <GridItem { ...grid } ><Center>
                    <Paginations
                        pages={pages}
                        color={color}
                    />
                </Center></GridItem>
            }
        </GridContainer>
    )
}

CardListItemImage.propTypes = {
    data: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.string.isRequired,
        title: PropTypes.string.isRequired,
        imagen: PropTypes.string.isRequired,
        descripcion: PropTypes.string,
        onClick: PropTypes.func,
        onChange: PropTypes.func,
        textFooterLeft: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
        textFooterRight: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
        grid: PropTypes.shape({
            xs: PropTypes.number,
            sm: PropTypes.number,
            md: PropTypes.number,
            lg: PropTypes.number
        }),
        actions: PropTypes.arrayOf(PropTypes.shape({
            color: PropTypes.oneOf(["transparent", "danger", "info", "warning", "success"]).isRequired,
            descripcion: PropTypes.string.isRequired,
            icono: PropTypes.object.isRequired
        }))
    })).isRequired,
    paginacion: PropTypes.object
};

export default CardListItemImage;
