import {
  primaryColor,
  grayColor,
  dangerColor,
  whiteColor,
  warningColor,
} from "../../material-dashboard-pro-react.js";

const productoStyle =  {
  root: {
    flexGrow: 1,
    marginBottom: "20px"
  },
  expansionPanel: {
    boxShadow: "none",
    "&:before": {
      display: "none !important"
    }
  },
  panelProducto:{
    width: "100%",
    marginTop: "10px",
    marginBottom: "10px",
    flexDirection: "row",
    fontFamily: '"sans-serif",Helvetica,Arial,sans-serif',
    borderRadius: "unset"

  },
  panelImagenes: { 
    //bakcgroundColor: "red",
    alignItems: "center",
    "@media (min-width: 300px)": {
        width:"100%",
        maxheight: "800px",
    },
    "@media (min-width: 992px)": {
        width:"100%",
        maxheight: "1500px",
    },
    "@media (min-width: 1200px)": {
        width:'calc(40% - 10px)',
        maxheight: "1500px",
        marginLeft: "10px",
    },
  },

  panelDetalle: {   
    borderStyle: "solid 1px",
    height: "auto",
    fontSize: "12px",
    width:"100%",
    padding: "10px",
    "@media (max-width: 300px)": {
        width:"100%",
        fontSize:"12px",
        padding:"10px",
    },
    "@media (min-width: 992px)": {
        width:"100%",
        fontSize:"12px",
        padding:"20px",
    },
    "@media (min-width: 1200px)": {
        width:'calc(60% - 10px)',
        fontSize:"12px",
        padding:"25px",
        marginLeft: "10px",
    },
  },

  titulo: {
    paddingTop: "0px",
    "@media (min-width: 300px)": {
      fontSize: "15px",
      fontWeight: "600",
    },
    "@media (min-width: 992px)": {
      fontSize:"20px",
      fontWeight: "400",
    },
    "@media (min-width: 1200px)": {
        fontSize:"25px",
        fontWeight: "400",
    },
  },
  link: { 
    fontSize:"14px",
    fontWeight: "400",
    
    fontColor: "blue",
    "@media (max-width: 300px)": {
      fontSize: "80px",
    },    
  },
  precioDetalle: {
    color:"red",
    "@media (min-width: 300px)": {
      fontSize: "15px",
      fontWeight: "400",
      paddingTop: "0px",
      paddingBottom: "5px",
    },
    "@media (min-width: 992px)": {
      fontSize:"20px",
      fontWeight: "300",
      paddingTop: "5px",
      paddingBottom: "5px",
    },
    "@media (min-width: 1200px)": {
       fontSize:"30px",
       fontWeight: "400",
       paddingTop: "12px",
       paddingBottom: "12px",
    },

  },
  etiqueta:{
     fontWeight: "bold"
  },
  descripcion: {
    padding: "0px",
    fontFamily: '"sans-serif",Helvetica,Arial,sans-serif',
    fontColor: grayColor[0],
    
    "@media (min-width: 300px)": {
    fontSize:"12px",
    fontWeight: "350",
    padding: "unset"
    },
    "@media (min-width: 992px)": {
    fontSize:"10px",
    fontWeight: "350",
    },
    "@media (min-width: 1200px)": {
    fontSize:"12px",
    fontWeight: "300",
    },
  },
  
  cantidadCarrito: {
    textAlign: "center",
    height: "40px",
    width: "60px",  
    padding: "unset",
    marginLeft: "0px",   
    border: "0px 0px",
    "@media (min-width: 300px)": {      
       marginLeft: "5px",                 
       "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
        "-webkit-appearance": "none"},
    },
    "@media (min-width: 992px)": {      
       marginLeft: "5px",          
    },
    "@media (min-width: 992px)": {      
       marginLeft: "5px",          
    },
    "@media (min-width: 1200px)": {      
       marginLeft: "0px",          
    },
  },
  botonReducir: {
    height: "40px",
    border: "1px solid",
    borderColor:  grayColor[0],
    borderRadius: "0%",
    minWidth: "unset",
    marginLeft: "10px",
    width: "40px",
    "@media (min-width: 300px)": {
        display:"none"
    },
    "@media (min-width: 992px)": {
        display:"none"
    },
    "@media (min-width: 1200px)": {
        display: "unset"
    },
  },
  botonIncremento: {
    height: "40px",
    border: "1px solid",
    borderColor:  grayColor[0],
    borderRadius: "0%",
    minWidth: "unset",
    width: "40px",
    "@media (min-width: 300px)": {
        display:"none"
    },
    "@media (min-width: 992px)": {
        display:"none"
    },
    "@media (min-width: 1200px)": {
        display: "unset"
    },
  },
  acciones:{
      heigth: "40px",
      marginLeft: "5px",
      marginRight: "5px",    
      alignItems: "center",
      width: "100%",    
  },
  cantidades:{
      heigth: "40px",
      marginLeft: "5px",
      marginRight: "5px",    
      alignItems: "center",
      width: "40%",      
  },
  botonAgregarCarrito:{
    marginLeft: "10px",
    marginRight: "10px",
    alignItems: "center",
    backgroundColor: dangerColor[1],
    fontColor: "white",
    borderRadius: "5px",
    "&:hover": {
    backgroundColor: dangerColor[0],
    color: whiteColor,
    },
    "@media (min-width: 300px)": {
      display:"none"
    },
    "@media (min-width: 992px)": {
      display: "none"
    },
    "@media (min-width: 1200px)": {
      display:"unset",

    },
  },
  botonAgregarResponsivo:{
    marginLeft: "5px",
    marginRight: "5px",
    marginBotton: "15px",
    width: "80px",
    heigth:"auto",
    backgroundColor: dangerColor[1],
    fontColor: "white",
    maxHeight: "unset",
    borderRadius: "5px",
    padding:"unset",
    fontsize:"10px",
    "&:hover": {
    backgroundColor: dangerColor[0],
    color: whiteColor,
    },
    "@media (min-width: 300px)": {
        padding:"8px",
        verticalAlign:"middle"
    },
    "@media (min-width: 992px)": {
        padding:"9px",
        verticalAlign:"middle"
    },
    "@media (min-width: 1200px)": {
        display: "none"
    },
  },
  borderIcon:{
      borderColor: dangerColor[1],

  },
  favorite:{
      backgroundColor: dangerColor[1]
  },
  panelAlternativos: {
    height: "auto",
    marginTop: "5px",
    marginLeft: "10px",
    padding: "15px",
    width: "100%",

  },
   tituloAlternativos: {
    padding: "10px",
    fontSize: "20px",
    fontWeight: "500"
  },


};

export default productoStyle;
