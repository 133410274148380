import { FetchAPI } from "@renedelangel/helpers";
import { NetworkError } from "_layout/genericComponents/Metodos";
import { urlServidor } from '../../helpers';


export const getRegimenFiscal = async (variables, token) => {

    let query = `query getRegimenFiscal($clave: ID!){
        getRegimenFiscal(id: $clave){              
            regimenFiscalID
            clave
            descripcion
            fisica
            moral                                        
            }
        }`;
    return await ejecutar(variables, token, query);
}

export const getAllRegimenFiscal = async (variables, token) => {

    let query = `query getAllRegimenFiscal{
        getAllRegimenFiscal{              
            regimenFiscalID
            clave
            descripcion
            fisica
            moral                                        
            }
        }`;
    return await ejecutar(variables, token, query);
}

export const getUsoCfdi = async (variables, token) => {

    let query = `query getUsoCFDI($clave: ID!){
        getUsoCFDI(id: $clave){              
            usoCFDIID 
            claveSAT 
            descripcion 
            pfisica 
            pmoral                                   
            }
        }`;
    return await ejecutar(variables, token, query);
}

export const getAllUsoCfdi = async (variables, token) => {

    let query = `query getAllUsoCFDI{
        getAllUsoCFDI{              
            usoCFDIID 
            claveSAT
            descripcion 
            pfisica 
            pmoral                                        
            }
        }`;
    return await ejecutar(variables, token, query);
}


export const selectRegimenFiscal  = async (variables, token) => {
    try {

        let regimenes = await getAllRegimenFiscal(variables, token);

        if(!Array.isArray(regimenes) || regimenes.length === 0) throw new Error("No se encontraron resultados")

        return regimenes.map(({ clave:value, descripcion:label }) => ({ value, label }));

    } catch({message:label}) {
        return [{
            label: NetworkError(label),
            value: null,
            disabled: true
        }]
    }
}

export const ejecutar = async (variables, token, query) => {
    try {
    
        let  respuesta  = await FetchAPI.graphql({
            token,
            variables,
            url: urlServidor,
            query: query
        });     
        return respuesta[Object.keys(respuesta)[0]];

    } catch({message}) { throw new Error(message); }
}
